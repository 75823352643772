import { StyleSheet } from 'react-native'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  return StyleSheet.create({
    textGreen: {
      textAlign: 'center',
      color: colors.success,
    },
    textRed: {
      textAlign: 'center',
      color: colors.error,
    },
  })
}
export default useStyles
