import React from 'react'

//import i18n from '../../locales/i18n'
import Main from './pages/Main'

export default () => [
  {
    title: 'Upselling' /*  i18n.t('title.more') */,
    name: 'upselling',
    route: {
      exact: true,
      path: '/upselling',
      children: <Main />,
    },
  },
]
