import { Platform, StyleSheet } from 'react-native'
import { usePrimaryBackground, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import colors from '../../../../../../constants/colors'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      borderRadius: 16,
      padding: 8,
      marginVertical: 8,
    },

    wrapperFilter: {
      position: 'absolute',
      backgroundColor: usePrimaryBackground(),
      zIndex: 1,
      alignSelf: 'flex-end',
      borderRadius: 16,
      padding: 8,
      top: 14,
      right: 4,
      borderColor: useTextColor(),
      borderWidth: 0.5,
    },

    switch: {
      marginBottom: Platform.select({ default: undefined, ios: 12 }),
      marginTop: Platform.select({ default: undefined, ios: -8 }),
      marginRight: Platform.select({ default: undefined, ios: 4 }),
    },

    filterOpenButton: {
      marginTop: 4,
      marginRight: 4,
    },

    closeFilter: {
      alignItems: 'flex-end',
    },

    wrapperVisitorInfoColumn: {
      flexDirection: 'column',
      marginLeft: 8,
      flex: 1,
    },

    wrapperVisitorInfoRow: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginTop: 8,
    },

    avatar: {
      borderRadius: 44,
      height: 88,
      width: 88,
      alignSelf: 'center',
    },

    toggleSessions: {
      marginTop: 4,
    },

    toggleSessionsButton: {
      marginTop: Platform.select({ default: 4, ios: undefined }),
      marginLeft: 4,
    },

    toggleVisible: {
      marginTop: 4,
      marginBottom: -8,
      marginRight: 4,
      alignItems: 'flex-end',
    },

    marginVertical1: {
      marginVertical: Platform.select({ default: undefined, web: 1 }),
    },

    marginLeft4: {
      marginLeft: Platform.select({ default: undefined, web: 4 }),
    },

    marginLeft8: {
      marginLeft: Platform.select({ default: undefined, web: 8 }),
    },

    flexDirectionRow: {
      flexDirection: 'row',
    },

    flexDirectionColumn: {
      flexDirection: 'column',
    },

    fontSize16: {
      fontSize: 16,
    },

    textAlignVertical: {
      textAlignVertical: 'center',
    },

    flex1: {
      flex: 1,
    },

    countryFlag: {
      fontSize: 24,
      marginRight: 4,
    },

    greyText: {
      color: useTextColor(),
    },

    fontAwesomeColumn: {
      alignSelf: 'center',
      marginRight: 4,
    },

    linkColor: {
      color: colors.primary,
      flex: 1,
    },

    filterRow: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 2,
    },

    userDetails: {
      flexDirection: 'row',
      paddingTop: 8,
    },

    fontPaddingLeft8: {
      paddingLeft: 8,
    },
  })
}
export default useStyles
