import { Reducer, useReducer } from 'react'
import { ApiLoadingTypes } from '../../types/api/types'

type ReducerT<Data> = {
  loading: boolean
  error?: Error
  data?: Data
}

const useApiLoading = <Data>() =>
  useReducer<
    Reducer<
      Readonly<ReducerT<Data>>,
      {
        type: ApiLoadingTypes
        payload?: {
          error?: Error
          data?: Data
        }
      }
    >
  >(
    (state, { type, payload: { data, error } = {} }) => {
      switch (type) {
        case ApiLoadingTypes.IS_LOADING:
          return { ...state, error: null, loading: true }
        case ApiLoadingTypes.HAS_ERROR:
          return { ...state, loading: false, error }
        case ApiLoadingTypes.HAS_DATA:
          return {
            ...state,
            loading: false,
            error: null,
            data: data,
          }
        default:
          throw new Error(`action type '${type}' not defined`)
      }
    },
    {
      loading: false,
      error: null,
      data: null,
    }
  )

export default useApiLoading
