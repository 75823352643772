import { useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { StyleSheet } from 'react-native'
import colors from '../../../../../../constants/colors'
import sizes from '../../../../../../constants/sizes'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      borderRadius: 15,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-start',
    },
    contentWrapper: {
      width: '100%',
      padding: 30,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    divider: {
      width: '100%',
      backgroundColor: theme === Themes.Dark ? colors.lightBg1 : colors.darkBg1,
      height: 1,
      minHeight: 1,
      maxHeight: 1,
    },
    itemsWrapper: {
      width: '50%',
      maxWidth: '50%',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    item: {
      width: '60%',
      marginVertical: 4,
    },
    heading: {
      color: colors.headingColor,
      width: '25%',
    },
    button: {
      backgroundColor: useHighlightColor(),
      width: '100%',
      marginVertical: 20,
      borderRadius: 10,
      height: sizes.buttonHeight,
    },
    dialogButton: {
      backgroundColor: useHighlightColor(),
      width: '100%',
      marginVertical: 5,
      borderRadius: 15,
      height: sizes.buttonHeight,
    },
    cancelButton: {
      borderRadius: 15,
      borderColor: useHighlightColor(),
      borderWidth: 1,
      height: sizes.buttonHeight,
      width: '100%',
    },
    modalWrapper: {
      width: '100%',
      paddingHorizontal: 30,
      paddingVertical: 10,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    modalFormWrapper: {
      width: '50%',
    },
    paymentMethodWrapper: {
      width: '60%',
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  })
}

export default useStyles
