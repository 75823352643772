import { usePrimaryBackground, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Platform, StyleSheet } from 'react-native'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      marginHorizontal: 16,
      marginVertical: 8,
      padding: Platform.select({ default: 8, web: 32 }),
      borderRadius: 16,
      height: Platform.select({ default: 336, web: 376 }),
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
    },
    noUser: {
      textAlign: 'center',
      fontSize: 24,
      marginTop: 96,
    },
    title: {
      padding: 5,
      fontSize: 18,
    },
    list: {
      backgroundColor: 'transparent',
      borderRadius: 10,
    },
    listItemTitle: {
      fontSize: 16,
      marginBottom: 8,
    },
    listItemIcon: {
      alignSelf: 'center',
      marginBottom: 8,
    },
    listItemInfoWrapper: {
      flexDirection: 'column',
      marginHorizontal: 8,
      marginVertical: 8,
      padding: 8,
      height: 112,
      width: 232,
      backgroundColor: theme === Themes.Dark ? colors.lightBgTransparent1 : colors.lightBgTransparent1,
      borderRadius: 16,
      justifyContent: 'space-evenly',
    },
    infoIcons: {
      alignSelf: 'center',
      marginRight: 8,
    },
    infoText: {
      flex: 1,
      color: colors.primary,
      marginLeft: Platform.select({ default: undefined, web: 8 }),
    },
    infoCurrency: {
      marginLeft: Platform.select({ default: undefined, web: 8 }),
    },
    rowWrapper: {
      flexDirection: 'row',
    },
    fontSize24: {
      fontSize: 24,
    },
    flex1: {
      flex: 1,
    },
    textColor: {
      color: useTextColor(),
    },
    avatar: {
      height: 80,
      width: 80,
      borderRadius: 40,
      borderWidth: 1,
      marginHorizontal: 16,
      borderColor: usePrimaryBackground(),
    },
    customer: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.darkBgTransparent6,
      padding: 8,
      marginHorizontal: 2.5,
      borderRadius: 16,
      overflow: 'hidden',
      alignItems: 'center',
      width: 240,
    },
  })
}

export default useStyles
