import { usePrimaryBackground, useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { Platform, StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    listwrapper: {
      alignSelf: 'center',
      width: '100%',
      maxWidth: Platform.select({ web: 598 }),
    },
    filteredNewsWrapper: {
      width: '100%',
      alignSelf: 'center',
      alignItems: 'center',
      backgroundColor: usePrimaryBackground(),
    },
    headerWrapper: {
      flexDirection: 'row',
      width: '100%',
      overflow: 'hidden',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: usePrimaryBackground(),
    },
    hashtagWrapper: {
      flexDirection: 'row',
    },
    hashtag: {
      fontSize: 20,
      backgroundColor: useSecondaryBackground(),
      borderRadius: 16,
      paddingRight: 15,
      marginTop: 4,
      marginBottom: 4,
      padding: 5,
    },
    hashtagBackButton: {
      position: 'absolute',
      right: -5,
    },
    wrapper: {},
    text: {
      alignSelf: 'center',
      fontSize: 20,
    },
  })
}

export default useStyles
