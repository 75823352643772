import { useCallback } from 'react'
import { useFirestore } from 'react-redux-firebase'
import useAuth from '@webstollen/react-native/lib/hooks/useAuth'
import { Favorite } from '../../types/Favorites'

const useAddFavorite = () => {
  const firestore = useFirestore()

  const auth = useAuth()

  return useCallback(
    async (favorite: Favorite) => {
      await firestore.collection(`user/${auth.uid}/favorites`).add(favorite)
    },
    [auth, firestore]
  )
}

export default useAddFavorite
