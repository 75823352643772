import { StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: 'transparent',
    },
    container: {
      flex: 1,
      width: '100%',
      alignItems: 'stretch',
      justifyContent: 'flex-end',
      marginBottom: 5,
    },
  })
}

export default useStyles
