import { IShop } from '@dash.bar/types'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { View } from 'native-base'
import React from 'react'
import Avatar from '../../../../../../../components/Avatar'
import useStyles from './styles'

const ShopView = ({ shop }: { shop: IShop }) => {
  const styles = useStyles()

  return (
    <View style={styles.shopViewWrapper}>
      <Avatar
        style={[styles.avatar]}
        photoURL={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.${shop?.domain}&size=128`}
      />
      <ColoredText style={styles.name}>{`${shop?.domain}`}</ColoredText>
    </View>
  )
}

export default ShopView
