import React from 'react'
import { ScrollView } from 'react-native'
import { IShop } from '@dash.bar/types'
import LiveVisitorView from '../../../component/LiveVisitor/v1'

type PropsT = {
  shop: IShop
  visitorId: string
}

const LiveVisitorV1 = ({ shop, visitorId }: PropsT) => {
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <LiveVisitorView shop={shop} visitorId={visitorId} />
    </ScrollView>
  )
}

export default LiveVisitorV1
