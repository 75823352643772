import React from 'react'
import { ChildrenProps } from '../../types/chart'
import colors from '../../constants/colors'
import { View } from 'react-native'
import { BarChart as ChartsBarChart, LineChart as ChartsLineChart } from 'react-native-svg-charts'
import { Defs, LinearGradient, Stop } from 'react-native-svg'

import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from 'react-i18next'

import { curveNatural } from 'd3-shape'
import numbro from 'numbro'
import useStyles from './styles'

type Props = {
  testID?: string
  list: Array<{ rate: number; sale: number; visitors: number }>
  contentInset?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  size: {
    width: number
    height: number
  }
}

const Gradient = (_: ChildrenProps) => (
  <Defs>
    <LinearGradient id={'gradient'} x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
      <Stop offset={'0%'} stopColor={`${colors.color2}`} stopOpacity={0.6} />
      <Stop offset={'100%'} stopColor={`${colors.color2}`} stopOpacity={0.1} />
    </LinearGradient>
  </Defs>
)

const MixedCharts = ({
  list,
  contentInset = {
    top: 20,
    bottom: 20,
    left: 5,
    right: 20,
  },
  size,
}: Props) => {
  const styles = useStyles(size)
  const { t } = useTranslation()

  const rateList = list.map(({ rate }) => rate)

  const maxRateList = numbro(Math.max(...rateList)).format({
    average: true,
    totalLength: 3,
  })

  // Normalizes Line Charts
  const visitorsList = list.map(({ visitors }) => visitors)
  const maxVisitorsList = numbro(Math.max(...visitorsList)).format({
    average: true,
    totalLength: 3,
  })
  const normalizedVisitorsList = list.map(({ visitors }) => visitors / Math.max(...visitorsList))

  const saleList = list.map(({ sale }) => sale)
  const maxSaleList = numbro(Math.max(...saleList)).format({
    average: true,
    totalLength: 3,
  })
  const normalizedSaleList = list.map(({ sale }) => sale / Math.max(...saleList))

  const lineChartsData = [
    {
      data: normalizedVisitorsList,
      svg: { strokeWidth: 2, stroke: colors.color1, fill: 'none' },
    },
    {
      data: normalizedSaleList,
      svg: { strokeWidth: 2, stroke: colors.color3, fill: 'none' },
    },
  ]

  return (
    <View style={styles.wrapper}>
      <ChartsBarChart
        style={styles.barChart}
        data={rateList}
        contentInset={contentInset}
        curve={curveNatural}
        svg={{ fill: 'url(#gradient)' }}
        gridMin={0}
        animate
        spacingInner={0.1}
      >
        <Gradient />
      </ChartsBarChart>
      <ChartsLineChart
        style={styles.lineChart}
        data={lineChartsData}
        contentInset={contentInset}
        curve={curveNatural}
        gridMin={0}
        numberOfTicks={4}
      >
        <Gradient />
      </ChartsLineChart>
      <View style={styles.textLabelWrapper}>
        <View style={styles.textLabelWrapper}>
          <View style={[styles.blueBackground, styles.textLabel]} />
          <View style={styles.textWrapper}>
            <ColoredText style={styles.textLabelFont}>{t('shop.widget.conversion.body.conversion')}</ColoredText>
            <ColoredText style={[styles.textLabelFont, styles.textColor]}>max {maxRateList}</ColoredText>
          </View>
        </View>
        <View style={styles.textLabelWrapper}>
          <View style={[styles.yellowBackground, styles.textLabel]} />
          <View style={styles.textWrapper}>
            <ColoredText style={styles.textLabelFont}>{t('shop.widget.conversion.body.sales')}</ColoredText>
            <ColoredText style={[styles.textLabelFont, styles.textColor]}>max {maxSaleList}</ColoredText>
          </View>
        </View>
        <View style={styles.textLabelWrapper}>
          <View style={[styles.purpleBackground, styles.textLabel]} />
          <View style={styles.textWrapper}>
            <ColoredText style={styles.textLabelFont}>{t('shop.widget.conversion.body.visitors')}</ColoredText>
            <ColoredText style={[styles.textLabelFont, styles.textColor]}>max {maxVisitorsList}</ColoredText>
          </View>
        </View>
      </View>
    </View>
  )
}

export default MixedCharts
