export enum BookingState {
  Pending, //Booking was created, but not paid yet
  Active, //Booking has been paid and is active (will extended at the next intervall run)
  Cancled, //Booking is still active but will be deleted after endDate
  Deleted, //Booking has been deleted -> remove booking
}

export type Amount = {
  currency: string
  value: string
}

export type MwstAmount = {
  amount: Amount
  mwst: Amount
  totalAmount: Amount
}

export type AmountDifference = {
  amountLeftTotal: Amount
  amountLeft: Amount
  amountByDay: Amount
  daysTotal: number
  daysLeft: number
}

export enum Interval {
  month = 'month',
  year = 'year',
}

export enum Bundle {
  free = 'free',
  business = 'business',
  pro = 'pro',
}

export type BookingAmounts = {
  netto: Amount
  credit: Amount | null
  nettoWithCredit: Amount | null
  mwst: Amount
  total: Amount
}

export type BundlePrice = {
  month: Amount
  year: Amount
}
