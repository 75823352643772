import React, { ReactElement } from 'react'

import i18n from '../../locales/i18n'

import DashboardHeader from '../dashboard/components/DashboardHeader'

import Main from './pages/Main'
//import Account from './pages/Account'
//import Theme from './pages/Theme'
//import Language from './pages/Language'
import ManageDashboards from './pages/ManageDashboards'
import ManageDashboard from './pages/ManageDashboard'
import { Platform } from 'react-native'
import CreateWidget from './pages/CreateWidget'
import EditWidget from './pages/EditWidget'
//import Company from './pages/Company'
//import NotificationSettings from './pages/NotificationSettings/notificationSettings'
//import CompanyUsers from './pages/CompanyUsers'
import Subscription from './pages/Subscription'
import Details from './pages/Subscription/Components/Details'
//import PaymentScreen from '../upselling/pages/Payment/PaymentScreen'
import Payments from './pages/Subscription/Components/Payments'
//import MandateChange from './pages/Subscription/Components/MandateChange/MandateChange'
import DetailsCurrentBundle from './pages/Subscription/Components/DetailsCurrentBundle/index.native'
import AccountOverview from './pages/AccountOverview'
import Settings from './pages/Settings'
import ContingentWrapper from '../../components/ContingentWrapper'
import { Migration } from './pages/MigrationTest/Migration'

const Routes = () => [
  {
    title: i18n.t('nav.settings'),
    name: '/settings',
    route: {
      exact: true,
      path: '/settings',
      children: <Main />,
    },
  },
  {
    title: i18n.t('nav.subscription'),
    name: '/settings/subscription',
    route: {
      exact: true,
      path: '/settings/subscription',
      children: <Subscription />,
    },
  },
  {
    title: i18n.t('nav.payments'),
    name: '/settings/subscription/payments',
    route: {
      exact: true,
      path: '/settings/subscription/payments',
      children: <Payments />,
    },
  },
  {
    title: i18n.t('nav.update_mandate'),
    name: '/settings/settings',
    route: {
      exact: true,
      path: '/settings/settings',
      children: <Settings />,
    },
  },
  {
    title: i18n.t('nav.subscription'),
    name: '/settings/subscription/current',
    route: {
      exact: true,
      path: '/settings/subscription/current',
      children: <DetailsCurrentBundle />,
    },
  },
  {
    title: i18n.t('nav.subscription'),
    name: '/settings/subscription/:bundle',
    route: {
      exact: true,
      path: '/settings/subscription/:bundle',
      children: <Details />,
    },
  },
  {
    title: i18n.t('nav.account_overview'),
    name: '/settings/accountOverview',
    route: {
      exact: true,
      path: '/settings/accountOverview',
      children: <AccountOverview />,
    },
  },
  {
    title: i18n.t('nav.account_overview'),
    name: '/settings/accountOverview/:invitationCode',
    route: {
      exact: true,
      path: '/settings/accountOverview/:invitationCode',
      children: <AccountOverview />,
    },
  },
  {
    title: 'Migration',
    name: '/settings/migration',
    route: {
      exact: true,
      path: '/settings/migration',
      children: <Migration />,
    },
  },
  /*
  {
    title: i18n.t('nav.company'),
    name: '/settings/company',
    route: {
      exact: true,
      path: '/settings/company',
      children: <Company />,
    },
  },
  {
    title: i18n.t('nav.company_users'),
    name: '/settings/company/users',
    route: {
      exact: true,
      path: '/settings/company/users',
      children: <CompanyUsers />,
    },
  },*/
  {
    title: i18n.t('nav.dashboards'),
    name: '/settings/dashboard',
    route: {
      exact: true,
      path: '/settings/dashboard',
      children: (
        <ContingentWrapper>
          <ManageDashboards />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: Platform.select<ReactElement>({ web: i18n.t('nav.dashboard'), default: <DashboardHeader /> }),
    name: '/settings/dashboard/:dashboardId',
    route: {
      exact: true,
      path: '/settings/dashboard/:dashboardId',
      children: (
        <ContingentWrapper>
          <ManageDashboard />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: i18n.t('nav.create-widget'),
    name: '/settings/dashboard/:dashboardId/create-widget',
    route: {
      exact: true,
      path: '/settings/dashboard/:dashboardId/create-widget',
      children: (
        <ContingentWrapper>
          <CreateWidget />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: Platform.select<ReactElement>({ web: i18n.t('nav.edit-widget'), default: i18n.t('nav.edit-widget') }),
    name: '/settings/dashboard/:dashboardId/edit-widget',
    route: {
      exact: true,
      path: '/settings/dashboard/:dashboardId/edit-widget/:widgetId',
      children: (
        <ContingentWrapper>
          <EditWidget />
        </ContingentWrapper>
      ),
    },
  },
]

export default Routes
