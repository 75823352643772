import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SessionContext } from '../../context/SessionContext'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { ImageBackground, View } from 'react-native'
import Routes from '@webstollen/react-native/lib/routes/Routes'
import useStyles from './styles'
import { Redirect, useHistory } from '@webstollen/react-native/lib/react-router'
import Header from '../Header'
import Sidebar from '../Sidebar'
import NotificationRecivedDialog from '../../hooks/notifications/components/notificationRecivedDialog'
import registerForPushNotificationsAsync, {
  NotificationStateType,
  registerNotificationListener,
} from '../../hooks/notifications/registerForPushNotificationsAsync'
import Footer from '../Footer'
import allRoutes from './../../routes/module-routes'
import { NotificationsCounterProvider } from '../../hooks/NotificationsCounterProvider'
const BackgroundDark = require('../../assets/imgs/backgrounds/bg_dark.jpg')
const BackgroundLight = require('../../assets/imgs/backgrounds/bg_light.jpg')
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import UpsellingProvider from '../../hooks/UpsellingDialogProvider'
import UpsellingDialog from '../../module/settings/pages/Subscription/Components/Dialogs/UpsellingDialog'

const AppLayout = () => {
  console.debug('(render) AppLayout')

  const history = useHistory()
  //TODO: Build provider to make info acessable in subcomponents without the need of reloading ?
  const { isLoaded, user, currentUser, savePushToken, company } = useContext(SessionContext)
  const styles = useStyles()
  const theme = useTheme()

  const [notificationState, setNotificationState] = useState<NotificationStateType>({
    description: '',
    title: '',
    route: '',
    visible: false,
  })
  const configPush = useCallback(async () => {
    const token = await registerForPushNotificationsAsync()
    if (!token) return undefined
    savePushToken(token)
    registerNotificationListener(history, setNotificationState)
    return token
  }, [setNotificationState, history, savePushToken])

  useEffect(() => {
    console.debug('(useEffect) AppLayout')
    if (isLoaded && currentUser) {
      configPush().then((token?: string) => console.debug('(useEffect) AppLayout : Token ', token))
    }
  }, [isLoaded, currentUser, configPush])

  if (!isLoaded || (isLoaded && (user === undefined || company === undefined))) {
    return <LoadingScreen />
  }

  if (isLoaded && !currentUser) {
    return <Redirect to={'/login'} />
  }

  if (isLoaded && user && !user.company) {
    return <Redirect to={'/no-company'} />
  }

  return (
    <UpsellingProvider>
      <NotificationsCounterProvider>
        <ImageBackground
          source={theme === Themes.Dark ? BackgroundDark : BackgroundLight}
          resizeMode="cover"
          style={styles.backgroundImage}
          blurRadius={70}
        >
          <View style={styles.main}>
            <Header />
            <View style={styles.container}>
              <Sidebar />
              <View style={styles.mainWrapper}>
                <View style={styles.mainBackground} />
                <View style={[styles.mainContent, { width: '100%' }]}>
                  {/*<ScrollView style={styles.mainContent} showsVerticalScrollIndicator={false}> */}
                  <Routes allRoutes={allRoutes} indexRedirect={'/index-redirect'} />
                  <NotificationRecivedDialog
                    dialogIsVisible={notificationState.visible}
                    setDialogVisible={(visible: boolean) =>
                      setNotificationState((prev) => ({
                        ...prev,
                        visible: visible,
                      }))
                    }
                    title={notificationState.title}
                    description={notificationState.description}
                    path={notificationState.route}
                  />
                  {/* </ScrollView>*/}
                </View>
              </View>
            </View>
            <Footer />
          </View>
        </ImageBackground>
      </NotificationsCounterProvider>
      <UpsellingDialog />
    </UpsellingProvider>
  )
}

export default AppLayout
