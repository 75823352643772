import React from 'react'
import { View } from 'react-native'

import useStyles from './styles'
import Logo from '../../components/Logo'

import useAuth from '@webstollen/react-native/lib/hooks/useAuth'
//import NotificationBellComponent from './NotificationButton/bellcomponent'
import { Route, Switch, useLocation } from '@webstollen/react-native/lib/react-router'
import HeaderWrapper from './Wrapper'
import allRoutes from '../../routes/module-routes'

const MyHeader = () => {
  // const links = useHeaderLinks()

  const auth = useAuth()
  const location = useLocation()
  const styles = useStyles()

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <Logo />
        {/* <View style={styles.spacer} /> */}
        {auth.isAuthed && (
          <View style={styles.links}>
            <Switch location={location}>
              {allRoutes.map(({ route: { path, children: _children, ...route }, title, name }) => {
                return (
                  <Route key={name} {...{ ...route, path }}>
                    {typeof title === 'string' ? <HeaderWrapper title={title} /> : title}
                  </Route>
                )
              })}
              <Route path="*" render={({ match }) => <HeaderWrapper title={match.url} />} />
            </Switch>
            {/* <NotificationBellComponent /> */}
          </View>
        )}
      </View>
    </View>
  )
}

export default MyHeader
