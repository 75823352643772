import React from 'react'

import { Platform, Text, TextProps } from 'react-native'

import ReactCountryBanner from 'react-country-flag'

// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397

interface IProps extends TextProps {
  countryCode: string
}

const ReactCountryFlag = ({ countryCode, ...props }: IProps) => {
  if (typeof countryCode !== 'string') {
    return null
  }

  const emoji = countryCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + OFFSET))

  return Platform.select({
    default: <Text {...props}>{emoji}</Text>,
    web: <ReactCountryBanner countryCode={countryCode} svg />,
  })
}

export default ReactCountryFlag
