import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import React from 'react'
import { View } from 'react-native'
import { BarChart as ChartsBarChart } from 'react-native-svg-charts'
import numbro from 'numbro'
import useStyles from './styles'
import colors from '../../../../../../constants/colors'
import LightText from '@webstollen/react-native/lib/components/CustomText/LightText'

type PropsT = {
  data: Array<number>
  title: string
  color: string
}

const BarChartsAnalytics = ({ data, title, color }: PropsT) => {
  const styles = useStyles({ color })

  return (
    <View style={styles.wrapper}>
      <ColoredText style={styles.title}>{title}</ColoredText>
      <View style={styles.barChartWrapper}>
        <ChartsBarChart
          style={{ flex: 1 }}
          data={data}
          gridMin={0}
          spacingInner={0.5}
          spacingOuter={0}
          svg={{ fill: colors.color2 }}
        />
        <View style={styles.xAxisWrapper}>
          {data.map((value, index) => (
            <LightText key={index} style={styles.xAxisLabels}>
              {numbro(value).format({
                mantissa: 1,
                spaceSeparated: true,
                average: true,
              })}
            </LightText>
          ))}
        </View>
      </View>
    </View>
  )
}

export default BarChartsAnalytics
