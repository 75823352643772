import { User } from '@dash.bar/types'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { View } from 'native-base'
import React, { useContext, useRef, useState } from 'react'
import Avatar from '../../../../../../../components/Avatar'
import { DeleteUserDialogContent } from '../../../../../../../components/Booking/DeleteUserDialogContent'
import { SetAdminDialogContent } from '../../../../../../../components/Booking/SetAdminDialogContent'
import { SetOwnerDialogContent } from '../../../../../../../components/Booking/SetOwnerDialogContent'
import Button from '../../../../../../../components/Button'
import ModalPopUp from '../../../../../../../components/Modal'
import { SessionContext } from '../../../../../../../context/SessionContext'
import useStyles from './styles'

const UserView = ({ user, isAdmin, isOwner }: { user: User; isAdmin?: boolean; isOwner?: boolean }) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const [deleteUser, setDeleteUser] = useState<boolean>(false)
  const [changeAdmin, setChangeAdmin] = useState<boolean>(false)
  const [changeOwner, setChangeOwner] = useState<boolean>(false)
  const modalRef = useRef<any>()
  const { currentUser, company } = useContext(SessionContext)

  return (
    <>
      <View style={styles.userViewWrapper}>
        <Avatar
          style={[styles.avatar, isOwner ? styles.owner : isAdmin ? styles.admin : null]}
          photoURL={user?.profileImage}
        />
        <ColoredText style={styles.name}>
          {!user?.firstName && !user?.lastName ? `${user?.email}` : `${user?.firstName} ${user?.lastName}`}
        </ColoredText>
        {company?.admin.includes(currentUser.uid) && !isOwner ? (
          <Button
            style={styles.manageButton}
            onPress={() => {
              if (modalRef && modalRef.current) modalRef.current.setVisible(true)
            }}
          >
            {t('accountOverview.user.manage')}
          </Button>
        ) : null}
      </View>
      <ModalPopUp
        title={
          deleteUser
            ? t('accountOverview.user.delete_user')
            : changeOwner
            ? t('accountOverview.user.promote_owner')
            : changeAdmin
            ? isAdmin
              ? t('accountOverview.user.demote_admin')
              : t('accountOverview.user.promote_admin')
            : t('accountOverview.user.manage')
        }
        ref={modalRef}
      >
        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <View style={styles.deleteModalWrapper}>
            {deleteUser ? (
              <DeleteUserDialogContent modalRef={modalRef} setDeleteUser={setDeleteUser} user={user} />
            ) : changeOwner ? (
              <SetOwnerDialogContent modalRef={modalRef} setSetOwner={setChangeOwner} user={user} />
            ) : changeAdmin ? (
              <SetAdminDialogContent modalRef={modalRef} setSetAdmin={setChangeAdmin} user={user} isAdmin={isAdmin} />
            ) : (
              <>
                {company?.owner === currentUser.uid && !isOwner ? (
                  <Button
                    onPress={() => {
                      setChangeOwner(true)
                    }}
                    textStyle={{ color: 'white' }}
                    style={styles.promoteButton}
                  >
                    {t('accountOverview.user.promote_owner')}
                  </Button>
                ) : null}
                {!isOwner ? (
                  <Button
                    onPress={() => {
                      setChangeAdmin(true)
                    }}
                    textStyle={{ color: 'white' }}
                    style={isAdmin ? styles.deleteButton : styles.promoteButton}
                  >
                    {isAdmin ? t('accountOverview.user.demote_admin') : t('accountOverview.user.promote_admin')}
                  </Button>
                ) : null}
                <Button
                  onPress={() => {
                    setDeleteUser(true)
                  }}
                  textStyle={{ color: 'white' }}
                  style={styles.deleteButton}
                >
                  {t('accountOverview.user.delete')}
                </Button>
                <Button
                  style={styles.cancelButton}
                  onPress={() => {
                    if (modalRef && modalRef.current) modalRef.current.setVisible(false)
                  }}
                >
                  {t('accountOverview.user.cancel')}
                </Button>
              </>
            )}
          </View>
        </View>
      </ModalPopUp>
    </>
  )
}

export default UserView
