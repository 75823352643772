import React from 'react'
import HeaderWrapper from '../../layout/Header/Wrapper'
import i18n from '../../locales/i18n'
import NotificationsList from './notificationsList'

export default () => [
  {
    title: <HeaderWrapper title={i18n.t('nav.notification')} />,
    name: 'notifications',
    route: {
      exact: true,
      path: '/notifications',
      children: <NotificationsList />,
    },
  },
]
