import React, { ReactElement } from 'react'

import i18n from '../../locales/i18n'
import Main from './pages/Main'
import Report from './pages/Report/details'
import FilteredNews from './pages/FilteredNews/index'
import { Platform } from 'react-native'
import FilteredNewsHeader from './components/FilteredNewsHeader'

export default () => [
  {
    title: i18n.t('title.stream'),
    name: 'stream',
    route: {
      exact: true,
      path: '/stream',
      children: <Main />,
    },
  },

  {
    title: i18n.t('title.stream'),
    name: 'report',
    route: {
      exact: true,
      path: '/report',
      children: <Report />,
    },
  },
  {
    title: Platform.select<ReactElement>({ web: i18n.t('title.stream'), default: <FilteredNewsHeader /> }),
    name: 'filteredNews',
    route: {
      exact: true,
      path: '/filteredNews/:hashtag',
      children: <FilteredNews />,
    },
  },
]
