import React, { useEffect, useState } from 'react'
import { IShop } from '@dash.bar/types'
import { ScrollView, View } from 'react-native'

import useStyles from './styles'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import ListView from '../../../../../components/SectionList'

import { DetailedOrderT } from './types'
import OrderListItem from './ListItem'
import useOrders from './useOrders'
import { usePrimaryBackground, useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'

moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: 'LL',
    lastWeek: 'LL',
    nextWeek: 'LL',
    sameElse: 'LL',
  },
})

moment.updateLocale('de', {
  calendar: {
    lastDay: '[Gestern]',
    sameDay: '[Heute]',
    nextDay: 'LL',
    lastWeek: 'LL',
    nextWeek: 'LL',
    sameElse: 'LL',
  },
})
const RefreshControl = () => {
  const backgroundColor = usePrimaryBackground()
  const borderColor = useSecondaryBackground()
  const { t } = useTranslation()
  return (
    <View
      style={{
        position: 'absolute',
        top: 10,
        left: 0,
        right: 0,

        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <LoadingScreen
        text={t('loading.orders')}
        style={{ backgroundColor, paddingVertical: 5, paddingHorizontal: 15, borderWidth: 5, borderColor }}
      />
    </View>
  )
}
const today = moment()

const OrdersV1 = ({ shop }: { shop: IShop }) => {
  const styles = useStyles()

  const { t } = useTranslation()

  const {
    state: { loading, orders },
    nextPage,
  } = useOrders({ shop })

  const [grouped, setGrouped] = useState<Array<{ title: string; data: Array<DetailedOrderT>; index: number }>>()

  useEffect(() => {
    if (orders && Object.keys(orders).length) {
      console.debug(orders)
      const grouped = Object.entries(
        orders.reduce((prevOrders, currentOrder) => {
          const diff = moment(currentOrder.created).calendar(today)

          return {
            ...prevOrders,
            [diff]: [...(prevOrders[diff] || []), currentOrder],
          }
        }, {} as Record<string, Array<DetailedOrderT>>)
      ).map(([title, data], index) => ({ title, data, index }))
      setGrouped(grouped)
      console.debug(grouped)
    }
  }, [orders, shop])

  if (!orders) {
    return <LoadingScreen text={t('loading.orders')} />
  }

  //return <div>X</div>

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        {loading ? <RefreshControl /> : null}
        <ScrollView
          showsVerticalScrollIndicator={false}
          horizontal
          contentContainerStyle={{
            flex: 1,
          }}
        >
          {grouped && (
            <ListView<DetailedOrderT>
              style={styles.listWrapper}
              refreshing={loading}
              renderItem={({ item, index }: { item: DetailedOrderT; index: number }) => {
                return <OrderListItem order={item} index={index} />
              }}
              data={grouped}
              onEndReachedThreshold={0.5}
              onEndReached={() => {
                if (!loading) {
                  nextPage()
                }
              }}
            />
          )}
        </ScrollView>
      </View>
    </View>
  )
}

export default OrdersV1
