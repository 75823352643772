import React, { useMemo } from 'react'
import { FlatList, Image, TouchableOpacity, View } from 'react-native'

import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import { IShop } from '@dash.bar/types'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import useStyles from './styles'
import { useTranslation } from 'react-i18next'

type PropsT = {
  shops: {
    [key: string]: IShop
  }
  selectedShop?: string
  selectShop: (key: string) => void
  disabled?: boolean
}

const WidgetShop = ({ selectedShop, selectShop, shops, disabled = false }: PropsT) => {
  const styles = useStyles()

  const { t } = useTranslation()

  const data = useMemo(
    () =>
      Object.entries<string, IShop>(shops).map(([key, entry]) => ({
        ...entry,
        key,
      })),
    [shops]
  )

  const initialIndex = selectedShop ? data.findIndex(({ key }) => key === selectedShop) : 0

  return (
    <View style={[styles.wrapper, disabled && styles.wrapperDisabled]}>
      <BoldText style={styles.title}>{t('widget.shop.title')}</BoldText>
      <FlatList
        horizontal
        style={styles.list}
        data={data}
        keyExtractor={({ key }) => key}
        initialScrollIndex={data.length < 4 ? 0 : initialIndex}
        getItemLayout={(_, index) => ({ index, length: 120, offset: index * 120 })}
        renderItem={({ item: { key, icon } }) => (
          <TouchableOpacity
            style={[styles.shop, selectedShop === key && styles.selectedShop]}
            disabled={disabled}
            onPress={() => {
              if (selectedShop === key) {
                selectShop(null)
              } else {
                selectShop(key)
              }
            }}
          >
            <View style={styles.shopIconWrapper}>
              <Image source={{ uri: icon }} style={styles.shopIcon} resizeMode={'stretch'} resizeMethod={'resize'} />
            </View>

            <ColoredText style={styles.shopName} numberOfLines={1}>
              {key}
            </ColoredText>
          </TouchableOpacity>
        )}
      />
    </View>
  )
}

export default WidgetShop
