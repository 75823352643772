import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import useIntervals from '../../hooks/widget/useIntervals'

import { View, TouchableOpacity, Platform } from 'react-native'
import SemiBoldText from '@webstollen/react-native/lib/components/CustomText/SemiBoldText'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import useStyles from './styles'
import { UpsellingContext } from '../../hooks/UpsellingDialogProvider'
import { SessionContext } from '../../context/SessionContext'
import useDeviceType from '../../hooks/useDeviceType'

const IntervalButton = ({
  interval,
  currentInterval,
  onIntervalChange,
}: {
  interval: {
    label: string
    value: string
    active: boolean
  }
  currentInterval: string
  onIntervalChange: (value: string) => void
}) => {
  const styles = useStyles()
  const { company } = useContext(SessionContext)
  const canUseInterval = company?.authorizations['dashboard.intervalFilter']
  const { showDialog } = useContext(UpsellingContext)
  const device = useDeviceType()

  const onIntervalLabelPress = () => {
    if (canUseInterval) {
      if (currentInterval !== interval.value) onIntervalChange(interval.value)
    } else {
      showDialog()
    }
  }

  return (
    <View style={device === 'mobile' ? { width: '25%', alignItems: 'center' } : { justifyContent: 'center' }}>
      <TouchableOpacity
        key={interval.value}
        onPress={() => onIntervalLabelPress()}
        style={[styles.tag, currentInterval === interval.value && styles.tagSelected]}
      >
        <ColoredText style={[styles.tagLabel, currentInterval === interval.value && styles.tagLabelSelected]}>
          {interval.label}
        </ColoredText>
      </TouchableOpacity>
    </View>
  )
}

interface IProps {
  currentInterval: string
  onIntervalChange: (value: string) => void
}

const IntervalSelection = (props: IProps) => {
  const { onIntervalChange, currentInterval } = props
  const styles = useStyles()
  const { t } = useTranslation()
  const intervals = useIntervals('page')
  return (
    <View>
      {Platform.select({
        default: <SemiBoldText style={styles.header}>{t('shop.widgets.interval.title')}</SemiBoldText>,
        web: undefined,
      })}
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center' }}>
        {intervals.map((interval) => {
          return (
            <IntervalButton
              key={interval.label}
              interval={interval}
              currentInterval={currentInterval}
              onIntervalChange={onIntervalChange}
            />
          )
        })}
      </View>
    </View>
  )
}

export default IntervalSelection
