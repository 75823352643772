import { Platform, StyleSheet } from 'react-native'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../../../../../constants/colors'

type PropsT = {
  color: string
}
const useStyles = ({ color }: PropsT) => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.darkBgTransparent6,
      margin: 8,
      padding: 16,
      borderRadius: 16,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
    },
    barChartWrapper: {
      height: 240,
      flexDirection: 'column',
    },
    xAxisWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 8,
    },
    xAxisLabels: {
      fontSize: 12,
      color: color,
      // Nicht schön zentriert. Diese Methode ist nicht schön !!Wenn das Fenster sehr klein wird funkioniert es nicht.
      marginHorizontal: Platform.select({ default: undefined, web: '4.8%' }),
    },
  })
}
export default useStyles
