import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import React, { useContext, useRef, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import {
  currencyToSymbol,
  dashboardsForBundle,
  notificationsForBundle,
  shopsForBundle,
  userForBundle,
} from '../../../../../../utils/booking'
import useDocument from '../../../../../../hooks/useDocument'
import useStyles from './styles'
import Button from '../../../../../../components/Button'
import colors from '../../../../../../constants/colors'
import BookingModal from '../BookingModal'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { Bundle, Interval } from '@dash.bar/types'
import { SessionContext } from '../../../../../../context/SessionContext'
import Pro from '../../../../../../assets/imgs/subscription/Pro'
import Business from '../../../../../../assets/imgs/subscription/Business'
import Free from '../../../../../../assets/imgs/subscription/Free'

const BundleCard = ({ bundle }: { bundle: Bundle }) => {
  const bundleDoc = useDocument<Record<string, any>>('bundle', bundle)
  const styles = useStyles()
  const { userIsAdmin } = useContext(SessionContext)
  const { t } = useTranslation()
  const modalRef = useRef<any>()
  const [{ interval }, setIntervalState] = useState<{
    interval: Interval
  }>({
    interval: Interval.year,
  })
  console.debug(`Bundle: ${bundle}`)

  return (
    <>
      <View style={styles.wrapper}>
        <View
          style={[
            styles.titleWrapper,
            bundle === Bundle.free
              ? styles.backgroundFree
              : bundle === Bundle.pro
              ? styles.backgroundPro
              : styles.backgroundBusiness,
          ]}
        >
          <ColoredText style={styles.title}>{`dash.bar ${bundle || Bundle.free}`}</ColoredText>
          <View>{bundle === Bundle.pro ? <Pro /> : bundle === Bundle.business ? <Business /> : <Free />}</View>
        </View>
        <View style={styles.contentWrapper}>
          <View style={styles.bundleOptionsWrapper}>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{userForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{shopsForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{dashboardsForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{notificationsForBundle(bundle)}</ColoredText>
            </View>
          </View>
          <View style={styles.divider} />
          <View style={styles.amountWrapper}>
            <TouchableOpacity
              style={[styles.intervalCard, interval === Interval.month ? styles.selected : null]}
              onPress={() => setIntervalState({ interval: Interval.month })}
            >
              <View style={styles.intervalWrapper}>
                <ColoredText style={styles.amountInterval}>
                  <>{t('subscription.interval.text_month')}</>
                </ColoredText>
                <ColoredText style={styles.amountValue}>{`${bundleDoc?.price?.month?.value}${currencyToSymbol(
                  bundleDoc?.price?.month?.currency
                )}`}</ColoredText>
                {interval === Interval.month ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.intervalCard, interval === Interval.year ? styles.selected : null]}
              onPress={() => setIntervalState({ interval: Interval.year })}
            >
              <View style={styles.intervalWrapper}>
                <ColoredText style={styles.amountInterval}>
                  <>{t('subscription.interval.text_year')}</>
                </ColoredText>
                <ColoredText style={styles.amountValue}>{`${bundleDoc?.price?.year?.value}${currencyToSymbol(
                  bundleDoc?.price?.year?.currency
                )}`}</ColoredText>
                {interval === Interval.year ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
                {interval === Interval.year && bundle != Bundle.free ? (
                  <ColoredText style={{ marginLeft: 20, color: 'red' }}>
                    <>{t('subscription.promote_text.discount_' + bundle)}</>
                  </ColoredText>
                ) : null}
              </View>
            </TouchableOpacity>

            <View style={styles.buttonWrapper}>
              {userIsAdmin ? (
                <Button
                  style={styles.button}
                  textStyle={{ color: 'white' }}
                  onPress={() => {
                    if (modalRef && modalRef.current) modalRef.current.setVisible(true)
                  }}
                >
                  {t('subscription.choose')}
                </Button>
              ) : (
                <ColoredText>
                  <>{t('subscription.only_admins')}</>
                </ColoredText>
              )}
            </View>
          </View>
        </View>
      </View>
      <BookingModal ref={modalRef} bundle={bundle} interval={interval} />
    </>
  )
}

export default BundleCard
