import { BaseActionTypes, BaseState, SET_LANGUAGE, SET_THEME, SetLanguageAction, SetThemeAction } from './types'

import initialState from './init'

const setTheme = (state: BaseState, action: SetThemeAction): BaseState => ({
  ...state,
  config: {
    ...state.config,
    theme: action.payload,
  },
})

const setLanguage = (state: BaseState, action: SetLanguageAction): BaseState => ({
  ...state,
  config: {
    ...state.config,
    language: action.payload,
  },
})

export const baseReducer = (state: BaseState = initialState, action: BaseActionTypes): BaseState => {
  switch (action.type) {
    case SET_THEME:
      return setTheme(state, action)
    case SET_LANGUAGE:
      return setLanguage(state, action)
    default:
      return state
  }
}
