import React, { useContext } from 'react'
import { View } from 'react-native'
import { Redirect, useRouteMatch } from '@webstollen/react-native/lib/react-router'

import useStyles from './styles'

import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useTranslation } from 'react-i18next'
import { SemVer } from 'semver'
import OrdersV1 from '../../component/Orders/v1'
import { SessionContext } from '../../../../context/SessionContext'

const Orders = () => {
  const styles = useStyles()

  const { t } = useTranslation()

  const match = useRouteMatch<{ shopId: string }>()

  const { shopId } = match.params

  const { shops } = useContext(SessionContext)
  const shop = shops ? shops[shopId] || null : undefined

  if (shops === undefined || shop === undefined) {
    return <LoadingScreen text={t('loading.shop')} />
  }

  if (shop === null) {
    return <Redirect to={'/shop'} />
  }

  const version = new SemVer(shop.platform.sdkVersion)

  switch (version.major) {
    case 1:
      return <OrdersV1 shop={shop} />
    default:
      return (
        <View style={styles.wrapper}>
          <View style={styles.container}>
            <ColoredText>{JSON.stringify(shop, null, 2)}</ColoredText>
          </View>
        </View>
      )
  }
}

export default Orders
