import React, { useEffect } from 'react'
import { IShop } from '@dash.bar/types'
import useOrder from './useOrder'
import { useTranslation } from 'react-i18next'
import HeaderWrapper from '../../../../../layout/Header/Wrapper'
import FavoriteHeader from '../../../../../layout/Header/FavoriteHeader'

type PropsT = {
  shop: IShop
  orderId: string | number
}

const OrderHeaderV1 = ({ shop, orderId }: PropsT) => {
  const { t } = useTranslation()
  const { getOrder, state } = useOrder({ shop })

  useEffect(() => {
    getOrder(orderId)
  }, [getOrder, orderId])

  if (state.loading || !state.order) {
    return <HeaderWrapper title={t('nav.order')} />
  }

  const { orderNumber } = state.order

  return <FavoriteHeader title={`${t('nav.order')}: ${orderNumber}`} subtitle={shop.domain} />
}

export default OrderHeaderV1
