import { Platform, StyleSheet } from 'react-native'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: Platform.select({ default: 'flex-end', web: undefined }),
      backgroundColor: 'transparent',
      marginTop: 10,
      // borderWidth: 2,
      // borderColor: 'blue',
    },
    container: {
      flex: 1,
      width: '100%',
      // display: 'flex',
      // flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: Platform.select({ default: 'flex-end', web: undefined }),
      marginBottom: 5,
      // borderWidth: 2,
      // borderColor: 'green',
    },
    button: {
      borderRadius: 16,
      //marginHorizontal: 10,
      marginVertical: 2,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 50,
      // width: '100%',
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      // borderWidth: 2,
      // borderColor: 'red',
    },
    buttonText: {
      width: '75%',
      // alignSelf: 'stretch',
    },
  })
}

export default useStyles
