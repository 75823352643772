import React, { useContext, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { useHistory, useRouteMatch } from '@webstollen/react-native/lib/react-router'

import useStyles from './styles'

import WidgetTypes from './WidgetSettings/Type'
import WidgetShop from './WidgetSettings/Shop'
import useAddWidget from '../../../../hooks/widget/useAddWidget'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import { SessionContext } from '../../../../context/SessionContext'

const CreateWidget = () => {
  const styles = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const [selectedType, selectType] = useState<string>()
  const [selectedShop, selectShop] = useState<string>()

  const { shops } = useContext(SessionContext)

  const {
    params: { dashboardId },
  } = useRouteMatch<{ dashboardId: string }>()

  const addWidget = useAddWidget(dashboardId)

  if (!shops) {
    return <LoadingScreen text={t('loading.shops')} />
  }

  const disabledButton = !selectedShop || !selectedType

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <WidgetTypes selectedType={selectedType} selectType={selectType} />
        <WidgetShop selectedShop={selectedShop} selectShop={selectShop} shops={shops} />

        <TouchableOpacity
          style={[styles.button, disabledButton && styles.disabledButton]}
          disabled={disabledButton}
          onPress={() => {
            addWidget({
              type: `${selectedType}`,
              settings: {
                shop: selectedShop,
                interval: 'D30',
              },
            }).then(() => {
              history.goBack()
            })
          }}
        >
          <BoldText style={styles.buttonText}>Create Widget</BoldText>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default CreateWidget
