import React, { useContext } from 'react'

import { Redirect } from '@webstollen/react-native/lib/react-router'
import { SessionContext } from '../../../../context/SessionContext'

const IndexRedirect = () => {
  const sessionCtx = useContext(SessionContext)
  //const dashboards = useDashboards()
  console.debug('(IndexRedirect)')
  if (sessionCtx.isLoaded && !sessionCtx.currentUser) {
    return <Redirect to={`/login`} />
  }
  /*
  if (!isLoaded(dashboards)) {
    return <LoadingScreen />
  }

  if (isEmpty(dashboards)) {
    return <Redirect to={'/settings/dashboard'} />
  }*/

  return <Redirect to={'/dashboard-redirect'} />
}

export default IndexRedirect
