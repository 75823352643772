import { TextArea } from 'native-base'
import React, { useState } from 'react'
import { View } from 'react-native'
import Button from '../../../../components/Button'
import getApp from '../../../../firebase'
import { getCompanyText, getUserText } from './exportText'

export const Migration = () => {
  const migrateUser = getApp().functions('us-central1').httpsCallable('migration-migrateUser')
  const migrateCompany = getApp().functions('us-central1').httpsCallable('migration-migrateCompany')
  const [text, setText] = useState<string>(getUserText())
  const [textCompany, setTextCompany] = useState<string>(getCompanyText())

  const userMigrate = () => {
    console.debug(JSON.parse(text))
    migrateUser({ users: JSON.parse(text) })
      .then(() => {})
      .catch((e) => {
        console.debug(e)
      })
  }
  const companyMigrate = () => {
    console.debug(JSON.parse(text))
    migrateCompany({ companies: JSON.parse(textCompany) })
      .then(() => {})
      .catch((e) => {
        console.debug(e)
      })
  }
  return (
    <View>
      <TextArea
        value={text}
        onChangeText={(text) => {
          setText(text)
        }}
      />
      <Button onPress={() => userMigrate()}>User Migrieren</Button>
      <TextArea
        value={textCompany}
        onChangeText={(text) => {
          setTextCompany(text)
        }}
      />
      <Button onPress={() => companyMigrate()}>Company Migrieren</Button>
    </View>
  )
}
