import React from 'react'

import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'

import { Link, useHistory } from '@webstollen/react-native/lib/react-router'

import { IWidgetProps } from '../../../../types/props/Widget'

import ListView from '../../../../components/ListView'
import ProductImage from '../../../../components/ProductImage'

import Widget from '../Widget'

import useStyles from './styles'
import { RunnersT } from '../../../../types/widgetDataTypes/types'
import { View } from 'react-native'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import icons from '../../../../constants/icons'

const Runners = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const styles = useStyles()

  return (
    <Widget<[RunnersT]>
      title={t('shop.widget.runners.title')}
      widget={widget}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval = 'D30', rowsPerPage = 4 }, { page = 1 } = {}) => ({
          body: {
            request: 'Analytics',
            widget: {
              renner: {
                options: {
                  active: true,
                  details: false,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                  pagination: { descending: true, page, rowsPerPage },
                },
              },
            },
          },
          widget: 'renner',
        }),
      ]}
      onClickWidget={() => {
        history.push(`/shop/${widget.settings.shop}/orders`)
      }}
      testID={'runners-widget'}
      {...props}
    >
      {([, [{ data }], , , size]) => {
        return (
          <ListView
            testID={'runners-list'}
            size={size}
            styles={{
              mainTitle: styles.mainTitle,
              mainSubtitle: styles.mainSubtitle,
            }}
            data={data.map(({ id, nAmount, nAmountUnique, cName, cURL, cThumbnail }) => {
              return {
                id,
                icon: <ProductImage style={styles.icon} photoURL={cThumbnail} accessibilityLabel="thumbnail" />,
                mainTitle: cName,
                mainSubtitle: t<string>('shop.widget.runners.sold', {
                  sold: nAmount,
                  count: nAmountUnique,
                }),
                actionTitle: (
                  <View style={styles.linkWrapper}>
                    <Link to={cURL} style={styles.link}>
                      <FontAwesomeIcon icon={[icons.faIconStyle, 'external-link']} />
                    </Link>
                  </View>
                ),
              }
            })}
          />
        )
      }}
    </Widget>
  )
}

export default Runners
