import React from 'react'
import { /*Image,*/ TouchableOpacity, View } from 'react-native'

import { useHistory, useLocation, useRouteMatch } from '@webstollen/react-native/lib/react-router'

import { IconName } from '@fortawesome/pro-solid-svg-icons'

//import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'

//import { useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'
import SemiBoldText from '@webstollen/react-native/lib/components/CustomText/SemiBoldText'

import useStyles from './styles'

import { IShop } from '@dash.bar/types'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from 'react-i18next'
//import FavoriteButton from '../../../Header/FavoriteButton'

interface ShopItem extends IShop {
  path: string
  fontAwesomeIcon?: IconName
}

type Props = {
  shops: Array<ShopItem>
}

const ShopMenuItems = ({ shops }: Props) => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { t } = useTranslation()
  const styles = useStyles()

  //const hightlightColor = useHighlightColor()

  return (
    <>
      {shops.map(({ /*fontAwesomeIcon, icon,*/ domain, path }) => (
        <View key={path} style={styles.wrapper}>
          <View style={styles.shopHeaderWrapper}>
            <SemiBoldText
              numberOfLines={1}
              style={[
                styles.name,
                match.url.indexOf(path) === 0 && styles.isActive,
                match.url.indexOf(`/shop/${domain}`) === 0 && styles.isActive,
              ]}
            >
              {domain}
            </SemiBoldText>
            {/*
            <View style={styles.iconWrapper}>
              {fontAwesomeIcon ? (
                <View style={styles.iconBackground}>
                  <FontAwesomeIcon
                    style={styles.icon}
                    color={match.url.indexOf(path) === 0 && hightlightColor ? hightlightColor : undefined}
                    icon={[icons.faIconStyle, fontAwesomeIcon]}
                  />
                </View>
              ) : (
                <View style={styles.iconBackground}>
                  <Image
                    style={styles.icon}
                    source={{
                      uri: icon,
                    }}
                    accessibilityLabel={domain}
                  />
                </View>
              )}
            </View>*/}
          </View>
          <View style={styles.shopItemsOuterWrapper}>
            {[
              {
                path: `/shop/${domain}/live-tracker`,
                title: t('nav.live-tracker'),
              },
              {
                path: `/shop/${domain}/analytics`,
                title: t('nav.analytics'),
              },
              {
                path: `/shop/${domain}/orders`,
                title: t('nav.orders'),
              },
            ].map(({ path, title }) => (
              <View key={path} style={[styles.itemWrapper, path === location.pathname ? styles.isActive : null]}>
                <View style={styles.shopItemsInnerWrapper}>
                  <TouchableOpacity onPress={() => history.push(path)} key={path}>
                    <View style={styles.shopSubHeaderWrapper}>
                      <ColoredText style={[styles.shopItems]}>{title}</ColoredText>
                      {/* Dont show favorite button 
                    <FavoriteButton url={path} title={title} subtitle={`${domain}`} />
                  */}
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            ))}
          </View>
        </View>
      ))}
    </>
  )
}

export default ShopMenuItems
