import { createContext, useContext, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setLanguage as setLanguageAction } from '../../module/base/store/actions'
import i18n from '../../locales/i18n'
import { Languages } from '../../types/language'

export const isGerman = (language: Languages) => language === Languages.DE
export const isEnglish = (language: Languages) => language === Languages.EN

export const LanguageContext = createContext(Languages.DE)

export const useLanguage = () => useContext(LanguageContext)

export const useSetLanguage = () => {
  const dispatch = useDispatch()
  return useCallback(
    (language: Languages) => {
      dispatch(setLanguageAction(language))
      i18n.changeLanguage(language)
    },
    [dispatch]
  )
}

export const useIsGerman = () => isGerman(useLanguage())
export const useIsEnglish = () => isEnglish(useLanguage())
