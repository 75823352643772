import 'firebase/firestore'

import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { baseReducer } from './../module/base/store/reducer'
import thunk from 'redux-thunk'
import { middleware as mwBase } from '../module/base/store/init'
import { firebaseReducer, getFirebase } from 'react-redux-firebase'

import { firestoreReducer } from 'redux-firestore'

export const rootReducer = combineReducers({
  base: baseReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  //firebaseUser: fbUserReducer,
  //firebaseUserNotifications: fbUserNotificationsReducer,
})

const middlewares = [thunk.withExtraArgument(getFirebase), ...mwBase]

let composeEnhancers = compose

if (process.env.NODE_ENV !== 'production') {
  const { composeWithDevTools } = require('redux-devtools-extension')
  composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
  })
  // const { createLogger } = require('redux-logger')
  // const logger = createLogger({
  //   duration: true,
  //   diff: false,
  // })
  // middlewares.push(logger)
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))

export default store
