import React, { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'

import useWidgetDataFromApi, { ApiConfigFunction } from '../../../../hooks/widget/useWidgetDataFromApi'

import useIntervals from '../../../../hooks/widget/useIntervals'

import IntervalSelection from '../../../components/IntervalSelection'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import { useUpdateWidgetContext } from '../../../../types/Widget'
import { IShop, IWidget, ShopMap } from '@dash.bar/types'

import WidgetView, { PropsT as BaseProps } from '../../../../components/Widget'
import { Modify } from '../../../../types'

import { usePrimaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-duotone-svg-icons'
import { IconName } from '@fortawesome/pro-solid-svg-icons'
import colors from '../../../../constants/colors'
import Button from '../../../../components/Button'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import useRemoveWidget from '../../../../hooks/widget/useRemoveWidget'
import useDashboard from '../../../../hooks/dashboard/useDashboard'
import { isLoaded } from 'react-redux-firebase'
import Actions from '../../../components/Actions'
import { View } from 'react-native'
import { useIsWidgetAllowedForUser } from '../../../index'
import icons from '../../../../constants/icons'
// const defaultFavIcon = require('../../../../assets/imgs/dblogo.png')
const defaultFavIcon = 'https://static.dash.bar/app/img/dblogo.png'

export interface WidgetSize {
  height: number
  width: number
}

type ChildrenParams<DataTypes> = [boolean, DataTypes, Error | null, IShop | null, WidgetSize]

interface WidgetProps<DataTypes> {
  widget: IWidget
  dashboardId: string
  getApiConfig: Array<ApiConfigFunction>
  children: (args: ChildrenParams<DataTypes>) => React.ReactChild
  hasInterval?: boolean
  showMissingData?: boolean
  shops: ShopMap
  testID?: string
  // testID is only used for @testing-library but needs to be declared
}

const Widget = <DataTypes extends Array<unknown>>({
  widget,
  dashboardId,
  children,
  footerContent,
  getApiConfig,
  hasInterval = false,
  showMissingData = false,
  showOptions = true,
  shops,
  ...props
}: Modify<BaseProps, WidgetProps<DataTypes>>) => {
  const backgroundColor = usePrimaryBackground()
  const history = useHistory()
  const updateWidget = useUpdateWidgetContext()
  const removeWidget = useRemoveWidget(dashboardId)
  const dashboard = useDashboard(dashboardId)
  const isWidgetAllowed = useIsWidgetAllowedForUser()

  const widgets = dashboard?.widgets

  const { t } = useTranslation()

  const [size, setSize] = useState({ height: 200, width: 100 })
  const onIntervalChange = useCallback(
    (newInterval) => {
      const newWidget = {
        ...widget,
        settings: {
          ...widget.settings,
          interval: newInterval,
        },
      }
      updateWidget(newWidget)
    },
    [widget, updateWidget]
  )

  const intervals = useIntervals()

  const {
    id,
    settings: { shop, ...settings },
  } = widget

  const widgetShop = shops[shop]
  const [loading, data = [] as DataTypes, error] = useWidgetDataFromApi<DataTypes>({
    id,
    settings,
    apiConfig: getApiConfig,
    shop: widgetShop,
  })

  if (!widgetShop) {
    return (
      <WidgetView
        title={`${t('error.configurationError')} (${props.title})`}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} color={colors.error} />}
      >
        <ColoredText style={{ padding: 15, color: colors.error, fontWeight: 'bold', lineHeight: 25 }}>
          {t('error.widgetShopMissing')}
        </ColoredText>
        <Button onPress={() => history.push('/settings/dashboard/')} style={{ backgroundColor: colors.error }}>
          {t('nav.settings')}
        </Button>
      </WidgetView>
    )
  }

  if (!isLoaded(widgets)) {
    return <LoadingScreen />
  }

  return (
    <WidgetView
      image={
        widgetShop?.icon && {
          source: widgetShop?.icon ?? defaultFavIcon,
          alt: widgetShop?.domain ?? '(domain missing)',
        }
      }
      icon={
        widgetShop?.icon ? undefined : <FontAwesomeIcon icon={[icons.faIconStyle, 'dashbar' as IconName]} color={colors.color3} />
      }
      footerContent={
        footerContent ||
        (error && error.message) ||
        (hasInterval && (intervals.find(({ value }) => widget.settings.interval === value) || {}).label)
      }
      options={[
        hasInterval && (
          <IntervalSelection
            intervals={intervals}
            key="interval-selection"
            settings={widget && widget.settings}
            onIntervalChange={onIntervalChange}
          />
        ),
        <Actions key={'actions'} widget={widget} widgets={widgets} onRemoveWidget={removeWidget} />,
      ]}
      showOptions={showOptions}
      onWidgetLayout={(evt) => {
        const { height, width } = evt.nativeEvent.layout

        setSize({ height, width })
      }}
      {...props}
    >
      {!isWidgetAllowed(widget) ? (
        <View style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <ColoredText style={{ textAlign: 'center' }}>{t('shop.widget.notAllowed')}</ColoredText>
          <Button
            primary
            style={{ marginTop: 10, paddingHorizontal: 5 }}
            onPress={() => {
              history.push('/upselling')
            }}
          >
            {t('shop.upselling.body.buy')}
          </Button>
        </View>
      ) : showMissingData ? (
        <>
          {children([loading, data, error, widgetShop, size])}
          {loading ? (
            <LoadingScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,

                backgroundColor,
                opacity: 0.7,
              }}
            />
          ) : null}
        </>
      ) : loading ? (
        <LoadingScreen />
      ) : !loading && data?.length > 0 ? (
        children([loading, data, error, widgetShop, size])
      ) : (
        <ColoredText>{t('shop.widget.no-data')}</ColoredText>
      )}
    </WidgetView>
  )
}

export default Widget
