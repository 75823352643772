import React from 'react'

import { useTranslation } from 'react-i18next'

import Widget from '../Widget'

import { IWidgetProps } from '../../../../types/props/Widget'
// import { useHistory } from '@webstollen/react-native/lib/react-router'
import { KeyFiguresT } from '../../../../types/widgetDataTypes/types'
import { PieChart } from 'react-native-svg-charts'
import colors from '../../../../constants/colors'
import { View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

const Gender = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  // const history = useHistory()

  return (
    <Widget<[KeyFiguresT]>
      title={t('shop.widget.gender.title')}
      widget={widget}
      getApiConfig={[
        // TODO: replace with gender query when available in dashbar plugin
        ({ autoRefresh, refreshInterval, interval = 'D30' }) => ({
          body: {
            request: 'Analytics',
            widget: {
              status: {
                info: ['sales', 'visitors', 'orders', 'conversions'],
                options: {
                  active: true,
                  details: false,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                },
              },
            },
          },
          widget: 'status',
        }),
      ]}
      showMissingData
      hasInterval
      onClickWidget={() => {
        alert('TODO: implement') /*"TODO: implement"*/
        // history.push(`/shop/${widget.settings.shop}/live-tracker`) // TODO: add correct link
      }}
      {...props}
    >
      {([, , , , size]) => {
        let pieData = []
        return (
          <View style={{ height: size.height, flexDirection: 'column' }}>
            <PieChart
              innerRadius={'85%'}
              padAngle={0}
              style={{ flex: 1 }}
              data={(() => {
                pieData = [
                  {
                    name: t('shop.widget.gender.male'),
                    color: colors.color1,
                    value: 66, // TODO: Update pieDate with date from shop
                    svg: {
                      fill: colors.color1,
                      // onPress: (event) => {
                      //   event.preventDefault()
                      //   // Code...
                      // },
                    },
                    key: 2,
                  },
                  {
                    value: 44, // TODO: Update pieDate with date from shop
                    name: t('shop.widget.gender.female'),
                    color: colors.color3,
                    svg: {
                      fill: colors.color3,
                      // onPress: (event) => {
                      //   event.preventDefault()
                      //   // Code...
                      // },
                    },
                    key: 1,
                  },
                ]
                return pieData
              })()}
            />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexBasis: 16 }}>
              {pieData.map((obj, index) => (
                <View key={index} style={{ flexDirection: 'row' }}>
                  <View
                    style={{ backgroundColor: obj.color, height: 12, width: 12, borderRadius: 2, marginRight: 5 }}
                  />
                  <ColoredText key={index} style={{ fontSize: 12 }}>
                    {`${obj.name} (${obj.value})`}
                  </ColoredText>
                </View>
              ))}
            </View>
          </View>
        )
      }}
    </Widget>
  )
}

export default Gender
