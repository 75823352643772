import React from 'react'
import { FlatList, Image, TouchableOpacity, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { get } from 'lodash'

import useStyles from './styles'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'

import shopWidgets from '../../../../../../widgets'
import widgetDefinitions, { useIsWidgetAllowedForUser } from '../../../../../../widgets'

import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { TFunction } from 'i18next'
import BuyWidgetOverlay from './BuyWidgetOverlay'

export const typeDefinitions = (t: TFunction = (key: string) => key) => [
  {
    group: t('widget.type.live'),
    widgets: ['shop.status_v1', 'shop.live_tracker_v1'],
  },
  {
    group: t('widget.type.orders'),
    widgets: ['shop.orders_v1', 'shop.runners_v1'],
  },
  {
    group: t('widget.type.analytics'),
    widgets: ['shop.sales_v1', 'shop.conversion_v1', 'shop.order_value_v1', 'shop.gender_v1'],
  },
  {
    group: t('widget.type.misc'),
    widgets: ['misc.advertisement_v1'],
  },
]

const WidgetType = ({ selectedType, selectType }: { selectedType: string; selectType: (type: string) => void }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const types = typeDefinitions(t)
  const isAllowed = useIsWidgetAllowedForUser()

  const theme = useTheme()

  const initialScrollIndex = () => {
    if (!selectedType) {
      return 0
    }

    return types.findIndex(({ widgets }) => widgets.find((type) => selectedType === type))
  }

  return (
    <View style={styles.wrapper}>
      <BoldText style={styles.title}>{t('widget.type.title')}</BoldText>
      <FlatList
        horizontal
        style={styles.list}
        data={types}
        keyExtractor={({ group }) => group}
        initialScrollIndex={initialScrollIndex()}
        getItemLayout={(data, index) => {
          const length = data[index].widgets.length * 150
          const offset = data.slice(0, index).reduce((prev, { widgets }) => prev + widgets.length * 150, 0)

          return { length, offset, index }
        }}
        renderItem={({ item: { group, widgets } }) => (
          <View key={group} style={styles.group}>
            <BoldText style={styles.groupTitle}>{group}</BoldText>
            <View style={styles.groupElements}>
              {widgets.map((key) => {
                const { icon, title } = get(shopWidgets, key)
                const widgetHasToBeBought = !isAllowed({ ...get(widgetDefinitions, key), type: key })
                return (
                  <TouchableOpacity
                    key={key}
                    style={[styles.type, selectedType === key && styles.selectedType]}
                    onPress={() => {
                      if (selectedType === key) {
                        selectType(null)
                      } else {
                        selectType(key)
                      }
                    }}
                  >
                    <Image source={icon[theme]} style={styles.typeIcon} resizeMode={'contain'} />
                    <BoldText style={styles.typeName}>{title}</BoldText>
                    {widgetHasToBeBought && <BuyWidgetOverlay />}
                  </TouchableOpacity>
                )
              })}
            </View>
          </View>
        )}
      />
    </View>
  )
}

export default WidgetType
