import { usePrimaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    modalView: {
      margin: 20,
      width: '100%',
      marginTop: 'auto',
      alignSelf: 'center',
      backgroundColor: usePrimaryBackground(),
      borderRadius: 20,
      paddingTop: 10,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 4,
    },
    planImageWrapper: {
      paddingVertical: '2%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    planLabel: {
      width: '80%',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
      position: 'absolute',
      top: -5,
      alignSelf: 'center',
    },
    planImage: {
      width: 130,
      height: 200,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 16,
      borderWidth: 4,
      borderColor: '#F5A623',
      overflow: 'hidden',
    },
  })
}

export default useStyles
