import React from 'react'
import { FlatList, Linking, TouchableOpacity, View } from 'react-native'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import useStyles from './styles'
import Avatar from '../../../../../../components/Avatar'
import { UserT } from '../../../../../../types/widgetDataTypes/types'
import md5 from 'md5'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { getBrowserIcon } from '../../../../../../utils/get-browser-icon'
import ReactCountryFlag from '../../../../../../components/ReactCountryFlag'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from 'react-i18next'
import numbro from 'numbro'
import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'
import { IShop } from '@dash.bar/types'
import moment from 'moment'
import { usePrimaryBackground, useSecondaryBackground, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import icons from '../../../../../../constants/icons'

type PropsT = {
  shop: IShop
  userList: Array<UserT>
  loading?: boolean
  onEndReached?: ((info: { distanceFromEnd: number }) => void) | null
  visitorID: string
}

const VisitorHorizontalScroll = ({ shop, userList, loading, onEndReached, visitorID }: PropsT) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const Url = require('url-parse')
  const history = useHistory()
  const textColor = useTextColor()

  const parsedUrl = (url: string) => {
    return new Url(url)
  }

  const RefreshControl = () => {
    const backgroundColor = usePrimaryBackground()
    const borderColor = useSecondaryBackground()
    const { t } = useTranslation()
    return (
      <View
        style={{
          position: 'absolute',
          top: 10,
          left: 0,
          right: 0,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <LoadingScreen
          text={t('loading.live-tracker')}
          style={{ backgroundColor, paddingVertical: 5, paddingHorizontal: 15, borderWidth: 5, borderColor }}
        />
      </View>
    )
  }

  return (
    <View style={styles.wrapper}>
      {loading ? <RefreshControl /> : null}
      <FlatList
        horizontal
        style={styles.list}
        data={userList}
        keyExtractor={({ mail }) => mail}
        onEndReached={onEndReached}
        initialScrollIndex={0}
        renderItem={({ item }) =>
          visitorID !== item.visitorID ? (
            <TouchableOpacity style={styles.customer} onPress={() => history.push(`${item.visitorID}`)}>
              <BoldText style={styles.listItemTitle} numberOfLines={1}>
                {item.name}
              </BoldText>
              <View style={[styles.rowWrapper, styles.flex1]}>
                <FontAwesomeIcon icon={getBrowserIcon(item.browser)} style={styles.listItemIcon} />
                <Avatar
                  style={[styles.avatar]}
                  photoURL={`https://avatar.ws-svc.de/?seed=${md5(item?.mail.trim().toLowerCase())}`}
                  accessibilityLabel={item.mail.trim().toLowerCase()}
                />
                {item.country === '' ? (
                  <FontAwesomeIcon icon={[icons.faIconStyle, 'flag']} style={styles.listItemIcon} />
                ) : (
                  <ReactCountryFlag countryCode={item.country} style={[styles.listItemIcon, styles.fontSize24]} />
                )}
              </View>
              <View style={styles.listItemInfoWrapper}>
                <View style={styles.rowWrapper}>
                  <FontAwesomeIcon icon={[icons.faIconStyle, 'shopping-cart']} style={styles.infoIcons} />
                  <ColoredText>
                    {numbro(item.basketValue).formatCurrency({
                      ...numbro.languageData().currencyFormat,
                      average: true,
                      currencySymbol: ` ${htmlDecode(shop.currency)}`,
                    })}
                  </ColoredText>
                </View>
                <View style={styles.rowWrapper}>
                  <FontAwesomeIcon icon={[icons.faIconStyle, 'link']} style={styles.infoIcons} />
                  <ColoredText
                    style={styles.infoText}
                    numberOfLines={1}
                    onPress={() =>
                      parsedUrl(item?.referrer).hostname === ''
                        ? Linking?.openURL(parsedUrl(item?.entryPage).origin)
                        : Linking?.openURL(parsedUrl(item?.referrer).origin)
                    }
                  >
                    {parsedUrl(item.referrer).hostname === ''
                      ? t('shop.live-tracker.directAccess')
                      : parsedUrl(item.referrer).hostname}
                  </ColoredText>
                </View>
                <View style={styles.rowWrapper}>
                  <FontAwesomeIcon icon={[icons.faIconStyle, 'bullseye']} style={styles.infoIcons} />
                  <ColoredText
                    style={styles.infoText}
                    numberOfLines={1}
                    onPress={() => Linking.openURL(item.entryPage)}
                  >
                    {parsedUrl(item.entryPage).pathname}
                  </ColoredText>
                </View>
              </View>
              <View style={styles.rowWrapper}>
                <FontAwesomeIcon icon={[icons.faIconStyle, 'clock']} style={styles.infoIcons} color={textColor} />
                <ColoredText style={styles.textColor} numberOfLines={1}>
                  {moment(item.lastAction).fromNow()}
                </ColoredText>
              </View>
            </TouchableOpacity>
          ) : null
        }
      />
    </View>
  )
}

export default VisitorHorizontalScroll
