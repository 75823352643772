import { StyleSheet } from 'react-native'
import {
  useSecondaryBackground,
  usePrimaryBackground,
  useHighlightColor,
} from '@webstollen/react-native/lib/hooks/useColors'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    listWrapper: {
      flex: 1,
    },

    headerWrapper: {
      paddingLeft: 5,
      paddingVertical: 5,
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent5 : colors.darkBgTransparent7,
      borderRadius: 10,
      marginVertical: 1,
      borderColor: 'transparent',
      borderTopWidth: 5,
    },

    header: {
      fontSize: 14,
      textAlignVertical: 'center',
    },

    listItemAlternate: {
      backgroundColor: useSecondaryBackground(),
    },
    listItem: {
      backgroundColor: usePrimaryBackground(),
      flexDirection: 'row',
      flex: 1,
      padding: 10,
      borderRadius: 10,
      justifyContent: 'space-between',
      minHeight: 50,
      alignItems: 'center',
    },
    filter: {
      flexDirection: 'row',
      borderBottomWidth: 2,
      justifyContent: 'space-between',
    },
    activeFilter: {
      borderBottomColor: useHighlightColor(),
    },
    filterIcons: {
      flexDirection: 'row',
    },

    cell: {
      marginHorizontal: 10,
    },
  })
}

export default useStyles
