import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import useCollection from '../useCollection'

/** Returns next booking if exists otherwise returns empty object
 *
 * @returns
 */
const useNextBundleBooking = (): Record<string, any> => {
  const { company } = useContext(SessionContext)
  const bookings = useCollection<any>(`company/${company?.id}/booking`)
  const nextBundle: Record<string, any> = Object.entries(bookings || {}).find(([k]) => {
    if (bookings[k].startDate.toDate() > new Date()) {
      return true
    }
    return false
  })
  return nextBundle ? nextBundle[1] : {}
}

export default useNextBundleBooking
