import { User } from '@dash.bar/types'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import firebase from 'firebase'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import ModalPopUp from '../../../../../../../components/Modal'
//import { EmailTemplates } from '../../../../../../../constants/emailTemplates'
import { SessionContext } from '../../../../../../../context/SessionContext'
import { functions } from '../../../../../../../firebase'
import useCollection from '../../../../../../../hooks/useCollection'
import useStyles from './styles'
import UserView from './UserView'
import { Toast } from 'native-base'
import EmailForm from '../../../../../../../components/Form/EmailForm'
import Button from '../../../../../../../components/Button'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import useDocument from '../../../../../../../hooks/useDocument'
import { UpsellingContext } from '../../../../../../../hooks/UpsellingDialogProvider'

const CompanyUsers = () => {
  const { company, user } = useContext(SessionContext)
  const styles = useStyles()
  const modalRef = useRef<any>()
  const [sendingIvitation, setSendingInvitation] = useState<boolean>(false)
  const { t } = useTranslation()
  const { showDialog } = useContext(UpsellingContext)

  const ownerId = company?.owner || '-'
  const adminList = (company?.admin?.length ? company?.admin : ['-'])?.filter((element) => {
    if (element !== '-') {
      return element !== ownerId
    }
    return true
  })
  const userList = (company?.users?.length ? company?.users : ['-'])?.filter((element) => {
    if (element !== '-') {
      return !adminList.includes(element) && element !== ownerId
    }
    return true
  })

  const admins = useCollection<User>('user', [
    [firebase.firestore.FieldPath.documentId() as unknown as string, 'in', adminList.length > 0 ? adminList : ['-']],
  ])
  const users = useCollection<User>('user', [
    [firebase.firestore.FieldPath.documentId() as unknown as string, 'in', userList.length > 0 ? userList : ['-']],
  ])

  const owner = useDocument<User>('user', ownerId)
  const createInvitation = useCallback(
    (email: string) => {
      setSendingInvitation(true)
      const createInvitation = functions().httpsCallable('calls-company-createInvitation')
      //const sendEmail = functions().httpsCallable('calls-email-sendEmail')
      createInvitation({ maxUses: 1, email: email, firstname: user.firstName, lastname: user.lastName })
        .then(() => {
          /*
          sendEmail({
            receivers: [{ email: email, name: 'test' }],
            templateId: EmailTemplates.INVITE_NEW_USER_TEMPLATE,
            params: {
              invitationCode: result.data,
              name: `${user.firstName} ${user.lastName}`,
              company: company?.name ?? '',
            },
          })
            .then(() => {
              Toast.show({
                description: t('notifications.success'),
              })
            })
            .catch((e) => {
              Toast.show({
                description: e.message,
              })
            })*/
        })
        .catch((e) => {
          Toast.show({
            description: e.message,
          })
        })
        .finally(() => {
          if (modalRef && modalRef.current) {
            modalRef.current.setVisible(false)
            setSendingInvitation(false)
          }
        })
    },
    [company?.name, t, Toast, user.firstName, user.lastName]
  )

  const onPressAddUser = () => {
    const canAddUsers = company?.users.length < company?.authorizations['general.users']

    if (modalRef && modalRef.current && canAddUsers) {
      modalRef.current.setVisible(true)
    } else {
      showDialog()
    }
  }

  return (
    <>
      <ColoredText style={{ paddingHorizontal: 30, paddingVertical: 10, fontSize: 18 }}>
        <>{t('accountOverview.company.employees')}</>
      </ColoredText>
      <View style={styles.userWrapper}>
        {owner?.id ? <UserView isOwner isAdmin user={owner} /> : null}
        {Object.entries(admins || {}).map(([k, value]) => {
          return <UserView isAdmin key={k} user={value} />
        })}
        {Object.entries(users || {}).map(([k, value]) => {
          return <UserView key={k} user={value} />
        })}

        <TouchableOpacity onPress={() => onPressAddUser()} style={styles.userViewWrapper}>
          <View style={styles.addButton}>
            <FontAwesomeIcon icon={'user-plus'} color="white" />
          </View>
          <ColoredText style={styles.name}>
            <>{t('accountOverview.company.add')}</>
          </ColoredText>
        </TouchableOpacity>

        <ModalPopUp ref={modalRef} title={t('accountOverview.company.invite_user')}>
          {sendingIvitation ? (
            <LoadingScreen />
          ) : (
            <View style={{ width: '50%', margin: 'auto' }}>
              <EmailForm
                style={{ width: '100%' }}
                onSubmit={({ email }) => createInvitation(email)}
                submitButtonText={t('company.inviteUser')}
                loading={false}
              />
              <Button
                style={styles.cancelButton}
                onPress={() => {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(false)
                }}
              >
                {t('accountOverview.profile.cancel')}
              </Button>
            </View>
          )}
        </ModalPopUp>
      </View>
    </>
  )
}

export default CompanyUsers
