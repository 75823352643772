import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import React, { useContext, useRef, useState } from 'react'
import { Modal, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import useStyles from '../../../../../upselling/components/styles'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { SessionContext } from '../../../../../../context/SessionContext'
import sizes from '../../../../../../constants/sizes'
import { Bundle, Interval } from '@dash.bar/types/booking'
import UpsellingDialogBody from './Components/UpsellingDialogBody'
import { isLoaded } from 'react-redux-firebase'
import useDeviceType from '../../../../../../hooks/useDeviceType'
import { usePrimaryBackground, useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { UpsellingContext } from '../../../../../../hooks/UpsellingDialogProvider'
import Pro from '../../../../../../assets/imgs/subscription/Pro'
import { getCardBackground } from './Components/BundleCard'
import Button from '../../../../../../components/Button'
import { BundleT } from '@dash.bar/types'
import useDocument from '../../../../../../hooks/useDocument'
import Business from '../../../../../../assets/imgs/subscription/Business'
import BookingModal from '../BookingModal'
import { Dialog } from 'react-native-paper'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import UpsellingCardRow from './Components/UpsellingCardRow'
import UpsellingDialogHeadline from './Components/UpsellingDialogHeadline'
import {
  dashboardsForBundle,
  notificationsForBundle,
  shopsForBundle,
  userForBundle,
} from '../../../../../../utils/booking'

const UpsellingDialog = () => {
  const { dialogVisible, hideDialog } = useContext(UpsellingContext)
  const [selectedBundle, setSelectedBundle] = useState<string>('')
  const [yearlyPaymentInterval, setYearlyPaymentInterval] = React.useState(true)
  const styles = useStyles()
  const proBundle = useDocument<BundleT>(`bundle`, 'pro')
  const businessBundle = useDocument<BundleT>(`bundle`, 'business')
  const modalRef = useRef<any>()

  const deviceType = useDeviceType()
  const { user, userIsAdmin, company } = useContext(SessionContext)
  const { t } = useTranslation()
  let selectableBundles = [Bundle.business, Bundle.pro]
  if (!isLoaded(company)) return null
  if (company?.bundle === Bundle.business) selectableBundles = [Bundle.pro]

  if (!isLoaded(proBundle)) return <LoadingScreen />
  if (!isLoaded(businessBundle)) return <LoadingScreen />

  const MobileDialog = () => {
    return (
      <Modal animationType="slide" transparent={true} visible={dialogVisible} onRequestClose={() => hideDialog()}>
        {/* allow closing modal when clicking on overlay */}
        <TouchableOpacity style={{ width: '100%', height: '100%', marginTop: 22 }} onPress={() => hideDialog()}>
          <TouchableWithoutFeedback
            style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 22 }}
          >
            <View style={styles.modalView}>
              <UpsellingDialogHeadline name={user?.firstName} />
              <UpsellingDialogBody selectableBundles={selectableBundles} isAdmin={userIsAdmin} />
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>
    )
  }
  const WebDialog = () => {
    const Card = ({ bundle }: { bundle: BundleT }) => {
      const bundlePrice = yearlyPaymentInterval ? bundle?.price?.year.value + ' €' : bundle?.price?.month.value + ' €'
      const paymentInterval = yearlyPaymentInterval ? 'yearly ' : 'monthly '
      const intervalButtonText = yearlyPaymentInterval ? 'pay monthly' : 'pay yearly'
      const oldPrice = bundle.type === Bundle.business.toUpperCase() ? '108,00 €' : '348,00€'

      return (
        <>
          <View
            style={{
              borderRadius: 16,
              height: 400,
              width: 300,
              overflow: 'hidden',
              backgroundColor: useSecondaryBackground(),
              marginTop: 10,
            }}
          >
            {/* Header  */}
            <View
              style={{
                height: '25%',
                backgroundColor: getCardBackground(bundle.type),
                padding: sizes.paddingHorizontal,
              }}
            >
              <ColoredText style={{ color: 'white', fontSize: 30 }}>{bundle.name}</ColoredText>
              {bundle.name === 'Pro' && (
                <Pro
                  style={{ position: 'absolute', bottom: 10, right: 10 }}
                  width={110}
                  height={60}
                  viewBox="0 0 190 100"
                />
              )}
              {bundle.name === 'Business' && (
                <Business
                  style={{ position: 'absolute', bottom: 10, right: 10 }}
                  width={110}
                  height={60}
                  viewBox="0 0 190 100"
                />
              )}
            </View>

            <View style={{ padding: sizes.paddingHorizontal, width: '100%', height: '50%' }}>
              <UpsellingCardRow item={userForBundle(bundle.type.toLowerCase())} />
              <UpsellingCardRow item={shopsForBundle(bundle.type.toLowerCase())} />
              <UpsellingCardRow item={dashboardsForBundle(bundle.type.toLowerCase())} />
              <UpsellingCardRow item={notificationsForBundle(bundle.type.toLowerCase())} />
            </View>
            <View
              style={{
                width: '100%',
                height: '25%',

                paddingHorizontal: sizes.paddingHorizontal,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <ColoredText style={{ position: 'absolute', left: 0 }}>{paymentInterval}: </ColoredText>
                {yearlyPaymentInterval && (
                  <ColoredText style={{ textDecorationLine: 'line-through', marginRight: 10 }}>{oldPrice}</ColoredText>
                )}
                <ColoredText
                  style={{
                    fontSize: 25,
                  }}
                >
                  {bundlePrice}
                </ColoredText>
              </View>
              <Button
                primary={true}
                onPress={() => {
                  setSelectedBundle(bundle.type)
                  modalRef?.current.setVisible(true)
                  hideDialog()
                }}
              >
                {t('subscription.choose')}
              </Button>
              <Button
                style={{ marginTop: 0 }}
                textStyle={{ textDecorationLine: 'underline' }}
                onPress={() => setYearlyPaymentInterval(!yearlyPaymentInterval)}
              >
                {intervalButtonText}
              </Button>
            </View>
          </View>
        </>
      )
    }
    return (
      <>
        <Dialog
          visible={dialogVisible}
          onDismiss={hideDialog}
          style={{
            backgroundColor: usePrimaryBackground(),
            width: '50%',
            minWidth: 500,
            padding: 20,
            borderRadius: 16,
            alignSelf: 'center',
          }}
        >
          <UpsellingDialogHeadline name={user?.firstName} />
          <View style={{ width: '100%', alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
            <Card bundle={businessBundle} />
            <Card bundle={proBundle} />
          </View>
        </Dialog>
      </>
    )
  }

  if (deviceType === 'web')
    return (
      <>
        <WebDialog />
        <BookingModal
          ref={modalRef}
          bundle={selectedBundle.toLowerCase() as Bundle}
          interval={yearlyPaymentInterval ? Interval.year : Interval.month}
        />
      </>
    )
  return <MobileDialog />
}

export default UpsellingDialog
