//START For transparent design
const darkBgTransparent1 = 'rgba(255,255,255, 0.1)'
const darkBgTransparent2 = 'rgba(255,255,255, 0.2)'
const darkBgTransparent4 = 'rgba(255,255,255, 0.4)'
const darkBgTransparent5 = 'rgba(255,255,255, 0.5)'
const darkBgTransparent6 = 'rgba(255,255,255, 0.6)'
const darkBgTransparent7 = 'rgba(255,255,255, 0.7)'
const darkBgTransparent8 = 'rgba(255,255,255, 0.8)'

const lightBgTransparent1 = 'rgba(0,0,0, 0.1)'
const lightBgTransparent2 = 'rgba(0,0,0, 0.2)'
const lightBgTransparent4 = 'rgba(0,0,0, 0.4)'
const lightBgTransparent6 = 'rgba(0,0,0, 0.6)'
const lightBgTransparent7 = 'rgba(0,0,0, 0.7)'
const lightBgTransparent8 = 'rgba(0,0,0, 0.8)'
//END For transparent design

const error = '#b10000'
const success = '#33b100'
const text = '#858585'

const darkBg1 = '#0e0e1c' // usePrimaryBackground
const darkBg2 = '#1e2239' // useSecondaryBackground

const lightBg1 = '#f4f5f9' // usePrimaryBackground
const lightBg2 = '#ffffff' // useSecondaryBackground

const darkText1 = lightBg1 // useTextColor
const lightText1 = darkBg1 // useTextColor

const color1 = '#73157c'
const color2 = '#2985d0'
const color3 = '#f5a623'

const bundleFree = color3
const bundleBusiness = color2
const bundlePro = color1

const highlightDark = color3 // useHighlightColor
const highlightLight = color1 // useHighlightColor

const headingColor = 'rgba(122, 122, 122,1)'

export default {
  error,
  success,
  primary: color2,
  text,
  bundleFree,
  bundleBusiness,
  bundlePro,
  darkBg1,
  darkBg2,
  lightBg1,
  lightBg2,
  darkText1,
  lightText1,
  color1,
  color2,
  color3,
  highlightDark,
  highlightLight,
  darkBgTransparent1,
  darkBgTransparent2,
  darkBgTransparent4,
  darkBgTransparent5,
  darkBgTransparent6,
  darkBgTransparent7,
  darkBgTransparent8,
  lightBgTransparent1,
  lightBgTransparent2,
  lightBgTransparent4,
  lightBgTransparent6,
  lightBgTransparent7,
  lightBgTransparent8,
  headingColor,
}
