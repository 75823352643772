import React from 'react'
import { useTranslation } from 'react-i18next'

import { IWidget } from '@dash.bar/types'

import { View } from 'react-native'
import SemiBoldText from '@webstollen/react-native/lib/components/CustomText/SemiBoldText'

import Button from '../../../components/Button'
import useStyles from '../IntervalSelection/styles'

interface IProps {
  widget: IWidget
  widgets: Array<IWidget>
  onRemoveWidget: (widget: IWidget, widgets: Array<IWidget>) => void
}

const ActionsSection = ({ widget, widgets, onRemoveWidget }: IProps) => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <SemiBoldText style={styles.header}>{t('shop.widgets.actions')}</SemiBoldText>
      <View key={'key'} style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button style={{ paddingHorizontal: 5 }} primary onPress={() => onRemoveWidget(widget, widgets)}>
          {t('dashboard.widgets.delete.confirm')}
        </Button>
      </View>
    </>
  )
}

export default ActionsSection
