import React, { useContext, useRef, useState } from 'react'
import { Button, Center, Modal, Radio, Text } from 'native-base'
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form'
import TextInputWithIcon from '../../../../components/TextInputWithIcon'
import { Platform, TextInput, View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { IModalProps } from 'native-base/lib/typescript/components/composites/Modal/types'
import { useTranslation } from 'react-i18next'
import WidgetShop from '../CreateWidget/WidgetSettings/Shop'
import { SessionContext } from '../../../../context/SessionContext'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import useStyles from './styles'
import icons from '../../../../constants/icons'

export type CreateDashboardFormType = {
  dashboardName: string
  shopDomain: string
  defaultWidgets: 'empty' | 'standard'
}

const CreateDashboardModal = ({
  createDashboard,
  loading,
  ...props
}: IModalProps & { createDashboard: SubmitHandler<CreateDashboardFormType>; loading: boolean }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [selectedShopDomain, _selectShopDomain] = useState<string>()
  const selectShopDomain = (shopDomain: string) => {
    setValue('shopDomain', shopDomain)
    _selectShopDomain(shopDomain)
  }

  const { shops } = useContext(SessionContext)
  const { domain } = Object.values(shops)[0]

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    setValue,
  } = useForm<CreateDashboardFormType>({ defaultValues: { shopDomain: domain, defaultWidgets: 'standard' } })
  const textInputRef = useRef<TextInput>(null)

  if (!shops) {
    return <LoadingScreen text={t('loading.shops')} />
  }

  return (
    <Modal initialFocusRef={textInputRef} {...props}>
      <Modal.Content maxWidth="800px">
        <Modal.CloseButton />
        <Modal.Header>{t('dashboard.create.title')}</Modal.Header>
        <Modal.Body>
          <Center>
            <View style={styles.containerInModal}>
              <Text marginY={3}>{t('dashboard.create.message')}</Text>
              <Controller
                name={'dashboardName'}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInputWithIcon
                    refForward={textInputRef}
                    icon={[icons.faIconStyle, 'clipboard']}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value ?? ''}
                    placeholder={t('dashboard.create.placeholder')}
                    autoCorrect={false}
                    editable={!loading}
                  />
                )}
              />
              <View>
                {errors?.dashboardName ? (
                  <ColoredText>{(errors.dashboardName as FieldError).message}</ColoredText>
                ) : null}
              </View>
            </View>
            <View style={styles.containerInModal}>
              <Text mt={3}>{t('dashboard.create.widgets')}</Text>
              <Controller
                name={'defaultWidgets'}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Radio.Group
                    mb={3}
                    name={'defaultWidgets'}
                    accessibilityLabel="defaultWidgets"
                    value={value}
                    onChange={onChange}
                  >
                    <Radio value="empty" my={1}>
                      {t('dashboard.create.radio.empty')}
                    </Radio>
                    <Radio value="standard" my={1}>
                      {t('dashboard.create.radio.standard')}
                    </Radio>
                  </Radio.Group>
                )}
              />
              <View>
                {errors?.defaultWidgets ? (
                  <ColoredText>{(errors.defaultWidgets as FieldError).message}</ColoredText>
                ) : null}
              </View>
            </View>
            <WidgetShop selectedShop={selectedShopDomain} selectShop={selectShopDomain} shops={shops} />
            <Button
              marginY={3}
              onPress={handleSubmit(createDashboard)}
              isDisabled={loading || !dirtyFields.dashboardName}
              _disabled={Platform.select({ web: { cursor: 'not-allowed' } })}
            >
              {t('button.confirm')}
            </Button>
          </Center>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

export default CreateDashboardModal
