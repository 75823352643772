import React, { forwardRef } from 'react'

import useDashboards from '../../../../hooks/dashboard/useDashboards'

import DashboardMenuItems from './MenuItems'
import { IDashboard } from '@dash.bar/types'

const DashboardsSidebar = forwardRef(() => {
  const dashboards = useDashboards() || {}

  const items = [
    ...Object.entries(dashboards)
      .filter(([, entry]) => entry != null)
      .map(([key, { icon, title }]) => ({
        icon,
        title,
        path: `/dashboard/${key}`,
      })),
  ] as Array<IDashboard & { path: string }>

  return <DashboardMenuItems dashboards={items} />
})

export default DashboardsSidebar
