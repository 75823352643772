import React from 'react'
import { Button, Dialog, Portal } from 'react-native-paper'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import { Linking, Platform } from 'react-native'
import useStyles from '../../styles'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'

const ShopConnectDialog = ({
  showShopConnect,
  setShowShopConnect,
}: {
  showShopConnect: boolean
  setShowShopConnect: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const styles = useStyles()
  const hideDialog = () => setShowShopConnect(false)
  const { t } = useTranslation()

  const HandleClick = () => {
    const url = 'https://www.webstollen.de/dashbar-Plugin-Download'

    if (Platform.OS == 'web') {
      window.open(url, '_blank')
    } else {
      Linking.canOpenURL(url).then(async (supported) => {
        if (supported) {
          await Linking.openURL(url)
        } else {
          console.debug("Don't know how to open URI: " + url)
        }
      })
    }
  }

  return (
    <Portal>
      <Dialog style={styles.dialog} visible={showShopConnect} onDismiss={hideDialog}>
        <Dialog.Title>
          <ColoredText>{t('text.newUserTutorial.shopConnect.howToConnect')}</ColoredText>
        </Dialog.Title>
        <Dialog.Content>
          <ColoredText style={styles.text}>{t('text.newUserTutorial.shopConnect.step1')}</ColoredText>
          <ColoredText style={styles.text}>{t('text.newUserTutorial.shopConnect.step2')}</ColoredText>
          <ColoredText style={styles.text}>{t('text.newUserTutorial.shopConnect.step3')}</ColoredText>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => HandleClick()}>
            <ColoredText>Install Plugin{t('text.newUserTutorial.shopConnect.installPlugin')}</ColoredText>
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}
export default ShopConnectDialog
