import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { Bundle, Interval } from '../../types/booking'
import { amountIsMore, bundlePrice, calculateDateForInterval } from '../../utils/booking'
import useDocument from '../useDocument'
import useCurrentBundleBooking from './useCurrentBundleBooking'

/** Calculates the expected start and extension date for given bundle and interval
 *
 * @param bundle
 * @param interval
 * @returns
 */
const useBookingDates = (bundle: Bundle, interval: Interval): { startDate: Date; extensionDate: Date } => {
  const { company } = useContext(SessionContext)
  const currentBooking = useCurrentBundleBooking()
  const currentBundle = company?.bundle || Bundle.free
  const currentInterval = currentBooking.interval
  const bundleDoc = useDocument<Record<string, any>>('bundle', bundle)
  const currentBundleDoc = useDocument<Record<string, any>>('bundle', currentBundle)
  let isUpgrade = false
  if (bundleDoc?.sort > currentBundleDoc?.sort) {
    if (interval === Interval.month && currentInterval === Interval.year) {
    } else {
      isUpgrade = true
    }
  } else if (bundleDoc?.sort === currentBundleDoc?.sort) {
    if (interval === Interval.year && currentInterval === Interval.month) {
      isUpgrade = true
    }
  } else if (bundleDoc?.sort < currentBundleDoc?.sort) {
    if (
      interval === Interval.year &&
      currentInterval === Interval.month &&
      amountIsMore(bundlePrice(bundleDoc?.price, interval), bundlePrice(currentBundleDoc?.price, currentInterval))
    ) {
      isUpgrade = true
    }
  }
  if (isUpgrade) {
    return {
      startDate: new Date(),
      extensionDate: calculateDateForInterval(interval, new Date()),
    }
  }
  return {
    startDate: currentBooking?.endDate?.toDate() || new Date(),
    extensionDate: calculateDateForInterval(interval, currentBooking?.endDate?.toDate() || new Date()),
  }
}

export default useBookingDates
