import React from 'react'

import useDashboard from '../../../../hooks/dashboard/useDashboard'

import { useRouteMatch } from '@webstollen/react-native/lib/react-router'
import { isLoaded } from 'react-redux-firebase'
import { useTranslation } from 'react-i18next'

import HeaderWrapper from '../../../../layout/Header/Wrapper'
import FavoriteHeader from '../../../../layout/Header/FavoriteHeader'

const DashboardHeader = () => {
  const { t } = useTranslation()

  const {
    params: { dashboardId },
  } = useRouteMatch<{ dashboardId: string }>()

  const dashboard = useDashboard(dashboardId)

  if (!isLoaded(dashboard) || !dashboard) {
    return <HeaderWrapper title={t('nav.dashboard')} />
  }

  return <FavoriteHeader title={dashboard?.title ?? ''} subtitle={'Dashboard'} />
}

export default DashboardHeader
