import { useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { Platform, StyleSheet } from 'react-native'

const useStyles = () => {
  const background = useSecondaryBackground()
  return StyleSheet.create({
    headline: {
      marginVertical: '3%',
    },
    borderStyle: {
      borderWidth: 2,
      borderRadius: 16,
      padding: '1%',
    },

    wrapper: {
      marginTop: '1%',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: Platform.select({ web: '40%', default: '90%' }),
    },
    dialog: {
      backgroundColor: background,
      width: '40%',
      alignSelf: 'center',
      alignItems: 'center',
      borderRadius: 16,
    },
    text: {
      fontSize: 16,
      marginVertical: '2%',
    },
  })
}

export default useStyles
