import { useHighlightColor, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { StyleSheet } from 'react-native'
import colors from '../../constants/colors'
import sizes from '../../constants/sizes'

const useStyles = () => {
  return StyleSheet.create({
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      margin: 20,
    },
    subtitle: {
      fontSize: 18,
      margin: 10,
    },
    user: {
      alignItems: 'center',
      width: '45%',
      padding: 5,
      borderRadius: 15,
      margin: 5,
      borderWidth: 1,
      borderColor: useTextColor(),
    },
    userSelected: {
      borderColor: useHighlightColor(),
      borderWidth: 3,
    },
    button: {
      backgroundColor: useHighlightColor(),
      width: '100%',
      height: sizes.buttonHeight,
      borderRadius: 10,
      color: 'white',
    },
    deleteButton: {
      backgroundColor: colors.error,
      width: '100%',
      height: sizes.buttonHeight,
      borderRadius: 10,
      color: 'white',
    },
    buttonWrapper: {
      width: '30%',
      alignContent: 'center',
      justifyContent: 'center',
    },
    textSelected: {
      fontWeight: 'bold',
      color: useHighlightColor(),
    },
    avatar: {
      height: 50,
      width: 50,
      borderRadius: 50,
    },
    userWrapper: {
      marginVertical: 20,
      padding: 15,
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '50%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
}

export default useStyles
