import { Platform, StyleSheet } from 'react-native'
import { usePrimaryBackground} from '@webstollen/react-native/lib/hooks/useColors'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    content: {
      display: 'flex',
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      justifyContent: 'center',
      height: 240,
      marginHorizontal: Platform.select({ default: 8, web: 16 }),
      marginVertical: Platform.select({ default: 8, web: 16 }),
      padding: Platform.select({ default: 8, web: 32 }),
      borderRadius: 16,
    },
    avatar: {
      height: 25,
      width: 25,
      borderRadius: 12.5,
      borderWidth: 1,
      borderColor: usePrimaryBackground(),
      position: 'absolute',
    },
    basketIcon: {
      marginLeft: 5,
    },
  })
}

export default useStyles
