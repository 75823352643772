import { StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      padding: 8,
    },
  })
}
export default useStyles
