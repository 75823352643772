import { Appearance } from 'react-native-appearance'

import reduxCookiesMiddleware, { getStateFromCookies } from 'redux-cookies-middleware'
import { Languages } from '../../../types/language'

import { Themes } from '@webstollen/react-native/lib/types/theme'

const initialState = {
  base: {
    config: {
      language: Languages.DE,
      theme: Appearance.getColorScheme() === 'no-preference' ? Themes.Light : Appearance.getColorScheme(),
      fluid: true,
    },
  },
}

const paths = {
  'base.language': { name: 'db_lang' },
  'base.theme': { name: 'db_theme' },
  'base.config': { name: 'db_config' },
}

const cookieState = getStateFromCookies(initialState, paths)
//console.log(cookieState);

/*initialState = {
  ...initialState.base,
  ...cookieState.base,
};*/

export default {
  ...initialState.base,
  ...cookieState.base,
}

export const middleware = [reduxCookiesMiddleware(paths)]
