import React, { ReactNode } from 'react'
import { View, GestureResponderEvent, TouchableOpacity, TextStyle, ViewStyle } from 'react-native'

import useStyles from './styles'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import { WidgetSize } from '../../widgets/shop/widgets_v1/Widget'

interface IProps {
  testID?: string
  data: Array<{
    id: string
    icon?: ReactNode
    mainTitle?: ReactNode
    mainSubtitle?: ReactNode
    actionTitle?: ReactNode
    actionSubtitle?: ReactNode
    onPress?: (evt: GestureResponderEvent) => void
  }>

  size: WidgetSize

  styles?: PartialRecord<
    | 'container'
    | 'listItemAlternate'
    | 'listItem'
    | 'content'
    | 'thumbnailContent'
    | 'thumbnailWrapper'
    | 'title'
    | 'mainTitle'
    | 'actionTitleText'
    | 'subtitle'
    | 'mainSubtitle'
    | 'actionSubtitle',
    TextStyle | ViewStyle
  >
}

const ButtonWrapper = (props: { onPress?: (evt: GestureResponderEvent) => void } & View['props']) => {
  if (!!props.onPress) {
    return <TouchableOpacity {...props} />
  }

  return <View {...props} />
}

const ListView = ({ size, data, styles = {} }: IProps) => {
  const componentStyles = useStyles()

  return (
    <View style={[size, componentStyles.container, styles.container]}>
      {data.map(({ id, icon, mainTitle, mainSubtitle, actionTitle, actionSubtitle, onPress }, index: number) => (
        <View
          key={id}
          style={[
            componentStyles.listItem,
            styles.listItem,
            index % 2 && [componentStyles.listItemAlternate, styles.listItemAlternate],
          ]}
        >
          <ButtonWrapper onPress={onPress} style={[componentStyles.content, styles.content]}>
            <View style={[componentStyles.thumbnailContent, styles.thumbnailContent]}>
              <View style={[componentStyles.thumbnailWrapper, styles.thumbnailWrapper]}>{icon}</View>
            </View>
            <View style={{ flex: 1, flexDirection: 'column', width: '90%' }}>
              <View style={[componentStyles.title, styles.title]}>
                <BoldText numberOfLines={1} style={[componentStyles.mainTitle, styles.mainTitle]}>
                  {mainTitle}
                </BoldText>

                {typeof actionTitle === 'string' ? (
                  <BoldText numberOfLines={1} style={[componentStyles.actionTitleText, styles.actionTitleText]}>
                    {actionTitle}
                  </BoldText>
                ) : (
                  actionTitle
                )}
              </View>
              <View style={[componentStyles.subtitle, styles.subtitle]}>
                <ColoredText numberOfLines={1} style={[componentStyles.mainSubtitle, styles.mainSubtitle]}>
                  {mainSubtitle}
                </ColoredText>
                <ColoredText numberOfLines={1} style={[componentStyles.actionSubtitle, styles.actionSubtitle]}>
                  {actionSubtitle}
                </ColoredText>
              </View>
            </View>
          </ButtonWrapper>
        </View>
      ))}
    </View>
  )
}

export default ListView
