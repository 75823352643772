import React from 'react'

import { Linking, TouchableOpacity } from 'react-native'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import useStyles from '../styles'

import { IconName } from '@fortawesome/fontawesome-svg-core'

import { useHighlightColor, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import icons from '../../../../../../constants/icons'

const MaybeClickableIcon = ({ value, type }: { value?: string; type: 'mail' | 'phone' | 'history' }) => {
  const styles = useStyles()
  const highlight = useHighlightColor()
  const textColor = useTextColor()

  let icon: IconName

  let onPress: () => void

  switch (type) {
    case 'mail': {
      icon = 'envelope'
      onPress = () => Linking.openURL(`mailto:${value}`)
      break
    }
    case 'phone': {
      icon = 'phone'
      onPress = () => Linking.openURL(`tel:${value}`)
      break
    }
    case 'history':
      icon = 'map-signs'
      break
  }

  return (
    <TouchableOpacity disabled={!value} style={styles.contactButton} onPress={onPress}>
      <FontAwesomeIcon icon={[icons.faIconStyle, icon]} color={!!value ? highlight : textColor} />
    </TouchableOpacity>
  )
}

export default MaybeClickableIcon
