import React, { useState } from 'react'
import { Dimensions, View } from 'react-native'
import { get } from 'lodash'
import widgetDefinitions from '../../../../widgets'
import { FlatGrid } from 'react-native-super-grid'
import WidgetWithoutData from '../../../../components/Widget'
import MonospaceText from '@webstollen/react-native/lib/components/CustomText/MonospaceText'
import { IDashboard, ShopMap } from '@dash.bar/types'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import NewUserTutorial from '../NewCostumerTutorial/NewUserTutorial'

interface IProps {
  dashboardId: string
  dashboard: IDashboard
  shops: ShopMap
}

const Dashboard = ({ dashboard, dashboardId, shops }: IProps) => {
  const { widgets } = dashboard

  // Add "create new widget"-widget to list
  const widgetsWithAddNewWidget = [
    ...widgets,
    {
      settings: {
        shop: Object.keys(shops).map((shopname: string) => shopname)[0], // Use first shop, just to have any shop at all
      },
      type: 'shop.add_new_widget_v1',
      id: 'add_new_widget',
    },
  ]

  const filteredWidgets = widgetsWithAddNewWidget
    .map(({ type, ...rest }) => ({ config: get(widgetDefinitions, type), type, ...rest }))
    .filter(({ config }) => !!config)
    .filter(({ type }) =>
      [
        'misc.advertisement_v1',
        'shop.status_v1',
        'shop.conversion_v1',
        'shop.orders_v1',
        'shop.live_tracker_v1',
        'shop.runners_v1',
        'shop.sales_v1',
        'shop.order_value_v1',
        'shop.gender_v1',
        'shop.add_new_widget_v1',
      ].includes(type)
    )

  const { width: deviceWidth } = Dimensions.get('window')

  const widgetSpacing = 10
  const maximalWidth = 365
  const widgetWidth = deviceWidth > maximalWidth + 2 * widgetSpacing ? maximalWidth : deviceWidth - 2 * widgetSpacing

  const [staticDimension, setStaticDimension] = useState(2 * widgetSpacing + 1 * widgetWidth)

  if (isLoaded(shops) && isEmpty(shops)) {
    return <NewUserTutorial />
  }

  return (
    <View
      style={{ flex: 1, width: '100%', alignItems: 'center' }}
      onLayout={({ nativeEvent: { layout } }) => {
        const { width: spaceAvailable } = layout

        if (spaceAvailable < widgetWidth) {
          setStaticDimension(spaceAvailable)
          return
        }

        let nextWrapperWidth = staticDimension
        let wrapperWidth

        let widgetSpacingMultiplier = 2
        let widthMultiplier = 1

        do {
          wrapperWidth = nextWrapperWidth

          widgetSpacingMultiplier += 1
          widthMultiplier += 1

          nextWrapperWidth = widthMultiplier * widgetWidth + widgetSpacingMultiplier * widgetSpacing
        } while (nextWrapperWidth < spaceAvailable)

        setStaticDimension(wrapperWidth)
      }}
    >
      <FlatGrid
        data={filteredWidgets}
        renderItem={({ item: { config, ...widget } }) => {
          return config ? (
            <config.component {...{ dashboardId, config, widget, shops }} />
          ) : (
            <WidgetWithoutData title={widget.type} footerContent={widget.id}>
              <MonospaceText>{JSON.stringify(widget, null, 2)} </MonospaceText>
            </WidgetWithoutData>
          )
        }}
        keyExtractor={({ id }) => id}
        staticDimension={staticDimension}
        itemDimension={widgetWidth}
        spacing={widgetSpacing}
        style={[
          {
            flex: 1,
            width: '100%',
          },
        ]}
        contentContainerStyle={[
          {
            alignItems: 'center',
            width: '100%',
          },
        ]}
      />
    </View>
  )
}

export default Dashboard
