import { useCallback, useContext } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { v4 } from 'uuid'

import { IWidget } from '@dash.bar/types'
import { SessionContext } from '../../context/SessionContext'

const useUpdateWidgets = (dashboardId: string) => {
  const firestore = useFirestore()
  const { user } = useContext(SessionContext)
  return useCallback(
    async (widgets: Array<IWidget>) => {
      const newWidgets = widgets.map((widget) => ({
        id: widget.id ?? v4(),
        ...widget,
      }))

      return firestore
        .collection(`company/${user?.company ?? '-'}/dashboard`)
        .doc(dashboardId)
        .update({
          widgets: newWidgets,
        })
    },
    [dashboardId, firestore, user?.company]
  )
}

export default useUpdateWidgets
