import React, { ReactNode } from 'react'
import { View, TouchableOpacity, SectionList as RNSectionList, TextStyle, SectionListProps } from 'react-native'

import useStyles from './styles'

import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

interface IProps<T> {
  data?: Array<{ title: string; data: Array<T>; index: number }>
  refreshing?: boolean
  fields?: Array<{
    header?: string
    width: number
    key: Extract<keyof T, string>
    sort?: boolean
    formatter?: (input: string | number, item: T) => ReactNode
    CellComponent?: (args: unknown) => JSX.Element
    style?: TextStyle
  }>
  onRowClick?: (arg: T) => void
}

const SectionList = <T extends { id?: string | number; [key: string]: unknown }>({
  data,
  renderItem,
  fields,
  onRowClick,
  ...props
}: IProps<T> & Partial<SectionListProps<T>>) => {
  const styles = useStyles()

  return (
    <RNSectionList
      nestedScrollEnabled
      style={styles.listWrapper}
      sections={data}
      {...props}
      renderSectionHeader={({ section: { title } }) => (
        <View style={[styles.headerWrapper]}>
          <ColoredText style={styles.header}>{title}</ColoredText>
        </View>
      )}
      renderItem={
        renderItem ||
        (({ item, index }) => {
          const Component: React.ElementType = onRowClick ? TouchableOpacity : View

          return (
            <Component
              onPress={
                onRowClick &&
                (() => {
                  onRowClick(item)
                })
              }
              style={[styles.listItem, index % 2 && styles.listItemAlternate]}
            >
              {fields.map(({ key, width, CellComponent = ColoredText, formatter = (input) => input, style }) => (
                <CellComponent key={key} numberOfLines={1} style={[{ width: width }, styles.cell, style]}>
                  {formatter(item[key] as string, item)}
                </CellComponent>
              ))}
            </Component>
          )
        })
      }
      keyExtractor={(item) => `${item.id}`}
    />
  )
}

export default SectionList
