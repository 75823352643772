import React, { useContext, useRef, useState } from 'react'
import { Image, Platform, SafeAreaView, TouchableOpacity, Vibration, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { isEmpty, isLoaded } from 'react-redux-firebase'

import FlatList from 'react-native-draggable-flatlist'
import { useHistory, useRouteMatch } from '@webstollen/react-native/lib/react-router'

import useDashboard from '../../../../hooks/dashboard/useDashboard'

import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'

import useStyles from './styles'
import useUpdateWidgets from '../../../../hooks/widget/useUpdateWidgets'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'

import widgetDefinitions from '../../../../widgets'
import { get } from 'lodash'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import useRemoveWidget from '../../../../hooks/widget/useRemoveWidget'
import useRemoveDashboard from '../../../../hooks/dashboard/useRemoveDashboard'
import colors from '../../../../constants/colors'

import { useTranslation } from 'react-i18next'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import { SessionContext } from '../../../../context/SessionContext'
import DeleteWidgetButtonWithModal from './DeleteWidgetButtonWithModal'
import DeleteDashboardButtonWithModal from './DeleteDashboardButtonWithModal'
import NewUserTutorial from '../../../dashboard/components/NewCostumerTutorial/NewUserTutorial'
import icons from '../../../../constants/icons'
import SetDashboardAsMainButtonWithModal from './SetDashboardAsMainButtonWithModal'
import useSetDocument from '../../../../hooks/useSetDocument'
import { User } from '@dash.bar/types/user'
import PageWrapper from '../../../../components/PageWrapper'

const ManageDashboard = () => {
  // const defaultFavIcon = require('../../../../assets/imgs/dblogo.png')
  const styles = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const {
    params: { dashboardId },
  } = useRouteMatch<{ dashboardId: string }>()

  const dashboard = useDashboard(dashboardId)
  if (!dashboard) {
    history.push('..')
  }
  const { shops, company, currentUser } = useContext(SessionContext)
  const scrollView = useRef<ScrollView>(null)
  const updateWidgets = useUpdateWidgets(dashboardId)
  const removeWidget = useRemoveWidget(dashboardId)

  const widgets = dashboard?.widgets
  const [data, setData] = useState(widgets)
  const [outerScrollEnabled, setOuterScrollEnabled] = useState(true)
  const highlightColor = useHighlightColor()

  const removeDashboard = useRemoveDashboard()
  const manageDashboard = (company?.authorizations['dashboard.edit'] as boolean) ?? false
  const updateUser = useSetDocument<User>('user')

  if (!isLoaded(dashboard, shops)) {
    return <LoadingScreen />
  }

  if (isEmpty(shops)) {
    return <NewUserTutorial />
  }

  //TODO: IMPORTANT: The scroll behaviour doesnt work as expected as long as there is a ScrollView in AppLayout (nestedScrollviews) --> Remove outer ScrollView
  // https://snack.expo.dev/@computerjazz/rndfl-nested-scrollview-example
  return (
    <SafeAreaView
      style={{
        flex: 1,
      }}
    >
      <PageWrapper isScrollable={true} scrollEnabled={outerScrollEnabled}>
        <FlatList
          data={data ?? []}
          simultaneousHandlers={scrollView}
          style={styles.wrapper}
          contentContainerStyle={styles.container}
          activationDistance={50}
          keyExtractor={({ id }) => id}
          //getItemLayout={(_, index) => ({ length: 55, offset: 55 * index, index })}
          //initialScrollIndex={(widgets?.length ?? 2) - 2}
          renderItem={({
            item: {
              id,
              type,
              settings: { shop },
            },
            item,
            drag,
            isActive,
          }) => {
            return (
              <View style={[styles.widget /*isActive && styles.selectedWidget*/]}>
                <View
                  style={{
                    height: 25,
                    width: 25,
                    backgroundColor: colors.lightBg1,
                    borderRadius: 12.5,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {['misc.advertisement_v1'].includes(type) ? (
                    <FontAwesomeIcon icon={[icons.faIconStyle, 'dashbar' as IconName]} color={colors.color3} />
                  ) : (
                    <Image
                      style={styles.widgetIcon}
                      height={25}
                      width={25}
                      resizeMode={'cover'}
                      source={{
                        uri: shops[shop]?.icon,
                      }}
                    />
                  )}
                </View>
                <ColoredText style={[styles.widgetText /*isActive && styles.selectedWidgetText*/]}>
                  {get(widgetDefinitions, type)?.title}
                </ColoredText>
                {!isActive ? (
                  <>
                    <TouchableOpacity
                      onLongPress={Platform.select({
                        native: () => {
                          Vibration.vibrate(20)
                          drag()
                        },
                        web: undefined,
                      })}
                      onPressIn={Platform.select({
                        web: () => {
                          drag()
                        },
                        native: undefined,
                      })}
                      style={styles.widgetButton}
                    >
                      <FontAwesomeIcon icon={[icons.faIconStyle, 'arrows-v']} />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        if (manageDashboard) {
                          history.push(`${dashboardId}/edit-widget/${id}`)
                        } else {
                          history.push('/upselling')
                        }
                      }}
                      style={styles.widgetButton}
                    >
                      <FontAwesomeIcon icon={[icons.faIconStyle, 'pen']} />
                    </TouchableOpacity>
                  </>
                ) : null}
                <DeleteWidgetButtonWithModal
                  removeWidget={removeWidget}
                  updateData={setData}
                  mayManageDashboard={manageDashboard}
                  widget={item}
                  widgets={widgets}
                />
              </View>
            )
          }}
          scrollEnabled={false}
          onDragEnd={({ data }) => {
            setOuterScrollEnabled(true)
            setData(data)
            updateWidgets(data)
          }}
          onDragBegin={() => {
            console.debug('(ScrollView) Drag starts')
            setOuterScrollEnabled(false)
            console.debug('(ScrollView) State: ' + outerScrollEnabled)
          }}
          ListFooterComponentStyle={{ marginBottom: 20 }}
          ListFooterComponent={() => (
            <>
              <TouchableOpacity
                onPress={() => {
                  history.push(`${dashboardId}/create-widget`)
                }}
                style={styles.manageDashboardButton}
              >
                <FontAwesomeIcon icon={[icons.faIconStyle, 'plus']} color={highlightColor} />
                <BoldText style={[styles.widgetText, { color: highlightColor }]}>{t('dashboard.addWidget')}</BoldText>
              </TouchableOpacity>
              <SetDashboardAsMainButtonWithModal
                mayManageDashboard={manageDashboard}
                setDashboardAsMain={() => updateUser({ mainDashboard: dashboardId }, currentUser.uid)}
                dashboardTitle={dashboard.title}
              />
              <DeleteDashboardButtonWithModal
                mayManageDashboard={manageDashboard}
                removeDashboard={() => removeDashboard(dashboardId)}
              />
            </>
          )}
        />
      </PageWrapper>
    </SafeAreaView>
  )
}

export default ManageDashboard
