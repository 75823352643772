import * as yup from 'yup'
import BillingAddressValidator from './BillingAddressValidator'
import { functions } from '../firebase'

const CompanyValidator = yup.object().shape({
  billingAddress: BillingAddressValidator,
  ustId: yup
    .string()
    .required()
    .test('vat-id-check', 'VAT invalid', async (value) => {
      const vatCheck = functions().httpsCallable('calls-vatCheck')
      const result = await vatCheck(value)
      return !!(result && result.data)
    }),
  name: yup.string().required(),
  supportEmail: yup.string().email(),
})

export default CompanyValidator
