import React from 'react'

import moment from 'moment'

import numbro from 'numbro'

import { useTranslation } from 'react-i18next'

import ListView from '../../../../components/ListView'

import htmlDecode from '../../../../utils/htmlDecode'

import Widget from '../Widget'

import { IWidgetProps } from '../../../../types/props/Widget'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'

import useStyles from './styles'

import { useHistory } from '@webstollen/react-native/lib/react-router'
import getOrderStatusIcon from '../../../../utils/get-order-status-icon'
import { OrdersT } from '../../../../types/widgetDataTypes/types'
import { Platform } from 'react-native'

const Orders = ({ widget, ...props }: IWidgetProps) => {
  const styles = useStyles()
  const history = useHistory()

  const { t } = useTranslation()

  return (
    <Widget<[OrdersT]>
      title={t<string>('shop.widget.orders.title')}
      widget={widget}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, rowsPerPage = 4, interval = 'D30' }, { page = 1, search = '' } = {}) => ({
          body: {
            request: 'Analytics',
            widget: {
              order: {
                options: {
                  active: true,
                  details: false,
                  refresh: autoRefresh ? refreshInterval : undefined,
                  interval,
                  pagination: { descending: true, page, rowsPerPage },
                },
                search,
              },
            },
          },
          widget: 'order',
        }),
      ]}
      onClickWidget={() => {
        history.push(`/shop/${widget.settings.shop}/orders`)
      }}
      {...props}
    >
      {([, [{ order }], , , size]) => {
        return (
          <ListView
            size={size}
            styles={{
              thumbnailWrapper: styles.iconWrapper,
              actionTitleText: styles.orderValue,
              actionSubtitle: styles.actionSubtitle,
            }}
            data={order.map(({ id, buyer, status, orderNumber, total, currency, created }) => ({
              id,
              icon: (
                <FontAwesomeIcon
                  color={'white'}
                  icon={getOrderStatusIcon(status)}
                  size={Platform.select({ default: undefined, web: 'sm' })}
                />
              ),
              mainTitle: buyer || t('shop.widget.orders.customer'),
              mainSubtitle: orderNumber,
              actionTitle: numbro(total).formatCurrency({
                ...numbro.languageData().currencyFormat,
                currencySymbol: htmlDecode(currency),
                spaceSeparated: true,
              }),
              actionSubtitle: moment(created).format('L LT'),

              onPress: (event) => {
                event.preventDefault()
                history.push(`/shop/${widget.settings.shop}/orders/${id}`)
              },
            }))}
          />
        )
      }}
    </Widget>
  )
}

export default Orders
