import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import React from 'react'
import { View } from 'react-native'
import { Defs, LinearGradient, Stop } from 'react-native-svg'
import { AreaChart, Grid, YAxis } from 'react-native-svg-charts'
import XAxisIntervalLabels from '../../../../../../components/XAxisIntervalLabels'
import colors from '../../../../../../constants/colors'
import useStyles from './styles'

type PropsT = {
  data: Array<number>
  interval: string
  title: string
  color: string
}

const Gradient = ({ color, gradientID }: { color: string; gradientID: string }) => (
  <Defs>
    <LinearGradient id={gradientID} x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
      <Stop offset={'0%'} stopColor={color} stopOpacity={0.7} />
      <Stop offset={'100%'} stopColor={color} stopOpacity={0.1} />
    </LinearGradient>
  </Defs>
)

const AreaChartsAnalytics = ({ data, interval, title, color }: PropsT) => {
  const styles = useStyles()

  const verticalContentInset = {
    top: 10,
    bottom: 10,
  }

  const gradientID = `gradient-${title}`

  return (
    <View style={styles.wrapper}>
      <ColoredText style={styles.title}>{title}</ColoredText>
      <View style={styles.lineChartWrapper}>
        <YAxis
          data={data}
          style={styles.yAxis}
          contentInset={verticalContentInset}
          svg={{
            fontSize: 10,
            fill: colors.text,
            //fontFamily: Platform.select({ default: 'Comfortaa_300Light', web: 'Comfortaa_500Medium' }),
          }}
        />
        <View style={styles.innerLineChartWrapper}>
          <AreaChart
            style={styles.lineChart}
            data={data}
            contentInset={verticalContentInset}
            svg={{ fill: `url(#${gradientID})` /* , stroke: color, strokeWidth: 2 */ }}
          >
            <Grid />
            <Gradient color={color} gradientID={gradientID} />
          </AreaChart>
          <XAxisIntervalLabels data={data} interval={interval} />
        </View>
      </View>
    </View>
  )
}

export default AreaChartsAnalytics
