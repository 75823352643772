import { useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'
import { StyleSheet } from 'react-native'
import sizes from '../../../../../../../constants/sizes'

const useStyles = () => {
  return StyleSheet.create({
    modalWrapper: {
      width: '100%',
      paddingHorizontal: 30,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    modalFormWrapper: {
      width: '50%',
    },
    cancelButton: {
      borderRadius: 15,
      borderColor: useHighlightColor(),
      borderWidth: 1,
      height: sizes.buttonHeight,
      width: '100%',
    },
    refresh_text: {
      textDecorationLine: 'underline',
    },
  })
}

export default useStyles
