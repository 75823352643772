import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Platform, StyleSheet } from 'react-native'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      marginHorizontal: 16,
      marginVertical: 8,
      padding: 8,
      borderRadius: 16,
      height: 200,
    },
    title: {
      fontSize: 18,
    },
    status: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignContent: 'stretch',
      flex: 1,
    },
    statusItem: {
      flexDirection: 'row',
      width: '50%',
    },

    itemText: {
      fontSize: 15,
      paddingLeft: 10,
      alignSelf: 'center',
      lineHeight: Platform.select({ default: 15, ios: 18 }),
    },
  })
}

export default useStyles
