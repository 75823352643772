import { OrderByOptions, useFirestoreConnect, WhereOptions } from 'react-redux-firebase'
import { IDProp, SelectorStateType } from '../types/firestore'
import md5 from 'md5'
import { useSelector } from 'react-redux'

const useCollectionGroup = <T>(
  collection: string,
  where?: WhereOptions | Array<WhereOptions>,
  orderBy?: OrderByOptions | Array<OrderByOptions>,
  limit = 15
): undefined | Record<string, T & IDProp> | null => {
  const storeHash: string = (where ? `${collection}_${md5(JSON.stringify([where]))}` : collection).replace(/\//g, '_') //changed replaceAll to replace because it caused errors on android

  useFirestoreConnect({
    collectionGroup: collection,
    orderBy: orderBy,
    limit: limit,
    where: where,
    storeAs: storeHash,
  })
  return useSelector((state: SelectorStateType<T>) => {
    if (!where) {
      if (state.firestore.data[storeHash] === undefined) {
        return undefined
      }
      return (state.firestore.data[storeHash] as Record<string, T & IDProp>) || null
    }

    if (state.firestore.ordered[storeHash] === undefined) {
      return undefined
    }
    return (state.firestore.ordered[storeHash] as Record<string, T & IDProp>) || null
  })
}

export default useCollectionGroup
