import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { IShop } from '@dash.bar/types'
import useStyles from './styles'
import IntervalSelectionDetail from '../../../../../components/IntervalSelectionDetail'
import ConversionStatistics from './ConversionStatistics'
import OrderValueStatistics from './OrderValueStatistics'
import KeyFigureStatistics from './KeyFigureStatistics'

type PropsT = {
  shop: IShop
}

const AnalyticsV1 = ({ shop }: PropsT) => {
  const styles = useStyles()

  const [interval, setInterval] = useState('D30')

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View style={styles.intervalSelectionWrapper}>
        <IntervalSelectionDetail currentInterval={interval} onIntervalChange={setInterval} />
      </View>
      <ConversionStatistics shop={shop} interval={interval} />
      <OrderValueStatistics shop={shop} interval={interval} />
      <KeyFigureStatistics shop={shop} interval={interval} />
    </ScrollView>
  )
}

export default AnalyticsV1
