import React from 'react'
import { IShop } from '@dash.bar/types'
import { TrackerDataT } from '../../../../../../types/widgetDataTypes/types'

import Item from './types/Item'
import News from './types/News'
import Search from './types/Search'
import PageView from './types/PageView'
import EMail from './types/EMail'
import Newsletter from './types/NewsLetter'
import Login from './types/Login'
import Registration from './types/Registration'
import WishList from './types/WishList'
import Basket from './types/Basket'
import Coupon from './types/Coupon'
import Order from './types/Order'
import BasketRemoved from './types/BasketRemoved'

type PropsT = {
  shop: IShop
  item: TrackerDataT
}

const VisitorTimelineItem = ({ shop, item }: PropsT) => {
  switch (item.type) {
    case 0:
      return <Item shop={shop} item={item} />

    case 1:
      return <News item={item} />

    case 2:
      return <Search item={item} />

    case 3:
      return <PageView item={item} />

    case 4:
      return <EMail item={item} />

    // Wird nicht bei Newsletter anmeldung getriggert
    // stadtdessen wird bloß ein Seitenaufruf getriggert
    // weiß nicht wie es aufgebaut ist
    case 5:
      return <Newsletter />

    case 6:
      return <Login item={item} />

    case 7:
      return <Registration item={item} />

    // Wunschliste
    // Nicht in Webstollen und Fischfuttertreff implementiert deshalb jetzt einfach wie bei Artikel
    case 8:
      return <WishList shop={shop} item={item} />

    case 9:
      return <Basket shop={shop} item={item} />

    case 10:
      return <Coupon item={item} />

    case 11:
      return <Order shop={shop} item={item} />

    case 12:
      return <BasketRemoved shop={shop} item={item} />

    default:
      return null
  }
}
export default VisitorTimelineItem
