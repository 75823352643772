import { StyleSheet } from 'react-native'

import { usePrimaryBackground, useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: usePrimaryBackground(),
      // borderWidth: 2,
      // borderColor: 'blue',
    },
    container: {
      flex: 1,
      width: '100%',
      // display: 'flex',
      // flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-end',
      marginBottom: 5,
      // borderWidth: 2,
      // borderColor: 'green',
    },
    button: {
      marginHorizontal: 10,
      marginVertical: 1,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 50,
      // width: '100%',
      backgroundColor: useSecondaryBackground(),
      // borderWidth: 2,
      // borderColor: 'red',
    },
    buttonText: {
      width: '75%',
      // alignSelf: 'stretch',
    },
  })
}

export default useStyles
