import colors from '../constants/colors'
import { OrderStatusType } from './get-order-status-icon'

const getOrderStatusColor = (status: OrderStatusType): string => {
  switch (status) {
    case 'paid':
      return colors.success
    case 'shipped':
      return colors.color3
    case 'open':
      return colors.color1
    case 'pending':
      return colors.text
  }
}

export default getOrderStatusColor
