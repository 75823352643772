import React from 'react'
import { Image, Linking, Platform, View } from 'react-native'
import useStyles from '../../pages/Main/styles'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { News } from '../../../../types/Stream'
import { Buffer } from 'buffer'

const decode = (str: string) => Buffer.from(str, 'base64').toString('binary')
/*BuildNewsBoxText takes the text which containes encoded links in the following form: 
[_________________#____________] 
the first part represents the URL which we use to link to an other website, the second part 
is the link, that should be displayed*/
const BuildNewsBoxText = (text: string) => {
  const styles = useStyles()
  const text_arr = text.trim().split(/(\[\w+=*#\w+=*\])/) // <=== =? ist jetzt =*
  const output = []
  let i = 0
  for (const line of text_arr) {
    const match = line.match(/\[(\w+=*)#(\w+=*)\]/)
    if (match) {
      output.push(BuildLink(decode(match[1]), decode(match[2]), `nbb_${i++}`))
    } else {
      output.push(
        <ColoredText key={`nbb_${i++}`} style={styles.text}>
          {line}
        </ColoredText>
      )
    }
  }
  return output
}
const BuildLink = (url: string, displayURL: string, key: undefined | string | number = undefined) => {
  const styles = useStyles()
  if (Platform.OS == 'web') {
    return (
      <ColoredText
        key={key}
        style={styles.hyperLink}
        onPress={() => {
          window.open(url, '_blank')
        }}
      >
        {displayURL}
      </ColoredText>
    )
  } else {
    return (
      <ColoredText
        key={key}
        style={styles.hyperLink}
        onPress={() => {
          Linking.openURL(url)
        }}
      >
        {displayURL}
      </ColoredText>
    )
  }
}
const BuildNewsBoxImage = ({ mediaUrl }: { mediaUrl: string }) => {
  const styles = useStyles()
  const mediaPictureUrl = { uri: mediaUrl }
  if (mediaUrl) return <Image source={mediaPictureUrl} resizeMode="contain" style={styles.picture} />
  return null
}

const NewsBoxBody = ({ text, mediaUrl }: News) => {
  const styles = useStyles()
  return (
    <View style={styles.bodyWrapper}>
      {BuildNewsBoxText(text)}
      <BuildNewsBoxImage mediaUrl={mediaUrl} />
    </View>
  )
}

export default NewsBoxBody
