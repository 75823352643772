import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AuthProvider as AuthProviderT } from '@firebase/auth-types'
import { useFirebase } from 'react-redux-firebase'
import AuthError from '../../../utils/AuthError'
import SignupView from '../../../components/Signup'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import { SessionContext } from '../../../context/SessionContext'
import { functions } from '../../../firebase'
import firebase from 'firebase/app'
import 'firebase/functions'
import { useParams } from 'react-router-dom'
import { View } from 'native-base'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useTranslation } from 'react-i18next'
import Logo from '../../../components/Logo'
import useStyles from './styles'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import icons from '../../../constants/icons'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { getTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import Button from '../../../components/Button'

const Signup = () => {
  const firebaseHook = useFirebase()
  const history = useHistory()
  const styles = useStyles()
  const { t } = useTranslation()
  const { invitationCode } = useParams<{ invitationCode: string }>()
  const [invitationEmail, setInvitationEmail] = useState<string | null>(null)
  const { isLoaded, currentUser } = useContext(SessionContext)

  const [signupState, setSignupState] = useState<{
    error?: AuthError
    loading: boolean
    invitationError?: boolean
    invitationLoading?: boolean
  }>({
    error: undefined,
    loading: false,
    invitationError: false,
    invitationLoading: false,
  })
  useEffect(() => {
    if (invitationCode) {
      setSignupState({ loading: false, invitationLoading: true })
      firebaseHook
        .firestore()
        .collection(`invitations`)
        .doc(invitationCode)
        .get()
        .then((result) => {
          if (result.exists) {
            setInvitationEmail(result.data().email)
            if (result.data().status === 0 || result.data().useCounter >= result.data().maxUses) {
              setSignupState({ loading: false, invitationError: true, invitationLoading: false })
            } else {
              setSignupState({ loading: false, invitationError: false, invitationLoading: false })
            }
          } else {
            setSignupState({ loading: false, invitationError: true, invitationLoading: false })
          }
        })
        .catch(() => {
          setSignupState({ loading: false, invitationError: true, invitationLoading: false })
        })
    }
  }, [invitationCode])

  useEffect(() => {
    if (isLoaded && currentUser) {
      history.push('/')
    }
  }, [history, isLoaded, currentUser])
  const createFirebaseUser = useCallback(
    async (user: firebase.auth.UserCredential) => {
      const createUser = functions().httpsCallable('calls-user-createUser')
      const result = await createUser({
        user: { uid: user.user.uid, email: user.user.email },
        invitationCode: invitationCode,
      })
      console.debug('(Signup) Result:' + result.data)
      return result
    },
    [invitationCode]
  )

  const handleSignup = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      setSignupState({ loading: true })
      if (invitationEmail !== null && invitationEmail !== email) {
        setSignupState({ loading: false, error: new AuthError('wrongEmail') })
        return
      }
      // if (process.env.NODE_ENV === 'production') {
      //   setSignupState({ loading: false, error: new AuthError('signupDisabled') })
      //   return
      // }

      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (user) => {
          createFirebaseUser(user).then(async () => {
            console.debug('(SignUp) Get new Token from User')
            await firebase.auth().currentUser.getIdToken(true)
            firebase
              .auth()
              .currentUser.getIdTokenResult()
              .then((result) => {
                console.debug('(SignUp) New Token company: ' + result.claims.company)
              })
            setSignupState({ loading: false })
          })
          user.user.sendEmailVerification()
        })
        //.catch(({ code }: { code: string }) => setSignupState({ loading: false, error: new AuthError(code) }))
        .catch(({ code }: { code: string }) => {
          setSignupState({ loading: false })
          console.debug('(Signup) Error:' + code)
        })
    },
    [firebaseHook, setSignupState, createFirebaseUser]
  )

  const handleProviderSignup = useCallback(
    (provider: AuthProviderT) => {
      setSignupState({ loading: true })

      // if (process.env.NODE_ENV === 'production') {
      //   setSignupState({ loading: false, error: new AuthError('signupDisabled') })
      //   return
      // }

      firebaseHook
        .auth()
        .signInWithPopup(provider)
        .then(async (user) => {
          if (invitationEmail !== null && invitationEmail !== user.user.email) {
            user.user.delete().then(() => {
              setSignupState({ loading: false, error: new AuthError('wrongEmail') })
            })
          } else {
            await createFirebaseUser(user)
            setSignupState({ loading: false })
          }
        })
        .catch(({ code }: { code: string }) => setSignupState({ loading: false, error: new AuthError(code) }))
    },
    [firebaseHook, setSignupState, createFirebaseUser]
  )
  const alert =
    signupState.error && !signupState.loading
      ? {
          content: signupState.error.message,
          color: 'error' as 'error' | 'success',
        }
      : undefined
  if (signupState.invitationLoading) {
    return <LoadingScreen />
  } else {
    if (signupState.invitationError) {
      return (
        <>
          <View style={styles.container}>
            <View style={styles.wrapper}>
              <Logo />

              <View style={[styles.hairLine]} />
              <View style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ margin: 5 }}>
                  <FontAwesomeIcon icon={[icons.faIconStyle, 'exclamation-circle']} size={'2x'} />
                </View>
                <View style={[styles.container, {}]}>
                  <ColoredText style={{ textAlign: 'center', fontSize: 25 }}>{t('error.invitation.title')}</ColoredText>
                  <ColoredText style={{ textAlign: 'center' }}>{t('error.invitation.text')}</ColoredText>
                </View>
              </View>
              <View style={[styles.hairLine]} />
              <View style={[styles.container]}>
                <Button to={'/'} style={styles.homeButton}>
                  <FontAwesomeIcon color={getTextColor(Themes.Dark)} icon={[icons.faIconStyle, 'home']} />
                </Button>
              </View>
            </View>
          </View>
        </>
      )
    } else {
      return (
        <SignupView
          {...{
            alert,
            loading: signupState.loading,
            handleSignup,
            handleProviderSignup,
          }}
        />
      )
    }
  }
}

export default Signup
