import { IShop } from '@dash.bar/types'
import { useCallback, useEffect } from 'react'
import api from '../../../../../widgets/shop/api'

import { UserT } from '../../../../../types/widgetDataTypes/types'
import useApiData from '../../../../../hooks/api/useApiData'

const useVisitor = ({ shop, visitorId }: { shop: IShop; visitorId: string }) => {
  const loadData = useCallback(async () => {
    const result: UserT = await api(shop.endpoint, shop.secretToken, {
      body: {
        request: 'EventTracker',
        widget: {
          visitor: {
            id: visitorId,
          },
        },
      },
      widget: 'visitor',
    })

    return result
  }, [shop, visitorId])

  const { getData, state } = useApiData(loadData)

  useEffect(() => {
    getData()
  }, [getData])

  return state
}

export default useVisitor
