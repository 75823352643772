import { IconName } from '@fortawesome/pro-solid-svg-icons'

export const getIcon = (type?: number): IconName => {
  switch (type) {
    // Artikel ohne Thumbnail
    case 1:
      return 'pizza-slice'
    // Versandpos
    case 2:
      return 'shipping-fast'
    // Kupon & Neukundenkupon
    case 3:
      return 'percent'
    // Gutschein
    case 4:
      return 'money-check'
    // Zahlungsart
    case 5:
      return 'credit-card'
    // Versandzuschlag & Versand Artikelabh. & Verpackung
    case 6:
      return 'box-usd'
    // Zahlungsartgebühr
    case 7:
      return 'hand-holding-usd'
    // Gratisgeschenk
    case 8:
      return 'gift'
    // Zinsaufschlag
    case 9:
      return 'badger-honey'
    // Bearbeitungsgebühr
    case 10:
      return 'shipping-timed'
    // Trusted Shops
    case 11:
      return 'shipping-timed'
    case null:
      return 'file-invoice'
    default:
      return 'empty-set'
  }
}
