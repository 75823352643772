import React /* ReactElement*/ from 'react'

import i18n from '../../locales/i18n'
import Shop from './pages/Main'
import Analytics from './pages/Analytics'
import Orders from './pages/Orders'
import Order from './pages/Order'
import LiveTracker from './pages/LiveTracker'
import LiveVisitor from './pages/LiveVisitor'
import OrderHeader from './pages/Order/Header'
import OrdersHeader from './pages/Orders/Header'
import LiveTrackerHeader from './pages/LiveTracker/Header'
import LiveVisitorHeader from './pages/LiveVisitor/Header'
import AnalyticsHeader from './pages/Analytics/Header'
import ContingentWrapper from '../../components/ContingentWrapper'

export default () => [
  {
    title: i18n.t('nav.shop'),
    name: 'shops',
    route: {
      exact: true,
      path: '/shop',
      children: (
        <ContingentWrapper>
          <Shop />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
  {
    title: <OrdersHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.orders'), default: <OrdersHeader /> }),
    name: 'orders',
    route: {
      exact: true,
      path: '/shop/:shopId/orders',
      children: (
        <ContingentWrapper>
          <Orders />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
  {
    title: <OrderHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.order'), default: <OrderHeader /> }),
    name: 'order',
    route: {
      exact: true,
      path: '/shop/:shopId/orders/:orderId',
      children: (
        <ContingentWrapper>
          <Order />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
  {
    title: <AnalyticsHeader />, //Show FavoriteIcon also on Web -----old:Platform.select<ReactElement>({ web: i18n.t('nav.analytics'), default: <AnalyticsHeader /> }),
    name: 'analytics',
    route: {
      exact: true,
      path: '/shop/:shopId/analytics',
      children: (
        <ContingentWrapper>
          <Analytics />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <LiveTrackerHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.live-tracker'), default: <LiveTrackerHeader /> }),
    name: 'live-tracker',
    route: {
      exact: true,
      path: '/shop/:shopId/live-tracker',
      children: (
        <ContingentWrapper>
          <LiveTracker />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: <LiveVisitorHeader />, //Show FavoriteIcon also on Web -----old: Platform.select<ReactElement>({ web: i18n.t('nav.live-visitor'), default: <LiveVisitorHeader /> }),
    name: 'live-visitor',
    route: {
      exact: true,
      path: '/shop/:shopId/live-tracker/:visitorId',
      children: (
        <ContingentWrapper>
          <LiveVisitor />
        </ContingentWrapper>
      ),
    },
  },
]
