import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AuthProvider as AuthProviderT, OAuthCredential as OAuthCredentialT } from '@firebase/auth-types'
import { useFirebase } from 'react-redux-firebase'
import AuthError from '../../../utils/AuthError'
import LoginView from '../../../components/Login'
import { SessionContext } from '../../../context/SessionContext'
import { useQuery } from '../../../utils/string'
import getApp, { functions } from '../../../firebase'
import { Redirect, useLocation } from '@webstollen/react-native/lib/react-router'

const Login = () => {
  const firebase = useFirebase()
  const { isLoaded, currentUser } = useContext(SessionContext)
  const query = useQuery()

  const [loginState, setLoginState] = useState<{
    error?: AuthError
    loading: boolean
    client_id?: string
    state?: string
    redirect_url?: string
    client?: Record<string, any>
  }>({
    error: undefined,
    loading: false,
    client_id: query.get('client_id'),
    state: query.get('state'),
  })

  useEffect(() => {
    if (loginState.state && loginState.client_id) {
      setLoginState((prevState) => ({ ...prevState, loading: true }))
      getApp()
        .firestore()
        .collection('oauth')
        .doc(loginState.client_id)
        .get()
        .then((data) => {
          console.log('OAUTH CLient', data.data())
          setLoginState((prevState) => ({
            ...prevState,
            client: data.data(),
            loading: false,
          }))
        })
    }
  }, [loginState.state, loginState.client_id])

  const handleProviderLogin = useCallback(
    (provider: AuthProviderT) => {
      setLoginState((prevState) => ({ ...prevState, loading: true }))
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(() => setLoginState((prevState) => ({ ...prevState, loading: false })))
        .catch((error: AuthError) => setLoginState((prevState) => ({ ...prevState, loading: false, error: error })))
    },
    [firebase, setLoginState]
  )

  const handleCredentialLogin = useCallback(
    (credential: OAuthCredentialT) => {
      setLoginState((prevState) => ({ ...prevState, loading: true }))
      firebase
        .auth()
        .signInWithCredential(credential)
        .then(() => setLoginState((prevState) => ({ ...prevState, loading: false })))
        .catch((error: AuthError) => setLoginState((prevState) => ({ ...prevState, loading: false, error: error })))
    },
    [firebase, setLoginState]
  )

  const handleLogin = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      setLoginState((prevState) => ({ ...prevState, loading: true }))
      firebase
        .auth()
        .signInWithEmailAndPassword(email.trim(), password.trim())
        .then(() => setLoginState((prevState) => ({ ...prevState, loading: false })))
        .catch(({ code }: { code: string }) =>
          setLoginState((prevState) => ({ ...prevState, loading: false, error: new AuthError(code) }))
        )
    },
    [firebase, setLoginState]
  )

  const currentLocation = useLocation<{ referrer: Location }>()

  useEffect(() => {
    if (isLoaded && currentUser && loginState.state && loginState.client_id) {
      functions()
        .httpsCallable('oauth-createOAuthToken')({
          clientId: loginState.client_id,
          state: loginState.state,
          userId: currentUser.uid,
        })
        .then((res) => {
          if (res.data?.code) {
            setLoginState((prevState) => ({ ...prevState, redirect_url: res.data.redirect_url }))
          }
        })
    }
  }, [isLoaded, currentUser, loginState.state, loginState.client_id])

  if (isLoaded && currentUser) {
    if (loginState.state && loginState.client_id) {
      if (loginState.redirect_url) {
        window.location.href = loginState.redirect_url
      }
      return (
        <div style={{ margin: '50px auto', backgroundColor: 'white', padding: '30px', borderRadius: '15px' }}>
          Du wirst gleich zu deiner App weitergeleitet...{' '}
          {loginState.redirect_url ? <a href={loginState.redirect_url}>weiter</a> : null}
        </div>
      )
    }
    return <Redirect to={currentLocation.state?.referrer.pathname || '/'} />
  }

  const alert =
    loginState.error && !loginState.loading
      ? {
          content: loginState.error.message,
          color: 'error' as 'error' | 'success',
        }
      : undefined

  return (
    <>
      {loginState.state && loginState.client ? (
        <div style={{ margin: '50px auto', backgroundColor: 'white', padding: '30px', borderRadius: '15px' }}>
          Verbinde deinen Account mit einem "{loginState.client.description}"-Plugin.
        </div>
      ) : null}
      <LoginView
        loading={loginState.loading}
        alert={alert}
        handleLogin={handleLogin}
        handleCredentialLogin={handleCredentialLogin}
        handleProviderLogin={handleProviderLogin}
      />
    </>
  )
}

export default Login
