import React from 'react'
import { NotType } from '@dash.bar/types/notification'
import { IDProp } from '../../../types/firestore'
import { NotificationSettings, User } from '@dash.bar/types/user'
import useNotifications from '../../../hooks/notifications/useNotifications'
import NotificationView from './NotificationView'
import NotificationFilterDialog from './NotificationFilterDialog'
import useDeleteDocument from '../../../hooks/useDeleteDocument'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'

const DisplayedNotificationsList = ({
  shops,
  user,
  isVisible,
  setisVisible,
}: {
  shops: Array<string>
  user: User & IDProp
  isVisible: boolean
  setisVisible: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [deletedNotificationIDs, setDeletedNotificationsIDs] = React.useState<Array<string>>([])
  const notificationList: Array<[string, NotType, string]> = useNotifications()
  const [filter, setFilter] = React.useState<NotificationSettings>({
    shopList: shops,
    notificationType: ['Bestellung', 'Bewertung', 'dash.bar Plugin', 'dash.bar'],
  })
  const { t } = useTranslation()

  //TODO: deleteNotification doesnt work properly
  const deleteNotification = useDeleteDocument(`company/${user.company ?? '-'}/notification`)
  const handleDelete = (id: string) => async () => await deleteNotification(id)

  const onDelete = (id: string) => {
    setDeletedNotificationsIDs([...deletedNotificationIDs, id])
    handleDelete(id)
  }
  if (notificationList.length === 0) return <LoadingScreen text={t('loading.notifications')} />

  // const filteredNotification = notificationList.filter((notification) => {
  //   // Filter if Notification is from Shop or has needed notificationType
  //   // TODO: replace notification.title with notification.type
  //   if (filter.shopList.includes(notification[2]) && filter.notificationType.includes(notification[1].title)) {
  //     if (deletedNotificationIDs.includes(notification[0])) return false
  //     return true
  //   }
  //   return false
  // })

  //TODO Replace notificationList with filteredNotification when filter works
  return (
    <>
      {/* {filteredNotification.map((n) => { */}
      {notificationList.map((n) => {
        return (
          <NotificationView key={n[0]} notification={n[1]} notificationID={n[0]} shopName={n[2]} onDelete={onDelete} />
        )
      })}

      <NotificationFilterDialog
        user={user}
        shops={shops}
        filter={filter}
        setFilter={setFilter}
        isVisible={isVisible}
        setisVisible={setisVisible}
      />
    </>
  )
}

export default DisplayedNotificationsList
