import React, { useContext } from 'react'

import { useRouteMatch } from '@webstollen/react-native/lib/react-router'

import { isLoaded } from 'react-redux-firebase'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useTranslation } from 'react-i18next'
import { SemVer } from 'semver'
import HeaderWrapper from '../../../../layout/Header/Wrapper'
import OrderHeaderV1 from './v1/Header'
import { SessionContext } from '../../../../context/SessionContext'

const OrderHeader = () => {
  const { t } = useTranslation()

  const match = useRouteMatch<{ shopId: string; orderId: string }>()

  const { shopId, orderId } = match.params

  const { shops } = useContext(SessionContext)
  const shop = shops[shopId]

  if (!isLoaded(shop)) {
    return <LoadingScreen text={t('loading.shop')} />
  }

  const version = new SemVer(shop.platform.sdkVersion)

  switch (version.major) {
    case 1:
      return <OrderHeaderV1 shop={shop} orderId={orderId} />
    default:
      return <HeaderWrapper title={t('nav.order')} />
  }
}

export default OrderHeader
