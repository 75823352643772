import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import React, { useContext, useRef, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { SessionContext } from '../../../../../../context/SessionContext'
import colors from '../../../../../../constants/colors'
import {
  currencyToSymbol,
  dashboardsForBundle,
  getCancelText,
  getCurrentBundleStateAsString,
  getTextForInterval,
  notificationsForBundle,
  shopsForBundle,
  userForBundle,
} from '../../../../../../utils/booking'
import useStyles from './styles'
import useDocument from '../../../../../../hooks/useDocument'
import Button from '../../../../../../components/Button'
import ComponentWrapper from '../../../../../../components/ComponentWrapper'
import BookingModal from '../BookingModal'
import useNextBundleBooking from '../../../../../../hooks/booking/useNextBundleBooking'
import useCurrentBundleBooking from '../../../../../../hooks/booking/useCurrentBundleBooking'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { Bundle, Interval } from '@dash.bar/types'
import ModalPopUp from '../../../../../../components/Modal'
import { functions } from '../../../../../../firebase'
import Pro from '../../../../../../assets/imgs/subscription/Pro'
import Business from '../../../../../../assets/imgs/subscription/Business'
import Free from '../../../../../../assets/imgs/subscription/Free'
import useCancelationPossible from '../../../../../../hooks/booking/useCancelationPossible'

const CurrentBundle = () => {
  const styles = useStyles()
  const { company, userIsAdmin, user } = useContext(SessionContext)
  const cancelBooking = functions().httpsCallable('calls-company-booking-cancel')
  const bundle = company?.bundle as Bundle
  const { t } = useTranslation()
  const modalRef = useRef<any>()
  const modalDeleteRef = useRef<any>()
  const bundleDoc = useDocument<Record<string, any>>('bundle', company?.bundle)
  const currentBooking = useCurrentBundleBooking()
  const isMigration: boolean = currentBooking?.isMigration || false
  const isTrial: boolean = currentBooking?.key === 'trial'
  const nextBooking = useNextBundleBooking()
  const canBeCanceled = useCancelationPossible()
  const currentInterval = currentBooking?.interval || Interval.year

  const [{ interval }, setIntervalState] = useState<{
    interval: Interval
  }>({
    interval: currentBooking?.interval || Interval.year,
  })

  const cancel = () => {
    cancelBooking({ companyId: user?.company, bookingId: currentBooking.id })
      .then(() => {})
      .finally(() => {
        if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(false)
      })
  }

  return (
    <ComponentWrapper title={t('subscription.your_subscription')}>
      <View
        style={[
          styles.wrapper,
          bundle === Bundle.pro
            ? styles.borderPro
            : bundle === Bundle.business
            ? styles.borderBusiness
            : styles.borderFree,
        ]}
      >
        <View
          style={[
            styles.titleWrapper,
            bundle === Bundle.free
              ? styles.backgroundFree
              : bundle === Bundle.pro
              ? styles.backgroundPro
              : styles.backgroundBusiness,
          ]}
        >
          <View style={styles.titleContentWrapper}>
            <ColoredText style={styles.title}>{`dash.bar ${bundle || Bundle.free}`}</ColoredText>
            <ColoredText style={styles.subtitle}>{`dash.bar ${bundle || Bundle.free} (${getTextForInterval(
              currentBooking ? currentBooking?.interval : Interval.month,
              isTrial
            )})`}</ColoredText>
            <ColoredText style={styles.subtitle}>
              {getCurrentBundleStateAsString(currentBooking || undefined, nextBooking || undefined)}
            </ColoredText>
          </View>
          <View>{bundle === Bundle.pro ? <Pro /> : bundle === Bundle.business ? <Business /> : <Free />}</View>
        </View>
        <View style={styles.contentWrapper}>
          <View style={styles.bundleOptionsWrapper}>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{userForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{shopsForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{dashboardsForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{notificationsForBundle(bundle)}</ColoredText>
            </View>
          </View>
          <View style={styles.divider} />
          <View style={styles.amountWrapper}>
            <TouchableOpacity
              style={[styles.intervalCard, interval === Interval.month ? styles.selected : null]}
              onPress={() => {
                setIntervalState({ interval: Interval.month })
                if ((currentInterval !== Interval.month || isMigration || isTrial) && userIsAdmin) {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(true)
                }
              }}
            >
              <View style={styles.intervalWrapper}>
                <ColoredText style={styles.amountInterval}>
                  <>{t('subscription.interval.text_month')}</>
                </ColoredText>
                <ColoredText style={styles.amountValue}>{`${bundleDoc?.price?.month?.value}${currencyToSymbol(
                  bundleDoc?.price?.month?.currency
                )}`}</ColoredText>
                {interval === Interval.month ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.intervalCard, interval === Interval.year ? styles.selected : null]}
              onPress={() => {
                setIntervalState({ interval: Interval.year })
                if ((currentInterval !== Interval.year || isMigration || isTrial) && userIsAdmin) {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(true)
                }
              }}
            >
              <View style={styles.intervalWrapper}>
                <ColoredText style={styles.amountInterval}>
                  <>{t('subscription.interval.text_year')}</>
                </ColoredText>
                <ColoredText style={styles.amountValue}>{`${bundleDoc?.price?.year?.value}${currencyToSymbol(
                  bundleDoc?.price?.year?.currency
                )}`}</ColoredText>
                {interval === Interval.year ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
              </View>
            </TouchableOpacity>
            <View style={styles.deleteWrapper}>
              {userIsAdmin ? (
                <>
                  <Button
                    onPress={() => {
                      if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(true)
                    }}
                    disabled={!canBeCanceled}
                    style={styles.button}
                    textStyle={{ color: colors.error }}
                  >
                    <>{t('subscription.cancel_subscription')}</>
                  </Button>
                  <ColoredText style={styles.deleteText}>{getCancelText(currentBooking || undefined)}</ColoredText>
                </>
              ) : (
                <ColoredText>
                  <>{t('subscription.only_admins')}</>
                </ColoredText>
              )}
            </View>
          </View>
        </View>
      </View>
      <BookingModal ref={modalRef} bundle={bundle} interval={interval} />
      <ModalPopUp title={t('subscription.cancel_subscription_title')} ref={modalDeleteRef}>
        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <View style={styles.deleteModalWrapper}>
            <ColoredText>
              <>{t('subscription.cancel_subscription_text')}</>
            </ColoredText>
            <Button
              onPress={() => {
                if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(false)
              }}
              style={styles.cancelButton}
            >
              <>{t('subscription.cancel')}</>
            </Button>
            <Button onPress={() => cancel()} style={styles.deleteButton} textStyle={{ color: 'white' }}>
              <>{t('subscription.cancel_subscription')}</>
            </Button>
          </View>
        </View>
      </ModalPopUp>
    </ComponentWrapper>
  )
}

export default CurrentBundle
