import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import React from 'react'
import useStyles from '../../styles'
import { Button, Dialog, Portal } from 'react-native-paper'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'

const CreateDashboardDialog = ({
  showCreateDashboardDialog,
  setCreateDashboardDialog,
}: {
  showCreateDashboardDialog: boolean
  setCreateDashboardDialog: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const styles = useStyles()
  const hideDialog = () => setCreateDashboardDialog(false)
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Portal>
      <Dialog style={styles.dialog} visible={showCreateDashboardDialog} onDismiss={hideDialog}>
        <Dialog.Title>
          <ColoredText>{t('text.newUserTutorial.createDashboard.howToCreate')}</ColoredText>
        </Dialog.Title>
        <Dialog.Content>
          <ColoredText style={styles.text}>{t('text.newUserTutorial.createDashboard.step1')}</ColoredText>
          <ColoredText style={styles.text}>{t('text.newUserTutorial.createDashboard.step2')}</ColoredText>
          <ColoredText style={styles.text}>{t('text.newUserTutorial.createDashboard.step3')}</ColoredText>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => history.push('/settings/dashboards')}>
            <ColoredText>{t('text.newUserTutorial.createDashboard.createDashboard')}</ColoredText>
          </Button>
          <ColoredText></ColoredText>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}
export default CreateDashboardDialog
