import { IconProp } from '@fortawesome/fontawesome-svg-core'

const browserResult = (browser: string) => {
  if (browser?.split(' ')[0].toLowerCase() === 'mobile') {
    return browser?.split(' ')[1].toLowerCase()
  } else {
    return browser?.split(' ')[0].toLowerCase()
  }
}

export const getBrowserIcon = (browser: string): IconProp => {
  switch (browserResult(browser)) {
    case 'chrome':
      return ['fab', 'chrome']
    case 'firefox':
      return ['fab', 'firefox-browser']
    case 'edge':
      return ['fab', 'edge']
    case 'safari':
      return ['fab', 'safari']
    case 'opera':
      return ['fab', 'opera']
    default:
      return ['fad', 'browser']
  }
}
