import React from 'react'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { View } from 'native-base'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import BlumeDark from '../../assets/imgs/underConstruction/BlumeDark'
import BlumeLight from '../../assets/imgs/underConstruction/BlumeLight'

//TODO Bild aktualisieren

const NotFound = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden',
        flexWrap: 'wrap',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          height: '40%',
          width: '100%',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        {theme === Themes.Dark ? <BlumeDark scale="4" /> : <BlumeLight scale="4" />}
      </View>
      <ColoredText
        style={
          Platform.OS === 'web'
            ? { fontSize: 32, textAlign: 'center' }
            : { fontSize: 22, textAlign: 'center', padding: 5 }
        }
      >
        {t('components.notFound')}
      </ColoredText>
    </View>
  )
}

export default NotFound
