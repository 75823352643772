import React from 'react'
import './fontawesome/icon-library'
import './locales/i18n'
import 'react-native-get-random-values'
import './__ignore_warnings__'
import { KeyboardAvoidingView, Platform } from 'react-native'
import { BackButton, Route, Router, Switch } from '@webstollen/react-native/lib/react-router'
import { AppearanceProvider } from 'react-native-appearance'
import { Provider } from 'react-redux'
import AppLoading from 'expo-app-loading'
import {
  Comfortaa_300Light,
  Comfortaa_400Regular,
  Comfortaa_500Medium,
  Comfortaa_600SemiBold,
  Comfortaa_700Bold,
  useFonts,
} from '@expo-google-fonts/comfortaa'
import { initFontFamily } from '@webstollen/react-native/lib/hooks/useFontFamily'
import getApp from './firebase'
import { ReactReduxFirebaseProvider, ReduxFirestoreProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import { initColorMap } from '@webstollen/react-native/lib/hooks/useColors'
import colors from './constants/colors'
import { Provider as PaperProvider } from 'react-native-paper'
import * as Sentry from 'sentry-expo'
import { mySentry } from './utils/sentry'
import SessionProvider from './context/SessionContext'
import { SafeAreaProvider } from 'react-native-safe-area-context'
//import AppLayout from './layout/AppLayout'
import ErrorBoundary from '@webstollen/react-native/lib/components/ErrorBoundary'
// import Toast from 'react-native-toast-message'
import AppLayout from './layout/AppLayout'
import NoCompany from './module/base/page/NoCompany'
import ThemeLanguageWrapper from './common/ThemeLanguageWrapper'
import Login from './module/base/page/Login'
import Signup from './module/base/page/Signup'
import ResetPassword from './module/base/page/ResetPassword'
import store from './store'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import AccountManagement from './module/base/page/AccountManagement/AccountManagement'
import PrepareUser from './module/base/page/PrepareUser'
import PaymentCompleted from './module/upselling/pages/PaymentCompleted/PaymentCompleted'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(fas)

//if (process.env.NODE_ENV === 'production') {
Sentry.init({
  dsn: 'https://349609f9d8e2455b8be4c373912e026d@o1045631.ingest.sentry.io/6021106',
  enableInExpoDevelopment: true,
  debug: true,
  environment: process.env.NODE_ENV,
})
//}

if (process.env.NODE_ENV === 'production') {
  console.debug('Debug logging disabled!')
  console.debug = (..._: Array<any>): any => null
}

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')

  whyDidYouRender(React, {
    trackAllPureComponents: false,
    // trackExtraHooks: [[ReactRedux, 'useSelector']],
  })
}

const firebase = getApp()

const rrfConfig = {
  userProfile: 'user',
  useFirestoreForProfile: true,
  enableLogging: true,
  attachAuthIsReady: true,
  autoPopulateProfile: true,
  logErrors: true,
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

initFontFamily({
  light: 'Comfortaa_300Light',
  regular: 'Comfortaa_400Regular',
  medium: 'Comfortaa_500Medium',
  semibold: 'Comfortaa_600SemiBold',
  bold: 'Comfortaa_700Bold',
  extrabold: 'Comfortaa_700Bold',
  monospace: undefined,
})

initColorMap(colors)

const App = () => {
  const [fontsLoaded] = useFonts({
    Comfortaa_300Light,
    Comfortaa_400Regular,
    Comfortaa_500Medium,
    Comfortaa_600SemiBold,
    Comfortaa_700Bold,
  })
  if (!fontsLoaded) {
    return (
      <AppLoading
        onError={(e) => mySentry().captureException(e)}
        onFinish={() => console.debug('AppLoading Finished!')}
        startAsync={async () => console.debug('AppLoading startAsync')}
      />
    )
  }

  return (
    <Router>
      <AppearanceProvider>
        <Provider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <ReduxFirestoreProvider {...rrfProps}>
              <SessionProvider>
                <SafeAreaProvider>
                  <KeyboardAvoidingView
                    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                    style={{ display: 'flex', flex: 1, height: '100%' }}
                    contentContainerStyle={{ display: 'flex', flex: 1, height: '100%' }}
                  >
                    <ThemeLanguageWrapper>
                      <PrepareUser>
                        <GestureHandlerRootView style={{ flex: 1 }}>
                          <PaperProvider>
                            <BackButton />
                            <Switch>
                              <Route path="/payment-completed" component={PaymentCompleted} />
                              <Route path="/login" component={Login} />
                              <Route path="/signup/:invitationCode" component={Signup} />
                              <Route path="/signup" component={Signup} />
                              <Route path="/reset-password" component={ResetPassword} />
                              <Route path="/account-management" component={AccountManagement} />
                              <Route path="/no-company" component={NoCompany} />
                              <Route path="/" component={AppLayout} />
                            </Switch>
                          </PaperProvider>
                        </GestureHandlerRootView>
                      </PrepareUser>
                    </ThemeLanguageWrapper>
                  </KeyboardAvoidingView>
                  {/* <Toast position={'bottom'} bottomOffset={Platform.select({ web: 10, default: 100 })} /> */}
                </SafeAreaProvider>
              </SessionProvider>
            </ReduxFirestoreProvider>
          </ReactReduxFirebaseProvider>
        </Provider>
      </AppearanceProvider>
    </Router>
  )
}

const ErrorBoundApp = () => (
  <ErrorBoundary
    onError={(error: Error, stackTrace: string) => {
      mySentry().captureException(error)
      console.debug('ErrorBoundary')
      console.debug(error)
      console.debug(stackTrace)
    }}
  >
    <App />
  </ErrorBoundary>
)

export default ErrorBoundApp
