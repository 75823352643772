import React from 'react'

import { StyleSheet, TextInput, TextInputProps, View } from 'react-native'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useTextColor } from '@webstollen/react-native/lib/hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    input: {
      height: 30,
      borderBottomWidth: 1,
      padding: 5,
      margin: 5,
      flex: 1,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  })
}

const TextInputWithIcon = ({
  icon,
  refForward,
  style,
  ...props
}: TextInputProps & { icon: IconProp; refForward?: (ref: TextInput) => void }) => {
  const color = useTextColor()
  const styles = useStyles()
  return (
    <View style={styles.wrapper}>
      <FontAwesomeIcon icon={icon} />
      <TextInput ref={refForward} style={[styles.input, style]} {...props} placeholderTextColor={color} />
    </View>
  )
}

export default TextInputWithIcon
