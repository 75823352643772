import { StyleSheet } from 'react-native'

import { getTextColor, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'

const useStyles = () => {
  const textColor = useTextColor()

  return StyleSheet.create({
    textInput: {
      height: 30,
      color: textColor,
      borderColor: textColor,
      borderBottomWidth: 1,
      padding: 5,
      margin: 5,
    },
    wrapper: {
      display: 'flex',
    },
    button: {
      borderColor: textColor,
      borderWidth: 1,
      height: 40,
    },
    loginText: {
      color: getTextColor(Themes.Dark),
    },
  })
}

export default useStyles
