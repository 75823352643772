import React from 'react'

import { useTranslation } from 'react-i18next'

import { IWidgetProps } from '../../../../types/props/Widget'

import Widget from '../Widget'
import MixedCharts from '../../../../components/charts/MixedCharts'

import { useHistory } from '@webstollen/react-native/lib/react-router'
import { ConversionsT } from '../../../../types/widgetDataTypes/types'

const Conversion = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Widget<[ConversionsT]>
      title={t('shop.widget.conversion.title')}
      widget={widget}
      hasInterval
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval }) => ({
          body: {
            request: 'Analytics',
            widget: {
              conversion: {
                options: {
                  active: true,
                  details: false,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                },
              },
            },
          },
          widget: 'conversion',
        }),
      ]}
      showOptions
      onClickWidget={() => {
        history.push(`/shop/${widget.settings.shop}/analytics`)
      }}
      {...props}
    >
      {([, [data], , , size]) => {
        return <MixedCharts list={data} size={size} />
      }}
    </Widget>
  )
}

export default Conversion
