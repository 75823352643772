import { StyleSheet } from 'react-native'

import { useSecondaryBackground, useTextColor, useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'

import colors from '../../constants/colors'

const useStyles = () => {
  return StyleSheet.create({
    alert: {
      borderColor: colors.error,
      borderBottomWidth: 2,
      margin: 5,
      padding: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    alertText: {
      color: colors.error,
    },
    container: {
      backgroundColor: useSecondaryBackground(),
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      margin: 20,
      flexDirection: 'row',
    },
    wrapper: {
      backgroundColor: useSecondaryBackground(),
      padding: 20,
      borderRadius: 10,
      maxWidth: 400,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    hairLine: {
      marginVertical: 5,
      display: 'flex',
      borderBottomColor: useTextColor(),
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
    homeButton: {
      marginTop: 10,
      paddingHorizontal: 10,
      backgroundColor: useHighlightColor(),
    },
  })
}

export default useStyles
