import React, { useContext, useRef, useState } from 'react'
import { View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import useDocument from '../../../../../../hooks/useDocument'
import { SessionContext } from '../../../../../../context/SessionContext'
import useStyles from './styles'
import PageWrapper from '../../../../../../components/PageWrapper'
import Pro from '../../../../../../assets/imgs/subscription/Pro'
import Free from '../../../../../../assets/imgs/subscription/Free'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import {
  currencyToSymbol,
  dashboardsForBundle,
  getCancelText,
  getCurrentBundleStateAsString,
  getTextForInterval,
  notificationsForBundle,
  shopsForBundle,
  userForBundle,
} from '../../../../../../utils/booking'
import colors from '../../../../../../constants/colors'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { Interval } from '../../../../../../types/booking'
import Button from '../../../../../../components/Button'
import useCurrentBundleBooking from '../../../../../../hooks/booking/useCurrentBundleBooking'
import useNextBundleBooking from '../../../../../../hooks/booking/useNextBundleBooking'
import BookingModal from '../BookingModal'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { Bundle } from '@dash.bar/types'
import { functions } from '../../../../../../firebase'
import ModalPopUp from '../../../../../../components/Modal'
import Business from '../../../../../../assets/imgs/subscription/Business'
import useCancelationPossible from '../../../../../../hooks/booking/useCancelationPossible'

const DetailsCurrentBundle = () => {
  const { company, userIsAdmin, user } = useContext(SessionContext)
  const bundle = company?.bundle as Bundle
  const modalRef = useRef<any>()
  const modalDeleteRef = useRef<any>()
  const bundleDoc = useDocument<Record<string, any>>('bundle', bundle)
  const styles = useStyles()
  const { t } = useTranslation()
  const currentBooking = useCurrentBundleBooking()
  const isMigration: boolean = currentBooking?.isMigration || false
  const isTrial: boolean = currentBooking?.key === 'trial'
  const cancelBooking = functions().httpsCallable('calls-company-booking-cancel')
  const nextBooking = useNextBundleBooking()
  const canBeCanceled = useCancelationPossible()
  const currentInterval = currentBooking?.interval || Interval.year
  const [{ interval }, setIntervalState] = useState<{
    interval: Interval
  }>({
    interval: Interval.year,
  })

  const cancel = () => {
    cancelBooking({ companyId: user?.company, bookingId: currentBooking.id })
      .then(() => {})
      .finally(() => {
        if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(false)
      })
  }
  return (
    <PageWrapper isScrollable={true}>
      <View style={styles.wrapper}>
        <View style={styles.mobileCard}>
          <View
            style={[
              styles.bundleWrapper,
              bundle === Bundle.free
                ? styles.backgroundFree
                : bundle === Bundle.pro
                ? styles.backgroundPro
                : styles.backgroundBusiness,
            ]}
          >
            <ColoredText style={{ textAlign: 'center', marginBottom: 15, color: 'white' }}>{bundle}</ColoredText>
            <View>
              {bundle === Bundle.pro ? (
                <Pro width={90} height={40} viewBox="0 0 190 100" />
              ) : bundle === Bundle.business ? (
                <Business width={90} height={50} viewBox="20 5 190 120" />
              ) : (
                <Free width={90} height={60} viewBox="-35 0 190 140" />
              )}
            </View>
          </View>
          <View style={styles.bundleTextWrapper}>
            <ColoredText>{`dash.bar ${bundle}`}</ColoredText>
            <ColoredText>{getTextForInterval(currentInterval, isTrial)}</ColoredText>
            <ColoredText>{getCurrentBundleStateAsString(currentBooking, nextBooking)}</ColoredText>
          </View>
        </View>
        <View style={styles.bundleOptionsWrapper}>
          <View style={styles.itemWrapper}>
            <FontAwesomeIcon icon={'check'} color={colors.success} />
            <ColoredText style={styles.itemText}>{userForBundle(bundle)}</ColoredText>
          </View>
          <View style={styles.itemWrapper}>
            <FontAwesomeIcon icon={'check'} color={colors.success} />
            <ColoredText style={styles.itemText}>{shopsForBundle(bundle)}</ColoredText>
          </View>
          <View style={styles.itemWrapper}>
            <FontAwesomeIcon icon={'check'} color={colors.success} />
            <ColoredText style={styles.itemText}>{dashboardsForBundle(bundle)}</ColoredText>
          </View>
          <View style={styles.itemWrapper}>
            <FontAwesomeIcon icon={'check'} color={colors.success} />
            <ColoredText style={styles.itemText}>{notificationsForBundle(bundle)}</ColoredText>
          </View>
        </View>
        <ColoredText>Option:</ColoredText>
        <TouchableOpacity
          style={[styles.touchWrapper, interval === Interval.month ? styles.selected : null]}
          containerStyle={[styles.intervalCard]}
          onPress={() => {
            if ((currentInterval !== Interval.month || isMigration || isTrial) && userIsAdmin) {
              if (modalRef && modalRef.current) modalRef.current.setVisible(true)
            }
            setIntervalState({ interval: Interval.month })
          }}
        >
          <View style={styles.intervalWrapper}>
            <ColoredText style={styles.amountInterval}>
              <>{t('subscription.interval.text_month')}</>
            </ColoredText>
            <ColoredText style={styles.amountValue}>{`${bundleDoc?.price?.month?.value}${currencyToSymbol(
              bundleDoc?.price?.month?.currency
            )}`}</ColoredText>
            {interval === Interval.month ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.touchWrapper, interval === Interval.year ? styles.selected : null]}
          containerStyle={[styles.intervalCard]}
          onPress={() => {
            if ((currentInterval !== Interval.year || isMigration || isTrial) && userIsAdmin) {
              if (modalRef && modalRef.current) modalRef.current.setVisible(true)
            }
            setIntervalState({ interval: Interval.year })
          }}
        >
          <View style={styles.intervalWrapper}>
            <ColoredText style={styles.amountInterval}>
              <>{t('subscription.interval.text_year')}</>
            </ColoredText>
            <ColoredText style={styles.amountValue}>{`${bundleDoc?.price?.year?.value}${currencyToSymbol(
              bundleDoc?.price?.year?.currency
            )}`}</ColoredText>
            {interval === Interval.year ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
          </View>
        </TouchableOpacity>
        <View style={styles.buttonWrapper}>
          {userIsAdmin ? (
            <>
              <Button
                onPress={() => {
                  if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(true)
                }}
                style={styles.deleteButton}
                disabled={!canBeCanceled}
                textStyle={{ color: colors.error }}
              >
                <> {t('subscription.cancel_subscription')}</>
              </Button>
              <ColoredText style={styles.deleteText}>{getCancelText(currentBooking || undefined)}</ColoredText>
            </>
          ) : (
            <ColoredText>
              <> {t('subscription.only_admins')}</>
            </ColoredText>
          )}
        </View>
      </View>
      <BookingModal ref={modalRef} bundle={bundle} interval={interval} />
      <ModalPopUp title={t('subscription.cancel_subscription_title')} ref={modalDeleteRef}>
        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <View style={styles.deleteModalWrapper}>
            <ColoredText>
              <>{t('subscription.cancel_subscription_text')}</>
            </ColoredText>
            <Button
              onPress={() => {
                if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(false)
              }}
              style={styles.cancelButton}
            >
              <>{t('subscription.cancel')}</>
            </Button>
            <Button onPress={() => cancel()} style={styles.deleteSubscriptionButton} textStyle={{ color: 'white' }}>
              <>{t('subscription.cancel_subscription')}</>
            </Button>
          </View>
        </View>
      </ModalPopUp>
    </PageWrapper>
  )
}

export default DetailsCurrentBundle
