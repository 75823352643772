import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import moment from 'moment'
import React from 'react'
import { Linking, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useStyles from '../../styles'
import { TrackerDataT } from '../../../../../../../../types/widgetDataTypes/types'

import Url from 'url-parse'
import icons from '../../../../../../../../constants/icons'

type PropsT = {
  item: TrackerDataT
}

const PageView = ({ item }: PropsT) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const parsedUrl = (url: string) => {
    return new Url(url)
  }

  const pageViewDifferentiator = () => {
    if (item?.data?.paymentMethod !== undefined) {
      return (
        <View style={styles.flexDirectionRow}>
          <ColoredText style={styles.fontSize13}>{t('shop.live-visitor.paymentMethod')}: </ColoredText>
          <BoldText style={styles.textParagraph} numberOfLines={1}>
            {item?.data?.paymentMethod}
          </BoldText>
        </View>
      )
    } else if (item?.data?.shippingMethod !== undefined) {
      return (
        <View style={styles.flexDirectionRow}>
          <ColoredText style={styles.fontSize13}>{t('shop.live-visitor.shippingMethod')}: </ColoredText>
          <BoldText style={styles.textParagraph} numberOfLines={1}>
            {item?.data?.shippingMethod}
          </BoldText>
        </View>
      )
    } else if (item?.data?.paymentMethod !== undefined && item?.data?.paymentMethod !== undefined) {
      return (
        <>
          <View style={styles.flexDirectionRow}>
            <ColoredText style={styles.fontSize13}>{t('shop.live-visitor.paymentMethod')}: </ColoredText>
            <BoldText style={styles.textParagraph} numberOfLines={1}>
              {item?.data?.paymentMethod}
            </BoldText>
          </View>
          <View style={styles.flexDirectionRow}>
            <ColoredText style={styles.fontSize13}>{t('shop.live-visitor.shippingMethod')}: </ColoredText>
            <BoldText style={styles.textParagraph} numberOfLines={1}>
              {item?.data?.shippingMethod}
            </BoldText>
          </View>
        </>
      )
    } else {
      return null
    }
  }

  return (
    <View style={styles.wrapper}>
      <View style={styles.labelWrapper}>
        <BoldText style={styles.labelTitle}>{t('shop.live-visitor.pageview')}</BoldText>
        <View style={[styles.fontAwesomeWrapper, styles.backgroundColorPurple]}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'hand-pointer']} />
        </View>
      </View>
      <BoldText style={styles.pageViewUrl} onPress={() => Linking?.openURL(parsedUrl(item?.data?.page).href)}>
        {parsedUrl(item?.data?.page).pathname}
      </BoldText>
      {pageViewDifferentiator()}
      <View style={styles.timeWrapper}>
        <ColoredText style={styles.runtime}>
          {t('shop.live-visitor.runTime')}: {item?.runtime}
        </ColoredText>
        <ColoredText style={styles.textColor} numberOfLines={2}>
          {`${moment(item?.timestamp).fromNow()}`}
        </ColoredText>
      </View>
    </View>
  )
}
export default PageView
