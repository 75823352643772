import { StyleSheet } from 'react-native'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.darkBgTransparent6,
      marginVertical: 8,
      marginHorizontal: 8,
      borderRadius: 16,
      padding: 8,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
    },
    lineChartWrapper: {
      height: 240,
      padding: 20,
      flexDirection: 'row',
    },
    innerLineChartWrapper: {
      flex: 1,
    },
    lineChart: {
      marginLeft: 10,
      flex: 1,
    },
    yAxis: {
      marginBottom: 10,
    },
  })
}
export default useStyles
