import merge from 'deepmerge'

import widgets_v1 from './widgets_v1'
import widgets_v2 from './widgets_v2'

type Wv1 = typeof widgets_v1

type Wv2 = typeof widgets_v2

type Export = Wv1 & Wv2

export default merge.all([widgets_v1, widgets_v2]) as Export
