import React from 'react'
import numbro from 'numbro'
import { View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { IShop } from '@dash.bar/types'

import { useTranslation } from 'react-i18next'
import ProductImage from '../../../../../../components/ProductImage'
import useStyles from '../styles'

import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'
import { OrderPosition as OrderPositionT } from '../../../Orders/v1/types'

type PropsT = {
  shop: IShop
  position: OrderPositionT
}

const OrderPosition = ({ position, shop }: PropsT) => {
  const { t } = useTranslation()

  const styles = useStyles()

  const { currency } = shop
  const { id, name, type, amount, thumbnail, productId, config, price } = position

  return (
    <View key={`${id}-${type}`} style={styles.card}>
      <View style={styles.amountWrapper}>
        <ColoredText style={styles.highlightText}>{amount}</ColoredText>
      </View>
      <ProductImage photoURL={thumbnail} style={styles.productImage} />
      <View style={styles.articleTextWrapper}>
        <ColoredText>{name}</ColoredText>
        {productId != null ? (
          <ColoredText>
            {t('shop.order.artNo')}: {productId}
          </ColoredText>
        ) : null}
        {config.map(({ name, value }) => (
          <ColoredText key={`${name}-${value}`}>
            {name} {value}
          </ColoredText>
        ))}
      </View>
      <ColoredText style={styles.articleCost}>
        {numbro(price * amount).formatCurrency({
          ...numbro.languageData().currencyFormat,
          average: true,
          currencySymbol: ` ${htmlDecode(currency)}`,
        })}
      </ColoredText>
    </View>
  )
}

export default OrderPosition
