import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { BookingState, Bundle, Interval } from '../../types/booking'
import useCollection from '../useCollection'
import useCurrentBundleBooking from './useCurrentBundleBooking'

/** Checks if booking is possible for given bundle and interval
 *
 * @param bundle
 * @param interval
 * @returns
 */
const useBookingPossibleState = (bundle: Bundle, interval: Interval): boolean => {
  const { company } = useContext(SessionContext)
  const bookings = useCollection<any>(`company/${company?.id}/booking`)
  const currentBooking = useCurrentBundleBooking()
  const isMigration: boolean = currentBooking?.isMigration || false
  const isTrial: boolean = currentBooking?.key === 'trial'
  if (currentBooking.status === BookingState.Pending) {
    return false
  }
  if (
    currentBooking?.bundle?.toLowerCase() === bundle &&
    currentBooking?.interval === interval &&
    !isMigration &&
    !isTrial
  ) {
    return false
  }
  if (Object.keys(bookings || {}).length > 1) {
    return false
  }
  return true
}

export default useBookingPossibleState
