import { StyleSheet } from 'react-native'
import { usePrimaryBackground, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    avatars: { flexDirection: 'row', position: 'relative' },
    content: {
      display: 'flex',
      backgroundColor: 'transparent',
      width: '100%',
      maxHeight: '100%',
      justifyContent: 'center',
      padding: 2,
      overflow: 'hidden',
    },
    avatar: {
      height: 25,
      width: 25,
      borderRadius: 12.5,
      borderWidth: 1,
      borderColor: usePrimaryBackground(),
    },
    actionWrapper: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    basketValue: {
      paddingLeft: 10,
    },
    basketIcon: {
      marginLeft: 5,
    },
    row: {
      flexDirection: 'row',
      backgroundColor: usePrimaryBackground(),
      height: '20%',
      padding: 5,
      borderRadius: 10,
      alignSelf: 'center',
      justifyContent: 'space-between',
      overflow: 'visible'
    },
    valueWrapper: {
      justifyContent: 'center',
      alignContent: 'center',
    },
    labelWrapper: {
      justifyContent: 'center',
      alignContent: 'center',
      paddingRight: 5,
    },
    label: {
      fontSize: 12,
      color: useTextColor(),
      textAlign: 'right',
    },
  })
}

export default useStyles
