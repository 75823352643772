import React from 'react'

import { useTranslation } from 'react-i18next'

import Widget from '../Widget'

import { IWidgetProps } from '../../../../types/props/Widget'
import { ISettings } from '@dash.bar/types'

import { useHistory } from '@webstollen/react-native/lib/react-router'

import AreaChart from '../../../../components/charts/Area'
import { OrderValueT } from '../../../../types/widgetDataTypes/types'

const OrderValue = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Widget<[OrderValueT]>
      title={t('shop.widget.order-value.title')}
      widget={widget}
      hasInterval
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval = 'D30' }: ISettings) => ({
          body: {
            request: 'Analytics',
            widget: {
              sales: {
                options: {
                  active: true,
                  details: true,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                },
              },
            },
          },
          widget: 'sales',
        }),
      ]}
      onClickWidget={() => {
        history.push(`/shop/${widget.settings.shop}/orders`)
      }}
      testID={'sales-widget'}
      {...props}
    >
      {([, [data], , , size]) => {
        if (data.avg_history) {
          return <AreaChart list={[data.avg, ...data.avg_history.map((val) => val)].reverse()} size={size} />
        } else {
          return <AreaChart list={[data.avg]} size={size} />
        }
      }}
    </Widget>
  )
}

export default OrderValue
