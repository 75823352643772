import { useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'
import { StyleSheet } from 'react-native'
import colors from '../../constants/colors'
import sizes from '../../constants/sizes'

const useStyles = () => {
  return StyleSheet.create({
    buttonWrapper: {
      width: '100%',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    highlightButton: {
      backgroundColor: useHighlightColor(),
      width: '30%',
      height: sizes.buttonHeight,
      borderRadius: sizes.buttonBorderRadius,
    },
    deleteButton: {
      backgroundColor: colors.error,
      width: '30%',
      height: sizes.buttonHeight,
      borderRadius: sizes.buttonBorderRadius,
    },
    promoteButton: {
      backgroundColor: colors.success,
      width: '30%',
      height: sizes.buttonHeight,
      borderRadius: sizes.buttonBorderRadius,
    },
    cancelButton: {
      width: '15%',
      borderRadius: sizes.buttonBorderRadius,
      height: sizes.buttonHeight,
      borderWidth: 2,
      borderColor: useHighlightColor(),
    },
    highlightColor: {
      color: useHighlightColor(),
    },
    modalContentText: {
      padding: 5,
      textAlign: 'left',
      width: '100%',
    },
  })
}

export default useStyles
