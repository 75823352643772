//import { useParams } from 'react-router-dom'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import React from 'react'
// import useDocument from '../../../../../../hooks/useDocument'
// import { Booking } from '@dash.bar/types'
// import { SessionContext } from '../../../../../../context/SessionContext'

const Details = () => {
  const history = useHistory()
  history.replace('/settings/subscription')
  // const { id } = useParams<{ id: string }>()
  // const { user } = useContext(SessionContext)
  //const booking = useDocument<Booking>(`company/${user?.company ?? '-'}/booking`, id)
  //const payments = useCollection<Record<string, never>>(`company/${user?.company ?? '-'}/booking/${id ?? '-'}/payments`)
  return <></>
}

export default Details
