import { Linking, View } from 'react-native'
import React, { useContext } from 'react'
import { usePrimaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from 'react-i18next'
import { SessionContext } from '../../../../context/SessionContext'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { Redirect } from '@webstollen/react-native/lib/react-router'
import Button from './../../../../components/Button'
import Card from './../../../../components/Card'
const NoCompany = () => {
  const { t } = useTranslation()
  const { isLoaded, currentUser, signOut, user } = useContext(SessionContext)
  const bgColor = usePrimaryBackground()
  if (!isLoaded) {
    return <LoadingScreen />
  }

  if (isLoaded && !currentUser) {
    return <Redirect to={'/login'} />
  }

  if (isLoaded && user?.company) {
    return <Redirect to={'/'} />
  }

  return (
    <View style={{ flex: 1, justifyContent: 'space-around', alignItems: 'center' }}>
      <View style={{ backgroundColor: 'powderblue' }}>
        <Card style={{ padding: 20, backgroundColor: bgColor }}>
          <ColoredText style={{ fontWeight: '700', fontSize: 16 }}>{t('title.noCompany')}</ColoredText>
          <View style={{ margin: 20, maxWidth: 480 }}>
            <ColoredText>{t('text.noCompany')}</ColoredText>
          </View>
          <View style={{ flex: 1, justifyContent: 'space-around', flexDirection: 'row' }}>
            <Button style={{ paddingHorizontal: 5 }} primary onPress={signOut}>
              {t('layout.logout')}
            </Button>

            <Button onPress={() => Linking.openURL('https://ws-url.de/help?db=noCompany')}>Support</Button>
          </View>
        </Card>
      </View>
    </View>
  )
}

export default NoCompany
