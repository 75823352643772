import { useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { StyleSheet } from 'react-native'
import colors from '../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    chip: {
      margin: '1%',
    },
    button: {
      backgroundColor: useSecondaryBackground(),
      width: '100%',
      marginVertical: 5,
      borderRadius: 15,
    },
    notificationCard: {
      marginTop: 5,
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      borderRadius: 15,
    },
  })
}

export default useStyles
