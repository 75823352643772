import React from 'react'
import { ChildrenProps } from '../../types/chart'
import colors from '../../constants/colors'
import { View } from 'react-native'
import { AreaChart as ChartsAreaChart } from 'react-native-svg-charts'
import { Defs, LinearGradient, Stop, Path, Circle } from 'react-native-svg'
import numbro from 'numbro'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import { curveNatural } from 'd3-shape'
import { useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'

type Props = {
  testID?: string
  list: Array<number>
  contentInset?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  size: {
    width: number
    height: number
  }
}

const Gradient = (_: ChildrenProps) => (
  <Defs>
    <LinearGradient id={'gradient'} x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
      <Stop offset={'0%'} stopColor={`${colors.color2}`} stopOpacity={0.7} />
      <Stop offset={'100%'} stopColor={`${colors.color2}`} stopOpacity={0.1} />
    </LinearGradient>
  </Defs>
)

const Line = ({ line }: ChildrenProps) => (
  <Path key={'line'} d={line} strokeWidth={2} stroke={`${colors.color2}`} fill={'none'} />
)

const Circles = ({ x, y, data }: ChildrenProps) => {
  const background = useSecondaryBackground()
  return (
    <>
      {data?.map((value, index) => {
        return x && y ? (
          <Circle
            key={index}
            cy={y(value)}
            cx={x(index)}
            r={3}
            fill={colors.color2}
            stroke={`${background}`}
            strokeWidth={1.5}
          />
        ) : null
      })}
    </>
  )
}

const AreaChart = ({ list, contentInset = { top: 10, bottom: 10, left: 10, right: 10 }, size }: Props) => {
  return (
    <View style={{ height: size.height, flexDirection: 'column' }}>
      <ChartsAreaChart
        style={{ flex: 1 }}
        data={list}
        contentInset={contentInset}
        curve={curveNatural}
        svg={{ fill: 'url(#gradient)' }}
        gridMin={0}
        numberOfTicks={4}
      >
        <Gradient />
        <Line />

        <Circles />
      </ChartsAreaChart>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {list.map((value, index) => (
          <ColoredText key={index} style={{ fontSize: 12 }}>
            {numbro(value).format({
              mantissa: 1,
              spaceSeparated: true,
              average: true,
            })}
          </ColoredText>
        ))}
      </View>
    </View>
  )
}

export default AreaChart
