import React from 'react'
import colors from '../../../../../constants/colors'
import { IShop } from '@dash.bar/types'
import useConversion from './useConversion'
import { useTranslation } from 'react-i18next'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import AreaChartsAnalytics from '../../../component/Analytics/v1/AreaChartsAnalytics'

type PropsT = {
  shop: IShop
  interval: string
}

const ConversionStatistics = ({ shop, interval }: PropsT) => {
  const { t } = useTranslation()

  const { loading, data } = useConversion({ shop, interval })

  if (loading || !data) {
    return (
      <LoadingScreen
        style={{
          height: 120,
        }}
        text={t('loading.data')}
      />
    )
  }

  return (
    <>
      <AreaChartsAnalytics
        data={data?.map(({ visitors }) => visitors) || []}
        interval={interval}
        title={t('shop.analytics.visitors')}
        color={colors.color1}
      />
      <AreaChartsAnalytics
        data={data?.map(({ sale }) => sale) || []}
        interval={interval}
        title={t('shop.analytics.sales')}
        color={colors.color3}
      />
      <AreaChartsAnalytics
        data={data?.map(({ rate }) => rate) || []}
        interval={interval}
        title={t('shop.analytics.conversionRate')}
        color={colors.color2}
      />
    </>
  )
}

export default ConversionStatistics
