import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Toast } from 'native-base'
import React from 'react'
import { Switch, TouchableOpacity, View } from 'react-native'
import { useLanguage, useSetLanguage } from '../../../../common/helpers/language'
import ComponentWrapper from '../../../../components/ComponentWrapper'
import PageWrapper from '../../../../components/PageWrapper'
import colors from '../../../../constants/colors'
//import useStoreLanguage from '../../../../hooks/preferences/useStoreLanguage'
//import useStoreTheme from '../../../../hooks/preferences/useStoreTheme'
import { Languages } from '../../../../types/language'
import { useSetTheme } from '../../../base/store/actions'
import useStyles from './styles'

const Settings = () => {
  const styles = useStyles()
  const setLanguage = useSetLanguage()
  const { t } = useTranslation()
  const setTheme = useSetTheme()
  const theme = useTheme()
  const highlightColor = useHighlightColor()
  //const storeTheme = useStoreTheme()
  //const storeLanguage = useStoreLanguage()

  const [ordersIsSwitchOn, ordersSetSwitchOn] = React.useState(true)
  const [shopDownIsSwitchOn, showDownSetSwitchOn] = React.useState(false)
  const [newArticleSwitchOn, newArticleSetSwitchOn] = React.useState(true)
  const [newReportSwitchOn, newReportSetSwitchOn] = React.useState(false)

  const currentLanguage = useLanguage()

  const languages = [
    {
      title: t('layout.language.deutsch'),
      language: Languages.DE,
    },
    {
      title: t('layout.language.english'),
      language: Languages.EN,
    },
  ]

  return (
    <PageWrapper isScrollable={true}>
      <ComponentWrapper title="Einstellungen">
        <View style={styles.wrapper}>
          <View style={styles.languageWrapper}>
            <ColoredText>
              <>{t('settings.language')}</>
            </ColoredText>
            {languages.map(({ title, language }) => (
              <TouchableOpacity
                key={title}
                onPress={() => {
                  if (currentLanguage !== language) {
                    setLanguage(language)
                    //storeLanguage(language)

                    Toast.show({
                      title: t('notifications.newLanguage'),
                    })
                  }
                }}
                style={styles.intervalCard}
              >
                <View style={styles.intervalWrapper}>
                  <ColoredText style={styles.itemText}>{title}</ColoredText>
                  {currentLanguage === language ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
                </View>
              </TouchableOpacity>
            ))}
          </View>
          <View style={styles.themeWrapper}>
            <ColoredText>
              <>{t('settings.theme')}</>
            </ColoredText>
            <TouchableOpacity
              onPress={() => {
                if (theme !== Themes.Dark) {
                  setTheme(Themes.Dark)
                  //storeTheme(Themes.Dark)
                }
              }}
              style={styles.intervalCard}
            >
              <View style={styles.intervalWrapper}>
                <ColoredText style={styles.itemText}>
                  <>{t('layout.theme.dark')}</>
                </ColoredText>
                {theme === Themes.Dark ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                if (theme !== Themes.Light) {
                  setTheme(Themes.Light)
                  //storeTheme(Themes.Light)
                }
              }}
              style={styles.intervalCard}
            >
              <View style={styles.intervalWrapper}>
                <ColoredText style={styles.itemText}>
                  <>{t('layout.theme.light')}</>
                </ColoredText>
                {theme === Themes.Light ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
              </View>
            </TouchableOpacity>
          </View>
          <View style={styles.divider} />
          <View style={styles.notificationWrapper}>
            <ColoredText style={styles.notificationTitle}>
              <>{t('settings.push_notifications')}</>
            </ColoredText>
            <View style={styles.notificationSettingsWrapper}>
              <View style={styles.notificationSettings}>
                <ColoredText style={styles.settingTitle}>{`Bestellungen`}</ColoredText>
                <Switch
                  thumbColor={highlightColor}
                  trackColor={{ true: highlightColor, false: 'grey' }}
                  value={ordersIsSwitchOn}
                  onValueChange={(value) => ordersSetSwitchOn(value)}
                />
              </View>
              <View style={styles.notificationSettings}>
                <ColoredText style={styles.settingTitle}>{`shop Down`}</ColoredText>
                <Switch
                  thumbColor={highlightColor}
                  trackColor={{ true: highlightColor, false: 'grey' }}
                  value={shopDownIsSwitchOn}
                  onValueChange={(value) => showDownSetSwitchOn(value)}
                />
              </View>
              <View style={styles.notificationSettings}>
                <ColoredText style={styles.settingTitle}>{`new Article`}</ColoredText>
                <Switch
                  thumbColor={highlightColor}
                  trackColor={{ true: highlightColor, false: 'grey' }}
                  value={newArticleSwitchOn}
                  onValueChange={(value) => newArticleSetSwitchOn(value)}
                />
              </View>
              <View style={styles.notificationSettings}>
                <ColoredText style={styles.settingTitle}>{`new Report`}</ColoredText>
                <Switch
                  thumbColor={highlightColor}
                  trackColor={{ true: highlightColor, false: 'grey' }}
                  value={newReportSwitchOn}
                  onValueChange={(value) => newReportSetSwitchOn(value)}
                />
              </View>
            </View>
          </View>
        </View>
      </ComponentWrapper>
    </PageWrapper>
  )
}

export default Settings
