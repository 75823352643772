import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import useStyles from './styles'
import { currencyToSymbol, getTextForInterval } from '../../../../../../utils/booking'
import Button from '../../../../../../components/Button'
import { functions } from '../../../../../../firebase'
import { SessionContext } from '../../../../../../context/SessionContext'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import * as WebBrowser from 'expo-web-browser'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import Error from '../../../../../../components/Error'
import moment from 'moment'
import useBookingDates from '../../../../../../hooks/booking/useBookingDates'
import useBookingAmounts from '../../../../../../hooks/booking/useBookingAmounts'
import useBookingPossibleState from '../../../../../../hooks/booking/useBookingPossibleState'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import Link from '../../../../../../components/Link'
import urls from '../../../../../../constants/urls'
import { Bundle, Interval } from '@dash.bar/types'
import ModalPopUp from '../../../../../../components/Modal'
import useAllowedUser from '../../../../../../hooks/booking/useAllowedUser'
import { UserContingentWarning } from '../../../../../../components/Booking/UserContigentWarning'
import Pro from '../../../../../../assets/imgs/subscription/Pro'
import Business from '../../../../../../assets/imgs/subscription/Business'
import Free from '../../../../../../assets/imgs/subscription/Free'

const BookingModal = forwardRef(
  (
    {
      bundle,
      interval,
    }: {
      bundle: Bundle
      interval: Interval
    },
    ref
  ) => {
    const createBooking = functions().httpsCallable('calls-company-booking-create')
    const styles = useStyles()
    const history = useHistory()
    const { t } = useTranslation()
    const { user, company } = useContext(SessionContext)
    const [userWarningAccepted, setUserWarningAccepted] = useState<boolean | null>(null)
    const bookingDates = useBookingDates(bundle, interval)
    const allowedUser = useAllowedUser(bundle)
    const amounts = useBookingAmounts(bundle, interval)
    const bookingPossible = useBookingPossibleState(bundle, interval)
    const modalRef = useRef<any>()

    useImperativeHandle(ref, () => ({
      setVisible(visible: boolean) {
        if (modalRef && modalRef.current) modalRef.current.setVisible(visible)
        if (company.users.length > allowedUser) {
          setUserWarningAccepted(false)
        }
      },
    }))

    const [{ booking }, setBookingState] = useState<{
      booking: boolean
    }>({
      booking: false,
    })
    const [{ error }, setErrorState] = useState<{
      error: string | null
    }>({
      error: null,
    })

    const book = () => {
      setBookingState({ booking: true })
      createBooking({
        companyId: user?.company,
        booking: {
          key: 'BUNDLE',
          bundle: bundle,
          interval: interval || Interval.month,
        },
      })
        .then((result) => {
          if (result.data.code === 'success' && result.data.checkoutUrl !== null) {
            if (Platform.OS === 'web') {
              window.location.href = result.data.checkoutUrl
            } else {
              WebBrowser.openBrowserAsync(result.data.checkoutUrl)
              setBookingState({ booking: false })
              history.push('/settings/subscription')
            }
          } else if (result.data.code === 'success' && result.data.checkoutUrl === null) {
            //history.push('/settings/subscription')
          }
          setBookingState({ booking: false })
          if (modalRef && modalRef.current) modalRef.current.setVisible(false)
          return
        })
        .catch((e) => {
          console.debug('(CreateBooking) Error: ' + e.message + '/ ' + e)
          setErrorState({ error: e.message })
          setBookingState({ booking: false })
        })
        .finally(() => {
          //setBookingState({ booking: false })
        })
    }

    let modalContent: any
    if (booking) {
      modalContent = <LoadingScreen text={t('subscription.booking_completion.complete_booking')} />
    } else if (!bookingPossible) {
      modalContent = (
        <View style={{ width: '100%', padding: 20, alignItems: 'center', justifyContent: 'center' }}>
          <ColoredText style={styles.title}>
            <>{t('subscription.booking_completion.booking_not_possible')}</>
          </ColoredText>
          <ColoredText style={styles.reasonText}>{`${t(
            'subscription.booking_not_possible.possible_reasons'
          )}`}</ColoredText>
          <ColoredText style={styles.reasonText}>{`\u2022 ${t(
            'subscription.booking_not_possible.not_paid'
          )}`}</ColoredText>
          <ColoredText style={styles.reasonText}>{`\u2022 ${t(
            'subscription.booking_not_possible.next_booking'
          )}`}</ColoredText>
          <Button
            textStyle={styles.highlightColor}
            style={styles.buttonCancel}
            onPress={() => {
              if (modalRef && modalRef.current) modalRef.current.setVisible(false)
            }}
          >
            {t('subscription.booking_completion.cancel')}
          </Button>
        </View>
      )
    } else if (error) {
      modalContent = (
        <Error
          message={error}
          onClose={() => {
            setErrorState({ error: null })
            if (modalRef && modalRef.current) {
              modalRef.current.setVisible(false)
            }
          }}
        />
      )
    } else {
      if (userWarningAccepted !== null && !userWarningAccepted) {
        modalContent = (
          <UserContingentWarning
            bundle={bundle}
            modalRef={modalRef}
            setUserWarningAccepted={setUserWarningAccepted}
            user={company.users.length}
          />
        )
      } else {
        modalContent = (
          <>
            <ColoredText style={styles.modalTitle}>
              <>{t('subscription.booking_completion.booking_summary')}</>
            </ColoredText>
            <ColoredText style={styles.modalSubtitle}>
              <>{t('subscription.booking_completion.booking_binding')}</>
            </ColoredText>
            <View
              style={[
                styles.bundleView,
                bundle === 'pro'
                  ? styles.backgroundPro
                  : bundle === 'business'
                  ? styles.backgroundBusiness
                  : styles.backgroundFree,
              ]}
            >
              <View style={styles.titleContentWrapper}>
                <ColoredText style={styles.bundleTitle}>
                  <>{`dash.bar ${bundle || 'free'}`}</>
                </ColoredText>
                <ColoredText style={styles.bundleTitle}>{getTextForInterval(interval || 'month')}</ColoredText>
              </View>
              <View>{bundle === 'pro' ? <Pro /> : bundle === 'business' ? <Business /> : <Free />}</View>
            </View>
            <ColoredText style={styles.modalSubtitle}>
              <>{t('subscription.booking_completion.billing_data')}</>
            </ColoredText>
            <View style={styles.divider} />
            <View style={styles.billingWrapper}>
              <TouchableOpacity
                onPress={() => {
                  modalRef.current.setVisible(false)
                  history.push('/settings/accountOverview')
                }}
                style={{ maxHeight: 20 }}
              >
                <ColoredText style={styles.clickableText}>
                  <>{t('subscription.booking_completion.change_data')}</>
                </ColoredText>
              </TouchableOpacity>
              <View style={styles.addressWrapper}>
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.company')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{company?.name || 'n/a'}</ColoredText>
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.street_number')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{`${company?.billingAddress?.address1 || 'n/a'}`}</ColoredText>
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.zip_city')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{`${company?.billingAddress?.zipCode || 'n/a'} ${
                  company?.billingAddress?.city
                }`}</ColoredText>
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.email')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{`${company?.billingAddress?.email || 'n/a'}`}</ColoredText>
              </View>
            </View>
            <ColoredText style={styles.modalSubtitle}>
              <>{t('subscription.booking_completion.subscription_data')}</>
            </ColoredText>
            <View style={styles.divider} />
            <View style={styles.billingWrapper}>
              <View style={styles.addressWrapper}>
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.subscription')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{`dash.bar ${bundle}`}</ColoredText>
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.interval')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{`${getTextForInterval(interval)}`}</ColoredText>
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.start')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{`${moment(bookingDates.startDate).format(
                  'DD.MM.YYYY'
                )}`}</ColoredText>
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.auto_extension')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{`${moment(bookingDates.extensionDate).format(
                  'DD.MM.YYYY'
                )}`}</ColoredText>
              </View>
              <View style={styles.addressWrapper}>
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.price_netto')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{`${amounts.netto.value}${currencyToSymbol(
                  amounts.netto.currency
                )}`}</ColoredText>
                {amounts.credit !== null ? (
                  <>
                    <ColoredText style={styles.item}>
                      <>{t('subscription.booking_completion.credit')}</>
                    </ColoredText>
                    <ColoredText style={styles.item}>{`-${amounts.credit.value}${currencyToSymbol(
                      amounts.credit.currency
                    )}`}</ColoredText>
                    <ColoredText style={styles.item}>
                      <>{t('subscription.booking_completion.price_new')}</>
                    </ColoredText>
                    <ColoredText style={styles.item}>{`${amounts.nettoWithCredit.value}${currencyToSymbol(
                      amounts.nettoWithCredit.currency
                    )}`}</ColoredText>
                  </>
                ) : null}
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.mwst')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{`${amounts.mwst.value}${currencyToSymbol(
                  amounts.mwst.currency
                )}`}</ColoredText>
                <ColoredText style={styles.item}>
                  <>{t('subscription.booking_completion.price_brutto')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{`${amounts.total.value}${currencyToSymbol(
                  amounts.total.currency
                )}`}</ColoredText>
              </View>
            </View>
            <View style={styles.divider} />
            <View style={{ marginVertical: 20 }}>
              <View style={styles.privacyTextWrapper}>
                <ColoredText style={[styles.privacyText]}>
                  {`${t('subscription.booking_completion.condition_text_1')}`}
                </ColoredText>
                <Link url={urls.URL_TERMES_OF_USE}>{`${t('subscription.booking_completion.condition')}`}</Link>
                <ColoredText style={[styles.privacyText]}>
                  {`${t('subscription.booking_completion.condition_text_2')}`}
                </ColoredText>
                <Link url={urls.URL_PRIVACY_POLICY}>{`${t('subscription.booking_completion.privacy_policy')}`}</Link>
                <ColoredText style={[styles.privacyText]}>
                  {`${t('subscription.booking_completion.condition_text_3')}`}
                </ColoredText>
              </View>
              <View style={styles.privacyTextWrapper}>
                <ColoredText style={[styles.privacyText]}>
                  {`${t('subscription.booking_completion.business_customers_only')}`}
                </ColoredText>
              </View>
            </View>
            <View style={styles.buttonWrapper}>
              <Button
                textStyle={styles.highlightColor}
                style={styles.buttonCancel}
                onPress={() => {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(false)
                  setUserWarningAccepted(null)
                }}
              >
                {t('subscription.booking_completion.cancel')}
              </Button>
              <Button style={styles.buttonBook} textStyle={{ color: 'white' }} onPress={() => book()}>
                {t('subscription.booking_completion.book')}
              </Button>
            </View>
          </>
        )
      }
    }
    return <ModalPopUp ref={modalRef}>{modalContent}</ModalPopUp>
  }
)

BookingModal.displayName = 'BookingModal'

export default BookingModal
