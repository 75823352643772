import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { News, NewsMap } from '../types/Stream'

export const useNewsObject = () => {
  useFirestoreConnect(() => [
    {
      collection: 'news',
      orderBy: ['timestamp', 'desc'],
    },
  ])
  const news: NewsMap = useSelector((state: { firestore: { data: { news: NewsMap } } }) => state.firestore.data.news)
  return news
}
export const useSortedNewsArray = (newsObject?: NewsMap) => {
  const newsArray: Array<News> = []
  if (newsObject) {
    Object.values(newsObject).map((newsObject) => {
      const news: News = {
        id: newsObject.id,
        originalSource: newsObject.originalSource,
        mediaUrl: newsObject.mediaUrl,
        profileImageUrl: newsObject.profileImageUrl,
        sourceUrl: newsObject.sourceUrl,
        timestamp: newsObject.timestamp,
        hashtags: newsObject.hashtags,
        text: newsObject.text,
        userName: newsObject.userName,
      }
      newsArray.push(news)
    })
  }

  return newsArray
}
export const useHashtagFilteredNewsArray = (hashtag: string) => {
  useFirestoreConnect(() => [
    {
      collection: 'news',
      where: ['hashtags', 'array-contains', hashtag],
      orderBy: ['timestamp', 'desc'],
    },
  ])
  const hashtags = useSelector((state: { firestore: { data: { news: NewsMap } } }) => state.firestore.data.news)

  return useSortedNewsArray(hashtags)
}

const useSortedNews = () => {
  const newsObject = useNewsObject()
  return useSortedNewsArray(newsObject)
}

export default useSortedNews
