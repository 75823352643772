import React from 'react'

import Users from './pages/Users'

const PREFIX = '/admin'

export default () => [
  {
    name: 'admin_users',
    route: {
      exact: true,
      path: PREFIX + '/users',
      children: <Users />,
    },
    requireAuth: true,
  },
]
