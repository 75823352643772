import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import moment from 'moment'
import React from 'react'
import { Linking, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useStyles from '../../styles'
import { TrackerDataT } from '../../../../../../../../types/widgetDataTypes/types'
import icons from '../../../../../../../../constants/icons'

type PropsT = {
  item: TrackerDataT
}

const EMail = ({ item }: PropsT) => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <View style={styles.wrapper}>
      <View style={styles.labelWrapper}>
        <BoldText style={styles.labelTitle}>{t('shop.live-visitor.eMail')}</BoldText>
        <View style={[styles.fontAwesomeWrapper, styles.backgroundColorPurple]}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'envelope']} />
        </View>
      </View>
      <BoldText style={styles.emailHeader} numberOfLines={1}>
        {item?.data?.name}
      </BoldText>
      <BoldText style={styles.header} numberOfLines={1}>
        - {item?.data?.template}
      </BoldText>
      <View style={styles.flexDirectionRow}>
        <ColoredText style={styles.fontSize13}>{t('shop.live-visitor.eMail')}: </ColoredText>
        <ColoredText
          style={styles.url}
          onPress={() => Linking?.openURL(`mailto:${item?.data?.mail}`)}
          numberOfLines={1}
        >
          {item?.data?.mail}
        </ColoredText>
      </View>
      <View style={styles.timeWrapper}>
        <ColoredText style={styles.runtime}>
          {t('shop.live-visitor.runTime')}: {item?.runtime}
        </ColoredText>
        <ColoredText style={styles.textColor} numberOfLines={2}>
          {`${moment(item?.timestamp).fromNow()}`}
        </ColoredText>
      </View>
    </View>
  )
}
export default EMail
