import { IShop } from '@dash.bar/types'

import api from '../../../../../widgets/shop/api'
import { Reducer, useCallback, useReducer } from 'react'
import { DetailedOrderT } from '../../../component/Orders/v1/types'

export enum Types {
  IS_LOADING = 'is-loading',
  HAS_ERROR = 'has-error',
  HAS_DATA = 'has-data',
}

const useOrderReducer = () =>
  useReducer<
    Reducer<
      Readonly<{ loading: boolean; error?: Error; order?: DetailedOrderT }>,
      { type: Types; payload?: { error?: Error; order?: DetailedOrderT } }
    >
  >(
    (state, { type, payload: { order, error } = {} }) => {
      switch (type) {
        case Types.IS_LOADING:
          return { ...state, error: null, loading: true }
        case Types.HAS_ERROR:
          return { ...state, loading: false, error }
        case Types.HAS_DATA:
          return {
            ...state,
            order,
            loading: false,
            error: null,
          }
        default:
          throw new Error(`action type '${type}' not defined`)
      }
    },
    {
      loading: false,
      error: null,
      order: null,
    }
  )

const useOrder = ({ shop }: { shop: IShop }) => {
  const [state, dispatch] = useOrderReducer()
  const loadOrder = useCallback(
    async (orderId: number | string): Promise<DetailedOrderT> => {
      const { order } = await api(shop.endpoint, shop.secretToken, {
        body: {
          request: 'Analytics',
          widget: {
            order: { id: orderId },
          },
        },
        widget: 'order',
      })

      return order
    },
    [shop.endpoint, shop.secretToken]
  )

  const getOrder = useCallback(
    async (orderId: number | string) => {
      dispatch({ type: Types.IS_LOADING })

      try {
        const order = await loadOrder(orderId)

        dispatch({ type: Types.HAS_DATA, payload: { order } })
      } catch (error) {
        dispatch({ type: Types.HAS_ERROR, payload: { error } })
      }
    },
    [dispatch, loadOrder]
  )

  return {
    loadOrder,
    getOrder,
    state,
  }
}

export default useOrder
