import { useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { Platform, StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    text: {
      textAlign: 'center',
    },

    header: {
      color: useTextColor(),
    },

    keyFigures: {
      marginVertical: Platform.select({ default: undefined, web: 4 }),
    },
  })
}
export default useStyles
