import statusDark from '../../../assets/imgs/widgets/status/dark.png'
import statusLight from '../../../assets/imgs/widgets/status/light.png'

import orderDark from '../../../assets/imgs/widgets/orders/dark.png'
import orderLight from '../../../assets/imgs/widgets/orders/light.png'

import liveTrackerDark from '../../../assets/imgs/widgets/livetracker/dark.png'
import liveTrackerLight from '../../../assets/imgs/widgets/livetracker/light.png'

import runnersDark from '../../../assets/imgs/widgets/runners/dark.png'
import runnersLight from '../../../assets/imgs/widgets/runners/light.png'

import salesDark from '../../../assets/imgs/widgets/sales/dark.png'
import salesLight from '../../../assets/imgs/widgets/sales/light.png'

import gendersDark from '../../../assets/imgs/widgets/genders/dark.png'
import gendersLight from '../../../assets/imgs/widgets/genders/light.png'

import Conversion from './Conversion'
import LiveTracker from './LiveTracker'
import Orders from './Orders'
import OrderValue from './OrderValue'
import Runners from './Runners'
import Sales from './Sales'
import Status from './Status'
import Gender from './Gender'

import i18n from '../../../locales/i18n'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import AddNewWidget from './AddNewWidget'
import { IWidgetDefinition } from '../../../types/Widget'

const widgetDefinitions: Record<string, IWidgetDefinition> = {
  conversion_v1: {
    component: Conversion,
    title: i18n.t('shop.widget.conversion.title'),
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: salesLight,
      [Themes.Dark]: salesDark,
    },
    // authorization: 'widget.conversion', // TODO: enable / refactor if needed
  },
  live_tracker_v1: {
    component: LiveTracker,
    title: i18n.t('shop.widget.live-tracker.title'),
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, rowsPerPage: 4 },
    icon: {
      [Themes.Light]: liveTrackerLight,
      [Themes.Dark]: liveTrackerDark,
    },
    // authorization: 'widget.liveTracker', // TODO: enable / refactor if needed
  },
  orders_v1: {
    component: Orders,
    title: i18n.t('shop.widget.orders.title'),
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, rowsPerPage: 4 },
    icon: {
      [Themes.Light]: orderLight,
      [Themes.Dark]: orderDark,
    },
    // authorization: 'widget.orders', // TODO: enable / refactor if needed
  },
  runners_v1: {
    component: Runners,
    title: i18n.t('shop.widget.runners.title'),
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: runnersLight,
      [Themes.Dark]: runnersDark,
    },
    // authorization: 'widget.runners', // TODO: enable / refactor if needed
  },
  sales_v1: {
    component: Sales,
    title: i18n.t('shop.widget.sales.title'),
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: salesLight,
      [Themes.Dark]: salesDark,
    },
    // authorization: 'widget.sales', // TODO: enable / refactor if needed
  },
  order_value_v1: {
    component: OrderValue,
    title: i18n.t('shop.widget.order-value.title'),
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: salesLight,
      [Themes.Dark]: salesDark,
    },
    // authorization: 'widget.orderValue', // TODO: enable / refactor if needed
  },
  status_v1: {
    component: Status,
    title: i18n.t('shop.widget.status.title'),
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10 },
    icon: {
      [Themes.Light]: statusLight,
      [Themes.Dark]: statusDark,
    },
    // authorization: 'widget.status', // TODO: enable / refactor if needed
  },
  gender_v1: {
    component: Gender,
    title: i18n.t('shop.widget.gender.title'),
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10 },
    icon: {
      [Themes.Light]: gendersLight,
      [Themes.Dark]: gendersDark,
    },
    // authorization: 'widget.gender', // TODO: enable / refactor if needed
  },
  add_new_widget_v1: {
    component: AddNewWidget,
    minVersion: '1.0.0',
  },
}

export default widgetDefinitions
