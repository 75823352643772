import { Platform, StyleSheet } from 'react-native'
import { usePrimaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    //NEWSBOX
    listWrapper: {
      maxWidth: Platform.select({ web: 598 }),
      width: '100%',
    },
    wrapper: {
      width: '100%',
      alignItems: 'center',
    },
    newsBoxWrapper: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      borderRadius: 16,
      paddingHorizontal: 8,
      paddingVertical: 8,
      marginBottom: 12,
    },

    newsBoxHeader: {
      flexDirection: 'row',
      backgroundColor: 'transparent',
      borderRadius: 16,
      alignItems: 'center',
    },
    profilePicture: {
      height: 40,
      width: 40,
      borderRadius: 20,
      marginLeft: 0,
    },
    headerText: {
      fontSize: 20,
      marginLeft: 8,
    },
    timestamp: {
      marginLeft: 'auto',
      paddingRight: 8,
    },

    bodyWrapper: {
      marginTop: 8,
      paddingHorizontal: 16,
      paddingVertical: 8,
      backgroundColor: theme === Themes.Dark ? colors.lightBgTransparent4 : colors.darkBgTransparent4,
      borderRadius: 16,
    },
    text: {
      lineHeight: 20,
    },
    hyperLink: {
      color: '#2985d0',
      lineHeight: 20,
    },

    picture: {
      //picture is resized with: resizeMode="contain" in newsBox.tsx
      width: '100%',
      minHeight: 200,
      // marginTop: 6,
    },

    hashtagWrapperMobile: {
      width: '100%',
      paddingLeft: 8,
    },

    hashtagWrapperWeb: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingLeft: 8,
    },

    hashtagButton: {
      fontSize: 14,
      marginTop: 8,
      borderRadius: 16,
      borderWidth: 2,
      padding: 5,
      borderColor: usePrimaryBackground(),
      marginRight: 10,
    },

    //REPORTBOX
    reportPicture: {
      width: '100%',
      height: 200,
      borderRadius: 16,
      marginTop: 8,
    },
  })
}

export default useStyles
