import { StyleSheet } from 'react-native'

import {
  useHighlightColor,
  useSecondaryBackground,
} from '@webstollen/react-native/lib/hooks/useColors'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      marginHorizontal: 10,
      marginBottom: 10,
      padding: 10,
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      height: 200,
      borderRadius: 16,
    },
    wrapperDisabled: {
      backgroundColor: `${useSecondaryBackground()}80`,
    },
    title: {
      padding: 5,
      fontSize: 18,
    },
    list: {
      backgroundColor: 'transparent',
      borderRadius: 10,
    },

    shop: {
      backgroundColor: theme === Themes.Dark ? colors.lightBgTransparent1 : colors.darkBgTransparent8,
      padding: 5,
      marginHorizontal: 2.5,
      borderWidth: 2,
      borderRadius: 10,
      borderColor: 'transparent',
      alignItems: 'center',
      maxWidth: 120,
    },

    selectedShop: {
      borderColor: useHighlightColor(),
    },

    shopIcon: {
      width: 90,
      height: 90,

      padding: 5,
    },
    shopIconWrapper: {
      width: 100,
      height: 100,

      backgroundColor: 'white',
      borderRadius: 50,

      overflow: 'hidden',
    },

    shopName: {
      marginTop: 5,
    },
  })
}

export default useStyles
