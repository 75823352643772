import React, { ReactNode } from 'react'
import { TouchableOpacity, GestureResponderEvent, StyleProp, ViewStyle, TextStyle, View } from 'react-native'

import { Link } from '@webstollen/react-native/lib/react-router'

import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import useStyles from './styles'

interface IProps {
  disabled?: boolean
  children: ReactNode
  onPress?: (e: GestureResponderEvent) => void
  to?: string
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  primary?: boolean
}

const Button = ({ style, textStyle, onPress, to, disabled, children, primary = false }: IProps) => {
  const styles = useStyles()

  if (to && !onPress) {
    return (
      <Link to={to}>
        <View style={[styles.button, primary && styles.primary, style]}>
          <ColoredText style={[primary && styles.primaryText, textStyle]}>{children}</ColoredText>
        </View>
      </Link>
    )
  }

  return (
    <TouchableOpacity style={[styles.button, primary && styles.primary, style]} onPress={onPress} disabled={disabled}>
      <ColoredText style={[primary && styles.primaryText, textStyle]}>{children}</ColoredText>
    </TouchableOpacity>
  )
}

export default Button
