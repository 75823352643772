import React from 'react'

import { View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import useStyles from '../styles'
import md5 from 'md5'

import Avatar from '../../../../../../components/Avatar'

import MaybeClickableIcon from '../MaybeClickableIcon'

import { OrderAdress } from '../../../Orders/v1/types'

type PropsT = {
  billingAddress: OrderAdress
}

const CustomerInfo = ({ billingAddress }: PropsT) => {
  const styles = useStyles()

  return (
    <View style={[styles.card, styles.customerCard]}>
      <Avatar
        style={styles.avatar}
        photoURL={`https://avatar.ws-svc.de/?seed=${md5(billingAddress.mail.trim().toLowerCase())}`}
        accessibilityLabel={billingAddress.mail.trim().toLowerCase()}
      />
      <ColoredText style={styles.name}>
        {billingAddress.prename} {billingAddress.lastname}
      </ColoredText>
      {billingAddress.company ? (
        <ColoredText style={styles.billingDetailsText}>{billingAddress.company}</ColoredText>
      ) : null}
      <View style={styles.contactButtons}>
        <MaybeClickableIcon type={'phone'} value={billingAddress.phone} />
        <MaybeClickableIcon type={'mail'} value={billingAddress.mail} />
        <MaybeClickableIcon type={'history'} />
      </View>
      <View style={styles.billingAdress}>
        <View style={styles.billingAdressLeft}>
          <ColoredText style={styles.billingAdressLeftDetails}>Adresse</ColoredText>
          <ColoredText style={styles.billingAdressLeftDetails}> </ColoredText>
          {billingAddress.phone ? <ColoredText style={styles.billingAdressLeftDetails}>Telefon</ColoredText> : null}
          <ColoredText style={styles.billingAdressLeftDetails}>Mail</ColoredText>
        </View>
        <View style={styles.cardColumn}>
          <ColoredText style={styles.billingDetailsText}>{billingAddress.address1}</ColoredText>
          <ColoredText style={styles.billingDetailsText}>
            {billingAddress.country} {billingAddress.zip} {billingAddress.city}
          </ColoredText>
          {billingAddress.phone ? (
            <ColoredText style={styles.billingDetailsText}>{billingAddress.phone}</ColoredText>
          ) : null}
          <ColoredText style={styles.billingDetailsText}>{billingAddress.mail}</ColoredText>
        </View>
      </View>
    </View>
  )
}

export default CustomerInfo
