import React from 'react'

import { useTranslation } from 'react-i18next'

import Widget from '../Widget'

import { IWidgetProps } from '../../../../types/props/Widget'

import StatusView from './View'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import { KeyFiguresT, StatusT } from '../../../../types/widgetDataTypes/types'

const Status = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Widget<[StatusT, KeyFiguresT]>
      title={t('shop.widget.status.title')}
      widget={widget}
      getApiConfig={[
        () => ({
          body: {
            request: 'Data',
            widget: {
              status: {
                start_time: Date.now(),
              },
            },
          },
          widget: 'status',
        }),
        ({ autoRefresh, refreshInterval, interval = 'D30' }) => ({
          body: {
            request: 'Analytics',
            widget: {
              status: {
                info: ['sales', 'visitors', 'orders', 'conversions'],
                options: {
                  active: true,
                  details: false,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                },
              },
            },
          },
          widget: 'status',
        }),
      ]}
      showMissingData
      hasInterval
      onClickWidget={() => {
        history.push(`/shop/${widget.settings.shop}/live-tracker`)
      }}
      {...props}
    >
      {([, data, , shop, size]) => {
        return data && data.length >= 2 && <StatusView status={data[0]} keyFigures={data[1]} shop={shop} size={size} />
      }}
    </Widget>
  )
}

export default Status
