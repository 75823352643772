import { IShop } from '@dash.bar/types'
import { useCallback, useEffect } from 'react'
import api from '../../../../../widgets/shop/api'

import { StatusT } from '../../../../../types/widgetDataTypes/types'
import useApiData from '../../../../../hooks/api/useApiData'

const useStatus = ({ shop }: { shop: IShop }) => {
  const loadData = useCallback(async () => {
    const result: StatusT = await api(shop.endpoint, shop.secretToken, {
      body: {
        request: 'Data',
        widget: {
          status: {
            start_time: Date.now(),
          },
        },
      },
      widget: 'status',
    })

    return result
  }, [shop])

  const { getData, state } = useApiData(loadData)

  useEffect(() => {
    getData()
  }, [getData])

  return state
}

export default useStatus
