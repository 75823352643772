import { StyleSheet } from 'react-native'

import {
  useHighlightColor,
} from '@webstollen/react-native/lib/hooks/useColors'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      marginHorizontal: 10,
      marginBottom: 10,
      padding: 10,
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      height: 300,
      borderRadius: 16,
    },
    title: {
      padding: 5,
      fontSize: 18,
    },
    list: {
      backgroundColor:'transparent',
      borderRadius: 10,
    },

    group: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.darkBgTransparent7,
      borderWidth: 2,
      borderRadius: 10,
      marginRight: 5,
      borderColor: 'transparent',
      padding: 5,
    },

    groupTitle: {
      padding: 5,
      fontSize: 16,
    },
    groupElements: {
      flexDirection: 'row',
    },

    type: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent2 : colors.lightBgTransparent1,
      padding: 5,
      marginHorizontal: 2.5,
      borderWidth: 2,
      borderRadius: 10,
      borderColor: 'transparent',
    },

    selectedType: {
      borderColor: useHighlightColor(),
    },

    typeIcon: {
      width: 150,
      height: 150,
    },

    typeName: {
      marginTop: 5,
    },
  })
}

export default useStyles
