import React from 'react'

import { useRouteMatch } from '@webstollen/react-native/lib/react-router'

import { useTranslation } from 'react-i18next'

import FavoriteHeader from '../../../../layout/Header/FavoriteHeader'

const LiveTrackerHeader = () => {
  const { t } = useTranslation()

  const {
    params: { shopId },
  } = useRouteMatch<{ shopId: string }>()

  return <FavoriteHeader title={`${t('nav.live-tracker')}`} subtitle={shopId} />
}

export default LiveTrackerHeader
