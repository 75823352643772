import React from 'react'
import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'
import numbro from 'numbro'

import useStyles from './styles'

import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import { TouchableOpacity, View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import OrderStatusIcon from '../../../../../../utils/OrderStatusIcon'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import { DetailedOrderT } from '../types'
import getOrderStatusColor from '../../../../../../utils/get-order-status-color'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import icons from '../../../../../../constants/icons'

type OrderListItemPropsT = {
  order: DetailedOrderT
  index: number
}

const OrderListItem = ({ order, index }: OrderListItemPropsT) => {
  const styles = useStyles()

  const { t } = useTranslation()

  const history = useHistory()

  const {
    id,
    billingAddress: { lastname, prename, company },
    total,
    currency,
    status,
    orderNumber,
    positions,
    paymentProvider,
    created,
    comment,
  } = order

  const name =
    company || prename || lastname ? (
      <>
        {company ? (
          <BoldText style={styles.largeText} numberOfLines={1}>
            {company}
          </BoldText>
        ) : null}
        <BoldText style={styles.largeText} numberOfLines={1}>
          {prename} {lastname}
        </BoldText>
      </>
    ) : (
      <BoldText style={styles.largeText} numberOfLines={1}>
        {t('shop.widget.orders.customer')}
      </BoldText>
    )
  return (
    <TouchableOpacity
      onPress={() => {
        history.push(`orders/${id}`)
      }}
      style={[styles.listItem, index % 2 && styles.listItemAlternate]}
    >
      <View style={styles.itemWrapper}>
        <View style={styles.name}>{name}</View>
      </View>

      <View style={styles.itemWrapper}>
        <BoldText style={styles.total}>
          {numbro(total).formatCurrency({
            ...numbro.languageData().currencyFormat,
            average: true,
            currencySymbol: ` ${htmlDecode(currency)}`,
          })}
        </BoldText>
      </View>

      <View style={styles.itemWrapper}>
        <ColoredText style={styles.orderText}>
          {t('shop.orders.articles', { count: positions.filter(({ type }) => type === 1).length })}
        </ColoredText>
      </View>

      <View style={styles.itemWrapper}>
        <ColoredText style={styles.orderText}>{orderNumber}</ColoredText>
      </View>

      <View style={styles.itemWrapper}>
        <ColoredText numberOfLines={1} style={[styles.itemWrapper, styles.orderText]}>
          {paymentProvider}
        </ColoredText>
      </View>

      <View style={styles.itemWrapper}>
        <View style={[styles.statusIconWrapper, { backgroundColor: getOrderStatusColor(status) }]}>
          <OrderStatusIcon status={status} />
        </View>
      </View>

      <View style={styles.itemWrapper}>
        <ColoredText style={styles.orderText} numberOfLines={2}>
          {comment}
        </ColoredText>
      </View>

      <View style={styles.rightSide}>
        <ColoredText style={{ color: useTextColor() }}>{moment(created).format('L LT')}</ColoredText>
        <FontAwesomeIcon icon={[icons.faIconStyle, 'external-link']} style={styles.rightIcon} />
      </View>
    </TouchableOpacity>
  )
}

export default OrderListItem
