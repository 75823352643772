import { useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { StyleSheet } from 'react-native'
import colors from '../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    sidebar: {
      backgroundColor: 'transparent',
      position: 'relative',
      width: 270,
      maxWidth: 270,
      overflow: 'scroll',
    },
    sidebarBackground: {
      backgroundColor: useSecondaryBackground(),
      opacity: 0.2,
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 1,
    },
    sidebarContent: {
      backgroundColor: 'transparent',
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 2,
    },
    mainItem: {
      margin: 8,
      marginBottom: 12,
      marginLeft: 8,
      padding: 8,
      height: 28,
      maxHeight: 28,
      minHeight: 28,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 10,
      flex: 1,
      backgroundColor: 'transparent',
    },
    isActive: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
    },
    mainItemText: {
      fontSize: 16,
      lineHeight: 20,
      paddingLeft: 10,
    },
    mainItemIcon: {
      justifyContent: 'center',
      fontSize: 40,
    },

    underscore: {
      borderBottomWidth: 1,
      marginRight: 8,
      marginLeft: 8,
      borderBottomColor: 'rgba(255, 255, 255, 0.3)',
    },
  })
}

export default useStyles
