import { useHighlightColor, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Platform, StyleSheet } from 'react-native'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      marginVertical: 8,
      borderRadius: 16,
      padding: 8,
      flexDirection: 'column',
    },
    labelWrapper: {
      flexDirection: 'row',
      alignSelf: 'flex-end',
    },
    imageInfoWrapper: {
      flexDirection: 'row',
      marginBottom: 8,
    },
    infoWrapper: {
      flexDirection: 'column',
      marginLeft: 8,
      alignSelf: 'center',
      flex: 1,
    },
    labelTitle: {
      marginRight: 8,
      marginTop: Platform.select({ default: undefined, web: undefined }),
      alignSelf: 'center',
    },
    image: {
      width: 80,
      height: 80,
      backgroundColor: useTextColor(),
      borderRadius: 40,
    },
    header: {
      flex: 1,
      fontSize: 20,
      marginBottom: 8,
    },
    emailHeader: {
      fontSize: 20,
      flex: 1,
    },
    price: {
      textAlign: 'center',
      fontSize: 16,
    },
    fontAwesomeWrapper: {
      borderRadius: 20,
      height: 40,
      width: 40,
      justifyContent: 'center',
      alignItems: 'center',
    },
    url: {
      color: colors.primary,
      flex: 1,
      fontSize: 13,
    },
    pageViewUrl: {
      color: colors.primary,
      fontSize: 20,
      marginBottom: 8,
    },
    textParagraph: {
      flex: 1,
      fontSize: 13,
    },
    orderNumber: {
      flex: 1,
      fontSize: 20,
      color: colors.primary,
    },
    priceOrder: {
      flex: 1,
      fontSize: 16,
      marginBottom: 8,
    },
    timeWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
    },
    runtime: {
      fontSize: 12,
      color: useTextColor(),
      textAlignVertical: 'bottom',
    },
    fontSize20: {
      fontSize: 20,
    },
    fontSize16: {
      fontSize: 16,
    },
    fontSize13: {
      fontSize: 13,
    },
    flexDirectionRow: {
      flexDirection: 'row',
    },
    textColor: {
      color: useTextColor(),
    },
    backgroundColorText: {
      backgroundColor: useTextColor(),
    },
    backgroundColorHighlight: {
      backgroundColor: useHighlightColor(),
    },
    backgroundColorPurple: {
      backgroundColor: colors.color1,
    },
  })
}
export default useStyles
