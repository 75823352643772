import { IShop } from '@dash.bar/types'
import React, { memo } from 'react'
import { FlatList, View } from 'react-native'
import { UserT } from '../../../../../../types/widgetDataTypes/types'
import { DetailsType } from '../VisitorDetail/DetailTypes'
import VisitorTimelineItem from '../VisitorTimelineItem'

type PropsT = {
  shop: IShop
  data: UserT
  show: DetailsType
}

const typeMap = {
  0: 'item',
  1: 'news',
  2: 'search',
  3: 'pageview',
  4: 'eMail',
  5: 'newsletter',
  6: 'login',
  7: 'registration',
  8: 'wishList',
  9: 'basket',
  10: 'coupon',
  11: 'order',
  12: 'basketRemoved',
} as Record<number, keyof DetailsType>

const VisitorTimeline = ({ shop, data, show }: PropsT) => {
  const eventList = data?.events

  return (
    <View>
      <FlatList
        data={eventList?.filter(({ type }) => show[typeMap[type]].value)}
        keyExtractor={(item) => JSON.stringify(item)}
        renderItem={({ item }) => (
          <View>
            <VisitorTimelineItem shop={shop} item={item} />
          </View>
        )}
      />
    </View>
  )
}

export default memo(VisitorTimeline, (_prevProps, _nextProps) => {
  return false
})
