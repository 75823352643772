import { StyleSheet } from 'react-native'
import { usePrimaryBackground, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    container: {},
    listItemAlternate: {
      backgroundColor: usePrimaryBackground(),
    },
    listItem: {
      padding: 5,
      borderRadius: 10,
      height: '25%',
    },
    content: {
      display: 'flex',
      alignItems: 'center',

      flexDirection: 'row',
    },
    thumbnailContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '10%',
      minWidth: 30,
      marginRight: 5,
      marginLeft: 5,
    },
    thumbnailWrapper: {
      height: 25,
      width: 25,
    },

    title: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      height: '55%',
    },

    mainTitle: {
      fontSize: 12,
      lineHeight: 16.5,
      textAlign: 'left',
      width: '68%',
    },

    actionTitleText: {
      fontSize: 12,
      lineHeight: 16.5,
      textAlign: 'right',
      marginTop: 5,
    },

    subtitle: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },

    mainSubtitle: {
      height: 16,
      lineHeight: 16,
      textAlignVertical: 'bottom',
      fontSize: 10,
      textAlign: 'left',
      color: useTextColor(),
    },

    actionSubtitle: {
      height: 16,
      lineHeight: 16,
      textAlignVertical: 'bottom',
      fontSize: 10,
      textAlign: 'right',

      color: useTextColor(),
    },
  })
}

export default useStyles
