import React from 'react'
import numbro from 'numbro'
import { ScrollView, View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { IShop } from '@dash.bar/types'

import { useTranslation } from 'react-i18next'

import useStyles from './styles'

import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'
import moment from 'moment'

import OrderPosition from './OrderPosition'
import ExtraPosition from './ExtraPosition'

import CustomerInfo from './CustomerInfo'
import { DetailedOrderT } from '../../Orders/v1/types'

type PropsT = {
  shop: IShop
  order: DetailedOrderT
}

const OrderView = ({ shop, order }: PropsT) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { positions, total, status, created, paymentProvider, currency, comment, billingAddress, taxPositions, paid } =
    order

  return (
    <ScrollView style={styles.scrollWrapper} showsVerticalScrollIndicator={false}>
      {positions
        .filter(({ type }) => type === 1)
        .map((position) => (
          <OrderPosition key={`${position.id}-${position.productId}`} position={position} shop={shop} />
        ))}

      <View style={[styles.card, styles.cardColumn]}>
        {positions
          .filter(({ type }) => type !== 1)
          .map((position) => (
            <ExtraPosition key={`${position.id}-${position.productId}`} position={position} shop={shop} />
          ))}
        {taxPositions.map((position) => (
          <ExtraPosition
            key={`${position.name}`}
            position={{
              ...position,
              name: t('shop.order.plusVat', { rate: position.name.match(/\d+%/) }),
            }}
            shop={shop}
          />
        ))}
        <View style={styles.sumWrapper}>
          <ColoredText style={styles.sumText}>{t('shop.order.total')}:</ColoredText>
          <ColoredText style={styles.sumTextNumber}>
            {numbro(total).formatCurrency({
              ...numbro.languageData().currencyFormat,
              average: true,
              currencySymbol: ` ${htmlDecode(currency)}`,
            })}
          </ColoredText>
        </View>
      </View>

      <CustomerInfo billingAddress={billingAddress} />

      <View style={[styles.card, styles.cardColumn]}>
        <ColoredText style={styles.orderInformationHeader}>{t('shop.order.orderInformation')}</ColoredText>
        {[
          {
            title: t('shop.order.status'),
            value: status,
          },
          {
            title: t('shop.order.orderedOn'),
            value: moment(created).format('L LT'),
          },
          {
            title: t('shop.order.paidOn'),
            value: paid ? moment(paid).format('L') : '---',
          },
          {
            title: t('shop.order.paymentMethod'),
            value: paymentProvider,
          },
        ].map(({ title, value }) => (
          <View key={title} style={styles.orderInformationTextWrapper}>
            <ColoredText style={styles.orderInformationText}>{title}: </ColoredText>
            <ColoredText style={styles.orderInformationTextInformation} numberOfLines={1}>
              {value}
            </ColoredText>
          </View>
        ))}
        {comment ? (
          <View style={styles.commentWrapper}>
            <ColoredText>{comment}</ColoredText>
          </View>
        ) : null}
      </View>
    </ScrollView>
  )
}

export default OrderView
