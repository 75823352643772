import i18n from '../locales/i18n'

const errorCodes = () => ({
  'auth.popup-closed-by-user': i18n.t('error.auth.popup-closed-by-user'),
  'auth.app-deleted': i18n.t('error.auth.app-deleted'),
  'auth.app-not-authorized': i18n.t('error.auth.app-not-authorized'),
  'auth.argument-error': i18n.t('error.auth.argument-error'),
  'auth.invalid-api-key': i18n.t('error.auth.invalid-api-key'),
  'auth.invalid-email': i18n.t('error.auth.invalid-email'),
  'auth.invalid-user-token': i18n.t('error.auth.invalid-user-token'),
  'auth.invalid-tenant-id': i18n.t('error.auth.invalid-tenant-id'),
  'auth.invalid-action-code': i18n.t('error.auth.invalid-action-code'),
  'auth.network-request-failed': i18n.t('error.auth.network-request-failed'),
  'auth.operation-not-allowed': i18n.t('error.auth.operation-not-allowed'),
  'auth.requires-recent-login': i18n.t('error.auth.requires-recent-login'),
  'auth.too-many-requests': i18n.t('error.auth.too-many-requests'),
  'auth.unauthorized-domain': i18n.t('error.auth.unauthorized-domain'),
  'auth.user-disabled': i18n.t('error.auth.user-disabled'),
  'auth.user-token-expired': i18n.t('error.auth.user-token-expired'),
  'auth.web-storage-unsupported': i18n.t('error.auth.web-storage-unsupported'),
  'auth.email-already-in-use': i18n.t('error.auth.email-already-in-use'),
  'auth.wrong-password': i18n.t('error.auth.wrong-password'),
  'auth.user-not-found': i18n.t('error.auth.wrong-password'),
  signupDisabled: i18n.t('error.auth.signupDisabled'),
  wrongEmail: i18n.t('error.auth.wrong-email'),
})

export type ErrorCodes = {
  'auth.popup-closed-by-user': string
  'auth.app-deleted': string
  'auth.app-not-authorized': string
  'auth.argument-error': string
  'auth.invalid-api-key': string
  'auth.invalid-email': string
  'auth.invalid-user-token': string
  'auth.invalid-tenant-id': string
  'auth.invalid-action-code': string
  'auth.network-request-failed': string
  'auth.operation-not-allowed': string
  'auth.requires-recent-login': string
  'auth.too-many-requests': string
  'auth.unauthorized-domain': string
  'auth.user-disabled': string
  'auth.user-token-expired': string
  'auth.web-storage-unsupported': string
  'auth.email-already-in-use': string
  'auth.wrong-password': string
  'auth.user-not-found': string
  wrongEmail: string
  signupDisabled: string
}

export type ErrorCode = keyof ErrorCodes

export default class AuthError extends Error {
  code: ErrorCode
  constructor(code: string) {
    const errorCode = code.replace('/', '.') as ErrorCode
    super(errorCode)

    this.code = errorCode
    this.message = errorCodes()[errorCode]
  }
}
