import React, { useContext } from 'react'
import { View } from 'react-native'
import useStyles from './styles'
import NewUserToDo from './Components/NewUserToDo'
import ShopConnectDialog from './Components/Dialogs/ShopConnectDialog'
import CreateDashboardDialog from './Components/Dialogs/CreateDashboardDialog'
import AppInstalledDialog from './Components/Dialogs/AppInstallDialog'
import CreateFavoriteDialog from './Components/Dialogs/CreateFavoriteDialog'
import { SessionContext } from '../../../../context/SessionContext'
import useDashboards from '../../../../hooks/dashboard/useDashboards'
import useFavorites from '../../../../hooks/favorites/useFavorites'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { isEmpty } from 'react-redux-firebase'

const NewUserTutorial = () => {
  const { t } = useTranslation()
  const { shops } = useContext(SessionContext)
  const dashboards = useDashboards()
  const favorites = useFavorites()

  const styles = useStyles()
  const [showShopConnectDialog, setShowShopConnectDialog] = React.useState(false)
  const [showCreateDashboardDialog, setCreateDashboardDialog] = React.useState(false)
  const [showAppInstallDialog, setAppInstallDialog] = React.useState(false)
  const [showCreateFavoriteDialog, setCreateFavoriteDialog] = React.useState(false)

  const shopIsDone = !isEmpty(shops)
  const dashboardsIsDone = !isEmpty(dashboards)
  const favoritesIsDone = !isEmpty(favorites)

  const todoObject = [
    {
      descriptionText: t('text.newUserTutorial.appInstall.description'),
      isDone: true,
      questionFunction: setAppInstallDialog,
    },
    {
      descriptionText: t('text.newUserTutorial.shopConnect.description'),
      isDone: shopIsDone,
      questionFunction: setShowShopConnectDialog,
    },
    {
      descriptionText: t('text.newUserTutorial.createDashboard.description'),
      isDone: dashboardsIsDone,
      questionFunction: setCreateDashboardDialog,
    },
    {
      descriptionText: t('text.newUserTutorial.favorite.description'),
      isDone: favoritesIsDone,
      questionFunction: setCreateFavoriteDialog,
    },
  ]

  return (
    <View style={[styles.wrapper, styles.borderStyle]}>
      <View style={styles.headline}>
        <ColoredText style={{ fontSize: 20 }}>{t('text.newUserTutorial.header')}</ColoredText>
      </View>
      {todoObject.map((todo) => {
        return (
          <NewUserToDo
            key={todo.descriptionText}
            descriptionText={todo.descriptionText}
            isDone={todo.isDone}
            questionFunction={todo.questionFunction}
          />
        )
      })}
      <AppInstalledDialog showAppInstallDialog={showAppInstallDialog} setAppInstallDialog={setAppInstallDialog} />
      <ShopConnectDialog showShopConnect={showShopConnectDialog} setShowShopConnect={setShowShopConnectDialog} />
      <CreateDashboardDialog
        showCreateDashboardDialog={showCreateDashboardDialog}
        setCreateDashboardDialog={setCreateDashboardDialog}
      />
      <CreateFavoriteDialog
        showCreateFavorite={showCreateFavoriteDialog}
        setShowCreateFavorite={setCreateFavoriteDialog}
      />
    </View>
  )
}

export default NewUserTutorial
