import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { processFontFamily } from 'expo-font'
import * as React from 'react'
import Svg, { SvgProps, G, Rect, Text, TSpan, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */

const EmailAnmeldenButton = (props: SvgProps) => {
  const { t } = useTranslation()
  return (
    <Svg width={210} height={40} {...props}>
      <G fill="none" fillRule="evenodd">
        <G>
          <Rect fill="#000" width={210} height={40} rx={4} />
          <Text fontFamily={processFontFamily('Comfortaa_500Medium')} fontSize={11} fontWeight="bold" fill="#FFF">
            <TSpan x={61.509} y={25}>
              {t('providers.buttons.connect_email')}
            </TSpan>
          </Text>
        </G>
        <Path
          d="M26.333 23.75c-.731 0-1.463-.24-2.087-.726L15 15.834v10.041c0 1.173.951 2.125 2.125 2.125h18.417a2.125 2.125 0 0 0 2.125-2.125v-10.04l-9.244 7.193a3.41 3.41 0 0 1-2.09.722ZM15.721 14.6l9.394 7.308c.717.558 1.721.558 2.438 0l9.394-7.309c.414-.354.72-.898.72-1.474A2.125 2.125 0 0 0 35.542 11H17.125A2.125 2.125 0 0 0 15 13.125c0 .576.266 1.12.721 1.474Z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </G>
    </Svg>
  )
}

export default EmailAnmeldenButton
