import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import { IDashboard } from '@dash.bar/types'
import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'

type DashboardMap = {
  [key: string]: IDashboard
}

const useDashboard = (dashboardId: string): IDashboard | undefined => {
  const { user, currentUser, company } = useContext(SessionContext)

  useFirestoreConnect(() => {
    return {
      collection: 'company',
      doc: user.company,
      subcollections: [
        {
          collection: 'dashboard',
          where: ['creator', '==', currentUser.uid],
          limit: (company?.authorizations && (company.authorizations['dashboard.dashboards'] as number)) ?? 1, // //Only allow 1 dashboard by default (free bundle)
        },
      ], //Only Query Dashboards of current User Only allow 1 dashboard by default
      storeAs: 'company_dashboards',
    }
  })
  return useSelector(
    (state: { firestore: { data: { company_dashboards?: DashboardMap } } }) =>
      (state.firestore.data.company_dashboards || {})[dashboardId] ?? null
  )
}

export default useDashboard
