import React from 'react'
import { ScrollView } from 'react-native'
import { IShop } from '@dash.bar/types'
import LiveTrackerView from '../../../component/LiveTracker/v1'

type PropsT = {
  shop: IShop
}

const LiveTrackerV1 = ({ shop }: PropsT) => {
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <LiveTrackerView shop={shop} />
    </ScrollView>
  )
}

export default LiveTrackerV1
