import { IShop } from '@dash.bar/types'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import md5 from 'md5'
import React, { useState } from 'react'
import { Linking, Platform, Switch, TouchableOpacity, View } from 'react-native'
import colors from '../../../../../../constants/colors'
import Avatar from '../../../../../../components/Avatar'
import useStyles from './styles'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { getBrowserIcon } from '../../../../../../utils/get-browser-icon'
import ReactCountryFlag from '../../../../../../components/ReactCountryFlag'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import numbro from 'numbro'
import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'
import { getOsIcon } from '../../../../../../utils/get-os-icons'
import { UserT } from '../../../../../../types/widgetDataTypes/types'
import { useHighlightColor, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import VisitorTimeline from '../VisitorTimeline'
import VisitorHorizontalScroll from '../VisitorHorizontalScroll'
import Url from 'url-parse'
import { DetailKeys } from '../../../../../../module/shop/component/LiveVisitor/v1/VisitorDetail/DetailTypes'
import { DetailsType } from './DetailTypes'
import icons from '../../../../../../constants/icons'

type PropsT = {
  shop: IShop
  data: UserT
}

const VisitorDetail = ({ shop, data }: PropsT) => {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()
  const textColor = useTextColor()
  const [show, setShow] = useState<DetailsType>({
    item: {
      label: t('shop.live-visitor.item'),
      value: true,
    },
    news: {
      label: t('shop.live-visitor.news'),
      value: true,
    },
    search: {
      label: t('shop.live-visitor.search'),
      value: true,
    },
    pageview: {
      label: t('shop.live-visitor.pageview'),
      value: true,
    },
    eMail: {
      label: t('shop.live-visitor.eMail'),
      value: true,
    },
    newsletter: {
      label: t('shop.live-visitor.newsletter'),
      value: true,
    },
    login: {
      label: t('shop.live-visitor.login'),
      value: true,
    },
    registration: {
      label: t('shop.live-visitor.registration'),
      value: true,
    },
    wishList: {
      label: t('shop.live-visitor.wishList'),
      value: true,
    },
    basket: {
      label: t('shop.live-visitor.basket'),
      value: true,
    },
    coupon: {
      label: t('shop.live-visitor.coupon'),
      value: true,
    },
    order: {
      label: t('shop.live-visitor.order'),
      value: true,
    },
    basketRemoved: {
      label: t('shop.live-visitor.basketRemoved'),
      value: true,
    },
  })

  const order: Array<DetailKeys> = [
    'item',
    'news',
    'search',
    'pageview',
    'eMail',
    'newsletter',
    'login',
    'registration',
    'wishList',
    'basket',
    'coupon',
    'order',
    'basketRemoved',
  ]

  const [sessions, setSessions] = useState(false)
  const styles = useStyles()
  const highlight = useHighlightColor()

  const [minheightView, setMinHeightView] = useState(undefined)

  const {
    mail,
    browser,
    country,
    name,
    city,
    referrer,
    entryPage,
    lastAction,
    created,
    os,
    basketValue,
    vIDs,
    visitorID,
  } = data || {}

  const parsedUrl = (url: string) => {
    return new Url(url)
  }

  const toggleVisible = () => {
    setVisible(!visible)
  }

  const toggleFilter = (type: keyof typeof show, value: boolean) => {
    setShow((prevShow) => ({
      ...prevShow,
      [type]: {
        ...prevShow[type],
        value,
      },
    }))
  }

  const toggleSessions = () => {
    setSessions(!sessions)
  }

  const showFilter = (visible: boolean) => {
    if (visible) {
      return (
        <View
          style={styles.wrapperFilter}
          onLayout={({ nativeEvent: { layout } }) => {
            const { height: spaceAvailable } = layout
            setMinHeightView(spaceAvailable + Platform.select({ default: 16, ios: 6, web: 8 }))
          }}
        >
          <View style={styles.closeFilter}>
            <TouchableOpacity onPress={toggleVisible}>
              <FontAwesomeIcon icon={[icons.faIconStyle, 'times-circle']} color={highlight} />
            </TouchableOpacity>
          </View>
          {order.map((key) => {
            const { value, label } = show[key]
            return (
              <View style={styles.filterRow} key={key}>
                <Switch
                  trackColor={{ false: textColor, true: colors.color3 }}
                  thumbColor={colors.color3}
                  ios_backgroundColor={textColor}
                  value={value}
                  onValueChange={(value) => toggleFilter(key, value)}
                />
                <ColoredText> {label}</ColoredText>
              </View>
            )
          })}
        </View>
      )
    } else {
      return null
    }
  }

  const showSessions = (sessions: boolean, vIDs: Array<UserT>) => {
    if (sessions == false && vIDs?.length > 1) {
      return (
        <TouchableOpacity onPress={toggleSessions} style={styles.toggleSessions}>
          <View style={styles.flexDirectionRow}>
            <BoldText>{`${t('shop.live-visitor.moreSessions')}`}</BoldText>
            <FontAwesomeIcon
              icon={[icons.faIconStyle, 'arrow-down']}
              style={{ marginTop: Platform.select({ default: 4, web: undefined, ios: undefined }), marginLeft: 4 }}
            />
          </View>
        </TouchableOpacity>
      )
    } else if (sessions == true && vIDs?.length > 1) {
      return (
        <>
          <TouchableOpacity onPress={toggleSessions} style={styles.toggleSessions}>
            <View style={styles.flexDirectionRow}>
              <BoldText>{`${t('shop.live-visitor.moreSessions')}`}</BoldText>
              <FontAwesomeIcon
                icon={[icons.faIconStyle, 'arrow-up']}
                style={{ marginTop: Platform.select({ default: 4, web: undefined, ios: undefined }), marginLeft: 4 }}
              />
            </View>
          </TouchableOpacity>
          <VisitorHorizontalScroll shop={shop} userList={vIDs} visitorID={visitorID} />
        </>
      )
    } else {
      return null
    }
  }

  return (
    <View style={{ minHeight: minheightView }}>
      {showFilter(visible)}
      <View style={styles.wrapper}>
        <View style={styles.userDetails}>
          <Avatar
            style={styles.avatar}
            key={mail}
            photoURL={mail ? `https://avatar.ws-svc.de/?seed=${md5(mail.trim().toLowerCase())}` : ''}
            accessibilityLabel={mail?.trim().toLowerCase()}
          />
          <View style={styles.wrapperVisitorInfoColumn}>
            <BoldText style={[styles.fontSize16, styles.marginVertical1]}>{name}</BoldText>
            <View style={[styles.flexDirectionRow, styles.marginVertical1]}>
              {country === '' ? (
                <FontAwesomeIcon icon={[icons.faIconStyle, 'flag']} />
              ) : (
                <ReactCountryFlag countryCode={country} style={styles.countryFlag} />
              )}
              <ColoredText style={[styles.textAlignVertical, styles.marginLeft8, styles.fontPaddingLeft8]}>
                {city}
              </ColoredText>
            </View>
            <View style={[styles.flexDirectionRow, styles.marginVertical1]}>
              <FontAwesomeIcon icon={[icons.faIconStyle, 'link']} style={styles.fontAwesomeColumn} />
              <ColoredText
                style={[styles.linkColor, styles.marginLeft8, styles.marginLeft4, styles.fontPaddingLeft8]}
                onPress={() =>
                  parsedUrl(referrer).hostname === ''
                    ? Linking?.openURL(parsedUrl(entryPage).href)
                    : Linking?.openURL(parsedUrl(referrer).href)
                }
                numberOfLines={2}
              >
                {' '}
                {parsedUrl(referrer).hostname === ''
                  ? t('shop.live-tracker.directAccess')
                  : parsedUrl(referrer).hostname}
              </ColoredText>
            </View>
            <View style={[styles.flexDirectionRow, styles.marginVertical1]}>
              <FontAwesomeIcon
                icon={[icons.faIconStyle, 'user-clock']}
                style={styles.fontAwesomeColumn}
                color={useTextColor()}
              />
              <ColoredText
                style={[styles.greyText, styles.flex1, styles.marginLeft4, styles.fontPaddingLeft8]}
                numberOfLines={2}
              >
                {`${t('shop.live-visitor.lastSeen')} ${moment(lastAction).fromNow()}`}
              </ColoredText>
            </View>
            <View style={[styles.flexDirectionRow, styles.marginVertical1]}>
              <FontAwesomeIcon
                icon={[icons.faIconStyle, 'clock']}
                style={styles.fontAwesomeColumn}
                color={useTextColor()}
              />
              <ColoredText style={[styles.greyText, styles.marginLeft8, styles.fontPaddingLeft8]} numberOfLines={1}>
                {`${t('shop.live-visitor.started')} ${moment(created).fromNow()}`}
              </ColoredText>
            </View>
          </View>
          <TouchableOpacity onPress={toggleVisible} style={styles.filterOpenButton}>
            <FontAwesomeIcon icon={[icons.faIconStyle, 'filter']} color={highlight} />
          </TouchableOpacity>
        </View>
        <View style={styles.wrapperVisitorInfoRow}>
          <View style={styles.flexDirectionColumn}>
            <FontAwesomeIcon
              icon={getBrowserIcon(browser)}
              style={{ alignSelf: 'center', marginBottom: Platform.select({ default: undefined, web: 4 }) }}
            />
            <ColoredText>{browser === '' ? 'other' : browser}</ColoredText>
          </View>
          <View style={styles.flexDirectionColumn}>
            <FontAwesomeIcon
              icon={getOsIcon(os)}
              style={{ alignSelf: 'center', marginBottom: Platform.select({ default: undefined, web: 4 }) }}
            />
            <ColoredText>{os === '' ? 'other' : os}</ColoredText>
          </View>
          <View style={styles.flexDirectionColumn}>
            <FontAwesomeIcon
              icon={[icons.faIconStyle, 'shopping-cart']}
              style={{ alignSelf: 'center', marginBottom: Platform.select({ default: undefined, web: 4 }) }}
            />
            <ColoredText>
              {numbro(basketValue).formatCurrency({
                ...numbro.languageData().currencyFormat,
                average: true,
                currencySymbol: ` ${htmlDecode(shop.currency)}`,
              })}
            </ColoredText>
          </View>
        </View>
        {showSessions(sessions, vIDs)}
      </View>
      <VisitorTimeline shop={shop} data={data} show={show} />
    </View>
  )
}
export default VisitorDetail
