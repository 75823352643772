import React from 'react'

import { useHistory, useLocation } from '@webstollen/react-native/lib/react-router'

import { IDashboard } from '@dash.bar/types'
import useStyles from './styles'
import SemiBoldText from '@webstollen/react-native/lib/components/CustomText/SemiBoldText'
import { TouchableOpacity, View } from 'react-native'
//import FavoriteButton from '../../../Header/FavoriteButton'

interface DashboardItem extends IDashboard {
  path: string
}

type Props = {
  dashboards: Array<DashboardItem>
}

const DashboardMenuItems = ({ dashboards }: Props) => {
  const history = useHistory()
  const location = useLocation()
  const styles = useStyles()

  return (
    <>
      {dashboards?.map(({ title, path }) => (
        <TouchableOpacity
          key={path}
          onPress={() => {
            history.push(path)
          }}
        >
          <View style={[styles.itemWrapper, path === location.pathname ? styles.isActive : null]}>
            <View style={[styles.wrapper]}>
              <SemiBoldText numberOfLines={1} style={[styles.name]}>
                {title}
              </SemiBoldText>
              {/* Dont show favorite button 
           <FavoriteButton url={path} title={title} subtitle={'Dashboard'} />
           */}
            </View>
          </View>
        </TouchableOpacity>
      ))}
    </>
  )
}

export default DashboardMenuItems
