import { useContext } from 'react'
import { SessionContext } from '../../../../../../context/SessionContext'
import ComponentWrapper from '../../../../../../components/ComponentWrapper'
import BundleCard from '../Bundle'
import React from 'react'
import { Bundle } from '@dash.bar/types'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'

const AvailableBundles = () => {
  const { t } = useTranslation()
  const { company } = useContext(SessionContext)
  if (company && company.bundle) {
    return (
      <ComponentWrapper title={t('subscription.available_subscriptions')}>
        {company.bundle !== Bundle.free && <BundleCard bundle={Bundle.free} />}
        {company.bundle !== Bundle.business && <BundleCard bundle={Bundle.business} />}
        {company.bundle !== Bundle.pro && <BundleCard bundle={Bundle.pro} />}
      </ComponentWrapper>
    )
  }
  return null
}

export default AvailableBundles
