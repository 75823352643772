import React, { useContext, useState } from 'react'
import { FlatList, View } from 'react-native'
import { functions } from '../../../../../firebase'
import useStyles from './styles'
import { SessionContext } from '../../../../../context/SessionContext'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import icons from '../../../../../constants/icons'
import { useEffect } from 'react'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import moment from 'moment'
import { translateTransactionState } from '../../../../../utils/booking'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import PageWrapper from '../../../../../components/PageWrapper'

const Payments = () => {
  const styles = useStyles()
  const getPayments = functions().httpsCallable('calls-abocloud-getPayments')
  const { user } = useContext(SessionContext)
  const { t } = useTranslation()

  const [{ payments }, setPaymentsState] = useState<{
    payments: Array<{
      createTime: Date
      type: number
      status: number
      product: string
      amount: { currency: string; value: string }
    }> | null
  }>({
    payments: null,
  })
  const [{ loading }, setLoadingState] = useState<{
    loading: boolean
  }>({
    loading: true,
  })

  useEffect(() => {
    setLoadingState({ loading: true })
    getPayments({ companyId: user?.company })
      .then((result) => {
        console.debug(JSON.stringify(result))
        setPaymentsState({ payments: result.data })
        setLoadingState({ loading: false })
      })
      .catch((e) => {
        console.debug(e)
        setLoadingState({ loading: false })
      })
  }, [user?.company])

  if (loading) {
    return <LoadingScreen text={t('subscription.payments.load_payments')} />
  }
  if (payments === null) {
    return (
      <ColoredText>
        <>{t('subscription.payments.no_payments')}</>
      </ColoredText>
    )
  }

  return (
    <PageWrapper isScrollable={false}>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <FlatList
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            data={payments}
            style={{ paddingTop: '2%', paddingHorizontal: '2%' }}
            renderItem={(payment) => (
              <View style={styles.card}>
                <FontAwesomeIcon
                  icon={payment.item.type === 1 ? [icons.faIconStyle, 'money-bill'] : [icons.faIconStyle, 'repeat']}
                  style={styles.icon}
                />
                <View style={styles.textWrapper}>
                  <ColoredText style={styles.textProduct} numberOfLines={1}>
                    {payment.item.product}
                  </ColoredText>
                  <ColoredText style={styles.textInfo} numberOfLines={1}>
                    {moment(payment.item.createTime).format('DD.MM.YYYY hh:mm')}
                  </ColoredText>
                  <ColoredText style={styles.textInfo}>{translateTransactionState(payment.item.status)}</ColoredText>
                </View>
                <ColoredText style={styles.textAmount} numberOfLines={1}>
                  {payment.item.amount.value} {payment.item.amount.currency}
                </ColoredText>
              </View>
            )}
          />
        </View>
      </View>
    </PageWrapper>
  )
}
export default Payments
