import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import { NotType } from '@dash.bar/types/notification'
import { Swipeable } from 'react-native-gesture-handler'
import colors from '../../../constants/colors'
import { faTrashAlt, IconName } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

import { usePrimaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import useDeviceType from '../../../hooks/useDeviceType'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { UseTimeStamp } from '../../stream/components/newsBox'
import icons from '../../../constants/icons'
import useStyles from './styles'

function getPath(notificationData: Record<string, any>, shopName: string) {
  switch (notificationData.type) {
    case 'order':
      let path = '/shop/' + shopName + '/orders'
      if (notificationData.id) path += '/' + notificationData.id
      return path
    case 'company:invitation:received':
      if (notificationData.invitationCode) {
        return `settings/accountOverview/${notificationData.invitationCode}`
      }
      break
    default:
      return notificationData.path
  }
}

const NotificationView = ({
  notification,
  notificationID,
  shopName,
  onDelete,
}: {
  notification: NotType
  notificationID: string
  shopName: string
  onDelete(id: string): void
}) => {
  const history = useHistory()
  const primaryBackground = usePrimaryBackground()
  const device = useDeviceType()
  const styles = useStyles()
  // console.log(onDelete)

  const NotificationIcon = ({ icon = 'info' }: { icon?: string }) => {
    let displayIcon: IconName = icon.trim() !== '' ? (icon as IconName) : ('info' as IconName)
    if (icon == 'cart-circle-xmark' || icon == 'cart-circle-plus') displayIcon = 'cart-plus'

    return (
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View
          style={{
            height: 45,
            width: 45,
            borderRadius: 50,
            backgroundColor: 'orange',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={[icons.faIconStyle, displayIcon]}
            style={{ backgroundColor: 'orange', alignSelf: 'center' }}
            color={'white'}
          />
        </View>
      </View>
    )
  }

  const NotificationBody = () => {
    return (
      <View style={{ paddingLeft: 10, maxWidth: '85%' }}>
        {notification.createdAt && (
          <ColoredText style={{ fontSize: 10, marginLeft: 5 }}>{UseTimeStamp(notification?.receivedAt)}</ColoredText>
        )}
        <ColoredText style={{ fontSize: 20, paddingTop: 5, marginLeft: 5 }}>{notification.title}</ColoredText>
        <ColoredText style={{ margin: 5, flexWrap: 'wrap' }}>{notification.body}</ColoredText>
      </View>
    )
  }

  const RightSwipeActions = () => {
    return (
      <TouchableOpacity
        style={{
          width: '25%',
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
          backgroundColor: colors.error,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={() => onDelete(notificationID)}
      >
        <FontAwesomeIcon color={primaryBackground} icon={faTrashAlt} />
      </TouchableOpacity>
    )
  }

  if (device === 'web') {
    return (
      <View style={styles.notificationCard}>
        <TouchableOpacity onPress={() => history.push(getPath(notification.data ?? {}, shopName))}>
          <View
            style={{
              backgroundColor: 'transparent',
              padding: 10,
              flexDirection: 'row',
              borderRadius: 16,
            }}
          >
            <NotificationIcon icon={notification.data.icon} />
            <NotificationBody />
            <TouchableOpacity
              style={{ position: 'absolute', right: 10, top: 10 }}
              onPress={() => onDelete(notificationID)}
            >
              <FontAwesomeIcon color={'black'} icon={faTimes} />
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </View>
    )
  }
  return (
    <View style={styles.notificationCard}>
      <Swipeable renderRightActions={RightSwipeActions}>
        <TouchableOpacity onPress={() => history.push(getPath(notification.data ?? {}, shopName))}>
          <View
            style={{
              backgroundColor: 'transparent',
              padding: 10,
              flexDirection: 'row',
              borderRadius: 15,
            }}
          >
            <NotificationIcon icon={notification.data.icon} />
            <NotificationBody />
          </View>
        </TouchableOpacity>
      </Swipeable>
    </View>
  )
}

export default NotificationView
