import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { View } from 'react-native'
import Button from '../../../../../../components/Button'
import ComponentWrapper from '../../../../../../components/ComponentWrapper'
import ModalPopUp from '../../../../../../components/Modal'
import { SessionContext } from '../../../../../../context/SessionContext'
import useSetDocument from '../../../../../../hooks/useSetDocument'
import PaymentMehod from './PaymentMethod'
import useStyles from './styles'
import { Company as DashbarCompany } from '@dash.bar/types'
import CompanyForm from '../../../../../../components/Form/CompanyForm'
import CompanyUsers from './CompanyUsers'
import CompanyShops from './CompanyShops'
import { functions } from '../../../../../../firebase'
import useDocument from '../../../../../../hooks/useDocument'
import firebase from 'firebase/app'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'

const Company = ({ invitationCode }: { invitationCode?: string }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const updateCompany = useSetDocument<DashbarCompany>('company')
  const createSevdeskContact = functions().httpsCallable('calls-sevdesk-createContact')
  const acceptInvitation = functions().httpsCallable('calls-company-acceptInvitation')
  const modalRefData = useRef<any>()
  const modalRefInvitation = useRef<any>()
  const [loading, setLoading] = useState(false)
  const [loadingNewCompany, setLoadingNewCompany] = useState(false)
  const invitation = useDocument<Record<string, any>>(`invitations`, invitationCode)
  const { company, user, userIsAdmin } = useContext(SessionContext)

  const handleAcceptInvitation = () => {
    setLoadingNewCompany(true)
    acceptInvitation({ invitationCode: invitationCode })
      .then(async () => {
        await firebase.auth().currentUser.getIdToken(true)
        await firebase.auth().currentUser.getIdTokenResult(true)
        await firebase.auth().currentUser.reload()
      })
      .catch((e) => {
        console.debug(e)
      })
      .finally(async () => {
        await firebase.auth().currentUser.getIdToken(true)
        if (modalRefInvitation && modalRefInvitation.current) {
          modalRefInvitation.current.setVisible(false)
        }
        setLoadingNewCompany(false)
      })
  }

  const handleSave = useCallback(
    async (data: Partial<DashbarCompany>) => {
      setLoading(true)
      await updateCompany(data, user?.company)
      await createSevdeskContact({
        companyId: user?.company,
      })
      if (modalRefData && modalRefData.current) modalRefData.current.setVisible(false)
      setLoading(false)
    },
    [updateCompany, user?.company]
  )

  if (invitation?.id) {
    if (invitation?.email === user.email && invitation?.useCounter < invitation?.maxUses && invitation?.status === 1) {
      if (modalRefInvitation && modalRefInvitation.current) {
        modalRefInvitation.current.setVisible(true)
      }
    }
  }

  return (
    <ComponentWrapper title={t('accountOverview.company.your_company')}>
      <View style={styles.wrapper}>
        <View style={styles.contentWrapper}>
          <View style={styles.itemsWrapper}>
            <ColoredText style={[styles.item, styles.heading]}>
              <>{t('accountOverview.company.address.company')}</>
            </ColoredText>
            <ColoredText style={styles.item}>{company?.name || 'n/a'}</ColoredText>
            <ColoredText style={[styles.item, styles.heading]}>
              <>{t('accountOverview.company.address.street_number')}</>
            </ColoredText>
            <ColoredText style={styles.item}>{company?.billingAddress.address1 || 'n/a'}</ColoredText>
            <ColoredText style={[styles.item, styles.heading]}>
              <>{t('accountOverview.company.address.zip_city')}</>
            </ColoredText>
            <ColoredText style={styles.item}>
              {`${company?.billingAddress?.zipCode} ${company?.billingAddress?.city}` || 'n/a'}
            </ColoredText>
            <ColoredText style={[styles.item, styles.heading]}>
              <>{t('accountOverview.company.address.email')}</>
            </ColoredText>
            <ColoredText style={styles.item}>{company?.billingAddress?.email || 'n/a'}</ColoredText>
            <ColoredText style={[styles.item, styles.heading]}>
              <> {t('accountOverview.company.address.support_email')}</>
            </ColoredText>
            <ColoredText style={styles.item}>{company?.supportEmail || 'n/a'}</ColoredText>
            <ColoredText style={[styles.item, { width: '100%', fontSize: 12, marginTop: 10 }]}>
              {`*${t('accountOverview.company.is_support_email')}`}
            </ColoredText>
          </View>
          {userIsAdmin ? (
            <View style={styles.itemsWrapper}>
              <ColoredText style={[styles.item, styles.heading]}>
                <>{t('accountOverview.company.ustid')}</>
              </ColoredText>
              <ColoredText style={styles.item}>{company?.ustId || 'n/a'}</ColoredText>
              <ColoredText style={[styles.item, styles.heading]}>
                <>{t('accountOverview.company.payment_method')}</>
              </ColoredText>
              <PaymentMehod />
              <Button
                onPress={() => {
                  if (modalRefData && modalRefData.current) modalRefData.current.setVisible(true)
                }}
                textStyle={{ color: 'white' }}
                style={styles.button}
              >
                <> {t('accountOverview.company.change_data')}</>
              </Button>
            </View>
          ) : null}
        </View>
        <View style={styles.divider} />
        <CompanyUsers />
        <View style={styles.divider} />
        <CompanyShops />
      </View>
      <ModalPopUp ref={modalRefData} title={t('accountOverview.company.change_data')}>
        <View style={styles.modalWrapper}>
          <View style={styles.modalFormWrapper}>
            {loading ? (
              <LoadingScreen />
            ) : (
              <>
                <CompanyForm company={company} loading={loading} onSubmit={handleSave} />
                <Button
                  style={styles.cancelButton}
                  onPress={() => {
                    if (modalRefData && modalRefData.current) modalRefData.current.setVisible(false)
                  }}
                >
                  <>{t('accountOverview.profile.cancel')}</>
                </Button>
              </>
            )}
          </View>
        </View>
      </ModalPopUp>
      <ModalPopUp ref={modalRefInvitation} title={t('accountOverview.company.invitation.accept_invitation')}>
        <View style={styles.modalWrapper}>
          <View style={styles.modalFormWrapper}>
            {loadingNewCompany ? (
              <LoadingScreen text={t('accountOverview.company.invitation.updating_company')} />
            ) : (
              <>
                <ColoredText>
                  <>{t('accountOverview.company.invitation.invitation_text', { company: invitation?.companyName })}</>
                </ColoredText>
                <ColoredText>
                  <>{t('accountOverview.company.invitation.invitation_infos')}</>
                </ColoredText>
                <Button
                  style={styles.cancelButton}
                  onPress={() => {
                    if (modalRefInvitation && modalRefInvitation.current) modalRefInvitation.current.setVisible(false)
                  }}
                >
                  <> {t('accountOverview.profile.cancel')}</>
                </Button>
                <Button
                  onPress={() => handleAcceptInvitation()}
                  style={styles.dialogButton}
                  textStyle={{ color: 'white' }}
                >
                  <>{t('accountOverview.company.invitation.join_company')}</>
                </Button>
              </>
            )}
          </View>
        </View>
      </ModalPopUp>
    </ComponentWrapper>
  )
}

export default Company
