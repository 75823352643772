import { IShop } from '@dash.bar/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useLiveTracker from '../../../../pages/LiveTracker/v1/useLiveTracker'
import AvatarHorizontalScroll from '../AvatarHorizontalScroll'

type PropsT = {
  shop: IShop
  type: string
}

const BasketHorizontalScroll = ({ shop, type }: PropsT) => {
  const {
    state: { loading, data },
    nextPage,
  } = useLiveTracker({ shop, type })
  const { t } = useTranslation()

  const basket = data?.data
  return (
    <>
      <AvatarHorizontalScroll
        shop={shop}
        title={t('shop.live-tracker.basket')}
        userList={basket}
        loading={loading}
        onEndReached={() => {
          if (!loading) {
            nextPage()
          }
        }}
      />
    </>
  )
}

export default BasketHorizontalScroll
