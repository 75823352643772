import React from 'react'

import Widget from '../Widget'

import { IWidgetProps } from '../../../../types/props/Widget'
import { ISettings } from '@dash.bar/types'

import { useHistory } from '@webstollen/react-native/lib/react-router'
import BarChart from '../../../../components/charts/Bar'
import { SalesT } from '../../../../types/widgetDataTypes/types'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'

const Sales = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Widget<[SalesT]>
      title={t('shop.widget.sales.title')}
      widget={widget}
      hasInterval
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval = 'D30' }: ISettings) => ({
          body: {
            request: 'Analytics',
            widget: {
              sales: {
                options: {
                  active: true,
                  details: true,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                },
              },
            },
          },
          widget: 'sales',
        }),
      ]}
      onClickWidget={() => {
        history.push(`/shop/${widget.settings.shop}/analytics`)
      }}
      testID={'sales-widget'}
      {...props}
    >
      {([, [data], , , size]) => {
        return <BarChart list={[data.current, ...data.history.map((val) => val)].reverse()} size={size} />
      }}
    </Widget>
  )
}

export default Sales
