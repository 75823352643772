import React from 'react'

import { useRouteMatch } from '@webstollen/react-native/lib/react-router'
import HeaderWrapper from '../../../layout/Header/Wrapper'

const FilteredNewsHeader = () => {
  const {
    params: { hashtag },
  } = useRouteMatch<{ hashtag: string }>()

  return <HeaderWrapper title={'#' + hashtag} />
}

export default FilteredNewsHeader
