import { BookingState, Bundle } from '@dash.bar/types'
import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import useCurrentBundleBooking from './useCurrentBundleBooking'

const useCancelationPossible = (): boolean => {
  const currentBooking = useCurrentBundleBooking()
  const { company } = useContext(SessionContext)
  if (company?.bundle === Bundle.free) {
    return false
  }
  if (currentBooking.status !== BookingState.Active || currentBooking.key === 'trial') {
    return false
  }
  return true
}

export default useCancelationPossible
