import { Platform } from 'react-native'

import firebase from 'firebase/app'

import { AuthProvider as AuthProviderT, OAuthCredential as OAuthCredentialT } from '@firebase/auth-types'

import * as AppleAuthentication from 'expo-apple-authentication'

import { sha256 } from 'js-sha256'

const loginWithApple = async ({
  handleCredentialLogin,
  handleProviderLogin,
}: {
  handleProviderLogin: (provider: AuthProviderT) => void
  handleCredentialLogin: (credential: OAuthCredentialT) => void
}) => {
  const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com')
  if (Platform.OS === 'web') {
    handleProviderLogin(appleAuthProvider)
  } else if (Platform.OS === 'ios') {
    const csrf = Math.random().toString(36).substring(2, 15)

    const nonce = Math.random().toString(36).substring(2, 10)

    const hashedNonce = sha256(nonce)

    const appleCredential = await AppleAuthentication.signInAsync({
      requestedScopes: [
        AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
        AppleAuthentication.AppleAuthenticationScope.EMAIL,
      ],
      state: csrf,
      nonce: hashedNonce,
    })

    const { identityToken, state } = appleCredential

    if (identityToken && state === csrf) {
      const credential = appleAuthProvider.credential({
        idToken: identityToken,
        rawNonce: nonce, // nonce value from above
      })

      handleCredentialLogin(credential)
    }
  }
}

export default loginWithApple
