import * as yup from 'yup'
import { equalTo } from './SignupValidator'
import { MIN_PASSWORD_LENGTH } from '../common/constants'

yup.addMethod(yup.string, 'equalTo', equalTo)

const ChangePasswordValidator = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword1: yup.string().min(MIN_PASSWORD_LENGTH).required(),
  newPassword2: yup.string().equalTo(yup.ref('newPassword1')).required(),
})

export default ChangePasswordValidator
