import React from 'react'
import { ScrollView } from 'react-native'
import { IShop } from '@dash.bar/types'
import useStyles from './styles'
import VisitorDetail from './VisitorDetail'
import useVisitor from '../../../../../module/shop/pages/LiveVisitor/v1/useVisitor'

type PropsT = {
  shop: IShop
  visitorId: string
}

const LiveTrackerView = ({ shop, visitorId }: PropsT) => {
  const styles = useStyles()
  const { data } = useVisitor({ shop, visitorId })
  return (
    <ScrollView style={styles.wrapper} showsVerticalScrollIndicator={false}>
      <VisitorDetail shop={shop} data={data} />
    </ScrollView>
  )
}

export default LiveTrackerView
