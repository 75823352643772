import React, { useContext } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import useStyles from './styles'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import useDashboards from '../../../../hooks/dashboard/useDashboards'
import { IDashboard } from '@dash.bar/types'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import FavoriteButton from '../../../../layout/Header/FavoriteButton'
import { SessionContext } from '../../../../context/SessionContext'
import NewUserTutorial from '../../components/NewCostumerTutorial/NewUserTutorial'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import icons from '../../../../constants/icons'
import PageWrapper from '../../../../components/PageWrapper'

const Dashboards = () => {
  console.debug('(render) Dashboards')
  const styles = useStyles()
  const history = useHistory()

  const dashboards = useDashboards()
  const { shops } = useContext(SessionContext)

  if (!isLoaded(dashboards, shops)) {
    return <LoadingScreen />
  }

  if (isEmpty(dashboards, shops)) {
    return <NewUserTutorial />
  }

  return (
    <PageWrapper isScrollable={true}>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          {dashboards &&
            Object.entries(dashboards).map(([key, { title }]: [string, IDashboard]) => {
              return (
                <TouchableOpacity
                  key={key}
                  onPress={() => {
                    history.push(`dashboard/${key}`)
                  }}
                  style={styles.button}
                >
                  <FavoriteButton title={title} subtitle={'Dashboard'} url={`dashboard/${key}`} />
                  <ColoredText style={styles.buttonText}>{title}</ColoredText>
                  <FontAwesomeIcon icon={[icons.faIconStyle, 'angle-right']} />
                </TouchableOpacity>
              )
            })}
        </View>
      </View>
    </PageWrapper>
  )
}

export default Dashboards
