import React, { ReactElement } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'

import { MemoryHistory } from 'history'
import { Helmet } from 'react-helmet'

import { useHistory } from '@webstollen/react-native/lib/react-router'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'

import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'

import useStyles from './styles'
import NotificationBellComponent from './NotificationButton/bellcomponent'
import icons from '../../constants/icons'
import ProfileMenu from './ProfileMenu/ProfileMenu'
import ThemeButton from './ThemeButton/ThemeButton'
//import LanguageButton from './LanguageButton/LanguageButton'
import BundleComponent from './BundleComponent/BundleComponent'

const HeaderWrapper = ({ title, additionalControls }: { title: string; additionalControls?: ReactElement }) => {
  const styles = useStyles()
  const history = useHistory() as MemoryHistory
  return Platform.OS === 'web' ? (
    //Show FavoriteIcon in Header
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <View style={styles.additionalControls}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          {additionalControls && additionalControls}
        </View>
      </View>
      <BundleComponent />
      <ThemeButton />
      <NotificationBellComponent />
      <ProfileMenu />
    </>
  ) : (
    <>
      {Platform.OS === 'ios' && history.index > 0 ? (
        <TouchableOpacity style={styles.backButton} onPress={() => history.goBack()}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'arrow-left']} />
        </TouchableOpacity>
      ) : (
        <View style={styles.backButton} />
      )}
      <View style={styles.titleWrapper}>
        <BoldText numberOfLines={1} style={styles.title}>
          {title}
        </BoldText>
      </View>
      <View style={styles.additionalControls}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <>
            {additionalControls ? additionalControls : null}
            <NotificationBellComponent />
          </>
        </View>
      </View>
    </>
  )
}

export default HeaderWrapper
