import React from 'react'
import { View } from 'react-native'

import { Controller, FieldError, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import Button from '../../Button'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import useStyles from '../login-styles'
import TextInputWithIcon from '../../TextInputWithIcon'
import { yupResolver } from '@hookform/resolvers/yup'
import LoginValidator from '../../../validators/LoginValidator'
import icons from '../../../constants/icons'

interface IProps {
  onSubmit: (params: { email: string; password: string }) => void
  loading: boolean
}

const LoginForm = ({ onSubmit, loading }: IProps) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<{ email: string; password: string }>({
    resolver: yupResolver(LoginValidator),
  })
  const { t } = useTranslation()

  const styles = useStyles()

  return (
    <View>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputWithIcon
            icon={[icons.faIconStyle, 'user']}
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={t('placeholder.baseLogin.email')}
            autoCompleteType={'email'}
            style={styles.textInput}
            editable={!loading}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        name={'email'}
      />

      <View style={styles.errorWrapper}>
        {errors.email ? <ColoredText>{(errors.email as FieldError).message}</ColoredText> : null}
      </View>

      <Controller
        control={control}
        name={'password'}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputWithIcon
            icon={[icons.faIconStyle, 'key']}
            style={styles.textInput}
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            secureTextEntry
            placeholder={t('placeholder.baseLogin.password')}
            textContentType="password"
            autoCompleteType={'password'}
            editable={!loading}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
      />

      <View style={styles.errorWrapper}>
        {errors.password ? <ColoredText>{(errors.password as FieldError).message}</ColoredText> : null}
      </View>

      <Button style={styles.button} textStyle={styles.buttonText} onPress={handleSubmit(onSubmit)} disabled={loading}>
        {t('button.baseLogin.login')}
      </Button>
    </View>
  )
}

export default LoginForm
