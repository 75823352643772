import React from 'react'

import { useTranslation } from 'react-i18next'

import { IWidgetProps } from '../../../../types/props/Widget'

import Widget from '../Widget'
import { useHistory } from '@webstollen/react-native/lib/react-router'

import LiveTrackerView from './View'
import { EventDataT } from '../../../../types/widgetDataTypes/types'

const LiveTracker = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Widget<[EventDataT]>
      title={t('shop.widget.live-tracker.title')}
      widget={widget}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, rowsPerPage = 100 }, { page = 1 } = {}) => ({
          body: {
            request: 'EventTracker',
            widget: {
              visitors: {
                options: {
                  active: true,
                  details: false,
                  refresh: autoRefresh ? refreshInterval : undefined,
                  filter: 'none',
                  interval: null,
                  rowsPerPage: 100,
                  pagination: { descending: true, page, rowsPerPage },
                },
              },
            },
          },
          widget: 'visitors',
        }),
      ]}
      onClickWidget={() => {
        history.push(`/shop/${widget.settings.shop}/live-tracker`)
      }}
      {...props}
    >
      {([, [data], , { currency }]) => {
        return <LiveTrackerView {...data} currency={currency} />
      }}
    </Widget>
  )
}

export default LiveTracker
