import colors from '../../../../../../../constants/colors'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Bundle } from '@dash.bar/types/booking'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import Pro from '../../../../../../../assets/imgs/subscription/Pro'
import Business from '../../../../../../../assets/imgs/subscription/Business'
import { useTranslation } from 'react-i18next'
export const getCardBackground = (currentBundle: string) => {
  if (currentBundle === Bundle.free || currentBundle === 'FREE') return colors.bundleFree
  if (currentBundle === Bundle.business || currentBundle === 'BUSINESS') return colors.bundleBusiness
  return colors.bundlePro
}

const BundleCard = ({ bundle, handleClick }: { bundle: string; handleClick: (bundle: string) => void }) => {
  const { t } = useTranslation()
  const bundleIsPro = bundle === Bundle.pro
  const BundleIsBusiness = bundle === Bundle.business

  return (
    <TouchableOpacity
      style={{
        height: 120,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 16,
        padding: 10,
        backgroundColor: useSecondaryBackground(),
        marginBottom: 10,
      }}
      onPress={() => handleClick(bundle)}
    >
      <View style={{ width: '30%' }}>
        <View
          style={{
            width: 100,
            height: 100,
            backgroundColor: getCardBackground(bundle),
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 16,
          }}
        >
          <ColoredText style={{ textAlign: 'center', marginBottom: 15, color: 'white' }}>{bundle}</ColoredText>
          {BundleIsBusiness && <Business width={90} height={50} viewBox="20 5 190 120" />}

          {bundleIsPro && <Pro width={90} height={40} viewBox="0 0 190 100" />}
        </View>
      </View>

      <View
        style={{
          width: '65%',
          height: '100%',
          alignItems: 'flex-start',
          justifyContent: 'center',
          alignSelf: 'flex-start',
        }}
      >
        <ColoredText style={{ fontSize: 20, marginBottom: 10 }}>dash.bar {bundle}</ColoredText>
        <ColoredText>{t('subscription.promote_text.' + bundle)}</ColoredText>
        {bundleIsPro && (
          <ColoredText style={{ color: 'red', marginTop: 10 }}>{t('subscription.promote_text.discount')}</ColoredText>
        )}

        {/* TODO: Sie sparen x  */}
      </View>
      <FontAwesomeIcon style={{ width: '5%' }} icon={'chevron-right'} />
    </TouchableOpacity>
  )
}

export default BundleCard
