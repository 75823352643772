import { useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { StyleSheet } from 'react-native'

//import { usePrimaryBackground } from '@webstollen/react-native/lib/hooks/useColors'

import { heightPercentageToDP } from 'react-native-responsive-screen'

const useStyles = () => {
  return StyleSheet.create({
    main: {
      flex: 1,
      //backgroundColor: usePrimaryBackground(),
      backgroundColor: 'transparent',
      maxHeight: heightPercentageToDP(100),
      flexDirection: 'column',
    },
    container: {
      flexDirection: 'row',
      flex: 1,
    },
    backgroundImage: {
      maxHeight: heightPercentageToDP(100),
      height: '100%',
    },
    mainWrapper: {
      flexDirection: 'row',
      flex: 1,
      overflow: 'hidden',
    },
    mainBackground: {
      backgroundColor: useSecondaryBackground(),
      opacity: 0.8,
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 1,
    },
    mainContent: {
      backgroundColor: 'transparent',
      zIndex: 2,
    },
  })
}

export default useStyles
