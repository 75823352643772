import React, { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'

import useWidgetDataFromApi, { ApiConfigFunction } from '../../../../hooks/widget/useWidgetDataFromApi'

import useIntervals from '../../../../hooks/widget/useIntervals'

import IntervalSelection from '../../../components/IntervalSelection'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import { useModifyWidgetContext } from '../../../../types/Widget'

import { IShop, IWidget, ShopMap } from '@dash.bar/types'

import WidgetView, { PropsT as BaseProps } from '../../../../components/Widget'
import { Modify } from '../../../../types'
import useRemoveWidget from '../../../../hooks/widget/useRemoveWidget'
import useDashboard from '../../../../hooks/dashboard/useDashboard'
import Actions from '../../../components/Actions'
import { isLoaded } from 'react-redux-firebase'
import { View } from 'react-native'
import Button from '../../../../components/Button'
import { useIsWidgetAllowedForUser } from '../../../index'
import { useHistory } from '@webstollen/react-native/lib/react-router'

interface Size {
  height: number
  width: number
}

type ChildrenParams<DataTypes> = [boolean, DataTypes, Error | null, IShop | null, Size]

interface WidgetProps<DataTypes> {
  widget: IWidget
  dashboardId: string
  getApiConfig: Array<ApiConfigFunction>
  children: (args: ChildrenParams<DataTypes>) => React.ReactChild
  hasInterval?: boolean
  showMissingData?: boolean
  shops: ShopMap
}

const Widget = <DataTypes extends Array<unknown>>({
  widget,
  dashboardId,
  children,
  footerContent,
  getApiConfig,
  hasInterval = false,
  showMissingData = false,
  showOptions = true,
  shops,
  ...props
}: Modify<BaseProps, WidgetProps<DataTypes>>) => {
  const { updateWidget } = useModifyWidgetContext()
  const removeWidget = useRemoveWidget(dashboardId)
  const dashboard = useDashboard(dashboardId)
  const history = useHistory()
  const isWidgetAllowed = useIsWidgetAllowedForUser()

  const widgets = dashboard?.widgets

  const { t } = useTranslation()

  const [size, setSize] = useState({ height: 0, width: 0 })

  const onIntervalChange = useCallback(
    (newInterval) => {
      const newWidget = {
        ...widget,
        settings: {
          ...widget.settings,
          interval: newInterval,
        },
      }

      updateWidget(newWidget)
    },
    [widget, updateWidget]
  )

  const intervals = useIntervals()

  const {
    id,
    settings: { shop, ...settings },
  } = widget

  const widgetShop = shops[shop]

  const [loading, data, error] = useWidgetDataFromApi<DataTypes>({
    id,
    settings,
    apiConfig: getApiConfig,
    shop: widgetShop,
  })

  if (!isLoaded(widgets)) {
    return <LoadingScreen />
  }

  return (
    <WidgetView
      image={{
        source: widgetShop.icon,
        alt: widgetShop.domain,
      }}
      footerContent={
        footerContent ||
        (error && error.message) ||
        (hasInterval && (intervals.find(({ value }) => widget.settings.interval === value) || {}).label)
      }
      options={[
        hasInterval && (
          <IntervalSelection
            intervals={intervals}
            key="interval-selection"
            settings={widget && widget.settings}
            onIntervalChange={onIntervalChange}
          />
        ),
        <Actions key={'actions'} widget={widget} widgets={widgets} onRemoveWidget={removeWidget} />,
      ]}
      showOptions={showOptions}
      onWidgetLayout={(evt) => {
        const { height, width } = evt.nativeEvent.layout

        setSize({ height, width })
      }}
      {...props}
    >
      {!isWidgetAllowed(widget) ? (
        <View style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <ColoredText style={{ textAlign: 'center' }}>{t('shop.widget.notAllowed')}</ColoredText>
          <Button
            primary
            style={{ marginTop: 10, paddingHorizontal: 5 }}
            onPress={() => {
              history.push('/upselling')
            }}
          >
            {t('shop.upselling.body.buy')}
          </Button>
        </View>
      ) : loading ? (
        <LoadingScreen />
      ) : !loading && ((showMissingData && !data) || data) ? (
        children([loading, data, error, widgetShop, size])
      ) : (
        <ColoredText>{t('shop.widget.no-data')}</ColoredText>
      )}
    </WidgetView>
  )
}

export default Widget
