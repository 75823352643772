import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { ScrollView } from 'native-base'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import Amex from '../../../../../../../assets/imgs/paymentMethods/amex'
import Cartasi from '../../../../../../../assets/imgs/paymentMethods/cartasi'
import Creditcard from '../../../../../../../assets/imgs/paymentMethods/creditcard'
import Directdebit from '../../../../../../../assets/imgs/paymentMethods/directdebit'
import Maestro from '../../../../../../../assets/imgs/paymentMethods/maestro'
import Mastercard from '../../../../../../../assets/imgs/paymentMethods/mastercard'
import Visa from '../../../../../../../assets/imgs/paymentMethods/visa'
import Button from '../../../../../../../components/Button'
import ModalPopUp from '../../../../../../../components/Modal'
import { SessionContext } from '../../../../../../../context/SessionContext'
import { functions } from '../../../../../../../firebase'
import MandateChange from './MandateChange/MandateChange'
import useStyles from './styles'

const PaymentMehod = () => {
  const { user } = useContext(SessionContext)
  const styles = useStyles()
  const { t } = useTranslation()
  const modalRefDebit = useRef<any>()
  const getMandate = functions().httpsCallable('calls-abocloud-getMandate')
  const [{ mandate }, setMandateState] = useState<{
    mandate: Record<string, any>
  }>({
    mandate: {},
  })
  const [{ loading }, setLoadingState] = useState<{
    loading: boolean
  }>({
    loading: true,
  })
  useEffect(() => {
    setLoadingState({ loading: true })
    getMandate({ companyId: user?.company })
      .then((result) => {
        setMandateState({ mandate: result.data })
        setLoadingState({ loading: false })
      })
      .catch(() => {
        setMandateState({ mandate: null })
        setLoadingState({ loading: false })
      })
  }, [])

  let methodImage
  if (mandate?.mandate?.method === 'creditcard') {
    switch (mandate?.mandate?.details?.cardLabel?.toLowerCase() || null) {
      case 'mastercard':
        methodImage = <Mastercard />
        break
      case 'visa':
        methodImage = <Visa />
        break
      case 'american express':
        methodImage = <Amex />
        break
      case 'carta si':
        methodImage = <Cartasi />
        break
      case 'maestro':
        methodImage = <Maestro />
        break
      default:
        methodImage = <Creditcard />
    }
  } else {
    methodImage = <Directdebit />
  }

  if (loading) {
    return (
      <ColoredText>
        <>{t('subscription.booking_completion.load_mandate')}</>
      </ColoredText>
    )
  }
  if (mandate === null) {
    return (
      <ColoredText>
        <>{t('subscription.booking_completion.no_mandate')}</>
      </ColoredText>
    )
  }

  return (
    <>
      <View style={{ width: '60%', flex: 1, flexDirection: 'row' }}>
        <View style={{ width: '20%' }}>{methodImage}</View>
        <TouchableOpacity
          onPress={() => {
            if (modalRefDebit && modalRefDebit.current) modalRefDebit.current.setVisible(true)
          }}
        >
          <ColoredText style={styles.refresh_text}>
            <>{t('accountOverview.company.refresh')}</>
          </ColoredText>
        </TouchableOpacity>
      </View>
      <ModalPopUp ref={modalRefDebit}>
        <ScrollView contentContainerStyle={styles.modalWrapper}>
          <View style={styles.modalFormWrapper}>
            <MandateChange />
            <Button
              style={styles.cancelButton}
              onPress={() => {
                if (modalRefDebit && modalRefDebit.current) modalRefDebit.current.setVisible(false)
              }}
            >
              {t('accountOverview.profile.cancel')}
            </Button>
          </View>
        </ScrollView>
      </ModalPopUp>
    </>
  )
}

export default PaymentMehod
