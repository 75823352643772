import { Platform, StyleSheet } from 'react-native'

import { usePrimaryBackground, useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { heightPercentageToDP } from 'react-native-responsive-screen'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      height: Platform.select({ default: '100%', web: 'auto' }),
      display: 'flex',
      marginHorizontal: 8,
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: 'transparent',
    },
    container: {
      flex: 1,
      width: '100%',
      alignItems: 'stretch',
      justifyContent: 'flex-end',
      marginBottom: 5,
    },
    button: {
      marginHorizontal: 10,
      marginVertical: 1,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 50,
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
    },
    buttonText: {
      width: '75%',
    },

    listWrapper: {
      height: heightPercentageToDP(100) - 56, // 56 is header height plus padding
    },

    listItemAlternate: {
      backgroundColor: useSecondaryBackground(),
    },
    listItem: {
      backgroundColor: usePrimaryBackground(),
      flexDirection: 'column',
      flex: 1,
      padding: 10,
      paddingLeft: 20,
      borderRadius: 10,
      justifyContent: 'space-between',
      minHeight: 50,
      alignItems: 'center',
    },
  })
}

export default useStyles
