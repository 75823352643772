import React from 'react'
import { View, Platform } from 'react-native'

import { AuthProvider as AuthProviderT, OAuthCredential as OAuthCredentialT } from '@firebase/auth-types'

import { useTranslation } from 'react-i18next'

import * as AppleAuthentication from 'expo-apple-authentication'

import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'

import Button from '../Button'

import useStyles from './styles'
import loginWithGoogle from '../../utils/auth/login-with-google'
import loginWithApple from '../../utils/auth/login-with-apple'

interface IProps {
  handleProviderLogin: (provider: AuthProviderT) => void
  handleCredentialLogin: (credential: OAuthCredentialT) => void
  loading: boolean
}

const AuthProvider = ({ loading, handleProviderLogin, handleCredentialLogin }: IProps) => {
  const { t } = useTranslation()

  const styles = useStyles()

  const onClickLoginWithGoogle = () => loginWithGoogle({ handleCredentialLogin, handleProviderLogin })

  const onClickLoginWithApple = () => loginWithApple({ handleCredentialLogin, handleProviderLogin })

  return (
    <View style={styles.wrapper}>
      <Button style={styles.button} onPress={onClickLoginWithGoogle} disabled={loading}>
        <FontAwesomeIcon icon={['fab', 'google']} /> {t('button.baseLogin.loginWith')} Google
      </Button>

      {Platform.OS === 'ios' && (
        <AppleAuthentication.AppleAuthenticationButton
          buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
          buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE_OUTLINE}
          cornerRadius={5}
          style={{ width: '100%', height: 40 }}
          onPress={onClickLoginWithApple}
        />
      )}
      {Platform.OS === 'web' && (
        <Button style={styles.button} disabled={loading} onPress={onClickLoginWithApple}>
          <FontAwesomeIcon icon={['fab', 'apple']} /> {t('button.baseLogin.loginWith')} Apple
        </Button>
      )}
    </View>
  )
}

export default AuthProvider
