import React from 'react'

import { useTranslation } from 'react-i18next'

import Widget from './Widget'

import { IWidgetProps } from '../../../types/props/Widget'

const Sleepers = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()

  return (
    <Widget
      title={t('shop.widget.sleepers.title')}
      widget={widget}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, rowsPerPage = 4 }, { page = 1 } = {}) => ({
          body: {
            request: 'Analytics',
            widget: {
              penner: {
                options: {
                  active: true,
                  details: false,
                  refreshInterval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                  pagination: { descending: true, page, rowsPerPage },
                },
              },
            },
          },
          widget: 'penner',
        }),
      ]}
      {...props}
    >
      {([, data]) => (
        <>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </>
      )}
    </Widget>
  )
}

export default Sleepers
