import React from 'react'

import Dashboards from './pages/Dashboards'
import Dashboard from './pages/Dashboard'
import DashboardRedirect from './pages/Dashboard-Redirect'
//import { Platform } from 'react-native'
//import i18n from '../../locales/i18n'
import DashboardHeader from './components/DashboardHeader'
import ContingentWrapper from '../../components/ContingentWrapper'

export default () => [
  {
    title: <DashboardHeader />, //Show FavoriteIcon also on Web -----old:Platform.select<ReactElement>({ web: i18n.t('nav.dashboard'), default: <DashboardHeader /> }),
    name: 'dashboard_index',
    route: {
      exact: true,
      path: '/dashboard/:dashboardId',
      children: (
        <ContingentWrapper>
          <Dashboard />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
  {
    title: 'Dashboards',
    name: 'dashboard_list',
    route: {
      exact: true,
      path: '/dashboard',
      children: (
        <ContingentWrapper>
          <Dashboards />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
  {
    title: 'Dashboard',
    name: 'dashboard_redirect',
    route: {
      exact: true,
      path: '/dashboard-redirect',
      children: (
        <ContingentWrapper>
          <DashboardRedirect />
        </ContingentWrapper>
      ),
    },
    requireAuth: true,
  },
]
