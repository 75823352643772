import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import moment from 'moment'
import React from 'react'
import { Linking, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useStyles from '../../styles'
import { TrackerDataT } from '../../../../../../../../types/widgetDataTypes/types'
import Url from 'url-parse'
import icons from '../../../../../../../../constants/icons'

type PropsT = {
  item: TrackerDataT
}

const News = ({ item }: PropsT) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const parsedUrl = (url: string) => {
    return new Url(url)
  }
  return (
    <View style={styles.wrapper}>
      <View style={styles.labelWrapper}>
        <BoldText style={styles.labelTitle}>{t('shop.live-visitor.news')}</BoldText>
        <View style={[styles.fontAwesomeWrapper, styles.backgroundColorText]}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'newspaper']} />
        </View>
      </View>
      <BoldText style={styles.header} numberOfLines={1}>
        {item?.data?.name}
      </BoldText>
      <View style={styles.flexDirectionRow}>
        <ColoredText style={styles.fontSize13}>{t('shop.live-visitor.url')}: </ColoredText>
        <BoldText
          style={styles.url}
          onPress={() => Linking?.openURL(parsedUrl(item?.data?.page).href)}
          numberOfLines={1}
        >
          {parsedUrl(item?.data?.page).pathname}
        </BoldText>
      </View>
      <View style={styles.timeWrapper}>
        <ColoredText style={styles.runtime}>
          {t('shop.live-visitor.runTime')}: {item?.runtime}
        </ColoredText>
        <ColoredText style={styles.textColor} numberOfLines={2}>
          {`${moment(item?.timestamp).fromNow()}`}
        </ColoredText>
      </View>
    </View>
  )
}
export default News
