import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { processFontFamily } from 'expo-font'
import * as React from 'react'
import Svg, { SvgProps, G, Rect, Text, TSpan, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */

const AppleAnmeldenButton = (props: SvgProps) => {
  const { t } = useTranslation()
  return (
    <Svg width={210} height={40} {...props}>
      <G fill="none" fillRule="evenodd">
        <Rect fill="#000" width={210} height={40} rx={4} />
        <Text fontFamily={processFontFamily('Comfortaa_500Medium')} fontSize={11} fontWeight="bold" fill="#FFF">
          <TSpan x={61.041} y={25}>
            {t('providers.buttons.connect_apple')}
          </TSpan>
        </Text>
        <Path
          d="M31.018 10.924c.876-1.132 1.541-2.731 1.3-4.364-1.432.1-3.107 1.016-4.084 2.211-.89 1.083-1.623 2.693-1.337 4.256 1.566.05 3.183-.89 4.12-2.103Zm7.742 15.358c-.627 1.397-.928 2.021-1.736 3.259-1.126 1.727-2.715 3.878-4.685 3.894-1.748.019-2.2-1.146-4.573-1.132-2.374.013-2.869 1.154-4.62 1.137-1.97-.018-3.474-1.958-4.6-3.686-3.152-4.827-3.484-10.493-1.54-13.507 1.382-2.14 3.563-3.392 5.611-3.392 2.085 0 3.397 1.15 5.124 1.15 1.674 0 2.694-1.153 5.106-1.153 1.825 0 3.76 1 5.135 2.726-4.512 2.488-3.781 8.97.778 10.704Z"
          fill="#FFF"
        />
      </G>
    </Svg>
  )
}

export default AppleAnmeldenButton
