import { User } from '@dash.bar/types/user'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import React from 'react'
import { useContext } from 'react'
import { isLoaded } from 'react-redux-firebase'
import { SessionContext } from '../context/SessionContext'
import useSetDocument from './useSetDocument'

const NotificationCounterContext = React.createContext(0)
const NotificationCounterUpdateContext = React.createContext(() => alert())
export function useNotificationCounter() {
  return useContext(NotificationCounterContext)
}

export function UseNotificationCounter() {
  return useContext(NotificationCounterUpdateContext)
}
export function NotificationsCounterProvider({ children }: { children: any }) {
  const { currentUser, user } = useContext(SessionContext)
  const counter = user?.notificationCounter

  //Just for testing can be removed if not working
  /*
  const notifications = useNotifications()
  if ('Notification' in window && Platform.OS === 'web') {
    Notification.requestPermission()
  }
  useEffect(() => {
    if (notifications && notifications[0] && notifications[0][1]) {
      new Notification(`${notifications[0][1].title}`, {
        icon: '../assets/imgs/favorites/favorite.png',
        image: '../assets/imgs/favorites/favorite.png',
        body: notifications[0][1].body,
      })
    }
  }, [counter])*/

  //const [counter, setCounter] = React.useState(user?.notificationCounter ?? 0)
  const updateUser = useSetDocument<User>('user')
  if (!isLoaded(user)) return <LoadingScreen />

  function setCounterToNull() {
    //setCounter(0)
    updateUser({ ...user, notificationCounter: 0 }, currentUser.uid)
  }
  return (
    <NotificationCounterContext.Provider value={counter}>
      <NotificationCounterUpdateContext.Provider value={setCounterToNull}>
        {children}
      </NotificationCounterUpdateContext.Provider>
    </NotificationCounterContext.Provider>
  )
}
