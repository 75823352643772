import React from 'react'
import { useTranslation } from 'react-i18next'

import { ISettings } from '@dash.bar/types'

import { View, TouchableOpacity } from 'react-native'
import SemiBoldText from '@webstollen/react-native/lib/components/CustomText/SemiBoldText'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import useStyles from './styles'

interface IProps {
  settings: ISettings
  onIntervalChange: (value: string) => void
  intervals: Array<{
    label: string
    value: string
    active: boolean
  }>
}

const IntervalSelection = ({ intervals, ...props }: IProps) => {
  const { settings, onIntervalChange } = props

  const styles = useStyles()

  const { t } = useTranslation()

  //const intervals = useIntervals(props.origin ?? 'page')

  const LINE_LENGTH = 4
  const intervalOptions = [...new Array(Math.ceil(intervals.length / LINE_LENGTH))].map((_, index) =>
    intervals.slice(index * LINE_LENGTH, index * LINE_LENGTH + LINE_LENGTH)
  )

  const { interval } = settings || {}

  return (
    <>
      <SemiBoldText style={styles.header}>{t('shop.widgets.interval.title')}</SemiBoldText>
      <View style={styles.tagContainer}>
        {intervalOptions.map((line, index) => (
          <View key={index} style={styles.lineContainer}>
            {line.map(({ label, value }) => (
              <TouchableOpacity
                key={value}
                onPress={interval !== value ? () => onIntervalChange(value) : () => undefined}
                style={[styles.tag, interval === value && styles.tagSelected]}
              >
                <ColoredText style={[styles.tagLabel, interval === value && styles.tagLabelSelected]}>
                  {label}
                </ColoredText>
              </TouchableOpacity>
            ))}
          </View>
        ))}
      </View>
    </>
  )
}

export default IntervalSelection
