import { IconProp } from '@fortawesome/fontawesome-svg-core'

const osResult = (os: string) => {
  if (os?.split(' ')[0].toLowerCase() === 'mobile') {
    return os?.split(' ')[1].toLowerCase()
  } else {
    return os?.split(' ')[0].toLowerCase()
  }
}

export const getOsIcon = (os: string): IconProp => {
  switch (osResult(os)) {
    case 'windows':
      return ['fab', 'windows']
    case 'mac':
      return ['fab', 'apple']
    case 'ubuntu':
      return ['fab', 'ubuntu']
    case 'linux':
      return ['fab', 'linux']
    case 'suse':
      return ['fab', 'suse']
    case 'redhat':
      return ['fab', 'redhat']
    case 'fedora':
      return ['fab', 'fedora']
    case 'centos':
      return ['fab', 'centos']
    default:
      return ['fad', 'desktop']
  }
}
