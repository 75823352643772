import { useCallback, useContext } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../context/SessionContext'

const useRemoveDashboard = () => {
  const firestore = useFirestore()
  const { user } = useContext(SessionContext)
  return useCallback(
    async (dashboardId: string) =>
      firestore
        .collection(`company/${user?.company ?? '-'}/dashboard`)
        .doc(dashboardId)
        .delete(),
    [firestore, user?.company]
  )
}

export default useRemoveDashboard
