import { Bundle } from '@dash.bar/types'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import React from 'react'
import { View } from 'react-native'
import useAllowedUser from '../../../hooks/booking/useAllowedUser'
import Button from '../../Button'
import useStyles from '../styles'

export const UserContingentWarning = ({
  user,
  bundle,
  modalRef,
  setUserWarningAccepted,
}: {
  user: number
  bundle: Bundle
  modalRef: React.MutableRefObject<any>
  setUserWarningAccepted: (arg0: boolean) => void
}) => {
  const { t } = useTranslation()
  const allowedUser = useAllowedUser(bundle)
  const styles = useStyles()
  return (
    <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ width: '60%', justifyContent: 'center', alignItems: 'center' }}>
        <ColoredText style={{ fontSize: 28, margin: 15 }}>{t('subscription.userWarning.attention')}</ColoredText>
        <View style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 10 }}>
          <ColoredText style={{ marginVertical: 5 }}>{t('subscription.userWarning.more_user_text')}</ColoredText>
          <ColoredText style={{ fontSize: 14, fontWeight: 'bold' }}>
            {t('subscription.userWarning.current_user', { userNumber: user })}
          </ColoredText>
          <ColoredText style={{ fontSize: 14, fontWeight: 'bold' }}>
            {t('subscription.userWarning.allowed_user', { userNumber: allowedUser })}
          </ColoredText>
          <ColoredText style={{ marginVertical: 5 }}>{t('subscription.userWarning.warning_text')}</ColoredText>
        </View>
        <View style={styles.buttonWrapper}>
          <Button
            textStyle={styles.highlightColor}
            style={styles.cancelButton}
            onPress={() => {
              if (modalRef && modalRef.current) modalRef.current.setVisible(false)
              setUserWarningAccepted(false)
            }}
          >
            {t('subscription.booking_completion.cancel')}
          </Button>
          <Button
            style={styles.highlightButton}
            textStyle={{ color: 'white' }}
            onPress={() => setUserWarningAccepted(true)}
          >
            {t('subscription.userWarning.continue_booking')}
          </Button>
        </View>
      </View>
    </View>
  )
}
