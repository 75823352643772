import { IShop } from '@dash.bar/types'
import { useCallback, useEffect } from 'react'
import api from '../../../../../widgets/shop/api'

import { KeyFiguresT } from '../../../../../types/widgetDataTypes/types'
import useApiData from '../../../../../hooks/api/useApiData'

const useKeyFigures = ({ shop, interval }: { shop: IShop; interval: string }) => {
  const loadData = useCallback(async () => {
    const result: KeyFiguresT = await api(shop.endpoint, shop.secretToken, {
      body: {
        request: 'Analytics',
        widget: {
          status: {
            start_time: Date.now(),
            info: ['sales', 'visitors', 'orders', 'conversions'],
            options: {
              active: true,
              details: false,
              interval,
            },
          },
        },
      },
      widget: 'status',
    })

    return result
  }, [shop, interval])

  const { getData, state } = useApiData(loadData)

  useEffect(() => {
    getData()
  }, [getData])

  return state
}

export default useKeyFigures
