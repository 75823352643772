import React from 'react'
import { Chip } from 'react-native-paper'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { View } from 'react-native'
import colors from '../../../constants/colors'
import { usePrimaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { useTranslation } from 'react-i18next'
import { User } from '@dash.bar/types/user'
import useSetDocument from '../../../hooks/useSetDocument'
import useStyles from './styles'
import { clone } from 'lodash'

// function updateFilter(
//   value: string,
//   array: Array<string>,
//   arrayType: string,
//   filter: {
//     shopList: Array<string>
//     notificationType: Array<string>
//   },
//   setFilter: React.Dispatch<
//     React.SetStateAction<{
//       shopList: Array<string>
//       notificationType: Array<string>
//     }>
//   >,
//   updateUser: (data: Partial<User>, id?: string) => Promise<void>,
//   user: User
// ) {
//   console.log('update Filter')
// }

const ShopSelectionDialogBody = ({
  shops,
  user,
  filter,
  setFilter,
}: {
  shops: Array<string>
  user: User
  filter: { shopList: Array<string>; notificationType: Array<string> }
  setFilter: React.Dispatch<
    React.SetStateAction<{
      shopList: Array<string>
      notificationType: Array<string>
    }>
  >
}) => {
  const styles = useStyles()
  const hightlightColor = colors.color3
  const notificationTypes = ['Bestellung', 'Bewertung', 'dash.bar Plugin', 'dash.bar']
  const backgroundColor = usePrimaryBackground()
  const { t } = useTranslation()
  const updateUser = useSetDocument<User>('user')

  function updateFilter(value: string, updateCategory: string) {
    const newFilter = clone(filter)

    // update ShopFilter
    if (updateCategory === 'shopList') {
      const containsItem = filter.shopList.includes(value)
      if (containsItem) {
        newFilter.shopList = newFilter.shopList.filter((shop) => shop !== value)
      } else {
        newFilter.shopList.push(value)
      }
    }

    // update NotificationFilter
    if (updateCategory === 'notificationType') {
      const containsItem = filter.notificationType.includes(value)
      if (containsItem) {
        newFilter.notificationType = newFilter.notificationType.filter((notType) => notType !== value)
      } else {
        newFilter.notificationType.push(value)
      }
    }

    setFilter(newFilter)
    updateUser({ ...user, notificationSettings: newFilter })
  }

  return (
    <View>
      <ColoredText style={{ fontSize: 16, alignSelf: 'center', paddingVertical: '5%' }}>
        {t('title.userProfile.shops')}
      </ColoredText>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        {shops.map((shop) => {
          const selected = filter.shopList.includes(shop)

          return (
            <Chip
              key={shop}
              style={[styles.chip, { backgroundColor: selected ? hightlightColor : backgroundColor }]}
              onPress={() => updateFilter(shop, 'shopList')}
            >
              <ColoredText>{shop}</ColoredText>
            </Chip>
          )
        })}
      </View>

      <ColoredText style={{ fontSize: 16, alignSelf: 'center', paddingVertical: '5%' }}>
        {t('notifications.notifications')}
      </ColoredText>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        {notificationTypes.map((type) => {
          const selected = filter.notificationType.includes(type)
          return (
            <Chip
              key={type}
              style={[styles.chip, { backgroundColor: selected ? hightlightColor : backgroundColor }]}
              onPress={() => updateFilter(type, 'notificationType')}
            >
              <ColoredText> {type} </ColoredText>
            </Chip>
          )
        })}
      </View>
    </View>
  )
}
export default ShopSelectionDialogBody
