import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import React from 'react'
import { View } from 'react-native'
import sizes from '../../../../../../../constants/sizes'

const UpsellingDialogHeadline = ({ name }: { name: string }) => {
  const { t } = useTranslation()

  return (
    <View style={{ alignItems: 'center' }}>
      <ColoredText style={{ fontSize: 18 }}>{t('shop.upselling.body.hello') + ' ' + name + ','}</ColoredText>
      <ColoredText style={{ marginVertical: sizes.paddingTop }}>
        {t('shop.upselling.body.messageToCustomer')}
      </ColoredText>
    </View>
  )
}

export default UpsellingDialogHeadline
