import React, { useRef } from 'react'

import { useTranslation } from 'react-i18next'

import useStyles from './styles'
import { View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import numbro from 'numbro'
import { IShop } from '@dash.bar/types'

import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'
import colors from '../../../../constants/colors'
import { KeyFiguresT, StatusT } from '../../../../types/widgetDataTypes/types'
import icons from '../../../../constants/icons'

interface Size {
  height: number
  width: number
}

interface PropsT {
  status?: StatusT
  keyFigures?: KeyFiguresT
  shop: IShop
  size: Size
}

const Status = ({
  status,
  keyFigures = {
    sales: { umsatz: 0, umsatz_history: 0 },
    visitors: { besucher: 0, besucher_history: 0 },
    orders: { bestellungen: 0, bestellungen_history: 0 },
    conversions: { conversion: 0, conversion_history: 0 },
  },
  shop,
  size,
}: PropsT) => {
  const { t } = useTranslation()

  const styles = useStyles()

  const timeOfFirstRender = useRef(Date.now())

  return (
    <View style={[styles.content, size]}>
      <View style={[styles.keyFigures]}>
        {[
          {
            value: numbro(keyFigures.sales.umsatz).formatCurrency({
              ...numbro.languageData().currencyFormat,
              currencySymbol: htmlDecode(shop.currency),
              spaceSeparated: true,
              average: true,
            }),
            previous: keyFigures.sales.umsatz_history,
            label: t('shop.widget.key-figures.body.sales'),
            rawValue: keyFigures.sales.umsatz,
          },
          {
            value: numbro(keyFigures.visitors.besucher).format({
              output: 'number',
              spaceSeparated: true,
              average: true,
            }),
            previous: keyFigures.visitors.besucher_history,
            label: t('shop.widget.key-figures.body.visitors'),
            rawValue: keyFigures.visitors.besucher,
          },
        ].map(({ value, label, previous, rawValue }, index) => {
          const number = previous === 0 ? 0 : (rawValue - previous) / previous

          return (
            <View key={label} style={[styles.item, index === 0 ? { paddingRight: 25 } : { paddingLeft: 25 }]}>
              <View style={styles.titleWrapper}>
                <ColoredText style={styles.itemTitle}>{label}</ColoredText>
                <BoldText style={styles.itemCurrentValue}>{value}</BoldText>
              </View>
              <ColoredText style={[styles.percentage, number >= 0 ? styles.positive : styles.negative]}>
                {numbro(number).format({
                  output: 'percent',
                  mantissa: 0,
                  forceSign: true,
                })}
              </ColoredText>
            </View>
          )
        })}
      </View>

      <View style={[styles.keyFigures]}>
        {[
          {
            value: numbro(keyFigures.orders.bestellungen).format({
              output: 'number',
              spaceSeparated: true,
              average: true,
            }),
            previous: keyFigures.orders.bestellungen_history,
            label: t('shop.widget.key-figures.body.orders'),
            rawValue: keyFigures.orders.bestellungen,
          },
          {
            value: numbro(keyFigures.conversions.conversion / 100).format({
              output: 'percent',
              mantissa: 2,
              spaceSeparated: true,
              average: true,
            }),
            previous: keyFigures.conversions.conversion_history,
            label: t('shop.widget.key-figures.body.conversion'),
            rawValue: keyFigures.conversions.conversion,
          },
        ].map(({ value, label, previous, rawValue }, index) => {
          const number = previous === 0 ? 0 : (rawValue - previous) / previous

          return (
            <View key={label} style={[styles.item, index === 0 ? { paddingRight: 25 } : { paddingLeft: 25 }]}>
              <View style={styles.titleWrapper}>
                <ColoredText style={styles.itemTitle}>{label}</ColoredText>
                <BoldText style={styles.itemCurrentValue}>{value}</BoldText>
              </View>
              <ColoredText style={[styles.percentage, number >= 0 ? styles.positive : styles.negative]}>
                {numbro(number).format({
                  output: 'percent',
                  mantissa: 0,
                  forceSign: true,
                })}
              </ColoredText>
            </View>
          )
        })}
      </View>

      <View style={{}} />

      <View style={[styles.status]}>
        {[
          {
            status: status != null,
            label: t('shop.widget.status.body.shop-online'),
          },
          {
            status: status && !status.maintenance_mode,
            label: t('shop.widget.status.body.maintenance'),
          },
          {
            status: status && timeOfFirstRender.current - status.start_time < 2500,
            label: t('shop.widget.status.body.performance'),
          },
          {
            status: status && status.caching_activated,
            label: `${t('shop.widget.status.body.cache')} ${
              status && status.caching_activated ? `(${status.caching_method})` : ''
            }`,
          },
        ].map(({ status, label }, index) => (
          <View key={label} style={[styles.statusItem, index % 2 === 0 ? { paddingRight: 20 } : { paddingLeft: 20 }]}>
            <FontAwesomeIcon
              color={status ? colors.success : colors.error}
              icon={[icons.faIconStyle, status ? 'check-circle' : 'times-circle']}
            />
            <ColoredText style={styles.itemText}>{label}</ColoredText>
          </View>
        ))}
      </View>
    </View>
  )
}

export default Status
