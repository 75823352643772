import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { processFontFamily } from 'expo-font'
import * as React from 'react'
import Svg, { SvgProps, G, Rect, Text, TSpan, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */

const GoogleTrennenButton = (props: SvgProps) => {
  const { t } = useTranslation()
  return (
    <Svg width={210} height={40} {...props}>
      <G fill="none" fillRule="evenodd">
        <Rect fill="#000" width={210} height={40} rx={4} />
        <Text fontFamily={processFontFamily('Comfortaa_500Medium')} fontSize={11} fontWeight="bold" fill="#FFF">
          <TSpan x={56.075} y={25}>
            {t('providers.buttons.disconnect_google')}
          </TSpan>
        </Text>
        <G fillRule="nonzero">
          <Path
            d="M40.44 20.302c0-.884-.078-1.724-.213-2.542H27.566v5.051h7.25c-.326 1.658-1.278 3.058-2.69 4.01v3.36h4.325c2.532-2.341 3.989-5.79 3.989-9.879Z"
            fill="#4285F4"
          />
          <Path
            d="M27.566 33.44c3.63 0 6.667-1.21 8.885-3.26l-4.325-3.36c-1.21.807-2.745 1.3-4.56 1.3-3.507 0-6.477-2.363-7.541-5.555h-4.46v3.46c2.208 4.391 6.746 7.415 12 7.415Z"
            fill="#34A853"
          />
          <Path
            d="M20.025 22.565A7.796 7.796 0 0 1 19.599 20a8.1 8.1 0 0 1 .426-2.565v-3.46h-4.46a13.28 13.28 0 0 0 0 12.05l4.46-3.46Z"
            fill="#FBBC05"
          />
          <Path
            d="M27.566 11.88c1.983 0 3.753.683 5.154 2.016l3.832-3.83c-2.32-2.173-5.356-3.506-8.986-3.506-5.255 0-9.793 3.024-12 7.414l4.459 3.461c1.064-3.192 4.034-5.555 7.54-5.555Z"
            fill="#EA4335"
          />
        </G>
      </G>
    </Svg>
  )
}

export default GoogleTrennenButton
