import { Platform } from 'react-native'

import firebase from 'firebase/app'

import { AuthProvider as AuthProviderT, OAuthCredential as OAuthCredentialT } from '@firebase/auth-types'

import * as Google from 'expo-google-app-auth'

const loginWithGoogle = async ({
  handleCredentialLogin,
  handleProviderLogin,
}: {
  handleProviderLogin: (provider: AuthProviderT) => void
  handleCredentialLogin: (credential: OAuthCredentialT) => void
}) => {
  if (Platform.OS === 'web') {
    handleProviderLogin(new firebase.auth.GoogleAuthProvider())
  } else {
    const response = await Google.logInAsync({
      androidClientId: '848029071102-b4mg7psk8g18a7amfkm0jrhfti9mri48.apps.googleusercontent.com',
      androidStandaloneAppClientId: '848029071102-uc6g1ubc3b13d8hbbhbl8a2pgilf00oc.apps.googleusercontent.com',
      iosClientId: '848029071102-dannivo9jbu26da47vt9200in50dkq3p.apps.googleusercontent.com',
      iosStandaloneAppClientId: '848029071102-vekr6amuur0gt7nprecrgpglhufo3h33.apps.googleusercontent.com',
      clientId: '848029071102-rdpn8p6c4mbvn96nphpn1uvptm6k3vhu.apps.googleusercontent.com',
      scopes: ['openid', 'profile'],
    })

    if (response.type === 'success') {
      const { accessToken } = response

      const credential = firebase.auth.GoogleAuthProvider.credential(null, accessToken)

      handleCredentialLogin(credential)
    }
  }
}

export default loginWithGoogle
