const api = (endpoint: string, secret: string, { body, widget }: { body: unknown; widget: string }) =>
  fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${secret}`,
    },
    cache: 'no-cache',
    redirect: 'error',
    body: typeof body === 'string' ? body : JSON.stringify(body),
  })
    .then((response) => response.json())
    .then(({ status, widget: result, error_message }) => {
      if (status === 'ok') {
        return result[widget]
      }

      if (status === 'error') {
        throw new Error(error_message)
      }
    })

export default api
