import React from 'react'
import { FlatList, View } from 'react-native'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import useStyles from './styles'
import { News } from '../../../../types/Stream'
import useSortedNews from '../../../../hooks/useNews'
import NewsBox from '../../components/newsBox'
import { useTranslation } from 'react-i18next'
import PageWrapper from '../../../../components/PageWrapper'

export const NewsFeed = ({ newsArray }: { newsArray: Array<News> }) => {
  const styles = useStyles()
  return (
    <PageWrapper isScrollable={true}>
      <View style={styles.wrapper}>
        <View style={styles.listWrapper}>
          <FlatList
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            data={newsArray}
            style={{ paddingTop: '2%', paddingHorizontal: '2%' }}
            renderItem={({ item }) => (
              <NewsBox
                key={item.id}
                id={item.id}
                profileImageUrl={item.profileImageUrl}
                mediaUrl={item.mediaUrl}
                originalSource={item.userName}
                text={item.text}
                hashtags={item.hashtags}
                sourceUrl={item.sourceUrl}
                timestamp={item.timestamp}
                userName={item.userName}
              />
            )}
          />
        </View>
      </View>
    </PageWrapper>
  )
}

const Main = () => {
  const { t } = useTranslation()

  const newsArray: Array<News> = useSortedNews()
  if (newsArray) return <NewsFeed newsArray={newsArray} />
  return <LoadingScreen text={t('loading.news')} />
}
export default Main
