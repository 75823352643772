import React from 'react'
import PageWrapper from '../../../../components/PageWrapper'
import AvailableBundles from './Components/AvailableBundles'
import CurrentBundle from './Components/CurrentBundle'

const Subscription = () => {
  return (
    <PageWrapper isScrollable={true}>
      <CurrentBundle />
      <AvailableBundles />
    </PageWrapper>
  )
}
export default Subscription
