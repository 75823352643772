import { IShop } from '@dash.bar/types'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import moment from 'moment'
import React from 'react'
import { View } from 'react-native'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import { useTranslation } from 'react-i18next'
import numbro from 'numbro'
import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'
import useStyles from '../../styles'
import { TrackerDataT } from '../../../../../../../../types/widgetDataTypes/types'
import icons from '../../../../../../../../constants/icons'

type PropsT = {
  shop: IShop
  item: TrackerDataT
}

const Order = ({ shop, item }: PropsT) => {
  const history = useHistory()
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <View style={styles.wrapper}>
      <View style={styles.labelWrapper}>
        <BoldText style={styles.labelTitle}>{t('shop.live-visitor.order')}</BoldText>
        <View style={[styles.fontAwesomeWrapper, styles.backgroundColorHighlight]}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'cart-arrow-down']} />
        </View>
      </View>
      <BoldText
        style={styles.orderNumber}
        numberOfLines={1}
        onPress={() => {
          history.push(`/shop/${shop.domain}/orders/${item?.data?.orderID}`)
        }}
      >
        {item?.data?.orderNumber}
      </BoldText>
      <BoldText style={styles.priceOrder} numberOfLines={1}>
        {numbro(item?.data?.total).formatCurrency({
          ...numbro.languageData().currencyFormat,
          average: true,
          currencySymbol: ` ${htmlDecode(shop.currency)}`,
        })}
      </BoldText>
      <View style={styles.flexDirectionRow}>
        <ColoredText style={styles.fontSize13}>{t('shop.live-visitor.paymentMethod')}: </ColoredText>
        <BoldText style={styles.textParagraph} numberOfLines={1}>
          {item?.data?.paymentMethod}
        </BoldText>
      </View>
      <View style={styles.flexDirectionRow}>
        <ColoredText style={styles.fontSize13}>{t('shop.live-visitor.shippingMethod')}: </ColoredText>
        <BoldText style={styles.textParagraph} numberOfLines={1}>
          {item?.data?.shippingMethod}
        </BoldText>
      </View>
      <View style={styles.timeWrapper}>
        <ColoredText style={styles.runtime}>
          {t('shop.live-visitor.runTime')}: {item?.runtime}
        </ColoredText>
        <ColoredText style={styles.textColor} numberOfLines={2}>
          {`${moment(item?.timestamp).fromNow()}`}
        </ColoredText>
      </View>
    </View>
  )
}
export default Order
