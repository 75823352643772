import React from 'react'

import { useTranslation } from 'react-i18next'

import { IWidgetProps } from '../../../../types/props/Widget'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import { TouchableOpacity, View } from 'react-native'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import { useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'
import useStyles from '../../../../components/Widget/styles'
import icons from '../../../../constants/icons'

const AddNewWidget = ({ dashboardId }: IWidgetProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  const highlightColor = useHighlightColor()
  const widgetStyles = useStyles()

  return (
    <TouchableOpacity
      onPress={() => {
        history.push(`/settings/dashboard/${dashboardId}/create-widget`)
      }}
    >
      <View style={[{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }, widgetStyles.widget]}>
        <FontAwesomeIcon icon={[icons.faIconStyle, 'plus']} color={highlightColor} />
        <BoldText style={{ color: highlightColor, marginLeft: 7 }}>{t('dashboard.addWidget')}</BoldText>
      </View>
    </TouchableOpacity>
  )
}

export default AddNewWidget
