import useDeepCompareEffect from 'use-deep-compare-effect'

import api from '../../widgets/shop/api'

import { ISettings, IShop } from '@dash.bar/types'

import useApiLoading from '../api/useApiLoading'
import { ApiLoadingTypes } from '../../types/api/types'

const timeouts: { [key: string]: number } = {}

export type ApiConfigFunction = (settings: ISettings, additionalOptions?: unknown) => { body: unknown; widget: string }

const useWidgetDataFromApi = <DataTypes extends Array<unknown>>({
  id,
  settings,
  apiConfig,
  shop,
  additionalOptions,
}: {
  id: string
  settings: ISettings
  apiConfig: Array<ApiConfigFunction>
  shop: IShop
  additionalOptions?: unknown
}): [boolean, DataTypes, Error | null] => {
  const [{ loading, data, error }, dispatch] = useApiLoading<DataTypes>()

  useDeepCompareEffect(() => {
    let canSetData = true
    const { autoRefresh, refreshInterval } = settings

    if (shop?.endpoint && shop?.secretToken) {
      const getDataFromApi = async () => {
        try {
          const datas = (await apiConfig.reduce(
            (promise, getApiConfig) =>
              promise.then(async (prev) => {
                const config = getApiConfig(settings, additionalOptions)

                return [...prev, await api(shop.endpoint, shop.secretToken, config)]
              }),
            Promise.resolve([])
          )) as DataTypes

          if (autoRefresh && !timeouts[id]) {
            timeouts[id] = setTimeout(() => {
              timeouts[id] = 0
              getDataFromApi()
            }, refreshInterval * 1000) as unknown as number // we have a react-native timeout, not a nodejs timeout
          }

          if (canSetData) {
            dispatch({ type: ApiLoadingTypes.HAS_DATA, payload: { data: datas } })
          }
        } catch (error) {
          if (canSetData) dispatch({ type: ApiLoadingTypes.HAS_ERROR, payload: { error } })
        }
      }

      dispatch({ type: ApiLoadingTypes.IS_LOADING })

      getDataFromApi()
    }
    return () => {
      canSetData = false
      clearTimeout(timeouts[id])
      timeouts[id] = 0
    }
  }, [id, settings, shop, additionalOptions])

  return [loading, data, error]
}

export default useWidgetDataFromApi
