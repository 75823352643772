export default {
  apiKey: 'AIzaSyBMC3AMUC17VR9EMuyGffoXTyjuBjGkzck',
  authDomain: 'api-project-848029071102.firebaseapp.com',
  databaseURL: 'https://api-project-848029071102.firebaseio.com',
  projectId: 'api-project-848029071102',
  storageBucket: 'api-project-848029071102.appspot.com',
  messagingSenderId: '848029071102',
  appId: '1:848029071102:web:779288dad6b719110657af',
  measurementId: 'G-FCJR3SKL3Z',
}
