import { StyleSheet } from 'react-native'

import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTextColor, useHighlightColor, getTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import sizes from '../../constants/sizes'

const useStyles = () => {
  const textColor = useTextColor()

  return StyleSheet.create({
    textInput: {
      color: textColor,
      borderColor: textColor,
    },
    errorWrapper: {
      marginBottom: 15,
    },
    button: {
      backgroundColor: useHighlightColor(),
      borderRadius: 15,
      height: sizes.buttonHeight,
    },
    buttonText: {
      color: getTextColor(Themes.Dark),
    },
  })
}

export default useStyles
