import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import React, { useContext, useState } from 'react'
import { Platform, TextInput, TouchableOpacity, View } from 'react-native'
import { functions } from '../../../../../../../../firebase'
import Button from '../../../../../../../../components/Button'
import useStyles from './styles'
import { SessionContext } from '../../../../../../../../context/SessionContext'
import { useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import * as WebBrowser from 'expo-web-browser'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import Error from '../../../../../../../../components/Error'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import PageWrapper from '../../../../../../../../components/PageWrapper'

enum MethodType {
  creditcard = 'creditcard',
  directdebit = 'directdebit',
}

const MandateChange = () => {
  const history = useHistory()
  const color = useTextColor()
  const { t } = useTranslation()
  const styles = useStyles()
  const { user } = useContext(SessionContext)
  const [consumerName, onChangeConsumerName] = React.useState('')
  const [consumerAccount, onChangeConsumerAccount] = React.useState('')
  const [{ method }, setMethodState] = useState<{
    method: MethodType | null
  }>({
    method: null,
  })
  const [{ updating }, setUpdatingState] = useState<{
    updating: boolean
  }>({
    updating: false,
  })
  const [{ error }, setErrorState] = useState<{
    error: string | null
  }>({
    error: null,
  })
  const updateMandate = functions().httpsCallable('calls-abocloud-updateMandate')

  const handleSubmit = () => {
    setUpdatingState({ updating: true })
    updateMandate({
      companyId: user?.company,
      mandate: {
        method: method,
        consumerName: consumerName,
        consumerAccount: consumerAccount,
      },
    })
      .then((result) => {
        console.debug(JSON.stringify(result.data))
        if (result.data.status === 'redirect' && result.data.location !== null) {
          if (Platform.OS === 'web') {
            window.location.href = result.data.location
          } else {
            WebBrowser.openBrowserAsync(result.data.location)
            setUpdatingState({ updating: false })
            history.push('/settings/accountOverview')
          }
        } else if (result.data.status === 'ok') {
          history.push('/settings/accountOverview')
        }
        setUpdatingState({ updating: false })
        return
      })
      .catch((e) => {
        setErrorState({ error: e.message })
        setUpdatingState({ updating: false })
        console.debug(JSON.stringify(e.message))
      })
  }
  if (updating) {
    return (
      <PageWrapper isScrollable={true}>
        <LoadingScreen text={t('subscription.mandate.updating_mandate')} />
      </PageWrapper>
    )
  }
  if (error) {
    return (
      <PageWrapper isScrollable={true}>
        <Error
          message={error}
          title={t('subscription.mandate.mandate_error')}
          onClose={() => setErrorState({ error: null })}
        />
      </PageWrapper>
    )
  }
  return (
    <View style={styles.mandateChangeWrapper}>
      <View style={styles.methodCardWrapper}>
        <TouchableOpacity
          onPress={() => setMethodState({ method: MethodType.creditcard })}
          style={[styles.methodCard, method === MethodType.creditcard ? styles.selected : null]}
        >
          <ColoredText style={[styles.text, method === MethodType.creditcard ? styles.textSelected : null]}>
            {t('subscription.mandate.creditcard')}
          </ColoredText>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => setMethodState({ method: MethodType.directdebit })}
          style={[styles.methodCard, method === MethodType.directdebit ? styles.selected : null]}
        >
          <ColoredText style={[styles.text, method === MethodType.directdebit ? styles.textSelected : null]}>
            {t('subscription.mandate.directdebit')}
          </ColoredText>
          {method === MethodType.directdebit ? (
            <View>
              <TextInput
                placeholderTextColor={color}
                style={styles.textInput}
                onChangeText={onChangeConsumerName}
                value={consumerName}
                placeholder={t('subscription.mandate.consumer_name')}
              />
              <TextInput
                placeholderTextColor={color}
                style={styles.textInput}
                onChangeText={onChangeConsumerAccount}
                value={consumerAccount}
                placeholder={t('subscription.mandate.consumer_account')}
              />
            </View>
          ) : null}
        </TouchableOpacity>
      </View>
      <Button onPress={() => handleSubmit()} style={styles.button} textStyle={{ color: 'white' }}>
        {t('subscription.mandate.change_mandate')}
      </Button>
    </View>
  )
}

export default MandateChange
