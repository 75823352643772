import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { View } from 'native-base'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import { Interfaces } from '../../../store'
import { useSetTheme } from '../../../module/base/store/actions'
import icons from '../../../constants/icons'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import useStyles from './styles'
//import useStoreTheme from '../../../hooks/preferences/useStoreTheme'

const ThemeButton = () => {
  const config = useSelector((state: Interfaces.Store) => state.base.config)
  const theme = useTheme()
  //const storeTheme = useStoreTheme()
  const setTheme = useSetTheme()
  const styles = useStyles()

  const setThemeDark = () => {
    setTheme(Themes.Dark)
    //storeTheme(Themes.Dark)
  }
  const setThemeLight = () => {
    setTheme(Themes.Light)
    //storeTheme(Themes.Light)
  }

  return (
    <View style={styles.menuButton}>
      <TouchableOpacity
        style={{ width: 40, height: 40, alignItems: 'center', justifyContent: 'center' }}
        onPress={config.theme === 'light' ? setThemeDark : setThemeLight}
      >
        <FontAwesomeIcon icon={theme === Themes.Dark ? [icons.faIconStyle, 'sun'] : [icons.faIconStyle, 'moon']} />
      </TouchableOpacity>
    </View>
  )
}

export default ThemeButton
