import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import React from 'react'
import { View } from 'react-native'
import icons from '../../../../../constants/icons'
import useStyles from '../styles'

const GetIcon = ({ isDone, background }: { isDone: boolean; background: string }) => {
  if (isDone)
    return (
      <FontAwesomeIcon style={{ paddingRight: '2%' }} color={background} icon={[icons.faIconStyle, 'check-square']} />
    )
  return <FontAwesomeIcon style={{ paddingRight: '2%' }} color={background} icon={[icons.faIconStyle, 'square']} />
}

const NewUserToDo = ({
  descriptionText,
  isDone,
  questionFunction,
}: {
  descriptionText: string
  isDone: boolean
  questionFunction: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const styles = useStyles()
  const background = 'black'
  return (
    <View
      style={[
        styles.borderStyle,
        {
          flexDirection: 'row',
          marginVertical: '1%',
          width: '60%',
          justifyContent: 'flex-start',
          backgroundColor: useSecondaryBackground(),
        },
      ]}
    >
      <GetIcon isDone={isDone} background={background} />

      <ColoredText> {descriptionText}</ColoredText>

      <FontAwesomeIcon
        onClick={() => questionFunction(true)}
        style={{ position: 'absolute', right: 5 }}
        color={background}
        icon={[icons.faIconStyle, 'question-circle']}
      />
    </View>
  )
}
export default NewUserToDo
