import React from 'react'
import { View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { AuthProvider as AuthProviderT } from '@firebase/auth-types'

import { Link } from '@webstollen/react-native/lib/react-router'

import Logo from '../Logo'

import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
// import { getTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import colors from '../../constants/colors'

import useStyles from '../Login/styles'

import SignupForm from '../Form/SignupForm'
import AuthProvider from '../AuthProvider'

// import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
// import Button from '../Button'
// import { Themes } from '@webstollen/react-native/lib/types/theme'
// import { faHome } from '@fortawesome/pro-duotone-svg-icons'
// import icons from '../../constants/icons'

interface ISignupProps {
  alert?: {
    content?: string
    color?: 'error' | 'success'
  }
  loading: boolean
  handleSignup: (params: { email: string; password: string }) => void
  handleProviderSignup: (provider: AuthProviderT) => void
}

const Signup = ({ alert, loading, handleSignup, handleProviderSignup }: ISignupProps) => {
  const { t } = useTranslation()

  const styles = useStyles()

  /*
 if (process.env.NODE_ENV === 'production') { 
    return (
      <View style={styles.container}>
        <View style={styles.wrapper}>
          <Logo />

          <View style={[styles.hairLine]} />
          <View style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ margin: 5 }}>
              <FontAwesomeIcon icon={[icons.faIconStyle, 'user-slash']} size={'2x'} />
            </View>
            <View style={[styles.container, {}]}>
              <ColoredText style={{ textAlign: 'center', fontSize: 25 }}>{t('error.signupDisabledTitle')}</ColoredText>
              <ColoredText style={{ textAlign: 'center' }}>{t('error.signupDisabled')}</ColoredText>
            </View>
          </View>
          <View style={[styles.hairLine]} />
          <View style={[styles.container]}>
            <Button to={'/'} style={styles.homeButton}>
              <FontAwesomeIcon color={getTextColor(Themes.Dark)} icon={faHome} />
            </Button>
          </View>
        </View>
      </View>
    )
  }*/
  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <View style={{ maxHeight: 75 }}>
          <Logo />
        </View>

        <ColoredText>{t('text.baseLogin.welcome')}</ColoredText>

        {alert && (
          <View style={[styles.alert, { borderColor: colors[alert.color] }]}>
            <ColoredText style={{ color: colors[alert.color] }}>{alert.content}</ColoredText>
          </View>
        )}

        <View
          style={{
            paddingTop: 20,
          }}
        >
          <SignupForm loading={loading} onSubmit={handleSignup} />
          <View style={styles.hairLine} />

          <AuthProvider
            loading={loading}
            handleProviderLogin={handleProviderSignup}
            handleCredentialLogin={() => undefined}
          />
          <View style={styles.hairLine} />

          <View style={{ flexDirection: 'row', display: 'flex' }}>
            <ColoredText>{t('text.baseSignup.hasAccount')} </ColoredText>
            <Link to={'/login'}>{t('link.baseSignup.login')}</Link>
          </View>
        </View>
      </View>
    </View>
  )
}

export default Signup
