import React, { useContext } from 'react'
import { Button } from 'react-native-paper'
import { SessionContext } from '../../context/SessionContext'
import { View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import useStyles from './components/styles'
import DisplayedNotificationsList from './components/DisplayedNotificationsList'
import { useTranslation } from 'react-i18next'
import { UseNotificationCounter } from '../../hooks/NotificationsCounterProvider'
import PageWrapper from '../../components/PageWrapper'
import { isEmpty } from 'react-redux-firebase'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'

const NotificationsList = () => {
  const [isVisible, setisVisible] = React.useState(false)
  const { shops, user } = useContext(SessionContext)
  const styles = useStyles()
  const { t } = useTranslation()
  const setNotificationCountertoNull = UseNotificationCounter()
  setNotificationCountertoNull()

  if (isEmpty(shops) || isEmpty(user)) {
    return <LoadingScreen />
  }

  const shopList: Array<string> = []

  Object.entries(shops || {}).map((shop) => shopList.push(shop[0]))

  return (
    <PageWrapper isScrollable>
      <View style={{ flexDirection: 'row' }}>
        <Button mode={'outlined'} style={styles.button} color={'black'} onPress={() => setisVisible(true)}>
          <ColoredText>{t('notifications.filter')} </ColoredText>
        </Button>
      </View>
      <DisplayedNotificationsList user={user} shops={shopList} isVisible={isVisible} setisVisible={setisVisible} />
    </PageWrapper>
  )
}

export default NotificationsList
