import { usePrimaryBackground, useSecondaryBackground, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { StyleSheet } from 'react-native'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      marginHorizontal: 8,
      marginVertical: 8,
      padding: 8,
      borderRadius: 16,
      height: 304,
      backgroundColor: useSecondaryBackground(),
    },
    title: {
      padding: 5,
      fontSize: 18,
    },
    list: {
      backgroundColor: useSecondaryBackground(),
      borderRadius: 10,
    },
    listItemTitle: {
      fontSize: 16,
      marginBottom: 8,
    },
    listItemIcon: {
      alignSelf: 'center',
      marginBottom: 8,
    },
    listItemInfoWrapper: {
      flexDirection: 'column',
      marginHorizontal: 8,
      marginVertical: 8,
      padding: 8,
      height: 112,
      width: 232,
      backgroundColor: useSecondaryBackground(),
      borderRadius: 16,
      justifyContent: 'space-evenly',
    },
    infoIcons: {
      alignSelf: 'center',
      marginRight: 8,
    },
    infoText: {
      flex: 1,
      color: colors.primary,
    },
    rowWrapper: {
      flexDirection: 'row',
    },
    fontSize24: {
      fontSize: 24,
    },
    flex1: {
      flex: 1,
    },
    textColor: {
      color: useTextColor(),
    },
    avatar: {
      height: 80,
      width: 80,
      borderRadius: 40,
      borderWidth: 1,
      marginHorizontal: 16,
      borderColor: usePrimaryBackground(),
    },
    customer: {
      backgroundColor: usePrimaryBackground(),
      padding: 8,
      marginHorizontal: 2.5,
      borderRadius: 16,
      overflow: 'hidden',
      alignItems: 'center',
      width: 240,
    },
  })
}

export default useStyles
