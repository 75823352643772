import React from 'react'
import { View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

class Users extends React.Component {
  render() {
    return (
      <View>
        <ColoredText>Users</ColoredText>
      </View>
    )
  }
}

export default Users
