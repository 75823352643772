import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { ProviderData } from '@webstollen/react-native/lib/types/Auth'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Platform, TextInput, TouchableOpacity, View } from 'react-native'
import Avatar from '../../../../../../components/Avatar'
import Button from '../../../../../../components/Button'
import ComponentWrapper from '../../../../../../components/ComponentWrapper'
import icons from '../../../../../../constants/icons'
import { SessionContext } from '../../../../../../context/SessionContext'
import getApp, { functions } from '../../../../../../firebase'
import useStyles from './styles'
import * as ImagePicker from 'expo-image-picker'
import useSetDocument from '../../../../../../hooks/useSetDocument'
import { Toast } from 'native-base'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import UserValidator from '../../../../../../validators/UserValidator'
import ModalPopUp from '../../../../../../components/Modal'
import { useFirebase } from 'react-redux-firebase'
import useLoginReducer, { Types } from '../../../../../../hooks/user/useLoginReducer'
import AuthError from '../../../../../../utils/AuthError'
import {
  AuthCredential as AuthCredentialT,
  AuthProvider as AuthProviderT,
  OAuthCredential as OAuthCredentialT,
} from '@firebase/auth-types'
import loginWithGoogle from '../../../../../../utils/auth/login-with-google'
import loginWithApple from '../../../../../../utils/auth/login-with-apple'
import fb from 'firebase/app'
import ChangePasswordForm from '../../../../../../components/Form/ChangePasswordForm'
import SignupForm from '../../../../../../components/Form/SignupForm'
import EmailTrennenButton from '../../../../../../assets/imgs/providers/EmailTrennenButton'
import EmailAnmeldenButton from '../../../../../../assets/imgs/providers/EmailAnmeldenButton'
import GoogleTrennenButton from '../../../../../../assets/imgs/providers/GoogleTrennenButton'
import GoogleAnmeldenButton from '../../../../../../assets/imgs/providers/GoogleAnmeldenButton'
import AppleTrennenButton from '../../../../../../assets/imgs/providers/AppleTrennenButton'
import AppleAnmeldenButton from '../../../../../../assets/imgs/providers/AppleAnmeldenButton'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'

const Profile = () => {
  const styles = useStyles()
  const { user, currentUser } = useContext(SessionContext)
  const [edit, setEdit] = useState<boolean>(false)
  const [deletingAccount, setDeletingAccount] = useState<boolean>(false)
  const { t } = useTranslation()
  const color = useTextColor()
  const [googleConnected, setGoogleConnected] = useState<boolean>(false)
  const [appleConnected, setAppleConnected] = useState<boolean>(false)
  const [pwConnected, setPwConnected] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [connectingProvider, setConnectingProvider] = useState(false)
  const [lastname, onChangeLastname] = React.useState(user?.lastName || '')
  const [firstname, onChangeFirstname] = React.useState(user?.firstName || '')
  const [providerData, setProviderData] = useState<Array<ProviderData>>()
  const firebase = useFirebase()
  const [, dispatch] = useLoginReducer()
  const modalRef = useRef<any>()
  const deleteModalRef = useRef<any>()
  const deleteUser = functions().httpsCallable('calls-user-deleteUser')
  const providers = ['password', 'google.com', Platform.OS === 'android' ? false : 'apple.com'].filter(
    (e) => e !== false
  ) as ['password' | 'google.com' | 'apple.com']
  const getProvider = useCallback(
    (providerName: string) => providerData && providerData.find(({ providerId }) => providerId === providerName),
    [providerData]
  )
  const setUser = useSetDocument(`user`)
  const uploadImage = async (uri: string, imageName: string): Promise<string> => {
    const response = await fetch(uri)
    const blob = await response.blob()
    const ref = getApp().storage().ref(`users/${currentUser.uid}/${imageName}`)
    await ref.put(blob)
    return await ref.getDownloadURL()
  }
  useEffect(() => {
    if (currentUser) {
      setProviderData(currentUser.providerData)
    }
  }, [currentUser])

  useEffect(() => {
    providers.map((providerId) => {
      const provider = getProvider(providerId)
      if (provider != null) {
        switch (providerId) {
          case 'password':
            setPwConnected(true)
            break
          case 'apple.com':
            setAppleConnected(true)
            break
          case 'google.com':
            setGoogleConnected(true)
            break
          default:
            console.debug(`Unkown provider`)
        }
      }
    })
  }, [providerData])

  const verifiyEmail = async () => {
    currentUser.sendEmailVerification().then(Toast.show({ title: 'Email mit Verifizierungslink gesendet' }))
  }

  const deleteAccount = async () => {
    setDeletingAccount(true)
    deleteUser({ userId: currentUser.uid })
      .catch((e) => {
        Toast.show({ title: 'Error deleting Account', description: e.message })
      })
      .finally(() => {
        if (deleteModalRef && deleteModalRef.current) deleteModalRef.current.setVisible(false)
        setDeletingAccount(false)
      })
  }

  const pickImage = async () => {
    const image = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: false,
    })
    if (image.cancelled === false) {
      const url = await uploadImage(image.uri, 'profileImage')
      currentUser.updateProfile({ photoURL: url })
      setUser({ profileImage: url }, currentUser.uid)
    }
  }

  const updateUser = () => {
    UserValidator.isValid({ firstName: firstname, lastName: lastname })
      .then(() => {
        setUser({ firstName: firstname, lastName: lastname }, currentUser.uid)
        Toast.show({
          title: t('accountOverview.profile.toasts.saved'),
          description: t('accountOverview.profile.toasts.data_saved'),
        })
      })
      .catch((err) => {
        Toast.show({ title: err.name, description: err.errors })
      })
  }

  const linkProviderLogin = useCallback(
    (provider: AuthProviderT) => {
      dispatch({ type: Types.START_LOGIN })

      firebase
        .auth()
        .currentUser.linkWithPopup(provider)
        .then(() => {
          dispatch({ type: Types.FINISH_LOGIN })
          switch (provider.providerId) {
            case 'password':
              setPwConnected(true)
              break
            case 'apple.com':
              setAppleConnected(true)
              break
            case 'google.com':
              setGoogleConnected(true)
              break
            default:
              console.debug(`Unkown provider`)
          }
        })
        .catch((error: AuthError) => {
          dispatch({ type: Types.ERROR_LOGIN, payload: { error } })
        })
    },
    [firebase, dispatch]
  )

  const linkCredentialLogin = useCallback(
    (credential: OAuthCredentialT) => {
      dispatch({ type: Types.START_LOGIN })

      firebase
        .auth()
        .currentUser.linkWithCredential(credential)
        .then(() => {
          dispatch({ type: Types.FINISH_LOGIN })
        })
        .catch((error: AuthError) => {
          dispatch({ type: Types.ERROR_LOGIN, payload: { error } })
        })
    },
    [firebase, dispatch]
  )

  const linkPasswordLogin = useCallback(
    (credential: AuthCredentialT) => {
      setConnectingProvider(true)
      dispatch({ type: Types.START_LOGIN })
      firebase
        .auth()
        .currentUser.linkWithCredential(credential)
        .then(() => {
          dispatch({ type: Types.FINISH_LOGIN })
        })
        .catch(({ code }: { code: string }) => {
          const error = new AuthError(code)
          dispatch({ type: Types.ERROR_LOGIN, payload: { error } })
        })
        .finally(() => {
          if (modalRef && modalRef.current) modalRef.current.setVisible(true)
          setConnectingProvider(false)
        })
    },
    [firebase, dispatch]
  )

  const connectProvider = useCallback(
    async (providerId: 'google.com' | 'apple.com' | 'password', credential?: fb.auth.AuthCredential) => {
      switch (providerId) {
        case 'google.com':
          // TODO: ERROR Handling
          await loginWithGoogle({ handleProviderLogin: linkProviderLogin, handleCredentialLogin: linkCredentialLogin })
          break
        case 'apple.com':
          // TODO: ERROR Handling
          await loginWithApple({ handleProviderLogin: linkProviderLogin, handleCredentialLogin: linkCredentialLogin })
          break
        case 'password':
          linkPasswordLogin(credential)
          return
      }
    },
    [linkCredentialLogin, linkPasswordLogin, linkProviderLogin]
  )

  const disconnect = (providerId: 'google.com' | 'apple.com' | 'password') => {
    if (currentUser.providerData.length > 1) {
      currentUser
        ?.unlink(providerId)
        .then(() => {
          switch (providerId) {
            case 'password':
              setPwConnected(false)
              break
            case 'apple.com':
              setAppleConnected(false)
              break
            case 'google.com':
              setGoogleConnected(false)
              break
            default:
              console.debug(`Unkown provider`)
          }
        })
        .catch((err) => {
          alert(JSON.stringify(err))
        })
    } else {
      Toast.show({
        title: t('accountOverview.profile.toasts.cannot_disconnect'),
        description: t('accountOverview.profile.toasts.required_number_too_low'),
      })
    }
  }

  const onChangePasswordSubmit =
    ({ setError, reset }: { setError: (key: string, type: string, message: string) => void; reset: () => void }) =>
    ({ currentPassword, newPassword1 }: { currentPassword: string; newPassword1: string }) => {
      setLoading(true)

      currentUser
        ?.updatePassword(newPassword1)
        .then(() => {
          reset()
          Toast.show({
            title: t('notifications.changedSuccessfully'),
          })
        })
        .catch((err) => {
          Toast.show({
            title: t('notifications.error'),
          })
          if (err.code === 'auth/requires-recent-login') {
            setLoading(true)

            const credential = fb.auth.EmailAuthProvider.credential(getProvider('password').email, currentPassword)

            currentUser
              ?.reauthenticateWithCredential(credential)
              .then(() => {
                onChangePasswordSubmit({ setError, reset })({ currentPassword, newPassword1 })
              })
              .catch((err) => {
                setError('currentPassword', 'invalid', t('error.' + err.code.replace('/', '.')))
              })
              .finally(() => setLoading(false))
          } else {
            alert(err.message)
          }
        })
        .finally(() => {
          setLoading(false)
          if (modalRef && modalRef.current) modalRef.current.setVisible(false)
        })
    }

  return (
    <ComponentWrapper title={t('accountOverview.profile.your_profile')}>
      <View style={styles.wrapper}>
        <View style={styles.contentWrapper}>
          <View style={styles.itemsWrapper}>
            {edit ? (
              <>
                <ColoredText style={[styles.item, styles.heading]}>
                  <>{t('placeholder.userProfile.firstName')}</>
                </ColoredText>
                <TextInput
                  style={styles.textInput}
                  onChangeText={onChangeFirstname}
                  placeholderTextColor={color}
                  value={firstname ?? ''}
                  autoCorrect={false}
                  placeholder={t('placeholder.userProfile.firstName')}
                />
                <ColoredText style={[styles.item, styles.heading]}>
                  <>{t('placeholder.userProfile.lastName')}</>
                </ColoredText>
                <TextInput
                  style={styles.textInput}
                  onChangeText={onChangeLastname}
                  placeholderTextColor={color}
                  value={lastname ?? ''}
                  autoCorrect={false}
                  placeholder={t('placeholder.userProfile.lastName')}
                />
                <View style={styles.buttonWrapper}>
                  {!currentUser.emailVerified && (
                    <Button style={styles.cancelButton} onPress={() => verifiyEmail()}>
                      {t('accountOverview.profile.verify_email')}
                    </Button>
                  )}
                  <Button
                    style={styles.deleteButton}
                    onPress={() => {
                      if (deleteModalRef && deleteModalRef.current) deleteModalRef.current.setVisible(true)
                    }}
                    textStyle={{ color: 'white' }}
                  >
                    {t('accountOverview.profile.delete_account')}
                  </Button>
                </View>
              </>
            ) : (
              <>
                <ColoredText style={[styles.item, styles.heading]}>
                  <> {t('placeholder.userProfile.firstName')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{user?.firstName || 'n/a'}</ColoredText>
                <ColoredText style={[styles.item, styles.heading]}>
                  <>{t('placeholder.userProfile.lastName')}</>
                </ColoredText>
                <ColoredText style={styles.item}>{user?.lastName || 'n/a'}</ColoredText>
              </>
            )}
          </View>
          <View>
            <View style={{ height: 70, width: 70, borderRadius: 50 }}>
              <Avatar style={{ height: 70, width: 70, borderRadius: 50 }} photoURL={user?.profileImage} />
              {edit ? (
                <TouchableOpacity
                  style={{
                    height: 70,
                    width: 70,
                    borderRadius: 50,
                    backgroundColor: 'black',
                    position: 'absolute',
                    opacity: 0.7,
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                  onPress={pickImage}
                >
                  <FontAwesomeIcon color="white" size="lg" icon={[icons.faIconStyle, 'cloud-upload']} />
                </TouchableOpacity>
              ) : null}
            </View>
          </View>
        </View>
        <View style={styles.buttonWrapper}>
          <View style={{ width: '50%' }}>
            <Button
              style={styles.button}
              textStyle={{ color: 'white' }}
              onPress={() => {
                if (edit) {
                  //save data
                  updateUser()
                }
                setEdit(!edit)
              }}
            >
              {edit ? t('accountOverview.profile.save_data') : t('accountOverview.profile.change_data')}
            </Button>
          </View>
        </View>
        <View style={styles.divider} />
        <View style={styles.buttonContentWrapper}>
          <View>
            <TouchableOpacity
              onPress={() => {
                if (pwConnected) {
                  disconnect('password')
                } else {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(true)
                }
              }}
            >
              {pwConnected ? <EmailTrennenButton /> : <EmailAnmeldenButton />}
            </TouchableOpacity>
            {pwConnected ? (
              <Button
                style={styles.changePasswortButton}
                onPress={() => {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(true)
                }}
              >
                {t('accountOverview.profile.change_password')}
              </Button>
            ) : null}
          </View>
          <TouchableOpacity
            onPress={() => {
              if (googleConnected) {
                disconnect('google.com')
              } else {
                connectProvider('google.com')
              }
            }}
          >
            {googleConnected ? <GoogleTrennenButton /> : <GoogleAnmeldenButton />}
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (appleConnected) {
                disconnect('apple.com')
              } else {
                connectProvider('apple.com')
              }
            }}
          >
            {appleConnected ? <AppleTrennenButton /> : <AppleAnmeldenButton />}
          </TouchableOpacity>
        </View>
      </View>
      <ModalPopUp ref={modalRef}>
        <View style={styles.modalWrapper}>
          <ColoredText style={styles.modalTitle}>
            <> {pwConnected ? t('accountOverview.profile.change_password') : t('providers.buttons.connect_email')}</>
          </ColoredText>
          <View style={styles.modalFormWrapper}>
            {pwConnected ? (
              <ChangePasswordForm onSubmit={onChangePasswordSubmit} loading={loading} />
            ) : connectingProvider ? (
              <LoadingScreen />
            ) : (
              <SignupForm
                style={{ width: '100%' }}
                onSubmit={({ email, password }) =>
                  connectProvider('password', fb.auth.EmailAuthProvider.credential(email, password))
                }
                submitButtonText={t('button.userProfile.connectProvider', { name: 'password' })}
                loading={false}
              />
            )}
            <Button
              style={styles.cancelButton}
              onPress={() => {
                if (modalRef && modalRef.current) modalRef.current.setVisible(false)
              }}
            >
              {t('accountOverview.profile.cancel')}
            </Button>
          </View>
        </View>
      </ModalPopUp>
      <ModalPopUp ref={deleteModalRef} title={t('accountOverview.profile.delete_account')}>
        {deletingAccount ? (
          <LoadingScreen />
        ) : (
          <>
            <ColoredText>
              <> {t('accountOverview.profile.delete_account_text')}</>
            </ColoredText>
            <ColoredText>
              {' '}
              <>{t('accountOverview.profile.delete_account_submit_text')}</>
            </ColoredText>
            <Button
              style={styles.cancelButton}
              onPress={() => {
                if (deleteModalRef && deleteModalRef.current) deleteModalRef.current.setVisible(false)
              }}
            >
              {t('accountOverview.profile.cancel')}
            </Button>
            <Button style={styles.button} onPress={() => deleteAccount()} textStyle={{ color: 'white' }}>
              {t('accountOverview.profile.delete_account')}
            </Button>
          </>
        )}
      </ModalPopUp>
    </ComponentWrapper>
  )
}

export default Profile
