import { IShop } from '@dash.bar/types'
import { useCallback, useEffect, useRef, useState } from 'react'
import api from '../../../../../widgets/shop/api'

import { EventDataT } from '../../../../../types/widgetDataTypes/types'
import useApiData from '../../../../../hooks/api/useApiData'

const useLiveTracker = ({ shop, type }: { shop: IShop; type: string }) => {
  const [page, setPage] = useState(1)
  const loadData = useCallback(
    async (page: number, state: { data?: EventDataT }) => {
      const result: EventDataT = await api(shop.endpoint, shop.secretToken, {
        body: {
          request: 'EventTracker',
          widget: {
            visitors: {
              options: {
                active: true,
                details: false,
                refresh: undefined,
                filter: type,
                interval: null,
                pagination: { descending: true, page, rowsPerPage: 10 },
              },
            },
          },
        },
        widget: 'visitors',
      })

      return {
        ...result,
        data: [...(state?.data?.data ?? []), ...result.data].reduce(
          (list, user) => (list.find(({ mail }) => mail === user.mail) ? list : [...list, user]),
          []
        ),
      } as EventDataT
    },
    [shop, type]
  )

  const { getData, state } = useApiData(loadData, page)
  const loading = useRef(false)

  useEffect(() => {
    if (!loading.current) {
      loading.current = true
      getData(state)
      loading.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData])

  const nextPage = () => {
    setPage((p) => p + 1)
  }

  return { state, nextPage }
}

export default useLiveTracker
