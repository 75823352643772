import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@webstollen/react-native/lib/react-router'
import Logo from '../Logo'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import colors from '../../constants/colors'
import ResetPasswordForm from '../Form/ResetPasswordForm'
import useStyles from './styles'
import { View } from 'native-base'

interface IResetPasswordProps {
  alert?: {
    content?: string
    color?: 'success' | 'error'
  }
  success?: boolean
  loading: boolean
  handleResetPassword: (params: { email: string }) => void
}

const ResetPassword = ({ alert, success, loading, handleResetPassword }: IResetPasswordProps) => {
  const { t } = useTranslation()

  const styles = useStyles()

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <View style={{ maxHeight: 75 }}>
          <Logo />
        </View>
        {!success ? <ColoredText>{t('text.baseResetPassword.welcome')}</ColoredText> : null}
        {alert && (
          <View style={styles.alert}>
            <ColoredText style={{ color: colors[alert.color] }}>{alert.content}</ColoredText>
          </View>
        )}
        <View>
          {success ? (
            <View style={{ paddingVertical: 5 }}>
              <ColoredText>{t('text.baseResetPassword.success')} </ColoredText>
            </View>
          ) : (
            <ResetPasswordForm loading={loading} onSubmit={handleResetPassword} />
          )}
          <View style={styles.hairLine} />
          <View style={{ flexDirection: 'row', display: 'flex' }}>
            <ColoredText>{t('text.baseLogin.noAccount')} </ColoredText>
            <Link to={'/signup'}>{t('link.baseLogin.signup')}</Link>
          </View>

          <View style={{ flexDirection: 'row', display: 'flex' }}>
            <ColoredText>{t('text.baseSignup.hasAccount')} </ColoredText>
            <Link to={'/login'}>{t('link.baseSignup.login')}</Link>
          </View>
        </View>
      </View>
    </View>
  )
}

export default ResetPassword
