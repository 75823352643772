import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import Logo from '../Logo'
import useStyles from '../Login/styles'
import icons from '../../constants/icons'
import Button from '../Button'
import { getTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useFirebase } from 'react-redux-firebase'
import { Toast } from 'native-base'
import { Redirect } from '@webstollen/react-native/lib/react-router'

export const EmailVerified = ({ oobCode }: { oobCode: string }) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const firebase = useFirebase()
  const [verify, setVerify] = useState<boolean>(true)
  const [verified, setVerified] = useState<boolean>(false)

  useEffect(() => {
    setVerify(true)
    firebase
      .auth()
      .applyActionCode(oobCode)
      .then(() => {
        setVerified(true)
      })
      .finally(() => {
        setVerify(false)
      })
  }, [oobCode])

  if (verify) {
    return <LoadingScreen />
  }
  if (!verified) {
    Toast.show({ title: 'Email could not be verified' })
    return <Redirect to={'/login'} />
  }
  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <Logo />

        <View style={[styles.hairLine]} />
        <View style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ margin: 5 }}>
            <FontAwesomeIcon icon={[icons.faIconStyle, 'check-circle']} size={'2x'} />
          </View>
          <View style={[styles.container, {}]}>
            <ColoredText style={{ textAlign: 'center', fontSize: 25 }}>{t('text.emailVerified.title')}</ColoredText>
            <ColoredText style={{ textAlign: 'center' }}>{t('text.emailVerified.text')}</ColoredText>
          </View>
        </View>
        <View style={[styles.hairLine]} />
        <View style={[styles.container]}>
          <Button to={'/'} style={styles.homeButton}>
            <FontAwesomeIcon color={getTextColor(Themes.Dark)} icon={[icons.faIconStyle, 'home']} />
          </Button>
        </View>
      </View>
    </View>
  )
}
