import React from 'react'
import { View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { AuthProvider as AuthProviderT, OAuthCredential as OAuthCredentialT } from '@firebase/auth-types'

import { Link } from '@webstollen/react-native/lib/react-router'

import Logo from '../Logo'

import LoginForm from '../Form/LoginForm'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import AuthProvider from '../AuthProvider'

import useStyles from './styles'

interface ILoginProps {
  alert?: { content: string; color?: 'error' | 'success' }
  loading: boolean
  handleLogin: (params: { email: string; password: string }) => void
  handleProviderLogin: (provider: AuthProviderT) => void
  handleCredentialLogin: (credential: OAuthCredentialT) => void
}

const Login = ({ alert, loading, handleLogin, handleProviderLogin, handleCredentialLogin }: ILoginProps) => {
  const { t } = useTranslation()

  const styles = useStyles()

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <View style={{ maxHeight: 75 }}>
          <Logo />
        </View>

        <ColoredText>{t('text.baseLogin.welcome')}</ColoredText>

        {alert && (
          <View style={styles.alert}>
            <ColoredText style={styles.alertText}>{alert.content}</ColoredText>
          </View>
        )}

        <View
          style={{
            paddingTop: 20,
          }}
        >
          <LoginForm onSubmit={handleLogin} loading={loading} />

          <View style={styles.hairLine} />

          <AuthProvider
            handleProviderLogin={handleProviderLogin}
            handleCredentialLogin={handleCredentialLogin}
            loading={loading}
          />

          <View style={styles.hairLine} />
          <View style={{ flexDirection: 'row', display: 'flex' }}>
            <ColoredText>{t('text.baseLogin.noAccount')} </ColoredText>
            <Link to={'/signup'}>{t('link.baseLogin.signup')}</Link>
          </View>

          <Link to={'/reset-password'}>{t('link.baseLogin.resetPassword')}</Link>
        </View>
      </View>
    </View>
  )
}

export default Login
