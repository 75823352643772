import { useHighlightColor, usePrimaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { StyleSheet } from 'react-native'
import colors from '../../../../../../constants/colors'
import sizes from '../../../../../../constants/sizes'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalWrapper: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 30,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.lightBgTransparent7,
    },
    modal: {
      width: '50%',
      maxWidth: '50%',
      minWidth: '50%',
      minHeight: '50%',
      borderRadius: 15,
      backgroundColor: usePrimaryBackground(),
      paddingVertical: 30,
      paddingHorizontal: 80,
    },
    bundleView: {
      borderRadius: 15,
      height: 170,
      minHeight: 170,
      maxHeight: 170,
      width: '100%',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 30,
    },
    modalTitle: {
      fontSize: 24,
    },
    modalSubtitle: {
      fontSize: 14,
      marginVertical: 15,
    },
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.darkBgTransparent5,
      borderRadius: 15,
      marginVertical: 15,
    },
    backgroundFree: {
      backgroundColor: colors.bundleFree,
    },
    backgroundBusiness: {
      backgroundColor: colors.bundleBusiness,
    },
    backgroundPro: {
      backgroundColor: colors.bundlePro,
    },
    titleContentWrapper: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    bundleTitle: {
      fontSize: 28,
      fontWeight: '500',
      marginVertical: 15,
      color: 'white',
    },
    divider: {
      width: '100%',
      backgroundColor: theme === Themes.Dark ? colors.lightBg1 : colors.darkBg1,
      height: 1,
      maxHeight: 1,
    },
    billingWrapper: {
      paddingVertical: 10,
      marginBottom: 20,
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
    },
    clickableText: {
      textDecorationLine: 'underline',
    },
    addressWrapper: {
      width: '40%',
      minWidth: '40%',
      maxWidth: '40%',
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
    },
    item: {
      width: '40%',
    },
    itemTitle: {
      color: 'grey',
    },
    buttonWrapper: {
      width: '100%',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    buttonBook: {
      backgroundColor: useHighlightColor(),
      width: '30%',
      height: sizes.buttonHeight,
      borderRadius: 10,
      color: 'white',
    },
    buttonCancel: {
      width: '15%',
      borderRadius: 10,
      height: sizes.buttonHeight,
      borderWidth: 2,
      borderColor: useHighlightColor(),
    },
    highlightColor: {
      color: useHighlightColor(),
    },
    privacyTextWrapper: {
      width: '100%',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
      marginVertical: 5,
    },
    privacyText: {
      fontSize: 14,
    },
    //Mobile Settings
    modalWrapperMobile: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 30,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.lightBgTransparent7,
    },
    modalMobile: {
      width: '95%',
      maxWidth: '95%',
      minWidth: '95%',
      minHeight: '60%',
      height: '90%',
      borderRadius: 15,
      backgroundColor: usePrimaryBackground(),
      paddingVertical: 10,
      paddingHorizontal: 10,
    },
    centeredViewMobile: {
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    billingWrapperMobile: {
      paddingVertical: 10,
      marginBottom: 20,
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'flex-start',
    },
    addressWrapperMobile: {
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
    },
    itemMobile: {
      width: '50%',
    },
    heading: {
      color: colors.headingColor,
      width: '35%',
    },
    // Booking not possible
    title: {
      fontSize: 18,
      padding: 10,
    },
    reasonText: {
      width: '80%',
      textAlign: 'left',
      fontSize: 14,
      padding: 5,
    },
  })
}

export default useStyles
