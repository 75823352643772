import { Themes } from '@webstollen/react-native/lib/types/theme'

import adLight from '../../../assets/imgs/widgets/advertisments/light.png'
import adDark from '../../../assets/imgs/widgets/advertisments/dark.png'

import i18n from '../../../locales/i18n'

import Advertisement from './Advertisement'

export default {
  advertisement_v1: {
    component: Advertisement,
    title: i18n.t('shop.widget.advertisement.title'),
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: adLight,
      [Themes.Dark]: adDark,
    },
  },
}
