import { StyleSheet, Platform } from 'react-native'

import { useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import colors from '../../constants/colors'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    widget: {
      borderRadius: 10,
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.darkBgTransparent8,
      height: 250,
      overflow: 'hidden',
      padding: 10,
    },
    header: {
      height: Platform.select({ web: 34, default: 30 }),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 5,
    },
    icon: {
      width: 24,
      height: 24,
      borderRadius: 12,
      backgroundColor: colors.lightBg1,
      position: 'absolute',
    },
    iconWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontWeight: '500',
      fontSize: 14,
      lineHeight: 14,
      paddingTop: Platform.select({ web: 5, default: 2 }),
      paddingLeft: 30,
      flexGrow: 2,
    },
    optionsButton: {},
    options: {
      padding: 5,
      display: 'flex',
      justifyContent: 'flex-end',
      borderRadius: 10,
    },
    optionsText: {
      textAlign: 'right',
    },
    drawerHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 5,
    },
    content: {
      overflow: 'hidden',
      flex: 1,

      marginHorizontal: 15,
    },
    footer: {
      paddingVertical: 10,
      height: 21,
    },
    footerText: {
      lineHeight: Platform.select({ default: 11, web: 5 }),
      height: Platform.select({ web: undefined, default: 21 }),
      fontSize: 11,
      textAlign: 'right',
      flexGrow: 1,
    },

    hairline: {
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderBottomColor: useSecondaryBackground(),
    },
  })
}

export default useStyles
