import { StyleSheet } from 'react-native'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginLeft: 39,
      marginRight: 8,
      marginBottom: 5,
      marginVertical: 3,
      borderRadius: 15,
      alignItems: 'center',
    },

    iconWrapper: {
      flexDirection: 'row',
      alignSelf: 'flex-end',
    },

    name: {
      fontSize: 12,
      flex: 1,
      marginRight: 4,
    },

    itemWrapper: {
      marginHorizontal: 8,
      paddingVertical: 2,
      borderRadius: 10,
      flex: 1,
      backgroundColor: 'transparent',
    },

    isActive: {
      backgroundColor: theme === Themes.Dark ? 'rgba(255,255,255, 0.07)' : 'rgba(0,0,0, 0.07)',
    },
  })
}

export default useStyles
