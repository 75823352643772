import React from 'react'
import { View } from 'react-native'

import useStyles from './styles'

import md5 from 'md5'
import numbro from 'numbro'

import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'

import Avatar from '../../../../components/Avatar'

import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import LiveTrackerRow from './LiveTrackerRow'
import { useTranslation } from 'react-i18next'
import { EventDataT, UserT } from '../../../../types/widgetDataTypes/types'
import { faSackDollar, faUsers, faUsersClass } from '@fortawesome/pro-duotone-svg-icons'
import icons from '../../../../constants/icons'

type PropsT = EventDataT & {
  currency: string
}

const LiveTrackerView = ({ visitors, customers, data, baskets, basketValue, currency }: PropsT) => {
  const styles = useStyles()

  const { t } = useTranslation()

  const getUserAvatar = ({ mail }: UserT, index: number) => (
    <Avatar
      key={mail}
      style={[
        styles.avatar,
        {
          position: 'absolute',
          left: 15 * index,
        },
      ]}
      photoURL={`https://avatar.ws-svc.de/?seed=${md5(mail.trim().toLowerCase())}`}
      accessibilityLabel={mail.trim().toLowerCase()}
    />
  )

  //const visitorAvatars = data.slice(0, Math.min(visitors, 8)).map(getUserAvatar)
  // from 27-roadmap access mannagement:
  const visitorAvatars = data
    .slice(0, Math.min(customers, 20))
    .reduce((list, datum) => {
      if (list.findIndex(({ mail }) => mail === datum.mail) === -1) {
        return [...list, datum]
      }
      return list
    }, [])
    .slice(0, Math.min(visitors, 8))
    .map(getUserAvatar)

  const customerAvatars = data
    .filter(({ customerId }) => customerId != null)
    // from 27-roadmap access mannagement
    .slice(0, Math.min(customers, 20))
    .reduce((list, datum) => {
      if (list.findIndex(({ mail }) => mail === datum.mail) === -1) {
        return [...list, datum]
      }
      return list
    }, [])
    //
    .slice(0, Math.min(customers, 7))
    .map(getUserAvatar)

  const basketIcon = <FontAwesomeIcon style={styles.basketIcon} icon={[icons.faIconStyle, 'shopping-cart']} />

  const rows = [
    {
      key: 'visitors',
      width: '100%',
      value: visitors,
      label: t('shop.widget.live-tracker.visitors'),
      avatars: visitorAvatars.length ? visitorAvatars : [<FontAwesomeIcon key={'visitors_icon'} icon={faUsersClass} />],
    },
    {
      key: 'customers',
      width: '90%',
      value: customers,
      label: t('shop.widget.live-tracker.customers'),
      avatars: customerAvatars.length ? customerAvatars : [<FontAwesomeIcon key={'customers_icon'} icon={faUsers} />],
    },
    {
      key: 'baskets',
      width: '80%',
      value: baskets,
      label: t('shop.widget.live-tracker.baskets', { count: baskets }),
      icon: basketIcon,
    },
    {
      key: 'basketValue',
      width: '70%',
      value: numbro(basketValue).formatCurrency({
        ...numbro.languageData().currencyFormat,
        currencySymbol: htmlDecode(currency),
        spaceSeparated: true,
        mantissa: 0,
      }),
      label: t('shop.widget.live-tracker.basketValue'),
      icon: <FontAwesomeIcon style={styles.basketIcon} icon={faSackDollar} />,
    },
  ]

  return (
    <View style={styles.content}>
      {rows.map(({ key, ...props }, index) => (
        <LiveTrackerRow key={key} {...props} index={index} />
      ))}
    </View>
  )
}

export default LiveTrackerView
