import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import React from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useStyles from '../../styles'

const Newsletter = () => {
  const styles = useStyles()
  const { t } = useTranslation()

  // Wird nicht bei Newsletter anmeldung getriggert
  // stadtdessen wird bloß ein Seitenaufruf getriggert
  // weiß nicht wie es aufgebaut ist
  return (
    <View style={styles.wrapper}>
      <ColoredText>{t('shop.live-visitor.newsletter')}</ColoredText>
    </View>
  )
}
export default Newsletter
