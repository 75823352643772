import React from 'react'

import { View } from 'react-native'

import { useIsDarkTheme } from '@webstollen/react-native/lib/utils/types/theme'

import styles from './styles'
import Logo from './Logo'

const LogoWrapper = () => {
  const isDark = useIsDarkTheme()

  const logoURL = `https://static.dash.bar/images/${isDark ? 'dblogo' : 'dblogo_blk'}.svg`

  return (
    <View style={styles.logo}>
      <Logo logoURL={logoURL} />
    </View>
  )
}

export default LogoWrapper
