import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { View } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'
import version from '../../../../../../constants/version'

const ReleaseInfo = () => {
  const { t } = useTranslation()
  return (
    <View style={{ alignItems: 'center', margin: 10, paddingVertical: 5 }}>
      <ColoredText>{t('app.version', { version: version.currentVersion })}</ColoredText>
    </View>
  )
}

export default ReleaseInfo
