import React from 'react'
import { ChildrenProps } from '../../types/chart'
import colors from '../../constants/colors'
import { View } from 'react-native'
import { BarChart as ChartsBarChart } from 'react-native-svg-charts'
import { Defs, LinearGradient, Stop } from 'react-native-svg'
import numbro from 'numbro'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import { curveNatural } from 'd3-shape'

type Props = {
  testID?: string
  list: Array<number>
  contentInset?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  size: {
    width: number
    height: number
  }
}

const Gradient = (_: ChildrenProps) => (
  <Defs>
    <LinearGradient id={'gradient'} x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
      <Stop offset={'0%'} stopColor={`${colors.color2}`} stopOpacity={0.6} />
      <Stop offset={'100%'} stopColor={`${colors.color2}`} stopOpacity={0.1} />
    </LinearGradient>
  </Defs>
)

const BarChart = ({
  list,
  contentInset = {
    top: 10,
    bottom: 10,
    left: 5,
    right: 5,
  },
  size,
}: Props) => {
  return (
    <View style={{ height: size.height, flexDirection: 'column' }}>
      <ChartsBarChart
        style={{ flex: 1 }}
        data={list}
        contentInset={contentInset}
        curve={curveNatural}
        svg={{ fill: 'url(#gradient)' }}
        gridMin={0}
        spacingInner={0.5}
        spacingOuter={0}
      >
        <Gradient />
      </ChartsBarChart>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {list.map((value, index) => (
          <ColoredText key={index} style={{ fontSize: 12 }}>
            {numbro(value).format({
              mantissa: 1,
              spaceSeparated: true,
              average: true,
            })}
          </ColoredText>
        ))}
      </View>
    </View>
  )
}

export default BarChart
