import { User } from '@dash.bar/types/user'
import { useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import React from 'react'
import { Dialog } from 'react-native-paper'
import MobileDialog from '../../../components/MobileDialog/MobileDialog'
import useDeviceType from '../../../hooks/useDeviceType'
import ShopSelectionDialogBody from './ShopSelectionDialogBody'

const NotificationFilterDialog = ({
  shops,
  user,
  isVisible,
  filter,
  setFilter,
  setisVisible,
}: {
  shops: Array<string>
  user: User
  isVisible: boolean
  filter: { shopList: Array<string>; notificationType: Array<string> }
  setFilter: React.Dispatch<
    React.SetStateAction<{
      shopList: Array<string>
      notificationType: Array<string>
    }>
  >
  setisVisible: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const device = useDeviceType()
  const secondaryBackground = useSecondaryBackground()

  if (device === 'mobile') {
    return (
      <MobileDialog isVisible={isVisible} showDialog={setisVisible}>
        <ShopSelectionDialogBody user={user} shops={shops} filter={filter} setFilter={setFilter} />
      </MobileDialog>
    )
  }
  return (
    <Dialog
      style={{ position: 'absolute', top: 40, alignSelf: 'center', backgroundColor: secondaryBackground }}
      visible={isVisible}
      onDismiss={() => setisVisible(false)}
    >
      <Dialog.Content>
        <ShopSelectionDialogBody user={user} shops={shops} filter={filter} setFilter={setFilter} />
      </Dialog.Content>
    </Dialog>
  )
}
export default NotificationFilterDialog
