import { useHighlightColor, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { StyleSheet } from 'react-native'
import colors from '../../../../../../constants/colors'
import sizes from '../../../../../../constants/sizes'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      borderRadius: 15,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-start',
    },
    contentWrapper: {
      width: '100%',
      padding: 30,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    buttonContentWrapper: {
      width: '100%',
      padding: 30,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    itemsWrapper: {
      width: '50%',
      maxWidth: '50%',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'flex-start',
      flexWrap: 'wrap',
    },
    item: {
      width: '45%',
      marginVertical: 4,
    },
    heading: {
      color: colors.headingColor,
    },
    imageWrapper: {
      width: '50%',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
    },
    title: {
      fontSize: 30,
      fontWeight: '600',
      marginVertical: 15,
    },
    textInput: {
      padding: 5,
      borderRadius: 10,
      borderWidth: 1,
      marginVertical: 3,
      color: useTextColor(),
      borderColor: useTextColor(),
    },
    divider: {
      width: '100%',
      backgroundColor: theme === Themes.Dark ? colors.lightBg1 : colors.darkBg1,
      height: 1,
      minHeight: 1,
      maxHeight: 1,
    },
    button: {
      backgroundColor: useHighlightColor(),
      width: '100%',
      borderRadius: 10,
      color: 'white',
      height: sizes.buttonHeight,
    },
    deleteButton: {
      backgroundColor: colors.error,
      width: '100%',
      borderRadius: 15,
      color: 'white',
      height: sizes.buttonHeight,
    },
    buttonWrapper: {
      width: '100%',
      padding: 10,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'flex-end',
    },
    modalWrapper: {
      width: '100%',
      paddingHorizontal: 30,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    modalTitle: {
      fontSize: 24,
      marginVertical: 20,
    },
    modalFormWrapper: {
      width: '50%',
    },
    cancelButton: {
      borderRadius: 15,
      borderColor: useTextColor(),
      borderWidth: 1,
      height: sizes.buttonHeight,
      width: '100%',
    },
    changePasswortButton: {
      borderRadius: 15,
      borderColor: useTextColor(),
      borderWidth: 1,
      height: sizes.buttonHeight,
      width: '100%',
    },
  })
}

export default useStyles
