import React, { useContext } from 'react'

import DashboardView from '../../components/Dashboard'

import { useRouteMatch } from '@webstollen/react-native/lib/react-router'
import useDashboard from '../../../../hooks/dashboard/useDashboard'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { Helmet } from 'react-helmet'
import { isLoaded } from 'react-redux-firebase'
import { UpdateWidgetContext } from '../../../../types/Widget'
import { IWidget } from '@dash.bar/types'
import useUpdateWidgets from '../../../../hooks/widget/useUpdateWidgets'
import { SessionContext } from '../../../../context/SessionContext'

const Dashboard = () => {
  console.debug('(render) Dashboard')
  const match = useRouteMatch<{ dashboardId: string }>()

  const { dashboardId } = match.params

  const dashboard = useDashboard(dashboardId)
  const { shops } = useContext(SessionContext)
  const updateWidgets = useUpdateWidgets(dashboardId)

  console.debug('(render) Dashboard - ID:', dashboardId)

  if (!isLoaded(dashboard)) {
    return <LoadingScreen />
  }

  return (
    <UpdateWidgetContext.Provider
      value={async (widget: IWidget) => {
        if (dashboard) {
          await updateWidgets(
            dashboard.widgets.map(({ id, ...rest }) => {
              if (id === widget.id) {
                return widget
              }

              return {
                id,
                ...rest,
              }
            })
          )
        }
      }}
    >
      <Helmet>
        <title>{`Dashboard - ${dashboard?.title ?? '-'}`}</title>
      </Helmet>
      {dashboard && <DashboardView dashboard={dashboard} dashboardId={dashboardId} shops={shops ?? {}} />}
    </UpdateWidgetContext.Provider>
  )
}

export default Dashboard
