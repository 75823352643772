import { useHistory as UseHistory, useRouteMatch } from '@webstollen/react-native/lib/react-router'
import { useHashtagFilteredNewsArray as UseHashtagFilteredNewsArray } from '../../../../hooks/useNews'
import { NewsFeed } from '../Main/index'
import React from 'react'
import { Platform, ScrollView, TouchableOpacity, View } from 'react-native'
import UseStyles from './styles'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import colors from '../../../../constants/colors'
import icons from '../../../../constants/icons'

const BuildWebStream = ({ hashtag }: { hashtag: string }) => {
  const styles = UseStyles()
  const history = UseHistory()
  const filteredNewsArray = UseHashtagFilteredNewsArray(hashtag)
  const FilterWrapper = () => {
    return (
      <View style={styles.headerWrapper}>
        <ColoredText style={styles.text}>filtere nach: </ColoredText>
        <View style={styles.hashtagWrapper}>
          <ColoredText style={styles.hashtag}>#{hashtag}</ColoredText>
          <TouchableOpacity
            style={styles.hashtagBackButton}
            key={Math.floor(Math.random() * 100000)}
            onPress={() => history.push('../stream')}
          >
            <FontAwesomeIcon color={colors.error} icon={[icons.faIconStyle, status ? 'check-circle' : 'times-circle']} />
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  return (
    <ScrollView style={styles.listwrapper} stickyHeaderIndices={[0]} showsVerticalScrollIndicator={false}>
      <FilterWrapper />
      <View style={styles.filteredNewsWrapper}>
        <View style={styles.listwrapper}>
          <NewsFeed newsArray={filteredNewsArray} />
        </View>
      </View>
    </ScrollView>
  )
}

const BuildMobileStream = ({ hashtag }: { hashtag: string }) => {
  const filteredNewsArray = UseHashtagFilteredNewsArray(hashtag)
  return <NewsFeed newsArray={filteredNewsArray} />
}

const Main = () => {
  const {
    params: { hashtag },
  } = useRouteMatch<{ hashtag: string }>()

  if (Platform.OS === 'web') return <BuildWebStream hashtag={hashtag} />
  return <BuildMobileStream hashtag={hashtag} />
}
export default Main
