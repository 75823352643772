import { ReactNode } from 'react'

import { RouteProps } from '@webstollen/react-native/lib/react-router'

import AdminModule from '../module/admin'
import BaseModule from '../module/base'
import DashboardModule from '../module/dashboard'
// TODO: update to https://github.com/jdalrymple/gitbeaker#readme as package gitlab is deprecated
// import DeveloperModule from '../module/developer'
//import UserModule from '../module/user'
import StreamModule from '../module/stream'
import FavoritesModule from '../module/favorites'
import SettingsModule from '../module/settings'
import ShopModule from '../module/shop'
import UpsellingModule from '../module/upselling'
import NotificationModule from '../module/notifications'
import NotFound from '../components/NotFound'

export default [
  ...AdminModule.routes(),
  ...BaseModule.routes(),
  ...DashboardModule.routes(),
  //...UserModule.routes(),
  ...StreamModule.routes(),
  ...FavoritesModule.routes(),
  ...SettingsModule.routes(),
  ...ShopModule.routes(),
  ...NotificationModule.routes(),
  ...UpsellingModule.routes(),
  ...NotFound.routes(), // must be last entry to work
] as Array<{
  route: RouteProps
  name: string
  title?: ReactNode
  requireAuth?: boolean
}>
