import React from 'react'
import { View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import moment from 'moment'
interface Props<T> {
  data: Array<T>
  interval: string
  numberOfTicks?: number
}
const getDateForIntervalAndIndex = (interval: string, index: number) => {
  const numericInterval = Number(interval.match(/\d+/)[0])
  if (interval.match(/^D/)) {
    return moment()
      .subtract(numericInterval - index - 1, 'day')
      .format('L')
  }
  if (interval.match(/^M/)) {
    return moment()
      .set('D', 1)
      .set('M', numericInterval - 1)
      .add(index, 'day')
      .format('L')
  }
  if (interval.match(/^Q/)) {
    return moment().set('D', 1).set('M', 0).set('Q', numericInterval).add(index, 'day').format('L')
  }
  if (interval.match(/^Y/)) {
    return moment().set('D', 1).set('M', 0).set('y', numericInterval).add(index, 'day').format('L')
  }
  return ''
}
const filterIndices = ({ numberOfTicks, maxIndex }: { numberOfTicks: number; maxIndex: number }) => {
  const indices = [
    0,
    ...[...Array(numberOfTicks - 1)].map((_, index, array) => Math.floor(((index + 1) / array.length) * maxIndex)),
  ]
  return (_: unknown, index: number) => indices.includes(index)
}
const XAxisIntervalLabels = <T extends unknown>({ data, interval, numberOfTicks = 3 }: Props<T>) => {
  const maxIndex = data.length - 1
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      {data
        .map((value, index) => ({
          value,
          date: getDateForIntervalAndIndex(interval, index),
        }))
        .filter(filterIndices({ numberOfTicks, maxIndex }))
        .map(({ date }) => {
          return (
            <ColoredText key={date} style={{ fontSize: 10 }}>
              {date}
            </ColoredText>
          )
        })}
    </View>
  )
}
export default XAxisIntervalLabels
