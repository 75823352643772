import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import React, { useContext } from 'react'
import { View } from 'react-native'
import { SessionContext } from '../../../../../../../context/SessionContext'
import useStyles from './styles'
import ShopView from './ShopView'

const CompanyShops = () => {
  const { shops } = useContext(SessionContext)
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <ColoredText style={{ paddingHorizontal: 30, paddingVertical: 10, fontSize: 18 }}>
        <>{t('accountOverview.shops.shops')}</>
      </ColoredText>
      <View style={styles.shopWrapper}>
        {Object.entries(shops || {}).map(([k, value]) => {
          return <ShopView key={k} shop={value} />
        })}
      </View>
    </>
  )
}

export default CompanyShops
