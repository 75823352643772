import React, { useRef } from 'react'
import { View } from 'react-native'
import { IShop } from '@dash.bar/types'
import useStyles from './styles'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import colors from '../../../../../../constants/colors'
import { useTranslation } from 'react-i18next'
import useStatus from '../../../../../shop/pages/LiveTracker/v1/useStatus'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import icons from '../../../../../../constants/icons'

type PropsT = {
  shop: IShop
}

const Status = ({ shop }: PropsT) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const timeOfFirstRender = useRef(Date.now())
  const { data, loading } = useStatus({ shop })

  if (loading || !data) {
    return (
      <LoadingScreen
        style={{
          height: 120,
        }}
        text={t('loading.data')}
      />
    )
  }

  return (
    <View style={styles.wrapper}>
      <BoldText style={styles.title}>{t('shop.live-tracker.status')}</BoldText>
      <View style={[styles.status]}>
        {[
          {
            status: data != null,
            label: t('shop.widget.status.body.shop-online'),
          },
          {
            status: data && !data.maintenance_mode,
            label: t('shop.widget.status.body.maintenance'),
          },
          {
            status: data && timeOfFirstRender.current - data.start_time < 2500,
            label: t('shop.widget.status.body.performance'),
          },
          {
            status: data && data.caching_activated,
            label: `${t('shop.widget.status.body.cache')} ${
              data && data.caching_activated ? `(${data.caching_method})` : ''
            }`,
          },
        ].map(({ status, label }, index) => (
          <View key={label} style={[styles.statusItem, index % 2 === 0 ? { paddingRight: 16 } : { paddingLeft: 16 }]}>
            <FontAwesomeIcon
              color={status ? colors.success : colors.error}
              icon={[icons.faIconStyle, status ? 'check-circle' : 'times-circle']}
              style={{ alignSelf: 'center' }}
            />
            <ColoredText style={styles.itemText}>{label}</ColoredText>
          </View>
        ))}
      </View>
    </View>
  )
}

export default Status
