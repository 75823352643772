import React, { ReactNode } from 'react'

import { StatusBar, StatusBarStyle } from 'expo-status-bar'

import { SafeAreaView } from 'react-native-safe-area-context'

import { Helmet } from 'react-helmet'

//import { useSelector } from 'react-redux'

import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

//import { Interfaces } from '../../store'

import '../../locales/i18n'

import { Themes } from '@webstollen/react-native/lib/types/theme'
import { ThemeContext, isThemeDark } from '@webstollen/react-native/lib/utils/types/theme'
import { LanguageContext } from '../../common/helpers/language'

import { getSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import colors from '../../constants/colors'
import { NativeBaseProvider, StorageManager, ColorMode } from 'native-base'
import { customNativeBaseTheme } from './NativeBase/customNativeBaseTheme'

import numbro from 'numbro'
import moment from 'moment'
import { Platform } from 'react-native'
//import useLoadTheme from '../../hooks/preferences/useLoadTheme'
//import useLoadLanguage from '../../hooks/preferences/useLoadLanguage'
import { useSelector } from 'react-redux'
import { Interfaces } from '../../store'

const lightTheme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: colors.lightBg1,
      paper: colors.lightBg2,
    },
  },
})

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: colors.darkBg1,
      paper: colors.darkBg2,
    },
  },
})

const ThemeLanguageWrapper = ({ children }: { children?: ReactNode }) => {
  const { theme, language } = useSelector((state: Interfaces.Store) => state.base.config)
  //ToDo Replaced line above by two bottom lines. Check if this makes problems
  //const theme = useLoadTheme()
  //const language = useLoadLanguage()
  const themeColorMode = isThemeDark(theme) ? 'dark' : 'light'
  const statusBarColorMode = isThemeDark(theme)
    ? Platform.select<StatusBarStyle>({ default: 'light', web: 'dark' })
    : Platform.select<StatusBarStyle>({ default: 'dark', web: 'light' })

  const nativeBaseColorModeManager: StorageManager = {
    get: async () => {
      try {
        const val = themeColorMode
        return val === 'dark' ? 'dark' : 'light'
      } catch (e) {
        return 'light'
      }
    },
    set: (value: ColorMode) => {
      console.log('Trying to set value in nativeBaseColorModeManager', value)
      // try {
      //   // Set value here if necessary
      // } catch (e) {
      //   console.log(e)
      // }
    },
  }

  numbro.setLanguage(language)
  moment.locale(language)

  return (
    <MuiThemeProvider theme={theme === Themes.Dark ? darkTheme : lightTheme}>
      <ThemeContext.Provider value={theme}>
        <LanguageContext.Provider value={language}>
          <NativeBaseProvider theme={customNativeBaseTheme} colorModeManager={nativeBaseColorModeManager}>
            <Helmet defaultTitle={'dash.bar'} titleTemplate={'%s | dash.bar'}></Helmet>
            <StatusBar style={statusBarColorMode} />
            <SafeAreaView style={{ flex: 1, backgroundColor: getSecondaryBackground(theme) }}>{children}</SafeAreaView>
          </NativeBaseProvider>
        </LanguageContext.Provider>
      </ThemeContext.Provider>
    </MuiThemeProvider>
  )
}

export default ThemeLanguageWrapper
