import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import Logo from '../Logo'
import useStyles from '../Login/styles'
import icons from '../../constants/icons'
import Button from '../Button'
import { getTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useFirebase } from 'react-redux-firebase'
import { Toast } from 'native-base'
import { Redirect } from '@webstollen/react-native/lib/react-router'

export const RecoverEmail = ({ oobCode }: { oobCode: string }) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const firebase = useFirebase()
  const [recover, setRecover] = useState<boolean>(true)
  const [recovered, setRecovered] = useState<boolean>(false)

  useEffect(() => {
    setRecover(true)
    let restoredEmail = ''
    firebase
      .auth()
      .checkActionCode(oobCode)
      .then((info) => {
        restoredEmail = info.data.email
        return firebase.auth().applyActionCode(oobCode)
      })
      .then(() => {
        setRecovered(true)
        firebase
          .auth()
          .sendPasswordResetEmail(restoredEmail)
          .catch(() => {
            Toast.show({ title: t('text.toast.pw_reset_mail') })
          })
      })
  }, [oobCode])

  if (recover) {
    return <LoadingScreen />
  }
  if (!recovered) {
    Toast.show({ title: t('text.toast.error') })
    return <Redirect to={'/login'} />
  }
  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <Logo />

        <View style={[styles.hairLine]} />
        <View style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ margin: 5 }}>
            <FontAwesomeIcon icon={[icons.faIconStyle, 'check-circle']} size={'2x'} />
          </View>
          <View style={[styles.container, {}]}>
            <ColoredText style={{ textAlign: 'center', fontSize: 25 }}>{t('text.emailRecovered.title')}</ColoredText>
            <ColoredText style={{ textAlign: 'center' }}>{t('text.emailRecovered.text')}</ColoredText>
          </View>
        </View>
        <View style={[styles.hairLine]} />
        <View style={[styles.container]}>
          <Button to={'/'} style={styles.homeButton}>
            <FontAwesomeIcon color={getTextColor(Themes.Dark)} icon={[icons.faIconStyle, 'home']} />
          </Button>
        </View>
      </View>
    </View>
  )
}
