import React from 'react'
import { View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { Link } from '@webstollen/react-native/lib/react-router'

import Logo from '../Logo'

import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import colors from '../../constants/colors'

import useStyles from '../Login/styles'
import Button from '../Button'
import { getTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import icons from '../../constants/icons'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import UpdatePasswordForm from '../Form/UpdatePasswordForm'

interface IConfirmPasswordProps {
  alert?: {
    content?: string
    color?: 'success' | 'error'
  }
  success?: boolean
  loading: boolean
  handleUpdatePassword: (params: { email: string; password: string }) => void
}

const UpdatePassword = ({ alert, success, loading, handleUpdatePassword }: IConfirmPasswordProps) => {
  const { t } = useTranslation()
  const styles = useStyles()
  if (success) {
    return (
      <View style={styles.container}>
        <View style={styles.wrapper}>
          <Logo />

          <View style={[styles.hairLine]} />
          <View style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ margin: 5 }}>
              <FontAwesomeIcon icon={[icons.faIconStyle, 'check-circle']} size={'2x'} />
            </View>
            <View style={[styles.container, {}]}>
              <ColoredText style={{ textAlign: 'center', fontSize: 25 }}>
                {t('text.baseUpdatePassword.successfulUpdatedTitle')}
              </ColoredText>
              <ColoredText style={{ textAlign: 'center' }}>
                {t('text.baseUpdatePassword.successfulUpdated')}
              </ColoredText>
            </View>
          </View>
          <View style={[styles.hairLine]} />
          <View style={[styles.container]}>
            <Button to={'/'} style={styles.homeButton}>
              <FontAwesomeIcon color={getTextColor(Themes.Dark)} icon={[icons.faIconStyle, 'home']} />
            </Button>
          </View>
        </View>
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <Logo />

        <ColoredText>{t('text.baseUpdatePassword.welcome')}</ColoredText>

        {alert && (
          <View style={styles.alert}>
            <ColoredText style={{ color: colors[alert.color] }}>{alert.content}</ColoredText>
          </View>
        )}

        <View
          style={{
            paddingTop: 20,
          }}
        >
          <UpdatePasswordForm loading={loading} onSubmit={handleUpdatePassword} />
          <View style={styles.hairLine} />
          <View style={{ flexDirection: 'row', display: 'flex' }}>
            <ColoredText>{t('text.baseLogin.noAccount')} </ColoredText>
            <Link to={'/signup'}>{t('link.baseLogin.signup')}</Link>
          </View>

          <View style={{ flexDirection: 'row', display: 'flex' }}>
            <ColoredText>{t('text.baseSignup.hasAccount')} </ColoredText>
            <Link to={'/login'}>{t('link.baseSignup.login')}</Link>
          </View>
        </View>
      </View>
    </View>
  )
}

export default UpdatePassword
