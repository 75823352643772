import { Text, View } from 'native-base'
import React, { useContext } from 'react'
import useStyles from './styles'
import { SessionContext } from '../../../context/SessionContext'

const BundleComponent = () => {
  const styles = useStyles()
  const { company } = useContext(SessionContext)
  return (
    <View style={styles.wrapper}>
      <Text style={styles.text}>{company?.bundle || 'free'}</Text>
    </View>
  )
}

export default BundleComponent
