import React from 'react'
import { Button, Dialog, Portal } from 'react-native-paper'

//import { Image } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import useStyles from '../../styles'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'

const CreateFavoriteDialog = ({
  showCreateFavorite,
  setShowCreateFavorite,
}: {
  showCreateFavorite: boolean
  setShowCreateFavorite: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const styles = useStyles()
  const hideDialog = () => setShowCreateFavorite(false)
  //const imgURL = require('../../../../../../assets/imgs/newCostumerTutorial/dashboardImg.jpg')
  const { t } = useTranslation()

  return (
    <Portal>
      <Dialog style={styles.dialog} visible={showCreateFavorite} onDismiss={hideDialog}>
        <Dialog.Title>
          <ColoredText>{t('text.newUserTutorial.favorite.createFavoriteDashboard')}</ColoredText>
        </Dialog.Title>
        <Dialog.Content>
          <ColoredText style={styles.text}>{t('text.newUserTutorial.favorite.goToDashboard')}</ColoredText>
          <ColoredText style={styles.text}>{t('text.newUserTutorial.favorite.clickFavButton')}</ColoredText>
          {/*<Image source={imgURL} resizeMode="contain" style={{ width: '80%', minHeight: 100, alignSelf: 'center' }} /> */}
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => alert('hier weiter empfehlen')}>
            <ColoredText>{t('text.newUserTutorial.appInstall.recommendUsHere')}</ColoredText>
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}

export default CreateFavoriteDialog
