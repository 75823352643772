//import { Platform } from 'react-native'

//const headbarHeight = Platform.OS === 'web' ? 80 : 42 change when header gets Transaparent
const headbarHeight = 0
const paddingHorizontal = 10
const paddingTop = 5
const opacity = 0.9
const maxWidth = 1024
const footerHeight = 52

//Buttons
const buttonHeight = 40
const buttonBorderRadius = 15
export default {
  headbarHeight,
  paddingHorizontal,
  paddingTop,
  opacity,
  maxWidth,
  footerHeight,
  buttonHeight,
  buttonBorderRadius,
}
