import React from 'react'
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import useAddFavorite from '../../../hooks/favorites/useAddFavorite'
import useRemoveFavorite from '../../../hooks/favorites/useRemoveFavorite'
import useFavorites from '../../../hooks/favorites/useFavorites'
import { Favorite as FavoriteT } from '../../../types/Favorites'
import Favorite from '../../../assets/imgs/favorites/Favorite'
import NoFavorite from '../../../assets/imgs/favorites/NoFavorite'

const FavoriteButton = ({ title, url, subtitle }: FavoriteT) => {
  const urlWithoutLeadingSlash = url.replace(/^\//, '')
  const addFavorite = useAddFavorite()
  const removeFavorite = useRemoveFavorite()
  const favorites = useFavorites()

  let currentIsFavorite = false
  let currentId: string
  if (!!favorites) {
    currentIsFavorite = Object.keys(favorites).some((id) => {
      if (favorites[id] && favorites[id].url === urlWithoutLeadingSlash) {
        currentId = id
        return true
      }
      return false
    })
  }

  return (
    <TouchableWithoutFeedback>
      <TouchableOpacity
        onPress={async (evt) => {
          evt.preventDefault()
          if (currentIsFavorite) {
            await removeFavorite(currentId)
          } else {
            await addFavorite({ url: urlWithoutLeadingSlash, title, subtitle })
          }
        }}
      >
        {currentIsFavorite ? <Favorite /> : <NoFavorite />}
      </TouchableOpacity>
    </TouchableWithoutFeedback>
  )
}

export default FavoriteButton
