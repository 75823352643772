import { User } from '@dash.bar/types'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import firebase from 'firebase'
import React, { ReactNode, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View } from 'react-native'
import { SessionContext } from '../../context/SessionContext'
import { functions } from '../../firebase'
import useAllowedUser from '../../hooks/booking/useAllowedUser'
import useCollection from '../../hooks/useCollection'
import Avatar from '../Avatar'
import Button from '../Button'
import ModalPopUp from '../Modal'
import useStyles from './styles'

const ContingentWrapper = ({ children }: { children: ReactNode }) => {
  const { company, userIsAdmin } = useContext(SessionContext)
  const styles = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const modalRef = useRef<any>()
  const [deletingUser, setDeletingUser] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<Array<string>>([])
  const allowedUser = useAllowedUser()
  const deleteUser = functions().httpsCallable('calls-user-deleteUser')
  const currentUserCount = company?.users.length
  const users = useCollection<User>('user', [
    [
      firebase.firestore.FieldPath.documentId() as unknown as string,
      'in',
      company?.users?.length ? company?.users : ['-'],
    ],
  ])

  const toggleIncludedUser = (userId: string) => {
    const index = selectedUser.indexOf(userId, 0)
    if (index > -1) {
      setSelectedUser([
        ...selectedUser.filter((value) => {
          return value !== userId
        }),
      ])
    } else {
      if (selectedUser.length < allowedUser) {
        setSelectedUser([...selectedUser, userId])
      }
    }
  }

  const deleteExcludedUser = async () => {
    setDeletingUser(true)
    await Promise.all(
      Object.entries(users)
        .filter(([, e]) => {
          return !selectedUser.includes(e.id)
        })
        .map(async ([, e]) => {
          console.debug(`Deleting user: ${e.id}`)
          try {
            await deleteUser({ userId: e.id })
          } catch (e) {
            if (modalRef && modalRef.current) modalRef.current.setVisible(false)
            setDeletingUser(false)
          }
          return true
        })
    )
    if (modalRef && modalRef.current) modalRef.current.setVisible(false)
    setDeletingUser(false)
  }

  if (currentUserCount > allowedUser) {
    if (userIsAdmin) {
      return (
        <>
          <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <ColoredText style={styles.title}>{t('contingentWrapper.too_many_user')}</ColoredText>
            <ColoredText style={styles.subtitle}>
              {t('contingentWrapper.choose_user', { count: allowedUser })}
            </ColoredText>
            <View style={styles.userWrapper}>
              <ColoredText style={{ width: '100%', fontWeight: 'bold', textAlign: 'center', marginVertical: 10 }}>
                {t('contingentWrapper.user_left', { left: +allowedUser - selectedUser.length })}
              </ColoredText>
              {Object.entries(users || {}).map(([, value]) => {
                return (
                  <TouchableOpacity
                    key={value.id}
                    style={[styles.user, selectedUser.includes(value.id) ? styles.userSelected : null]}
                    onPress={() => toggleIncludedUser(value.id)}
                  >
                    <Avatar style={styles.avatar} photoURL={value.profileImage} />
                    <ColoredText style={selectedUser.includes(value.id) ? styles.textSelected : null}>
                      {value.email}
                    </ColoredText>
                  </TouchableOpacity>
                )
              })}
            </View>
            <View style={styles.buttonWrapper}>
              <Button
                onPress={() => {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(true)
                }}
                style={styles.button}
                textStyle={{ color: 'white' }}
              >
                {t('contingentWrapper.choose')}
              </Button>
              <Button
                onPress={() => {
                  history.replace(`/settings/subscription`)
                }}
              >
                {t('contingentWrapper.upgrade')}
              </Button>
            </View>
          </View>
          <ModalPopUp ref={modalRef} title={t('contingentWrapper.modal.title')}>
            {deletingUser ? (
              <LoadingScreen />
            ) : (
              <View>
                <ColoredText>{t('contingentWrapper.modal.text')}</ColoredText>
                <ColoredText>{t('contingentWrapper.modal.upgrade_text')}</ColoredText>
                <Button
                  style={styles.button}
                  textStyle={{ color: 'white' }}
                  onPress={() => {
                    history.replace(`/settings/subscription`)
                    if (modalRef && modalRef.current) modalRef.current.setVisible(false)
                  }}
                >
                  {t('contingentWrapper.modal.upgrade')}
                </Button>
                <Button style={styles.deleteButton} textStyle={{ color: 'white' }} onPress={() => deleteExcludedUser()}>
                  {t('contingentWrapper.modal.remove_user')}
                </Button>
              </View>
            )}
          </ModalPopUp>
        </>
      )
    } else {
      return (
        <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <ColoredText style={styles.title}>{t('contingentWrapper.needs_config')}</ColoredText>
        </View>
      )
    }
  }
  return <>{children}</>
}

export default ContingentWrapper
