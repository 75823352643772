import { Platform, StyleSheet } from 'react-native'

import { useHighlightColor, usePrimaryBackground } from '@webstollen/react-native/lib/hooks/useColors'
import colors from '../../../constants/colors'

const useStyles = () => {
  return StyleSheet.create(
    JSON.parse(
      JSON.stringify({
        header: {
          fontSize: 13,
          marginTop: 5,
          marginLeft: 8,
          marginBottom: 9,
        },
        tagContainer: {
          display: 'flex',
        },
        lineContainer: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        },
        tag: {
          margin: 5,
          paddingVertical: 5,
          paddingHorizontal: 10,
          cursor: Platform.select({ web: 'pointer', default: undefined }),
          borderRadius: 10,
          backgroundColor: usePrimaryBackground(),
        },
        tagSelected: {
          cursor: Platform.select({ web: 'default', default: undefined }),
          backgroundColor: useHighlightColor(),
        },
        tagLabel: {
          fontSize: 12,
        },
        tagLabelSelected: {
          color: colors.darkText1,
        },
      })
    )
  )
}

export default useStyles
