import React, { useContext } from 'react'

import ShopMenuItems from './MenuItems'

import { IShop } from '@dash.bar/types'
import { SessionContext } from '../../../../context/SessionContext'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'

const defaultFavIcon = require('../../../../assets/imgs/dblogo.png')

const ShopsSidebar = () => {
  const { shops } = useContext(SessionContext)

  if (shops === undefined) {
    return <LoadingScreen />
  }

  const items = shops
    ? ([
        ...Object.entries(shops)
          .filter(([, entry]) => entry != null)
          .map(([key, shop]) => ({
            domain: shop.domain,
            path: `/shops/${key}`,
            icon: shop.icon ?? defaultFavIcon,
          })),
      ] as Array<IShop & { path: string }>)
    : []

  return <ShopMenuItems shops={items} />
}
export default ShopsSidebar
