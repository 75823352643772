import React, { useState } from 'react'

import { IWidget } from '@dash.bar/types'

import WidgetView, { PropsT as BaseProps } from '../../../../components/Widget'

interface Size {
  height: number
  width: number
}

type ChildrenParams = [Size]

interface WidgetProps {
  widget: IWidget
  children: (args: ChildrenParams) => React.ReactChild
  testID?: string
}

type Props = BaseProps & WidgetProps

const Widget = ({ children, ...props }: Props) => {
  const [size, setSize] = useState({ height: 200, width: 100 })

  return (
    <WidgetView
      showOptions={false}
      onWidgetLayout={(evt) => {
        const { height, width } = evt.nativeEvent.layout

        setSize({ height, width })
      }}
      {...props}
    >
      {children([size])}
    </WidgetView>
  )
}

export default Widget
