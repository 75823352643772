import React from 'react'

import { useTranslation } from 'react-i18next'

import { IWidgetProps } from '../../../../types/props/Widget'

import Widget from '../Widget'

import { Image } from 'react-native'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { IconName } from '@fortawesome/pro-solid-svg-icons'
import colors from '../../../../constants/colors'
import { Link } from '@webstollen/react-native/lib/react-router'
import icons from '../../../../constants/icons'

const Advertisement = ({ widget }: IWidgetProps) => {
  const { t } = useTranslation()

  return (
    <Widget
      title={t('shop.widget.advertisement.title')}
      widget={widget}
      icon={<FontAwesomeIcon icon={[icons.faIconStyle, 'dashbar' as IconName]} color={colors.color3} />}
    >
      {([size]) => {
        return (
          <Link to={'https://ws-url.de/tm-dashbar'}>
            <Image
              {...{
                ...size,
                resizeMode: 'contain',
                source: { uri: 'https://cdn.webstollen.com/projekte/marketing/aktionsbanner/tm-image.png' },
                style: {
                  ...size,
                },
              }}
            />
          </Link>
        )
      }}
    </Widget>
  )
}

export default Advertisement
