import React, { useEffect } from 'react'

import { IShop } from '@dash.bar/types'
import useOrder from './useOrder'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useTranslation } from 'react-i18next'

import OrderView from '../../../component/Order/v1'

type PropsT = {
  shop: IShop
  orderId: string | number
}

const OrderV1 = ({ shop, orderId }: PropsT) => {
  const { t } = useTranslation()

  const { getOrder, state } = useOrder({ shop })

  useEffect(() => {
    getOrder(orderId)
  }, [getOrder, orderId])

  if (state.loading || !state.order) {
    return <LoadingScreen text={t('loading.order')} />
  }

  return <OrderView shop={shop} order={state.order} />
}

export default OrderV1
