import { IShop } from '@dash.bar/types'
import { useCallback, useEffect } from 'react'
import api from '../../../../../widgets/shop/api'

import { EventDataT } from '../../../../../types/widgetDataTypes/types'
import useApiData from '../../../../../hooks/api/useApiData'

const useLiveTracker = ({ shop }: { shop: IShop }) => {
  const loadData = useCallback(async () => {
    const result: EventDataT = await api(shop.endpoint, shop.secretToken, {
      body: {
        request: 'EventTracker',
        widget: {
          visitors: {
            options: {
              active: true,
              details: false,
              refresh: undefined,
              filter: 'none',
              interval: null,
              rowsPerPage: 100,
              pagination: { descending: true, page: 1, rowsPerPage: 100 },
            },
          },
        },
      },
      widget: 'visitors',
    })

    return result
  }, [shop])

  const { getData, state } = useApiData(loadData)

  useEffect(() => {
    getData()
  }, [getData])

  return state
}

export default useLiveTracker
