import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import React from 'react'
import { View } from 'react-native'
import colors from '../../../../../../../constants/colors'

const UpsellingCardRow = ({ item }: { item: string }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingBottom: 15,
      }}
    >
      <FontAwesomeIcon icon={'check'} color={colors.success} />

      <ColoredText style={{ marginLeft: 20 }}>{item}</ColoredText>
    </View>
  )
}

export default UpsellingCardRow
