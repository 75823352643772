import { useCallback } from 'react'
import { useFirestore } from 'react-redux-firebase'
import useAuth from '@webstollen/react-native/lib/hooks/useAuth'

const useRemoveFavorite = () => {
  const firestore = useFirestore()

  const auth = useAuth()

  return useCallback(
    async (...ids: Array<string>) => {
      console.debug('IDS:', ids)
      for (const id of ids) {
        console.debug('ID:', `user/${auth.uid}/favorites`, id)
        await firestore.collection(`user/${auth.uid}/favorites`).doc(id).delete()
      }
    },
    [auth, firestore]
  )
}

export default useRemoveFavorite
