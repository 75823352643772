import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { isLoaded } from 'react-redux-firebase'
import { useRouteMatch } from '@webstollen/react-native/lib/react-router'
import { SemVer } from 'semver'
import useStyles from './styles'
import AnalyticsV1 from './v1'
import { SessionContext } from '../../../../context/SessionContext'

const Analytics = () => {
  const styles = useStyles()

  const { t } = useTranslation()

  const match = useRouteMatch<{ shopId: string }>()

  const { shopId } = match.params
  const { shops } = useContext(SessionContext)
  const shop = shops && shops[shopId]

  if (!isLoaded(shop) || shop === null) {
    return <LoadingScreen text={t('loading.shop')} />
  }

  const version = new SemVer(shop?.platform.sdkVersion)

  switch (version.major) {
    case 1:
      return <AnalyticsV1 shop={shop} />
    default:
      return (
        <View style={styles.wrapper}>
          <View style={styles.container}></View>
        </View>
      )
  }
}

export default Analytics
