import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import Constants from 'expo-constants'

import firebaseConfig from '../firebaseConfig'
import { FUNCTIONS_REGION } from '../../functions/src/constants'
//import { Platform } from 'react-native'

const getApp = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
    if (Constants.manifest?.extra?.useEmulators) {
      const lanIP = Constants.manifest.extra?.lanIP
      firebase.functions().useEmulator(lanIP, 5050)
      firebase.firestore().settings({
        ignoreUndefinedProperties: true,
        host: `${lanIP}:8080`,
        ssl: false,
      })
      firebase.auth().useEmulator(`http://${lanIP}:9099`)
    } else if (!__DEV__) {
      // if (Platform.OS === 'web') {
      //   require('firebase/performance')
      //   require('firebase/analytics')
      //   firebase.performance()
      //   firebase.analytics.isSupported().then((supported) => (supported ? firebase.analytics() : null))
      // }
      firebase.firestore().settings({
        ignoreUndefinedProperties: true,
      })
    } else {
      firebase.firestore().settings({
        ignoreUndefinedProperties: true,
      })
    }
  }
  return !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.apps[0]
}

export const functions = (region = FUNCTIONS_REGION): firebase.functions.Functions => {
  if (Constants.manifest?.extra?.useEmulators) {
    const lanIP = Constants.manifest.extra?.lanIP
    const functions = getApp().functions(region)
    functions.useEmulator(lanIP, 5050)
    return functions
  }
  return getApp().functions(region)
}

export default getApp
