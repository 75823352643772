import { IShop } from '@dash.bar/types'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import moment from 'moment'
import React from 'react'
import { Image, Linking, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import numbro from 'numbro'
import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'
import useStyles from '../../styles'
import { TrackerDataT } from '../../../../../../../../types/widgetDataTypes/types'

import Url from 'url-parse'
import { faTrash } from '@fortawesome/pro-duotone-svg-icons'

type PropsT = {
  shop: IShop
  item: TrackerDataT
}

const BasketRemoved = ({ shop, item }: PropsT) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const parsedUrl = (url: string) => {
    return new Url(url)
  }

  return (
    <View style={styles.wrapper}>
      <View style={styles.labelWrapper}>
        <BoldText style={styles.labelTitle}>{t('shop.live-visitor.basketRemoved')}</BoldText>
        <View style={[styles.fontAwesomeWrapper, styles.backgroundColorHighlight]}>
          <FontAwesomeIcon icon={faTrash} />
        </View>
      </View>
      <View style={styles.imageInfoWrapper}>
        <Image source={{ uri: item?.data?.thumbnail }} style={styles.image} />
        <View style={styles.infoWrapper}>
          <BoldText style={styles.fontSize20} numberOfLines={1}>
            {item?.data?.name}
          </BoldText>
          <ColoredText style={styles.price}>
            {numbro(item?.data?.price).formatCurrency({
              ...numbro.languageData().currencyFormat,
              average: true,
              currencySymbol: ` ${htmlDecode(shop.currency)}`,
            })}
          </ColoredText>
        </View>
      </View>
      <View style={styles.flexDirectionRow}>
        <ColoredText style={styles.fontSize13}>{t('shop.live-visitor.url')}: </ColoredText>
        <BoldText
          style={styles.url}
          onPress={() => Linking?.openURL(parsedUrl(item?.data?.page).href)}
          numberOfLines={1}
        >
          {parsedUrl(item?.data?.page).pathname}
        </BoldText>
      </View>
      <View style={styles.timeWrapper}>
        <ColoredText style={styles.runtime}>
          {t('shop.live-visitor.runTime')}: {item?.runtime}
        </ColoredText>
        <ColoredText style={styles.textColor} numberOfLines={2}>
          {`${moment(item?.timestamp).fromNow()}`}
        </ColoredText>
      </View>
    </View>
  )
}
export default BasketRemoved
