import { IShop } from '@dash.bar/types'
import React from 'react'
import BasketHorizontalScroll from '../BasketHorizontalScroll'
import CustomerHorizontalScroll from '../CustomerHorizontalScroll'
import VisitorHorizontalScroll from '../VisitorHorizontalScroll'

type PropsT = {
  shop: IShop
}

const CumulatedHorizontalScroll = ({ shop }: PropsT) => {
  const visitor = 'visitor'
  const customer = 'customer'
  const basketValue = 'basketvalue'

  return (
    <>
      <VisitorHorizontalScroll shop={shop} type={visitor} />
      <CustomerHorizontalScroll shop={shop} type={customer} />
      <BasketHorizontalScroll shop={shop} type={basketValue} />
    </>
  )
}

export default CumulatedHorizontalScroll
