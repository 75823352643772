import { StyleSheet } from 'react-native'

import {useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      //alignItems: 'center',
      backgroundColor: 'transparent',
    },
    container: {
      marginBottom: 5,
    },
    button: {
      marginHorizontal: 10,
      marginVertical: 1,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: 50,
      backgroundColor: useSecondaryBackground(),
      borderColor: 'white',
      borderWidth: 1,
      borderRadius: 16,
    },
    disabledButton: {
      opacity: 0.5,
      borderWidth: 0,
    },
    buttonText: {},
  })
}

export default useStyles
