import { Reducer, useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { DetailedOrderT, OrderT } from './types'
import { IShop } from '@dash.bar/types'
import api from '../../../../../widgets/shop/api'
import useOrder from '../../Order/v1/useOrder'

export enum Types {
  IS_LOADING = 'is-loading',
  HAS_ERROR = 'has-error',
  HAS_DATA = 'has-data',
}

const useOrdersReducer = () =>
  useReducer<
    Reducer<
      Readonly<{ loading: boolean; error?: Error; orders?: Array<DetailedOrderT>; totalitems?: number }>,
      { type: Types; payload?: { error?: Error; orders?: Array<DetailedOrderT>; totalitems?: number } }
    >
  >(
    (state, { type, payload: { orders, totalitems, error } = {} }) => {
      switch (type) {
        case Types.IS_LOADING:
          return { ...state, error: null, loading: true }
        case Types.HAS_ERROR:
          return { ...state, loading: false, error }
        case Types.HAS_DATA:
          return {
            ...state,
            loading: false,
            error: null,
            orders: [...(state.orders || []), ...orders].reduce(
              (list, order) => (list.find(({ id }) => id === order.id) ? list : [...list, order]),
              []
            ),
            totalitems,
          }
        default:
          throw new Error(`action type '${type}' not defined`)
      }
    },
    {
      loading: false,
      error: null,
      orders: null,
    }
  )

const useOrders = ({ shop }: { shop: IShop }) => {
  const [state, dispatch] = useOrdersReducer()
  const { loadOrder } = useOrder({ shop })

  const [page, setPage] = useState(1)

  const getData = useCallback(
    async (page) => {
      dispatch({ type: Types.IS_LOADING })
      try {
        const result = await api(shop.endpoint, shop.secretToken, {
          body: {
            request: 'Analytics',
            widget: {
              order: {
                options: {
                  active: true,
                  details: false,
                  refresh: undefined,
                  pagination: { descending: true, page, rowsPerPage: 4 },
                },
              },
            },
          },
          widget: 'order',
        })

        const hydratedOrders: Array<DetailedOrderT> = await result.order.reduce(
          (promise: Promise<Array<DetailedOrderT>>, { id }: OrderT) =>
            promise.then(async (prevOrders: Array<DetailedOrderT>) => {
              return [...prevOrders, await loadOrder(id)]
            }),
          Promise.resolve([] as Array<DetailedOrderT>)
        )

        dispatch({ type: Types.HAS_DATA, payload: { orders: hydratedOrders, totalitems: result.totalitems } })
      } catch (error) {
        dispatch({ type: Types.HAS_ERROR, payload: { error } })
      }
    },
    [shop, dispatch, loadOrder]
  )

  const loading = useRef(false)

  useEffect(() => {
    if (!loading.current) {
      loading.current = true
      getData(page)
      loading.current = false
    }
  }, [page, getData])

  const nextPage = async () => {
    setPage((p) => p + 1)
  }

  return {
    state,
    getData,
    nextPage,
  }
}

export default useOrders
