import { useCallback } from 'react'
import useApiLoading from './useApiLoading'

import { ApiLoadingTypes } from '../../types/api/types'

const useApiData = <Data, OptionsT>(
  loadData: (options?: OptionsT, prevState?: { data?: Data }) => Promise<Data>,
  additionalOptions?: OptionsT
) => {
  const [state, dispatch] = useApiLoading<Data>()

  const getData = useCallback(
    async (prevState?: { data?: Data }) => {
      dispatch({ type: ApiLoadingTypes.IS_LOADING })

      try {
        const data = await loadData(additionalOptions, prevState)
        dispatch({ type: ApiLoadingTypes.HAS_DATA, payload: { data } })
      } catch (error) {
        dispatch({ type: ApiLoadingTypes.HAS_ERROR, payload: { error } })
      }
    },
    [dispatch, loadData, additionalOptions]
  )

  return { getData, state }
}

export default useApiData
