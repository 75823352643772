import React, { ReactNode } from 'react'
import { FlexStyle, ScrollView, View } from 'react-native'
import layoutSizes from './layoutSizes'
import useDeviceType from '../../hooks/useDeviceType'

const PageWrapper = ({
  children,
  isScrollable,
  scrollViewStyle,
  fullScreen = false,
  scrollEnabled = true,
}: {
  children: ReactNode
  isScrollable: boolean
  scrollViewStyle?: FlexStyle
  fullScreen?: boolean
  scrollEnabled?: boolean
}) => {
  let styling: FlexStyle
  const device = useDeviceType()
  if (device === 'web' && !fullScreen) {
    styling = {
      width: layoutSizes.maxWidth,
      maxWidth: '100%',
      alignSelf: 'center',
      paddingTop: layoutSizes.headerHeight + layoutSizes.paddingTop,
      overflow: 'scroll',
    }
  } else if (device === 'web') {
    styling = {
      width: '100%',
      maxWidth: '100%',
      alignSelf: 'center',
      paddingTop: layoutSizes.headerHeight + layoutSizes.paddingTop,
    }
  } else {
    styling = {
      width: '100%',
      maxWidth: '100%',
      alignSelf: 'center',
      paddingHorizontal: layoutSizes.paddingHorizontal,
      paddingTop: layoutSizes.headerHeight, //+ sizes.paddingTop,
      // marginBottom: sizes.footerHeight,
    }
  }
  if (isScrollable) {
    return (
      <ScrollView
        scrollEnabled={scrollEnabled}
        /*contentContainerStyle={[
          scrollViewStyle,
          {
            alignItems: 'center',
            alignSelf: 'center',
            width: '100%',
            //width: Platform.OS === 'web' && fullScreen ? '100%' : sizes.maxWidth,
          },
        ]}*/
        //style={[{ height: '100%', marginBottom: Platform.OS === 'ios' ? 10 : 0, overflow: 'hidden' }, styling]}
        style={styling}
        contentContainerStyle={scrollViewStyle}
        showsVerticalScrollIndicator={false}
      >
        <>{children}</>
      </ScrollView>
    )
  }
  return (
    <View style={[styling, scrollViewStyle]}>
      <>{children}</>
    </View>
  )
}

export default PageWrapper
