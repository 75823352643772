import React, { Fragment } from 'react'

import { useHistory, useLocation } from '@webstollen/react-native/lib/react-router'

import { useTranslation } from 'react-i18next'

import DashboardSubmodule from './submodule/Dashboard'
import ShopSubmodule from './submodule/Shop'

import { TouchableOpacity, View } from 'react-native'
import useStyles from './style'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import BoldText from '@webstollen/react-native/lib/components/CustomText/BoldText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'

import icons from '../../constants/icons'
import { ScrollView } from 'native-base'
import Favorite from '../../assets/imgs/favorites/Favorite'

const Sidebar = () => {
  const history = useHistory()
  const location = useLocation()
  const styles = useStyles()
  const { t } = useTranslation()

  const sitemap: Record<
    'dashboard' | 'shop' | 'stream' | 'extensions' | 'favorites' /* | 'more' |'settings'*/,
    { name: string; path: string; icon?: IconProp; image?: JSX.Element; onPress: () => void; children?: JSX.Element }
  > = {
    dashboard: {
      name: t<string>('nav.dashboard'),
      path: '/dashboard',
      icon: [icons.faIconStyle, 'chart-pie'],
      onPress: () => history.push('/dashboard'),
      children: <DashboardSubmodule key="dashboard-submodule" />,
    },
    shop: {
      name: t<string>('nav.shop'),
      path: '/shop',
      icon: [icons.faIconStyle, 'store'],
      onPress: () => history.push('/shop'),
      children: <ShopSubmodule key="shops-submodule" />,
    },
    stream: {
      name: t<string>('nav.stream'),
      path: '/stream',
      icon: [icons.faIconStyle, 'newspaper'],
      onPress: () => history.push('/stream'),
      children: undefined,
    },
    extensions: {
      name: t<string>('nav.extensions'),
      path: '/upselling',
      icon: [icons.faIconStyle, 'boxes'],
      onPress: () => history.push('/upselling'),
      children: undefined,
    },
    favorites: {
      name: t<string>('nav.favorites'),
      path: '/favorites',
      image: <Favorite />,
      onPress: () => history.push('/favorites'),
      children: undefined,
    },
    /* Jetzt im Profile-Menu enthalten
    settings: {
      name: t<string>('nav.settings'),
      path: '/settings',
      icon: [icons.faIconStyle, 'cogs'],
      onPress: () => history.push('/settings'),
      children: undefined,
    },*/
    /*more: {
      name: t<string>('nav.more'),
      icon: [icons.faIconStyle, 'ellipsis-h'],
      onPress: () => history.push('/more'),
      children: undefined,
    },*/
  }

  const transformedSitemap = Object.entries(sitemap).map(([key, entry]) => (
    <Fragment key={`fragment-${key}`}>
      <TouchableOpacity
        style={[styles.mainItem, location.pathname.startsWith(entry.path) ? styles.isActive : null]}
        onPress={entry.onPress}
      >
        {entry.icon && <FontAwesomeIcon icon={entry.icon} style={styles.mainItemIcon} />}
        {entry.image && entry.image}
        <BoldText style={styles.mainItemText}>{entry.name}</BoldText>
      </TouchableOpacity>
      <View>{entry.children}</View>
    </Fragment>
  ))

  return (
    <ScrollView style={styles.sidebar} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
      <View style={styles.sidebarBackground}></View>
      <View style={styles.sidebarContent}>{transformedSitemap}</View>
    </ScrollView>
  )
}

// Sidebar.whyDidYouRender = true

export default Sidebar
