import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { Center } from 'native-base'
import React from 'react'
import { useWindowDimensions, View } from 'react-native'
import useDeviceType from '../../../../hooks/useDeviceType'
import icons from '../../../../constants/icons'
import { Redirect } from 'react-router'

const PaymentCompleted = () => {
  const { t } = useTranslation()
  const windowWidth = useWindowDimensions().width
  const device = useDeviceType()
  if (device === 'web' && windowWidth >= 700) {
    return <Redirect to={'/settings/subscription'} />
  } else {
    return (
      <View style={{ width: '100%', height: '100%' }}>
        <Center style={{ width: '100%', height: '100%' }}>
          <View style={{ margin: 10 }}>
            <FontAwesomeIcon icon={[icons.faIconStyle, 'badge-check']} size={'5x'} />
          </View>
          <ColoredText style={{ fontSize: 24, fontWeight: '300', margin: 10 }}>
            {t('text.basePaymentCompleted.title')}
          </ColoredText>
          <ColoredText style={{ fontSize: 16, fontWeight: '100', margin: 10 }}>
            {t('text.basePaymentCompleted.text')}
          </ColoredText>
        </Center>
      </View>
    )
  }
}

export default PaymentCompleted
