import React from 'react'
import numbro from 'numbro'
import { View } from 'react-native'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { IShop } from '@dash.bar/types'

import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { getIcon } from '../Position/Icon'
import useStyles from '../styles'

import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'
import { OrderPosition as OrderPositionT } from '../../../Orders/v1/types'
import { useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'
import icons from '../../../../../../constants/icons'

type PropsT = {
  shop: IShop
  position: OrderPositionT
}

const ExtraPosition = ({ position, shop }: PropsT) => {
  const styles = useStyles()

  const highlightColor = useHighlightColor()

  const { currency } = shop
  const { id, name, type, amount, price } = position

  return (
    <View key={`${id}-${type}`} style={styles.cardColumn}>
      <View style={styles.summaryEnumerationInnerWrapper}>
        <View style={[styles.fontAwesomeWrapper, styles.fontAwesomePaymentSummaryWrapper]}>
          <FontAwesomeIcon icon={[icons.faIconStyle, getIcon(type)]} color={highlightColor} />
        </View>
        <View style={styles.articleTextWrapper}>
          <ColoredText style={styles.textColor}>{name}</ColoredText>
        </View>
        <ColoredText style={styles.articleCost}>
          {numbro(price * amount).formatCurrency({
            ...numbro.languageData().currencyFormat,
            average: true,
            currencySymbol: ` ${htmlDecode(currency)}`,
          })}
        </ColoredText>
      </View>
    </View>
  )
}

export default ExtraPosition
