import { User } from '@dash.bar/types'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { Toast } from 'native-base'
import React, { useState } from 'react'
import { View } from 'react-native'
import { functions } from '../../../firebase'
import Button from '../../Button'
import useStyles from '../styles'

export const DeleteUserDialogContent = ({
  user,
  modalRef,
  setDeleteUser,
}: {
  user: User
  modalRef: React.MutableRefObject<any>
  setDeleteUser: (arg0: boolean) => void
}) => {
  const [deletingUser, setDeletingUser] = useState<boolean>(false)
  const deleteUser = functions().httpsCallable('calls-user-deleteUser')
  const { t } = useTranslation()
  const styles = useStyles()

  if (deletingUser) {
    return <LoadingScreen />
  }

  return (
    <>
      <ColoredText style={styles.modalContentText}>
        <> {t('accountOverview.user.submit_delete_text', { user: `${user?.firstName} ${user?.lastName}` })}</>
      </ColoredText>
      <ColoredText style={styles.modalContentText}>
        <>{t('accountOverview.user.delete_text')}</>
      </ColoredText>
      <View style={styles.buttonWrapper}>
        <Button
          style={styles.cancelButton}
          onPress={() => {
            if (modalRef && modalRef.current) modalRef.current.setVisible(false)
            setDeleteUser(false)
          }}
        >
          {t('accountOverview.user.cancel')}
        </Button>
        <Button
          onPress={() => {
            setDeletingUser(true)
            deleteUser({ userId: user.id })
              .then(() => {
                Toast.show({ title: t('accountOverview.user.successfully_deleted') })
              })
              .catch((err) => {
                Toast.show({ title: t('accountOverview.user.error_deleting'), description: err.message })
              })
              .finally(() => {
                if (modalRef && modalRef.current) modalRef.current.setVisible(false)
                setDeletingUser(false)
                setDeleteUser(false)
              })
          }}
          textStyle={{ color: 'white' }}
          style={styles.deleteButton}
        >
          {t('accountOverview.user.delete')}
        </Button>
      </View>
    </>
  )
}
