import React, { ReactNode } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../constants/colors'

const Card = ({ children, style }: { children?: ReactNode; style?: StyleProp<ViewStyle> }) => {
  const theme = useTheme()
  return (
    <View
      style={[
        {
          margin: 20,
          padding: 30,
          maxWidth: 1024,
          backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
          borderRadius: 10,
          minHeight: 233,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

export default Card
