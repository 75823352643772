import React, { useContext } from 'react'
import { Redirect } from '@webstollen/react-native/lib/react-router'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../../../context/SessionContext'
import { useEffect } from 'react'

const DashboardsRedirect = () => {
  const { user, currentUser } = useContext(SessionContext)
  const [loading, setLoading] = React.useState(true)
  const [redirectPath, setRedirectPath] = React.useState('/settings/dashboard')
  const firestore = useFirestore() //useDashboards was used here before but caused unwanted behaviour because it leads to redirects when document is not completly loaded yet

  useEffect(() => {
    const loadDashboards = async () => {
      if (!user) {
        return //Skip if user isn't loaded yet
      }
      const dashboards = await firestore
        .collection(`company/${user.company}/dashboard`)
        .where('creator', '==', currentUser.uid)
        .get()
      if (dashboards.empty) {
        setLoading(false)
      } else {
        if (user?.mainDashboard) {
          dashboards.forEach((dashboard) => {
            if (dashboard.id === user.mainDashboard) {
              setRedirectPath(`/dashboard/${dashboard.id}`)
              setLoading(false)
              return
            }
          })
        }
        setRedirectPath(`/dashboard/${dashboards.docs[0].id}`)
        setLoading(false)
      }
    }
    loadDashboards()
  }, [firestore, user, currentUser])

  if (loading) {
    return <LoadingScreen />
  } else {
    return <Redirect to={redirectPath} />
  }
}

export default DashboardsRedirect
