import React from 'react'

import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'

import numbro from 'numbro'
import { View } from 'react-native'
import PercentageKeyFigures from '../PercentageKeyFigures'
import useStyles from './styles'

type PropsT = {
  title: string
  currentData: number
  percentageData: number | string
  type?: 'currency' | 'percentage'
  currencySymbol?: string
}

const getKeyFigure = ({
  currentData,
  currencySymbol,
  type,
}: {
  currentData: number
  type?: 'currency' | 'percentage'
  currencySymbol?: string
}) => {
  switch (type) {
    case 'currency': {
      return numbro(currentData).formatCurrency({
        ...numbro.languageData().currencyFormat,
        average: false,
        currencySymbol,
      })
    }
    case 'percentage': {
      return `${currentData} %`
    }
    default: {
      return currentData
    }
  }
}

const KeyFigures = ({ title, currentData, percentageData, type, currencySymbol }: PropsT) => {
  const styles = useStyles()

  return (
    <View style={{ padding: 16 }}>
      <ColoredText style={[styles.text, styles.header]}>{title}</ColoredText>
      <ColoredText style={[styles.text, styles.keyFigures]}>
        {getKeyFigure({ currencySymbol, currentData, type })}
      </ColoredText>
      <PercentageKeyFigures percentage={percentageData} />
    </View>
  )
}
export default KeyFigures
