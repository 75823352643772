import React, { useContext } from 'react'
import { Platform, View } from 'react-native'
import { useHistory } from '@webstollen/react-native/lib/react-router'
//import { ShowUpsellingDialog } from '../../../../../../hooks/UpsellingDialogProvider'
import { Bundle } from '@dash.bar/types/booking'
import BundleCard from './BundleCard'
import { UpsellingContext } from '../../../../../../../hooks/UpsellingDialogProvider'

const UpsellingDialogBody = ({
  selectableBundles,
  isAdmin,
}: {
  selectableBundles: Array<Bundle>
  isAdmin: boolean
}) => {
  const history = useHistory()
  const { hideDialog } = useContext(UpsellingContext)
  const handleClick = (bundle: string) => {
    if (isAdmin) {
      const subscriptionPath = Platform.OS !== 'web' ? '/settings/subscription/' + bundle : '/settings/subscription/'
      history.push(subscriptionPath)
      hideDialog()
    } else {
      alert('You are no admin')
      hideDialog()
    }
  }

  return (
    <View style={{ width: '100%', padding: 10, alignItems: 'center' }}>
      {selectableBundles.map((bundle) => {
        return <BundleCard handleClick={handleClick} key={bundle} bundle={bundle} />
      })}
    </View>
  )
}
export default UpsellingDialogBody
