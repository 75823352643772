/**
 * Use this instead if String.replaceAll(), because it causes an error on Android
 */
import { useLocation } from '@webstollen/react-native/lib/react-router'
import { useMemo } from 'react'

export const replaceAll = (str: string, find: string, replace: string) => str.replace(new RegExp(find, 'g'), replace)

export const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}
