import * as yup from 'yup'
import Reference from 'yup/lib/Reference'
import { MIN_PASSWORD_LENGTH } from '../common/constants'

export function equalTo(ref: Reference<unknown>, msg?: string) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test: function (value: string) {
      return value === this.resolve(ref)
    },
  })
}

yup.addMethod(yup.string, 'equalTo', equalTo)

const SignupValidator = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(MIN_PASSWORD_LENGTH).required(),
  confirmPassword: yup.string().equalTo(yup.ref('password')).required(),
})

export default SignupValidator
