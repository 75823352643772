import { StyleSheet } from 'react-native'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTextColor } from '@webstollen/react-native/lib/hooks/useColors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      flex: 1,
      flexDirection: 'column',
      alignContent: 'flex-start',
    },

    shopHeaderWrapper: {
      flex: 1,
      flexDirection: 'row',
      marginLeft: 47,
      marginRight: 15,
      marginBottom: 2,
      marginTop: 5,
    },

    iconWrapper: {
      alignContent: 'flex-end',
    },

    iconBackground: {
      backgroundColor: 'white',
      borderRadius: 9,
    },

    name: {
      paddingRight: 8,
      color: useTextColor(),
      fontSize: 12,
      fontWeight: '400',
      marginLeft: 0,
    },

    icon: {
      height: 16,
      width: 16,
    },

    shopItemsOuterWrapper: {
      marginBottom: 8,
      marginRight: 5,
    },
    shopItemsInnerWrapper: {
      marginLeft: 39,
      marginRight: 5,
    },

    shopSubHeaderWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginVertical: 2,
    },

    shopItems: {
      fontSize: 11,
      marginVertical: 1,
      fontWeight: '100',
    },

    itemWrapper: {
      marginHorizontal: 8,
      paddingVertical: 2,
      borderRadius: 10,
      backgroundColor: 'transparent',
    },
    isActive: {
      backgroundColor: theme === Themes.Dark ? 'rgba(255,255,255, 0.07)' : 'rgba(0,0,0, 0.07)',
    },
  })
}

export default useStyles
