import { StyleSheet } from 'react-native'
import { usePrimaryBackground, useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    placeholder: {
      backgroundColor: useSecondaryBackground(),
      borderColor: usePrimaryBackground(),
      borderWidth: 1,
    },
    loadingImage: {
      height: 1,
      width: 1,
      position: 'absolute',
      top: -10,
      left: -10,
      zIndex: -9999,
    },
  })
}

export default useStyles
