import React, { useContext } from 'react'
import { Platform, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'

import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import colors from '../../../constants/colors'
import { useNotificationCounter } from '../../../hooks/NotificationsCounterProvider'
import { SessionContext } from '../../../context/SessionContext'
import icons from '../../../constants/icons'
import useStyles from './styles'
import { useHistory } from '@webstollen/react-native/lib/react-router'

const BellCounter = ({ counter, style }: { counter: number; style: StyleProp<ViewStyle> }) => {
  return (
    <View
      style={[
        {
          backgroundColor: colors.color3,
          borderRadius: 16,
          alignItems: 'center',
          justifyContent: 'center',
        },
        Platform.select({
          web: { width: 16, height: 16 },
          default: { width: 16, height: 16 },
        }),
        style,
      ]}
    >
      {counter !== undefined && <ColoredText style={{ fontSize: 10 }}>{counter.toString()}</ColoredText>}
    </View>
  )
}

const NotificationBellComponent = () => {
  const history = useHistory()
  const counter = useNotificationCounter()
  const { user } = useContext(SessionContext)
  const styles = useStyles()

  return (
    <View style={styles.menuButton}>
      {counter !== 0 && (
        <BellCounter style={{ position: 'absolute', right: 2, top: 0 }} counter={user?.notificationCounter} />
      )}
      <TouchableOpacity
        style={Platform.select({
          web: { width: 40, height: 40, alignItems: 'center', justifyContent: 'center' },
          default: { width: 40, height: 40, alignItems: 'center', justifyContent: 'center' },
        })}
        // TODO: Longpress only for testing -> remove
        // onPress={() => showDialog()}
        onPress={() => history.push('/notifications')}
      >
        <FontAwesomeIcon icon={[icons.faIconStyle, 'bell']} />
      </TouchableOpacity>
    </View>
  )
}

export default NotificationBellComponent
