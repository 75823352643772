import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import useCollection from '../useCollection'

/** Returns current booking if exists otherwise returns empty object
 *
 * @returns
 */
const useCurrentBundleBooking = (): Record<string, any> => {
  const { company } = useContext(SessionContext)
  const bookings = useCollection<any>(`company/${company?.id}/booking`)
  const currentBundle: Record<string, any> = Object.entries(bookings || {}).find(([k]) => {
    if (
      bookings[k].startDate.toDate() < new Date() &&
      (bookings[k]?.endDate?.toDate() > new Date() || !bookings[k]?.endDate) &&
      ['bundle', 'trial'].includes(bookings[k]?.key)
    ) {
      return true
    }
    return false
  })
  return currentBundle ? { ...currentBundle[1], id: currentBundle[0] } : {}
}

export default useCurrentBundleBooking
