import Sleepers from './Sleepers'
import i18n from '../../../locales/i18n'
import { IWidgetDefinition } from '../../../types/Widget'

const widgetDefinitions: Record<string, IWidgetDefinition> = {
  sleepers_v2: {
    component: Sleepers,
    title: i18n.t('shop.widget.sleepers.title'),
    minVersion: '2.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    // authorization: 'widget.sleepers', // TODO: enable / refactor if needed
  },
}

export default widgetDefinitions
