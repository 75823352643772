import { Bundle } from '@dash.bar/types'
import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import useDocument from '../useDocument'

/** Returns number of user included in given bundle. When no bundle is given returns number of user for current bundle
 *
 * @returns
 */
const useAllowedUser = (bundle?: Bundle): number => {
  const { company } = useContext(SessionContext)
  const bundleDoc = useDocument<Record<string, any>>('bundle', bundle)
  if (bundle) {
    return +(
      Object.entries(bundleDoc?.addons || { 'general.users': 15 })
        .filter(([k]) => {
          return k.toString() === 'general.users'
        })
        .map(([, value]) => {
          return value
        }) || 1
    )
  } else {
    return +(
      Object.entries(company?.authorizations || { 'general.users': 15 })
        .filter(([k]) => {
          return k.toString() === 'general.users'
        })
        .map(([, value]) => {
          return value
        }) || 1
    )
  }
}

export default useAllowedUser
