import React from 'react'
import { View } from 'react-native'
import { IShop } from '@dash.bar/types'
import useStyles from './styles'
import useKeyFigures from './useKeyFigures'
import { useTranslation } from 'react-i18next'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import KeyFigures from '../../../component/Analytics/v1/KeyFigures'
import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'

type PropsT = {
  shop: IShop
  interval: string
}

const getPercentage = (value: number, previous: number) => (previous === 0 ? '---' : (value - previous) / previous)

const KeyFigureStatistics = ({ shop, interval }: PropsT) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const { data, loading } = useKeyFigures({ shop, interval })

  if (loading || !data) {
    return (
      <LoadingScreen
        style={{
          height: 120,
        }}
        text={t('loading.keyFigures')}
      />
    )
  }

  const { sales, conversions, orders, visitors } = data

  return (
    <>
      <View style={styles.keyFiguresWrapper}>
        <View style={[styles.keyFiguresConstant]}>
          <KeyFigures
            title={t('shop.analytics.sales')}
            currentData={sales?.umsatz}
            percentageData={getPercentage(sales?.umsatz, sales?.umsatz_history)}
            currencySymbol={` ${htmlDecode(shop.currency)}`}
            type="currency"
          />
        </View>
        <View style={[styles.keyFiguresConstant]}>
          <KeyFigures
            title={t('shop.analytics.conversionRate')}
            currentData={conversions?.conversion}
            percentageData={getPercentage(conversions?.conversion, conversions?.conversion_history)}
            type="percentage"
          />
        </View>
      </View>
      <View style={styles.keyFiguresWrapper}>
        <View style={[styles.keyFiguresConstant]}>
          <KeyFigures
            title={t('shop.analytics.orders')}
            currentData={orders?.bestellungen}
            percentageData={getPercentage(orders?.bestellungen, orders?.bestellungen_history)}
          />
        </View>
        <View style={[styles.keyFiguresConstant]}>
          <KeyFigures
            title={t('shop.analytics.visitors')}
            currentData={visitors?.besucher}
            percentageData={getPercentage(visitors?.besucher, visitors?.besucher_history)}
          />
        </View>
      </View>
    </>
  )
}

export default KeyFigureStatistics
