import shop from './shop'
import misc from './misc'
import { IWidgetDefinition } from '../types/Widget'
import { IWidget } from '@dash.bar/types/index'
import { useCallback, useContext } from 'react'
import { SessionContext } from '../context/SessionContext'
import { get } from 'lodash'

const widgetDefinitions = {
  shop,
  misc,
}

export const widgetMap = {
  ...shop,
  ...misc,
} as Record<string, IWidgetDefinition>

export const allWidgetAuthorizations: Array<string> = Object.values(widgetMap).reduce((prev, value) => {
  if (value?.authorization) {
    return [...prev, value?.authorization]
  }
  return prev
}, [])

export const useIsWidgetAllowedForUser = () => {
  const {
    company: { authorizations: companyAuthorizations },
  } = useContext(SessionContext)

  return useCallback(
    (widget: IWidget) => {
      // console.log('useIsWidgetAllowedForUser', widget)
      const thisWidgetAuthorization: string = get(widgetDefinitions, widget.type).authorization
      const authorizedByDefault = !allWidgetAuthorizations.includes(thisWidgetAuthorization)

      if (authorizedByDefault) {
        return true
      }
      return companyAuthorizations?.[thisWidgetAuthorization] ?? false
    },
    [companyAuthorizations]
  )
}

export default widgetDefinitions
