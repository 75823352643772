import React, { PropsWithChildren, useState } from 'react'
//import { useContext } from 'react'

/*
const UpsellingDialoigContext = React.createContext(false)
const UpsellingDialogUpdateContext = React.createContext(() => alert('Test'))
export function useShowUseUpsellingDialog() {
  return useContext(UpsellingDialoigContext)
}

export function ShowUpsellingDialog() {
  return useContext(UpsellingDialogUpdateContext)
}
export function UpsellingDialogProvider({ children }: { children: any }) {
  const [showDialog, setShowDialog] = React.useState(false)

  function toggleDialog() {
    setShowDialog(!showDialog)
  }
  return (
    <UpsellingDialoigContext.Provider value={showDialog}>
      <UpsellingDialogUpdateContext.Provider value={toggleDialog}>{children}</UpsellingDialogUpdateContext.Provider>
    </UpsellingDialoigContext.Provider>
  )
}*/
export type SessionContextValueType = {
  dialogVisible: boolean
  toggleDialog: () => void
  showDialog: () => void
  hideDialog: () => void
}

export const UpsellingContext = React.createContext<SessionContextValueType>({
  dialogVisible: false,
  toggleDialog: () => {},
  showDialog: () => {},
  hideDialog: () => {},
})

const UpsellingProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [showDialogState, setShowDialog] = useState<boolean>(false)
  const toggleDialog = () => {
    setShowDialog(!showDialog)
  }
  const showDialog = () => {
    setShowDialog(true)
  }
  const hideDialog = () => {
    setShowDialog(false)
  }

  return (
    <UpsellingContext.Provider
      value={{
        dialogVisible: showDialogState,
        toggleDialog: toggleDialog,
        showDialog: showDialog,
        hideDialog: hideDialog,
      }}
    >
      {children}
    </UpsellingContext.Provider>
  )
}

export default UpsellingProvider
