import { extendTheme } from 'native-base'

// 1. Create custom theme
export const customNativeBaseTheme = extendTheme({
  components: {
    Button: {
      baseStyle: (/*props: Record<string, any>*/) => {
        return {
          // _text: {
          //   color: mode('green.400', 'pink.500')(props),
          // },
        }
      },
    },
  },
  colors: {
    primary: {
      // Colors made with https://smart-swatch.netlify.app/ based on #e60000
      50: '#ffe7ff',
      100: '#d967e4',
      200: '#cf3ddd',
      300: '#b524c3',
      400: '#8e1b98',
      500: '#73157c',
      600: '#3e0942',
      700: '#3e0942',
      800: '#17021a',
      900: '#17021a',
    },
    // Identical to primary
    purple: {
      50: '#ffe7ff',
      100: '#d967e4',
      200: '#cf3ddd',
      300: '#b524c3',
      400: '#8e1b98',
      500: '#73157c',
      600: '#3e0942',
      700: '#3e0942',
      800: '#17021a',
      900: '#17021a',
    },
    amber: {
      50: '#fff4dc',
      100: '#fee1b1',
      200: '#face82',
      300: '#face82',
      400: '#f7ba52',
      500: '#f5a623',
      600: '#dc8d0a',
      700: '#ab6d05',
      800: '#7b4e02',
      900: '#4a2f00',
    },
    gray: {
      50: '#f4f5f9',
      100: '#cdd0e5',
      200: '#acb1d0',
      300: '#8b92bd',
      400: '#6b73ab',
      500: '#515a92',
      600: '#3f4672',
      700: '#2d3252',
      800: '#1a1e32',
      900: '#080a15',
    },
  },
  fontConfig: {
    Comfortaa: {
      100: { normal: 'Comfortaa_300Light' },
      200: { normal: 'Comfortaa_300Light' },
      300: { normal: 'Comfortaa_300Light' },
      400: { normal: 'Comfortaa_400Regular' },
      500: { normal: 'Comfortaa_500Medium' },
      600: { normal: 'Comfortaa_600SemiBold' },
      700: { normal: 'Comfortaa_700Bold' },
      800: { normal: 'Comfortaa_700Bold' },
      900: { normal: 'Comfortaa_700Bold' },
    },
  },
  // Make sure values below matches any of the keys in `fontConfig`
  fonts: {
    heading: 'Comfortaa',
    body: 'Comfortaa',
    mono: 'Comfortaa',
  },
  useSystemColorMode: false,
  initialColorMode: 'light',
})

// 2. Get the type of the CustomTheme
type CustomThemeType = typeof customNativeBaseTheme

// 3. Extend the internal NativeBase Theme
declare module 'native-base' {
  interface ICustomTheme extends CustomThemeType {}
}
