import { Platform, StyleSheet } from 'react-native'

import colors from '../../../../../constants/colors'
import {
  useHighlightColor,
  usePrimaryBackground,
  useSecondaryBackground,
  useTextColor,
} from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  const highlightColor = useHighlightColor()

  return StyleSheet.create({
    scrollWrapper: {
      paddingHorizontal: 8,
    },

    card: {
      flexDirection: 'row',
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      marginTop: 8,
      borderRadius: 10,
      padding: 16,
    },

    cardColumn: {
      flexDirection: 'column',
    },

    productImage: {
      height: 72,
      width: 72,
      backgroundColor: colors.lightBg1,
      borderRadius: 50,
      marginRight: 8,
    },

    amountWrapper: {
      backgroundColor: usePrimaryBackground(),
      borderRadius: 16,
      marginRight: '2%',
      height: 32,
      width: 32,
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },

    articleTextWrapper: {
      flexDirection: 'column',
      flex: 1,
      alignSelf: 'center',
    },
    highlightText: {
      color: highlightColor,
    },

    articleCost: {
      marginLeft: 8,
      alignSelf: 'center',
      color: highlightColor,
    },

    orderInformationTextWrapper: {
      flexDirection: 'row',
      marginVertical: 4,
    },

    orderInformationHeader: {
      textAlign: 'center',
      fontSize: 24,
    },

    orderInformationTextInformation: {
      flex: 1,
      color: useTextColor(),
      fontSize: 16,
    },

    orderInformationText: {
      fontSize: 16,
    },

    summaryArticleInnerWrapper: {
      flexDirection: 'row',
    },
    summaryEnumerationOuterWrapper: {
      flexDirection: 'column',
    },

    sumWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
    },

    sumText: {
      fontSize: 18,
    },

    sumTextNumber: {
      fontSize: 16,
      color: highlightColor,
    },

    summaryEnumerationInnerWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottomWidth: 1.5,
      borderColor: usePrimaryBackground(),
      paddingBottom: 4,
      paddingTop: 4,
    },

    paymentSummaryWrapper: {
      backgroundColor: useSecondaryBackground(),
      borderRadius: 16,
    },

    textColor: {
      color: useTextColor(),
    },

    fontAwesomeWrapper: {
      backgroundColor: usePrimaryBackground(),
      borderRadius: 15,
      marginRight: 16,
      height: 30,
      width: 30,
      justifyContent: 'center',
      alignItems: 'center',
    },

    fontAwesomePaymentSummaryWrapper: {
      alignSelf: 'center',
    },

    name: {
      fontSize: 24,
    },

    billingDetailsText: {
      color: useTextColor(),
      marginVertical: Platform.select({ default: undefined, web: 2 }),
    },

    billingAdress: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    billingAdressLeft: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginRight: '6%',
    },

    billingAdressLeftDetails: {
      marginVertical: Platform.select({ default: undefined, web: 2 }),
    },

    avatar: {
      height: 100,
      width: 100,
      borderRadius: 50,
      marginBottom: 8,
    },

    customerCard: {
      flexDirection: 'column',
      alignItems: 'center',
    },

    contactButtons: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginVertical: 16,
    },

    contactButton: {
      backgroundColor: usePrimaryBackground(),
      borderRadius: 20,
      marginHorizontal: 8,
      height: 40,
      width: 40,
      justifyContent: 'center',
      alignItems: 'center',
    },

    commentWrapper: {
      backgroundColor: usePrimaryBackground(),
      padding: 6,
      alignItems: 'center',
      borderRadius: 16,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.2,
      shadowRadius: 1.41,
      elevation: 2,
    },
  })
}
export default useStyles
