import React, { ReactNode, useRef, ReactElement } from 'react'
import { View, Image, LayoutChangeEvent, TouchableOpacity, GestureResponderEvent } from 'react-native'

import SemiBoldText from '@webstollen/react-native/lib/components/CustomText/SemiBoldText'
import LightText from '@webstollen/react-native/lib/components/CustomText/LightText'

import useStyles from './styles'
import DrawerLayout from 'react-native-gesture-handler/DrawerLayout'

import { useSecondaryBackground, useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import { faEllipsisH, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'

interface BaseProps {
  footerContent?: ReactNode
  title: string
  children: ReactNode
  options?: Array<ReactNode>
  showOptions?: boolean
  onWidgetLayout?: (evt: LayoutChangeEvent) => void

  onClickWidget?: (evt: GestureResponderEvent) => void
}

interface IconProps extends BaseProps {
  icon?: ReactElement
}
interface ImageProps extends BaseProps {
  image?: { source: string; alt: string }
}

export type PropsT = IconProps | ImageProps

const Widget = ({
  title,
  children,
  footerContent,
  options,
  showOptions = false,
  onWidgetLayout,
  onClickWidget,
  ...props
}: PropsT) => {
  const styles = useStyles()
  const highlightColor = useHighlightColor()

  const drawer = useRef<DrawerLayout>()

  const { icon } = props as IconProps
  const { image } = props as ImageProps

  return (
    <View style={[styles.widget]}>
      <DrawerLayout
        ref={drawer}
        drawerLockMode={showOptions ? 'unlocked' : 'locked-closed'}
        drawerWidth={300}
        drawerPosition={'right'}
        drawerType="front"
        drawerBackgroundColor={useSecondaryBackground()}
        renderNavigationView={() => (
          <View style={styles.options}>
            <View style={styles.drawerHeader}>
              <SemiBoldText>{title}</SemiBoldText>
              <TouchableOpacity onPress={() => drawer.current.closeDrawer()}>
                <FontAwesomeIcon color={highlightColor} icon={faTimesCircle} />
              </TouchableOpacity>
            </View>
            {options && options.filter((e) => !!e)}
          </View>
        )}
      >
        <TouchableOpacity style={{ flex: 1 }} onPress={onClickWidget}>
          <View style={[styles.header]}>
            <View style={[styles.icon, styles.iconWrapper]}>
              {icon && React.cloneElement(icon, { style: styles.icon })}
              {image && <Image style={styles.icon} source={{ uri: image.source }} accessibilityLabel={image.alt} />}
            </View>
            <SemiBoldText style={styles.title}>{title}</SemiBoldText>
            <View style={styles.optionsButton}>
              {showOptions && (
                <TouchableOpacity
                  onPress={(event) => {
                    event.preventDefault()
                    drawer.current.openDrawer()
                  }}
                >
                  <FontAwesomeIcon color={highlightColor} icon={faEllipsisH} />
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={styles.content} onLayout={onWidgetLayout}>
            {children}
          </View>
          {footerContent ? (
            <View style={[styles.footer]}>
              <LightText style={[styles.footerText]}>{footerContent}</LightText>
            </View>
          ) : null}
        </TouchableOpacity>
      </DrawerLayout>
    </View>
  )
}

export default Widget
