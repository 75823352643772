import { useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { StyleSheet } from 'react-native'
import colors from '../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      borderRadius: 15,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-start',
    },
    languageWrapper: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      paddingHorizontal: 30,
      paddingVertical: 10,
    },
    themeWrapper: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      paddingHorizontal: 30,
      paddingVertical: 10,
    },
    intervalCard: {
      borderRadius: 15,
      borderWidth: 1,
      borderColor: colors.color2,
      padding: 15,
      width: '40%',
      minWidth: '40%',
      maxWidth: '40%',
    },
    selected: {
      borderWidth: 2,
    },
    itemText: {
      width: '60%',
      textAlign: 'center',
    },
    intervalWrapper: {
      width: '100%',
      height: '100%',
      minHeight: '100%',
      flex: 1,
      justifyContent: 'flex-start',
      alignContent: 'space-around',
      alignItems: 'center',
      flexDirection: 'row',
    },
    notificationWrapper: {
      width: '100%',
      paddingHorizontal: 30,
      paddingVertical: 10,
      flexDirection: 'column',
    },
    notificationSettingsWrapper: {
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    notificationSettings: {
      width: '50%',
      flexDirection: 'row',
      padding: 10,
    },
    notificationTitle: {
      paddingVertical: 20,
      fontSize: 18,
    },
    settingTitle: {
      width: '50%',
    },
    switch: {
      color: useHighlightColor(),
    },
    divider: {
      width: '100%',
      backgroundColor: theme === Themes.Dark ? colors.lightBg1 : colors.darkBg1,
      height: 1,
      minHeight: 1,
      maxHeight: 1,
    },
  })
}

export default useStyles
