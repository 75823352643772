import React from 'react'
import { ScrollView } from 'react-native'
import { IShop } from '@dash.bar/types'
import Status from '../../../component/LiveTracker/v1/Status'
import LiveTrackerColumn from '../../../component/LiveTracker/v1/LiveTrackerColumn'
import CumulatedHorizontalScroll from './CumulatedHorizontalScroll'

type PropsT = {
  shop: IShop
}

const LiveTrackerView = ({ shop }: PropsT) => {
  return (
    <ScrollView>
      <LiveTrackerColumn shop={shop} />
      <CumulatedHorizontalScroll shop={shop} />
      <Status shop={shop} />
    </ScrollView>
  )
}

export default LiveTrackerView
