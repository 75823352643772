import React, { useContext } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core'

import { useHistory } from '@webstollen/react-native/lib/react-router'

import useStyles from './styles'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { faSignOut } from '@fortawesome/pro-solid-svg-icons'
import { SessionContext, SessionContextValueType } from '../../../../context/SessionContext'
import icons from '../../../../constants/icons'
import PageWrapper from '../../../../components/PageWrapper'

const Settings = () => {
  const styles = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { signOut } = useContext<SessionContextValueType>(SessionContext)

  const sitemap = {
    accountOverview: {
      name: t<string>('nav.account_overview'),
      icon: [icons.faIconStyle, 'user' as IconName],
      onPress: () => history.push('/settings/accountOverview'),
    },
    dashboard: {
      name: t<string>('nav.edit_dashboards'),
      icon: [icons.faIconStyle, 'chart-pie' as IconName],
      onPress: () => history.push('/settings/dashboard'),
    },
    subscription: {
      name: t<string>('nav.subscription'),
      icon: [icons.faIconStyle, 'dashbar' as IconName],
      onPress: () => history.push('/settings/subscription'),
    },
    shop: {
      name: t<string>('nav.shop'),
      icon: [icons.faIconStyle, 'store' as IconName],
      onPress: () => history.push('/settings/shop'),
    },
    settings: {
      name: t<string>('nav.settings'),
      icon: [icons.faIconStyle, 'cog' as IconName],
      onPress: () => history.push('/settings/settings'),
    },
    logout: {
      name: t<string>('nav.logout'),
      icon: faSignOut,
      onPress: signOut,
    },
  } as Record<
    'accountOverview' | 'dashboard' | 'subscription' | 'shop' | 'settings' | 'logout',
    { name: string; icon: IconProp; onPress: () => void; children?: JSX.Element }
  >

  return (
    <PageWrapper scrollViewStyle={{ justifyContent: 'flex-end', height: '100%' }} isScrollable={true}>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          {Object.entries(sitemap).map(([key, { icon, onPress, name }]) => (
            <TouchableOpacity key={key} onPress={onPress} style={styles.button}>
              <FontAwesomeIcon icon={icon as IconProp} />
              <ColoredText style={styles.buttonText}>{name}</ColoredText>
              <FontAwesomeIcon icon={[icons.faIconStyle, 'angle-right']} />
            </TouchableOpacity>
          ))}
        </View>
      </View>
    </PageWrapper>
  )
}

export default Settings
