import { clone } from 'lodash'

const removeEmpty = <T>(obj: T): T => {
  const clonedObj = clone(obj)

  Object.entries(clonedObj).forEach(([key, val]) => {
    if (val && typeof val === 'object') {
      clonedObj[key] = removeEmpty(val)
    } else if (val == null) {
      // == because it matches null or undefined
      delete clonedObj[key]
    }
  })

  return clonedObj
}

export default removeEmpty
