import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'

/**
 * Wrapper for SignUp-Process
 *   if user is authed but company claim has not been set yet -> show Preparing User Dialog
 */
const PrepareUser = ({ children }: { children: any }) => {
  const { t } = useTranslation()
  const [{ preparing }, setPrepareUserState] = useState<{
    preparing: boolean
  }>({
    preparing: true,
  })

  /**
   * Check if user is authenticated and has companyClaim
   *   if false => showPreparingUser Component
   *   if true  => showChildren
   */
  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((auth) => {
        if (auth) {
          auth.getIdTokenResult().then((result) => {
            if (result.claims.company) {
              //Check if user has already a company in claims otherwise this will cause problems in signup process (no dashboars/shops shown on first signup)
              setPrepareUserState({ preparing: false })
            } else {
              setPrepareUserState({ preparing: true })
            }
          })
        } else {
          setPrepareUserState({ preparing: false })
        }
      }),
    [setPrepareUserState]
  )

  /**
   * Check if user is authenticated and has companyClaim
   *   if false => showPreparingUser Component
   *   if true  => showChildren
   */
  useEffect(
    () =>
      firebase.auth().onIdTokenChanged((auth) => {
        if (auth) {
          auth.getIdTokenResult().then((result) => {
            if (result.claims.company) {
              setPrepareUserState({ preparing: false })
            } else {
              setPrepareUserState({ preparing: true })
            }
          })
        } else {
          setPrepareUserState({ preparing: false })
        }
      }),
    [setPrepareUserState]
  )

  if (preparing) {
    return <LoadingScreen text={t('text.baseSignup.prepareUser')} />
  } else {
    return children
  }
}
export default PrepareUser
