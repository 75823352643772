import React from 'react'

import Login from './page/Login'
import Signup from './page/Signup'
import ResetPassword from './page/ResetPassword'

import i18n from '../../locales/i18n'
import IndexRedirect from './page/IndexRedirect'
import NoCompany from './page/NoCompany'
import AccountManagement from './page/AccountManagement/AccountManagement'

export default () => [
  {
    title: i18n.t('title.login'),
    name: 'login',
    route: {
      exact: true,
      path: '/login',
      children: <Login />,
    },
  },
  {
    title: i18n.t('title.noCompany'),
    name: 'no-company',
    route: {
      exact: true,
      path: '/no-company',
      children: <NoCompany />,
    },
  },
  {
    title: i18n.t('title.signup'),
    name: 'signup',
    route: {
      exact: true,
      path: '/signup',
      children: <Signup />,
    },
  },
  {
    title: i18n.t('title.signup'),
    name: 'signup',
    route: {
      exact: true,
      path: '/signup/:code',
      children: <Signup />,
    },
  },
  {
    title: i18n.t('title.resetPassword'),
    name: 'resetPassword',
    route: {
      exact: true,
      path: '/reset-password',
      children: <ResetPassword />,
    },
  },
  {
    title: i18n.t('title.resetPassword'),
    name: 'accountManagement',
    route: {
      exact: true,
      path: '/account-management',
      children: <AccountManagement />,
    },
  },
  {
    title: 'redirect',
    name: 'index-redirect',
    route: {
      exact: true,
      path: '/index-redirect',
      children: <IndexRedirect />,
    },
  },
]
