import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import React from 'react'
import { View } from 'react-native'
import { Button, Dialog } from 'react-native-paper'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import useDeviceType from '../../../hooks/useDeviceType'
import MobileDialog from '../../../components/MobileDialog/MobileDialog'
// import Toast from 'react-native-toast-message'

const NotificationRecivedDialog = ({
  dialogIsVisible,
  setDialogVisible,
  title,
  description,
  path,
}: {
  dialogIsVisible: boolean
  setDialogVisible: (v: boolean) => void
  title: string
  description: string
  path: string
}) => {
  const history = useHistory()
  const device = useDeviceType()
  const onShow = () => {
    setDialogVisible(false)
    history.push(path)
  }
  if (device === 'mobile') {
    return (
      <MobileDialog isVisible={dialogIsVisible} showDialog={setDialogVisible}>
        <ColoredText style={{ fontSize: 16, alignSelf: 'center', paddingVertical: '5%' }}>{title}</ColoredText>
        <ColoredText style={{ fontSize: 16, alignSelf: 'center', paddingVertical: '5%' }}>{description}</ColoredText>
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Button onPress={() => setDialogVisible(false)}>Dismiss</Button>
          <Button onPress={onShow}>Show</Button>
        </View>
      </MobileDialog>
    )
  } else {
    // Toast.show({ type: 'success', text1: title, text2: description })
    return (
      <Dialog
        visible={dialogIsVisible}
        onDismiss={() => setDialogVisible(false)}
        style={{ alignItems: 'center', position: 'absolute', top: 10, alignSelf: 'center', width: 500 }}
      >
        <Dialog.Title>
          <ColoredText> {title}</ColoredText>
        </Dialog.Title>
        <Dialog.Content>
          <ColoredText> {description}</ColoredText>
        </Dialog.Content>
        <Dialog.Actions>
          <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <Button onPress={() => setDialogVisible(false)}>Dismiss</Button>
            <Button onPress={onShow}>Show</Button>
          </View>
        </Dialog.Actions>
      </Dialog>
    )
  }
}

export default NotificationRecivedDialog
