import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import numbro from 'numbro'
import React from 'react'
import useStyles from './styles'

type PropsT = {
  percentage: number | string
}

const PercentageKeyFigures = ({ percentage }: PropsT) => {
  const styles = useStyles()

  if (typeof percentage === 'string') {
    return <ColoredText style={styles.textRed}>{percentage}</ColoredText>
  }

  return (
    <ColoredText style={percentage >= 0 ? styles.textGreen : styles.textRed}>
      {numbro(percentage).format({
        mantissa: 0,
        output: 'percent',
        forceSign: true,
      })}
    </ColoredText>
  )
}
export default PercentageKeyFigures
