import { StyleSheet } from 'react-native'
import { useHighlightColor } from '@webstollen/react-native/lib/hooks/useColors'
import colors from '../../../../constants/colors'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      backgroundColor: 'transparent',
      width: '100%',
    },
    container: {
      margin: 0,
      width: '100%',
    },

    widget: {
      borderRadius: 16,
      marginTop: 5,
      paddingVertical: 10,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 50,
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
    },
    selectedWidget: {
      backgroundColor: useHighlightColor(),
    },

    widgetText: {
      width: '50%',
      paddingLeft: 10,
    },
    selectedWidgetText: {
      color: colors.darkText1,
    },

    widgetButton: {
      justifyContent: 'center',
      flexDirection: 'row',
      paddingHorizontal: 10,
    },

    widgetIcon: {
      height: 25,
      width: 25,

      borderRadius: 12.5,
      overflow: 'hidden',
    },

    manageDashboardButton: {
      borderRadius: 16,
      marginTop: 5,
      paddingVertical: 10,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      height: 50,
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
    },
  })
}

export default useStyles
