import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import { BaseActionTypes, LanguageType, SET_LANGUAGE, SET_THEME } from './types'

export const useSetTheme = () => {
  const dispatch = useDispatch()
  return useCallback(
    (theme: Themes) => {
      dispatch(setTheme(theme))
    },
    [dispatch]
  )
}

export const setTheme = (theme: Themes): BaseActionTypes => {
  return {
    type: SET_THEME,
    payload: theme,
  }
}

export const setLanguage = (lang: LanguageType): BaseActionTypes => {
  return {
    type: SET_LANGUAGE,
    payload: lang,
  }
}
