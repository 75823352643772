import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import React from 'react'
import * as WebBrowser from 'expo-web-browser'
import { Platform, TouchableOpacity } from 'react-native'

const Link = ({ url, children }: { url: string; children: React.ReactNode }) => {
  const openLink = () => {
    if (Platform.OS === 'web') {
      window.location.href = url
    } else {
      WebBrowser.openBrowserAsync(url)
    }
  }
  return (
    <TouchableOpacity onPress={() => openLink()}>
      <ColoredText style={{ marginHorizontal: 4, textDecorationLine: 'underline' }}>{children}</ColoredText>
    </TouchableOpacity>
  )
}

export default Link
