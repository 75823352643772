import React from 'react'

import { ImageProps } from 'react-native'
import LazyLoadingImage from '../LazyLoadingImage'

const Avatar = ({ photoURL, ...props }: Partial<ImageProps> & { photoURL: string }) => {
  const avatar = require('../../assets/imgs/avatar.png')

  return <LazyLoadingImage defaultImage={avatar} imageUrl={photoURL} {...props} />
}

export default Avatar
