import { User } from '@dash.bar/types'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { Toast } from 'native-base'
import React, { useState } from 'react'
import { View } from 'react-native'
import getApp from '../../../firebase'
import Button from '../../Button'
import useStyles from '../styles'

export const SetAdminDialogContent = ({
  user,
  modalRef,
  setSetAdmin,
  isAdmin,
}: {
  user: User
  modalRef: React.MutableRefObject<any>
  setSetAdmin: (arg0: boolean) => void
  isAdmin: boolean
}) => {
  const { t } = useTranslation()
  const [settingAdmin, setSettingAdmin] = useState<boolean>(false)
  const styles = useStyles()
  const admin = getApp().functions('us-central1').httpsCallable('auth-user-admin')
  return settingAdmin ? (
    <LoadingScreen />
  ) : (
    <>
      <ColoredText style={styles.modalContentText}>
        <>
          {isAdmin
            ? t('accountOverview.user.submit_demote_text', { user: `${user?.firstName} ${user?.lastName}` })
            : t('accountOverview.user.submit_promote_text', { user: `${user?.firstName} ${user?.lastName}` })}
        </>
      </ColoredText>
      <ColoredText style={styles.modalContentText}>
        <> {isAdmin ? t('accountOverview.user.demote_text') : t('accountOverview.user.promote_text')}</>
      </ColoredText>
      <View style={styles.buttonWrapper}>
        <Button
          style={styles.cancelButton}
          onPress={() => {
            if (modalRef && modalRef.current) modalRef.current.setVisible(false)
            setSetAdmin(false)
          }}
        >
          {t('accountOverview.user.cancel')}
        </Button>
        <Button
          onPress={() => {
            setSettingAdmin(true)
            admin({ uid: user.id, admin: !isAdmin })
              .then(() => {
                Toast.show({
                  title: isAdmin
                    ? t('accountOverview.user.successfully_demoted')
                    : t('accountOverview.user.successfully_promoted'),
                })
              })
              .catch((err) => {
                Toast.show({
                  title: isAdmin ? t('accountOverview.user.error_demoting') : t('accountOverview.user.error_promoting'),
                  description: err.message,
                })
              })
              .finally(() => {
                if (modalRef && modalRef.current) modalRef.current.setVisible(false)
                setSettingAdmin(false)
                setSetAdmin(false)
              })
          }}
          textStyle={{ color: 'white' }}
          style={isAdmin ? styles.deleteButton : styles.promoteButton}
        >
          {isAdmin ? t('accountOverview.user.demote_admin') : t('accountOverview.user.promote_admin')}
        </Button>
      </View>
    </>
  )
}
