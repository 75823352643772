import { StyleSheet, Platform } from 'react-native'

import {
  useHighlightColor,
  usePrimaryBackground,
  useSecondaryBackground,
  useTextColor,
} from '@webstollen/react-native/lib/hooks/useColors'
import colors from '../../constants/colors'

const useStyles = () => {
  return StyleSheet.create(
    JSON.parse(
      JSON.stringify({
        header: {
          fontSize: 13,
          marginBottom: 5,
        },
        tagContainer: {
          display: Platform.select({ default: 'flex', web: undefined }),
        },
        lineContainer: {
          display: Platform.select({ default: 'flex', web: undefined }),
          flexDirection: 'row',
          justifyContent: Platform.select({ default: 'space-evenly', web: 'flex-end' }),
        },
        tag: {
          margin: 5,
          paddingVertical: 5,
          paddingHorizontal: 10,
          cursor: Platform.select({ web: 'pointer', default: undefined }),
          borderRadius: 10,
          backgroundColor: Platform.select({ default: usePrimaryBackground(), web: useSecondaryBackground() }),
        },
        tagSelected: {
          cursor: Platform.select({ web: 'default', default: undefined }),
          backgroundColor: useHighlightColor(),
        },
        tagLabel: {
          fontSize: 12,
          color: Platform.select({ default: undefined, web: useTextColor() }),
        },
        tagLabelSelected: {
          color: colors.darkText1,
        },
      })
    )
  )
}

export default useStyles
