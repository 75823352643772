import { useHighlightColor, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { StyleSheet } from 'react-native'
import colors from '../../../../../../../constants/colors'
import sizes from '../../../../../../../constants/sizes'

const useStyles = () => {
  return StyleSheet.create({
    userWrapper: {
      width: '100%',
      padding: 30,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'flex-start',
    },
    userViewWrapper: {
      width: '20%',
      maxWidth: '20%',
      minWidth: '20%',
      marginVertical: 10,
      flex: 1,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    avatar: {
      height: 70,
      width: 70,
      borderRadius: 50,
    },
    admin: {
      borderColor: colors.error,
      borderWidth: 4,
    },
    owner: {
      borderColor: colors.success,
      borderWidth: 4,
    },
    addButton: {
      height: 70,
      width: 70,
      borderRadius: 50,
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: useHighlightColor(),
    },
    manageButton: {
      borderRadius: 10,
      borderWidth: 1,
      borderColor: useTextColor(),
      padding: 5,
      paddingVertical: 2,
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    name: {
      padding: 10,
      width: '100%',
      textAlign: 'center',
    },
    cancelButton: {
      borderRadius: 15,
      borderColor: useHighlightColor(),
      borderWidth: 1,
      height: sizes.buttonHeight,
      width: '100%',
    },
    deleteButton: {
      backgroundColor: colors.error,
      width: '100%',
      height: sizes.buttonHeight,
      borderRadius: 10,
      color: 'white',
    },
    promoteButton: {
      backgroundColor: colors.success,
      width: '100%',
      height: sizes.buttonHeight,
      borderRadius: 10,
      color: 'white',
    },
    deleteModalWrapper: {
      width: '70%',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    deleteModalText: {
      padding: 5,
      textAlign: 'left',
      width: '100%',
    },
  })
}

export default useStyles
