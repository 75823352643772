import React from 'react'
import { IShop } from '@dash.bar/types'
import useOrderValue from './useOrderValue'
import { useTranslation } from 'react-i18next'
import LoadingScreen from '@webstollen/react-native/lib/components/LoadingScreen'
import BarChartsAnalytics from '../../../component/Analytics/v1/BarChartsAnalytics'
import { useTextColor } from '@webstollen/react-native/lib/hooks/useColors'

type PropsT = {
  shop: IShop
  interval: string
}

const OrderValueStatistics = ({ shop, interval }: PropsT) => {
  const { t } = useTranslation()
  const textColor = useTextColor();
  const { loading, data } = useOrderValue({ shop, interval })

  if (loading || !data) {
    return (
      <LoadingScreen
        style={{
          height: 120,
        }}
        text={t('loading.data')}
      />
    )
  }

  const orderValueAccumulated = [data?.avg, ...(data?.avg_history || [].map((val) => val))]

  return (
    <BarChartsAnalytics
      data={orderValueAccumulated}
      // title={'test'}
      title={t('shop.analytics.orderValue')}
      color={textColor}
    />
  )
}

export default OrderValueStatistics
