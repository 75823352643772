import { TouchableOpacity, View } from 'react-native'
import Button from '../../../../../../components/Button'
import React from 'react'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'
import { useHistory } from '@webstollen/react-native/lib/react-router'

const BuyWidgetOverlay = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TouchableOpacity
        style={{
          position: 'absolute',
          top: -2,
          left: -2,
          right: -2,
          bottom: -2,
          backgroundColor: 'black',
          opacity: 0.5,
          borderRadius: 10,
        }}
        onPress={(e) => e.preventDefault()}
      />
      <Button primary style={{ paddingHorizontal: 5 }} onPress={() => history.push('/upselling')}>
        {t('shop.upselling.body.buy')}
      </Button>
    </View>
  )
}

export default BuyWidgetOverlay
