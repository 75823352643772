import React from 'react'
import { Button, Dialog, Portal } from 'react-native-paper'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import useStyles from '../../styles'
import { useTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'

const AppInstalledDialog = ({
  showAppInstallDialog,
  setAppInstallDialog,
}: {
  showAppInstallDialog: boolean
  setAppInstallDialog: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const hideDialog = () => setAppInstallDialog(false)

  return (
    <Portal>
      <Dialog style={styles.dialog} visible={showAppInstallDialog} onDismiss={hideDialog}>
        <Dialog.Title>
          <ColoredText>{t('text.newUserTutorial.appInstall.thankYouForTrust')}</ColoredText>
        </Dialog.Title>
        <Dialog.Content>
          <ColoredText style={styles.text}>{t('text.newUserTutorial.appInstall.recommendUs')}</ColoredText>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => alert('hier weiter empfehlen')}>
            <ColoredText>{t('text.newUserTutorial.appInstall.recommendUsHere')}</ColoredText>
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  )
}

export default AppInstalledDialog
