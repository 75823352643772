import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import React from 'react'
import { View } from 'react-native'
import useStyles from './styles'

const Report = () => {
  const styles = useStyles()

  const sales = '1172 €'
  const salesRaise = '33,49%'
  const visitor = '458'
  const visitorRaise = '8,53%'
  const conversionrate = '9,61%'
  const name = 'Maiximilian'
  const shop = 'fischfuttertreff'

  return (
    <View style={styles.infoWrapper}>
      <ColoredText>
        Hallo {name},{'\n'}
        hier haben wir dir ein paar intereassante Fakten von gestern in deinem Shop {shop}
        zusammengefasst:{'\n'}
      </ColoredText>

      <View style={styles.dataWrapper}>
        <ColoredText>
          - Dein Umsatz lag mit {sales} um rund {salesRaise} über dem von vorgestern {'\n'} {'\n'}- Deine Besucherzahl
          unterscheidet sich um {visitorRaise}({visitor}) zu vorgestern {'\n'} {'\n'}- Deine Konversionsrate stieg auf{' '}
          {conversionrate}
        </ColoredText>
      </View>
    </View>
  )
}

export default Report
