import React from 'react'
import { View } from 'react-native'
import useStyles from './styles'
import md5 from 'md5'
import numbro from 'numbro'
import htmlDecode from '@webstollen/react-native/lib/utils/htmlDecode'
import Avatar from '../../../../../../components/Avatar'
import { FontAwesomeIcon } from '@webstollen/react-native/lib/fontawesome'
import LiveTrackerRow from '../../../../../../widgets/shop/widgets_v1/LiveTracker/LiveTrackerRow'
import { useTranslation } from 'react-i18next'
import { UserT } from '../../../../../../types/widgetDataTypes/types'
import useLiveTrackerColumn from '../../../../pages/LiveTracker/v1/useLiveTrackerColumn'
import { IShop } from '@dash.bar/types'
import icons from '../../../../../../constants/icons'

type PropsT = {
  shop: IShop
}

const LiveTrackerColumn = ({ shop }: PropsT) => {
  const styles = useStyles()

  const { t } = useTranslation()
  const { data } = useLiveTrackerColumn({ shop })

  const getUserAvatar = ({ mail }: UserT, index: number) => (
    <Avatar
      key={`a${mail}${index}`}
      style={[
        styles.avatar,
        {
          left: 15 * index,
        },
      ]}
      photoURL={`https://avatar.ws-svc.de/?seed=${md5(mail.trim().toLowerCase())}`}
      accessibilityLabel={mail.trim().toLowerCase()}
    />
  )

  const visitorAvatars = data?.data?.slice(0, Math.min(data?.visitors, 8)).map(getUserAvatar)

  const customerAvatars = data?.data
    .filter(({ customerId }) => customerId != null)
    .slice(0, Math.min(data?.customers, 7))
    .map(getUserAvatar)

  const basketIcon = <FontAwesomeIcon style={styles.basketIcon} icon={[icons.faIconStyle, 'shopping-cart']} />

  const rows = [
    {
      key: 'visitors',
      width: '100%',
      value: data?.visitors,
      label: t('shop.widget.live-tracker.visitors'),
      avatars: visitorAvatars,
    },
    {
      key: 'customers',
      width: '90%',
      value: data?.customers,
      label: t('shop.widget.live-tracker.customers'),
      avatars: customerAvatars,
    },
    {
      key: 'baskets',
      width: '80%',
      value: data?.baskets,
      label: t('shop.widget.live-tracker.baskets', { count: data?.baskets }),
      icon: basketIcon,
    },
    {
      key: 'basketValue',
      width: '70%',
      value: numbro(data?.basketValue).formatCurrency({
        ...numbro.languageData().currencyFormat,
        currencySymbol: htmlDecode(shop?.currency),
        spaceSeparated: true,
        mantissa: 0,
      }),
      label: t('shop.widget.live-tracker.basketValue'),
      icon: basketIcon,
    },
  ]

  return (
    <View style={styles.content}>
      {rows.map(({ key, ...props }, index) => (
        <LiveTrackerRow key={key} {...props} index={index} />
      ))}
    </View>
  )
}

export default LiveTrackerColumn
