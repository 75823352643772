import { useCallback } from 'react'

import { IWidget } from '@dash.bar/types'
import useDashboard from '../dashboard/useDashboard'
import useUpdateWidgets from './useUpdateWidgets'

const useUpdateWidget = (dashboardId: string) => {
  const updateWidgets = useUpdateWidgets(dashboardId)

  const dashboard = useDashboard(dashboardId)

  return useCallback(
    async (widget: IWidget) =>
      updateWidgets(
        dashboard.widgets.map(({ id, ...rest }) => {
          if (id === widget.id) {
            return widget
          }

          return {
            id,
            ...rest,
          }
        })
      ),
    [dashboard, updateWidgets]
  )
}

export default useUpdateWidget
