import { Platform, StyleSheet } from 'react-native'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    intervalSelectionWrapper: {
      marginVertical: Platform.select({ default: 8, web: undefined }),
      marginBottom: Platform.select({ default: undefined, web: -8 }),
      marginHorizontal: 8,
      borderRadius: 10,
      backgroundColor: 'transparent',
      padding: 8,
    },
    keyFiguresWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginHorizontal: 4,
    },
    keyFiguresConstant: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.darkBgTransparent6,
      borderRadius: 10,
      flex: 1,
      margin: 8,
    },
  })
}

export default useStyles
