import { Platform, StyleSheet } from 'react-native'
import { useSecondaryBackground, usePrimaryBackground, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'

import colors from '../../constants/colors'

const useStyles = (size: { width: number; height: number }) => {
  return StyleSheet.create({
    wrapper: {
      paddingVertical: 3,
      position: 'relative',
    },

    tooltip: {
      backgroundColor: useSecondaryBackground(),
      borderRadius: 10,
      padding: 15,
      height: size.height,
      borderWidth: 2,
      borderColor: usePrimaryBackground(),
    },

    textLabelWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    textLabel: {
      height: 10,
      width: 10,
      borderRadius: 2,
      alignSelf: 'center',
      marginRight: Platform.select({ default: undefined, web: 4 }),
    },

    label: {
      paddingBottom: 10,
    },

    textWrapper: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginHorizontal: '1.5%',
    },

    textLabelFont: {
      fontSize: 12,
    },

    blueBackground: {
      backgroundColor: colors.color2,
    },

    yellowBackground: {
      backgroundColor: colors.color3,
    },

    purpleBackground: {
      backgroundColor: colors.color1,
    },

    textColor: {
      color: useTextColor(),
    },

    lineChart: {
      height: size.height - 40,
    },

    barChart: {
      position: 'absolute',
      top: 0,
      height: size.height - 40,
      right: 0,
      left: 0,
      marginTop: '5%',
    },

    payload: {
      padding: 5,
    },
  })
}

export default useStyles
