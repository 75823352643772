import { StyleSheet } from 'react-native'
import { useSecondaryBackground } from '@webstollen/react-native/lib/hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    infoWrapper: {
      backgroundColor: useSecondaryBackground(),
      borderRadius: 16,
      margin: 8,
      padding: 8,
    },
    dataWrapper: {},
  })
}

export default useStyles
