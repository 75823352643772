import { isDevice } from 'expo-device'
import * as Notifications from 'expo-notifications'
import { Platform } from 'react-native'
import * as H from 'history'
import { UseNotificationCounter } from '../NotificationsCounterProvider'
async function registerForPushNotificationsAsync() {
  let token: string | undefined
  if (Platform.OS !== 'web') {
    if (isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync()
      let finalStatus = existingStatus
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync()
        finalStatus = status
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!')
        return null
      }
      token = (await Notifications.getExpoPushTokenAsync()).data
    } else {
      alert('Must use physical device for Push Notifications')
    }

    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      })
    }
  }
  return token
}

export type NotificationStateType = {
  title: string
  description: string
  route: string
  visible: boolean
}

export default registerForPushNotificationsAsync

export const registerNotificationListener = (
  history: H.History,
  setNotifyState: React.Dispatch<React.SetStateAction<NotificationStateType>>
) => {
  const addCounter = UseNotificationCounter()
  console.log('addcounter: ', addCounter)

  Notifications.addNotificationResponseReceivedListener((response) => {
    console.log('respone' + response)
    const obj: { path: string; type: string } = response.notification.request.content.data as {
      path: string
      type: string
    }
    const shopName = response.notification.request.content.subtitle

    const orderId = response.notification.request.content.data.id ?? ''
    const invitationCode = response.notification.request.content.data.invitationCode
    console.debug(`NotificationType: ${obj.type}`)
    switch (obj.type) {
      case 'order':
        let path = '/shop/' + shopName + '/orders'
        if (orderId) path += '/' + orderId
        history.push(path)
        break
      case 'company:invitation:received':
        if (invitationCode) {
          history.push(`settings/accountOverview/${invitationCode}`)
        }
        break
      default:
        history.push(obj.path)
    }
    addCounter()
  })

  //show Dialog when App is in foreground
  Notifications.addNotificationReceivedListener((notification) => {
    console.log('notification' + notification)
    const obj: { path: string; type: string } = notification.request.content.data as { path: string; type: string }
    const shopName = notification.request.content.subtitle
    const orderId = notification.request.content.data.id ?? ''
    const invitationCode = notification.request.content.data.invitationCode

    switch (obj.type) {
      case 'order':
        obj.path = '/shop/' + shopName + '/orders'
        if (orderId) obj.path += '/' + orderId
        break
      case 'company:invitation:received':
        if (invitationCode) {
          history.push(`settings/accountOverview/${invitationCode}`)
        }
        break
      default:
        history.push(obj.path)
    }

    setNotifyState({
      title: notification.request.content.title,
      description: notification.request.content.body,
      route: obj.path,
      visible: true,
    })
    addCounter()
  })
}
