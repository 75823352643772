import React from 'react'
//import UpsellingView from './UpsellingView'
// import useDeviceType from '../../../../hooks/useDeviceType'
//import UpsellingDialogBody from '../../components/UpsellingDialogBody'
//import { ShowUpsellingDialog } from '../../../../hooks/UpsellingDialogProvider'
import UnderConstruction from '../../../../components/UnderConstruction'

const Main = () => {
  //const deviceType = useDeviceType()
  //const toggleDialog = ShowUpsellingDialog()

  //if (deviceType !== 'mobile') return <UpsellingView showIntervalSwitch={true} />

  //TODO: build own component
  return (
    <UnderConstruction />
    //<UpsellingDialogBody closeDialog={() => toggleDialog} />
    // <View>
    //   <View style={styles.wrapper}>
    //     <View style={styles.wrapperTitleText}>
    //       <ColoredText>{t('shop.upselling.body.hello')}</ColoredText>
    //       <ColoredText style={styles.messageToCustomer}>{t('shop.upselling.body.messageToCustomer')}</ColoredText>
    //     </View>
    //     <View style={styles.imageWrapper}>
    //       <TouchableOpacity>
    //         {Platform.select({
    //           web: (
    //             <img
    //               src={ImagePro12}
    //               style={{
    //                 height: 320,
    //                 width: 160,
    //               }}
    //               alt="Twelve Months Pro"
    //             />
    //           ),
    //           default: <Image source={ImagePro12} />,
    //         })}
    //       </TouchableOpacity>
    //       <TouchableOpacity>
    //         {Platform.select({
    //           web: (
    //             <img
    //               src={ImageBusiness12}
    //               style={{
    //                 height: 320,
    //                 width: 160,
    //               }}
    //               alt="Twelve Months Business"
    //             />
    //           ),
    //           default: <Image source={ImageBusiness12} />,
    //         })}
    //       </TouchableOpacity>
    //       <TouchableOpacity>
    //         {Platform.select({
    //           web: (
    //             <img
    //               src={ImageBusines1}
    //               style={{
    //                 height: 320,
    //                 width: 160,
    //               }}
    //               alt="Twelve Months Pro"
    //             />
    //           ),
    //           default: <Image source={ImageBusines1} />,
    //         })}
    //       </TouchableOpacity>
    //     </View>
    //     <View style={styles.buttonWrapper}>
    //       <TouchableOpacity style={styles.buttonYes}>
    //         <ColoredText>{t('shop.upselling.body.adjustNow')}</ColoredText>
    //       </TouchableOpacity>
    //       <TouchableOpacity style={styles.buttonNo} onPress={() => history.push('/dashboard')}>
    //         <ColoredText>{t('shop.upselling.body.noThanks')}</ColoredText>
    //       </TouchableOpacity>
    //     </View>
    //   </View>
    // </View>
  )
}

export default Main
