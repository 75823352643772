import i18n, { LanguageDetectorModule } from 'i18next'

import { initReactI18next } from 'react-i18next'

import * as Localization from 'expo-localization'

//import JavascriptTimeAgo from 'javascript-time-ago'
//import enLocale from 'javascript-time-ago/locale/en'
//import deLocale from 'javascript-time-ago/locale/de'

import numbro from 'numbro'

import deDE from 'numbro/languages/de-DE'
import enGB from 'numbro/languages/en-GB'

import moment from 'moment'
import 'moment/locale/de'

import de from './de.json'
import en from './en.json'
import { Languages } from '../types/language'
import { getTranslation } from '@webstollen/react-native/lib/hooks/useTranslation'

numbro.registerLanguage({
  ...deDE,
  languageTag: 'de',
  abbreviations: {
    thousand: 'Tsd.',
    million: 'Mill.',
    billion: 'Mrd.',
    trillion: 'Bill.',
  },
  currencyFormat: {
    thousandSeparated: true,
    spaceSeparated: true,
    mantissa: 2,
  },
})

numbro.registerLanguage({
  ...enGB,
  languageTag: 'en',
  currencyFormat: {
    thousandSeparated: true,
    spaceSeparated: true,
    mantissa: 2,
  },
})

//JavascriptTimeAgo.locale(enLocale)
//JavascriptTimeAgo.locale(deLocale)

const resources = {
  [Languages.DE]: {
    translation: de,
  },
  [Languages.EN]: {
    translation: en,
  },
}

const detector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => {
    const language = Localization.locale.split('-')[0].split('_')[0]

    numbro.setLanguage(language)

    moment.locale(language)
    return language
  },
  init: () => undefined,
  cacheUserLanguage: () => undefined,
}

i18n.use(detector)

i18n.use(initReactI18next).init({
  resources: resources,
  whitelist: [Languages.DE, Languages.EN],
  fallbackLng: Languages.DE,
  // debug: process.env.NODE_ENV !== 'production',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    useSuspense: true,
  },
})

i18n.on('languageChanged', (lng) => {
  numbro.setLanguage(lng)
  moment.locale(lng)
})

getTranslation({
  tFunction: i18n.t.bind(i18n),
  i18n,
})

export default i18n
