import { StyleSheet } from 'react-native'

import { useHighlightColor, useTextColor } from '@webstollen/react-native/lib/hooks/useColors'
import { useTheme } from '@webstollen/react-native/lib/utils/types/theme'
import { Themes } from '@webstollen/react-native/lib/types/theme'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  const highlightColor = useHighlightColor()
  return StyleSheet.create({
    listItemAlternate: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
    },

    listItem: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent2 : colors.lightBgTransparent2,
      flex: 1,
      padding: 10,
      marginBottom: 5,
      paddingLeft: 20,
      borderRadius: 10,
      justifyContent: 'space-between',
      minHeight: 50,
      alignItems: 'center',
      flexDirection: 'row',
    },

    itemWrapper: {
      flex: 1,
    },

    largeText: {
      fontSize: 16,
    },

    leftSide: {
      flex: 1,
      paddingRight: 5,
    },

    rightSide: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    name: {
      height: 45,
      justifyContent: 'center',
    },

    total: {
      textAlign: 'center',
      fontSize: 14,
      color: highlightColor,
    },

    statusIconWrapper: {
      padding: 3,
      height: 25,
      borderRadius: 12.5,
      justifyContent: 'center',
      alignItems: 'center',
      width: '30%',
    },

    orderText: {
      color: useTextColor(),
    },

    rightIcon: {
      margin: 8,
    },
  })
}

export default useStyles
