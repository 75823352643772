import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { BookingAmounts, Bundle, Interval } from '../../types/booking'
import { amountIsMore, bundlePrice, calculateAmountLeftToPay, calculateMwSt } from '../../utils/booking'
import useDocument from '../useDocument'
import useCurrentBundleBooking from './useCurrentBundleBooking'

/** Calculates the expected amounts as netto, mwst, credit, nettoWithCredit and total for given bundle and interval
 *
 * @param bundle
 * @param interval
 * @returns {{netto: Amount, mwst: Amount, total: Amount, credit: Amount | null, nettoWithCredit: Amount | null}}
 */
const useBookingAmounts = (bundle: Bundle, interval: Interval): BookingAmounts => {
  const bundleDoc = useDocument<Record<string, any>>('bundle', bundle)
  const { company } = useContext(SessionContext)
  const countryCode = company?.billingAddress.country || 'DE'
  const currentBooking = useCurrentBundleBooking()
  if (
    currentBooking &&
    currentBooking.price &&
    amountIsMore(bundlePrice(bundleDoc?.price, interval), bundlePrice(currentBooking?.price, currentBooking?.interval))
  ) {
    console.debug(
      `Amount is more ${bundlePrice(bundleDoc?.price, interval).value} and ${
        bundlePrice(currentBooking?.price, currentBooking?.interval).value
      }`
    )
    const amountLeft = calculateAmountLeftToPay(
      bundlePrice(currentBooking.price, currentBooking.interval),
      bundlePrice(bundleDoc.price, interval),
      currentBooking?.interval || 'month',
      new Date(),
      currentBooking?.endDate?.toDate() || new Date()
    )
    const mwstAmount = calculateMwSt(amountLeft.amountLeftTotal, countryCode)
    if (amountLeft.amountLeft.value === '0.00') {
      return {
        netto: bundlePrice(bundleDoc.price, interval),
        credit: null,
        nettoWithCredit: null,
        mwst: mwstAmount.mwst,
        total: mwstAmount.totalAmount,
      }
    }
    return {
      netto: bundlePrice(bundleDoc.price, interval),
      credit: amountLeft.amountLeft,
      nettoWithCredit: mwstAmount.amount,
      mwst: mwstAmount.mwst,
      total: mwstAmount.totalAmount,
    }
  } else {
    const mwstAmount = calculateMwSt(bundlePrice(bundleDoc?.price, interval), countryCode)
    return {
      netto: mwstAmount.amount,
      mwst: mwstAmount.mwst,
      total: mwstAmount.totalAmount,
      credit: null,
      nettoWithCredit: null,
    }
  }
}

export default useBookingAmounts
