export const FUNCTIONS_REGION = 'europe-west3'

/**
 * OAuth Request gets outdated, after x minutes.
 **/
export const OAUTH_STATE_LIFETIME = 3

export const SCHEDULER_CHECK_BOOKINGS_SCHEDULE = '0 */12 * * *'

/**
 * Time in days after that company account should be blocked if there is no payment for repeated booking
 */
export const BOOKING_TOLERANCE_REPEATED_IN_DAYS = 7

/**
 * Time in days after that company account should be blocked if there is no payment for first booking
 */
export const BOOKING_TOLERANCE_FIRST_IN_DAYS = 14

/**
 * Time in days after that reminder mail should be send to notify about missing payment
 */
export const BOOKING_PAYMENT_REMINDER_AFTER_DAYS = 4

/**
 * Vat amount for 'de'
 */
export const VAT_AMOUNT_IN_PERCENT_DEFAULT = 19

/**
 * Vat amount for EU
 */
export const VAT_AMOUNT_IN_PERCENT_EU = 19

/**
 * Vat amount for non EU
 */
export const VAT_AMOUNT_IN_PERCENT_NOT_EU = 0

/**
 * Part id for dash.bar pro
 */
export const SEVDESK_PART_ID_DASHBAR_PRO = 4651062

/**
 * Part id for dash.bar business
 */
export const SEVDESK_PART_ID_DASHBAR_BUSINESS = 4651059

/**
 * Contact person from webstollen
 */
export const SEVDESK_CONTACT_PERSON_ID = 312392

/**
 * Payment Method abocloud
 */
export const SEVDESK_PAYMENT_METHOD_ID_ABOCLOUD = 55502

/**
 * CostCentre dashbar
 */
export const SEVDESK_COST_CENTRE_ID_DASHBAR = 22578

/**
 * CostCentre dashbar
 */
export const SEVDESK_TAX_SET_ID_EU = 23088

/**
 * CostCentre dashbar
 */
export const SEVDESK_TAX_SET_ID_NOT_EU = 23129

/**
 * CostCentre dashbar
 */
export const SEVDESK_TAX_SET_ID_DEFAULT = 40966

/**
 * Trial length in days
 */
export const TRIAL_IN_DAYS = 14
