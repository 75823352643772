import moment from 'moment'
import { useTranslation } from 'react-i18next'

const useIntervals = (origin: 'dashboard' | 'page' = 'dashboard') => {
  const { t } = useTranslation()

  // const filterKey = `${origin}.intervalFilter` as 'dashboard.intervalFilter' | 'page.intervalFilter'
  // const { company } = useContext(SessionContext)
  // const [hasExtendedFilters, setHasExtendedFilters] = useState(true)
  const hasExtendedFilters = true
  // useEffect(
  //   () => setHasExtendedFilters(company.authorizations && (company.authorizations[filterKey] as boolean)),
  //   [company.authorizations]
  // )

  return [
    {
      label: t('shop.widgets.interval.today'),
      value: 'D1',
      active: hasExtendedFilters,
    },
    {
      label: t('shop.widgets.interval.7days'),
      value: 'D7',
      active: hasExtendedFilters,
    },
    {
      label: t('shop.widgets.interval.30days'),
      value: 'D30',
      active: true,
    },
    {
      label: t('shop.widgets.interval.90days'),
      value: 'D90',
      active: hasExtendedFilters,
    },

    {
      label: 'Q1',
      value: 'Q1',
      active: hasExtendedFilters,
    },
    {
      label: 'Q2',
      value: 'Q2',
      active: hasExtendedFilters,
    },
    {
      label: 'Q3',
      value: 'Q3',
      active: hasExtendedFilters,
    },
    {
      label: 'Q4',
      value: 'Q4',
      active: hasExtendedFilters,
    },
    {
      label: t('shop.widgets.interval.thisMonth'),
      value: 'M' + parseInt(moment().format('M')),
      active: hasExtendedFilters,
    },
    {
      label: '' + (parseInt(moment().format('Y')) - 1),
      value: 'Y' + (parseInt(moment().format('Y')) - 1),
      active: hasExtendedFilters,
    },
    {
      label: '' + moment().format('Y'),
      value: 'Y' + moment().format('Y'),
      active: hasExtendedFilters,
    },
    // {
    //   label: t('shop.widgets.interval.individual'),
    //   value: 'I',
    //   active: hasExtendedFilters,
    // },
  ].filter(({ active }) => active)
}
export default useIntervals
