import React from 'react'

import { useLocation } from '@webstollen/react-native/lib/react-router'

import HeaderWrapper from '../../../layout/Header/Wrapper'
import FavoriteButton from '../FavoriteButton'

type PropsT = {
  title: string
  subtitle?: string
}

const FavoriteHeader = ({ title, subtitle }: PropsT) => {
  const { pathname } = useLocation()

  const url = pathname.replace(/^\/+/, '')

  return (
    <HeaderWrapper title={title} additionalControls={<FavoriteButton title={title} subtitle={subtitle} url={url} />} />
  )
}

export default FavoriteHeader
