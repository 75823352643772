import React from 'react'
import { Image, Platform, ScrollView, TouchableOpacity, View } from 'react-native'
import useStyles from '../pages/Main/styles'
import ColoredText from '@webstollen/react-native/lib/components/CustomText/ColoredText'
import { News } from '../../../types/Stream'

import NewsBoxBody from './newsBoxComponents/newsBoxBody'
import { Timestamp } from '@firebase/firestore-types'
import { useHistory } from '@webstollen/react-native/lib/react-router'
import moment from 'moment'

const GetHashtagsJSXWeb = (hashtags: Array<string>) => {
  const styles = useStyles()
  const history = useHistory()
  return (
    <View style={styles.hashtagWrapperWeb}>
      {hashtags.map((value) => {
        return (
          <TouchableOpacity
            key={Math.floor(Math.random() * 100000)}
            onPress={() => history.push('/filteredNews/' + value)}
          >
            <ColoredText style={styles.hashtagButton}>{'#' + value}</ColoredText>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}
const GetHashtagsJSXMobile = (hashtags: Array<string>) => {
  const styles = useStyles()
  const history = useHistory()

  return (
    <View style={styles.hashtagWrapperMobile}>
      <ScrollView horizontal={true} showsVerticalScrollIndicator={false}>
        {hashtags.map((value) => {
          return (
            <TouchableOpacity
              key={Math.floor(Math.random() * 100000)}
              onPress={() => history.push('/filteredNews/' + value)}
            >
              <ColoredText style={styles.hashtagButton}>{'#' + value}</ColoredText>
            </TouchableOpacity>
          )
        })}
      </ScrollView>
    </View>
  )
}

export const UseTimeStamp = (timestamp: Timestamp) => {
  const date = timestamp.toDate()
  const now = new Date()
  const passedMinutes = Math.floor((now.getTime() - date.getTime()) / 1000 / 60)
  if (passedMinutes < 60) return Math.floor(passedMinutes) + 'Min'
  if (passedMinutes < 1440) return Math.floor(passedMinutes / 60) + ' Std'
  return moment(timestamp.toDate()).fromNow()
  // return ('0' + date.getUTCDate()).slice(-2) + '.' + ('0' + (date.getUTCMonth() + 1)).slice(-2)
}

const NewsBox = ({ text, hashtags, mediaUrl, profileImageUrl, userName, timestamp, id }: News) => {
  const styles = useStyles()
  //display picture with uri object :
  const profilePictureUrl = { uri: profileImageUrl }

  return (
    <View style={styles.newsBoxWrapper}>
      <View style={styles.newsBoxHeader}>
        <Image source={profilePictureUrl} style={styles.profilePicture} />
        <ColoredText style={styles.headerText}>{userName}</ColoredText>
        <ColoredText style={styles.timestamp}>~ {timestamp && UseTimeStamp(timestamp)}</ColoredText>
      </View>

      <NewsBoxBody key={id} text={text} mediaUrl={mediaUrl} />

      {Platform.select({
        web: hashtags && GetHashtagsJSXWeb(hashtags),
        default: hashtags && GetHashtagsJSXMobile(hashtags),
      })}
    </View>
  )
}

export default NewsBox
